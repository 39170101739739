import React, {Component} from 'react'
import {Button, ButtonBase, Input, Modal, Typography} from '@material-ui/core'
import {X} from 'react-feather'

import {isEmpty , values} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {getErrorFromCode} from 'avoapp-react-common/dist/utils'

import '../assets/scss/AmbassadorUseCodeModal.scss'

class AmbassadorsUseCodeModal extends Component {
    constructor() {
        super()

        this.state = {
            ambassadorCode: ''
        }
    }

    handleChangeAmbassadorCode = (event) => {
        const {value} = event.target

        this.setState({ambassadorCode: value})
    }

    handleCreateAmbassadorCodeUsage = () => {
        const {selectedEntityID} = this.props
        const {ambassadorCode} = this.state

        this.props.dispatch(RESOURCES.ambassadorCodeUsages.create({
            entity_id: selectedEntityID,
            ambassador_code: ambassadorCode
        }))
    }

    render() {
        const {isOpen, handleCloseActivateCodeModal, isLoading, errors} = this.props
        const {ambassadorCode} = this.state
        
        return (
            <Modal open={isOpen} onClose={handleCloseActivateCodeModal}>
                <div id="ambassador-use-code-main-div">
                    <div className="container">
                        <div className="header">
                            <ButtonBase className='close-button' onClick={handleCloseActivateCodeModal}>
                                <X className='close-button-icon'/>
                            </ButtonBase>
                        </div>
                        <div className="content">
                            <div className="texts">
                                <Typography className="title">
                                    Ne bucurăm că folosești aplicația Monitor Dosare!
                                </Typography>
                                <Typography className='subtitle'>
                                    În această secțiune ai posibilitatea de a introduce 
                                    codul primit de la un ambasador Monitor Dosare. 
                                </Typography>
                                <Typography className='subtitle'>
                                    Odată validat, acest cod va genera un discount 
                                    automat de 50% la prima factură emisă în contul tău.
                                </Typography>
                            </div>
                            <div className="code-div">
                                {!isEmpty(errors) && values(errors).map((errorCategories) =>
                                    errorCategories.map((error, index) => (
                                        <div key={index} className='error-div'>
                                            <Typography className='error-text'>
                                                {getErrorFromCode(error, 'ambassadorCodeUsages')}
                                            </Typography>
                                        </div>
                                    ))
                                )}
                                <Typography className='use-code-text'>
                                    Introdu codul de ambasador pe care dorești să îl folosești
                                </Typography>
                                <Input
                                    placeholder='Codul de ambasador'
                                    onChange={(event)=>{this.handleChangeAmbassadorCode(event)}}
                                    className='ambassador-code-input' 
                                    value={ambassadorCode}
                                    autoFocus
                                />
                                <Button
                                    size="large"
                                    variant="contained"
                                    onClick={this.handleCreateAmbassadorCodeUsage}
                                    disabled={isLoading || isEmpty(ambassadorCode)}
                                >
                                    <Typography>Folosește codul</Typography>
                                </Button>
                            </div>
                        </div>
                        <div className="footer" />
                    </div>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = function(state) {
    return {
        selectedEntityID: state.localConfigs.selectedEntityID,
        isLoading: state.ambassadorCodeUsages.isLoading,
        errors: state.ambassadorCodeUsages.errors
    }
}

export default connect(mapStateToProps)(AmbassadorsUseCodeModal)
