import {toast} from 'react-toastify'
import {push} from 'connected-react-router'
import {takeEvery, put, select} from 'redux-saga/effects'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {setCurrent} from 'avoapp-react-common/dist/redux/partySubscriptions'
import {getErrorFromCode} from 'avoapp-react-common/dist/utils'

const {partySubscriptions: resource} = RESOURCES

function* handleCreatePartySubscriptionSuccess(response) {
    const redirectuRL = '/clients/' + response.payload.client_id
    const message = 'Monitorizarea a început'
    toast.success(message)

    yield put(push(redirectuRL))
}

function* handleCreatePartySubscriptionFail(response) {
    if (response.payload.code === 'plan_error') {
        yield put(push('/limit-reached'))
    } else {
        response.payload.non_field_errors.map((error) => toast.error(getErrorFromCode(error), 'partySubscriptions'))
    }
}

function* handleUpdatePartySubscriptionSuccess(response) {
    yield put(setCurrent(response.payload))
}

function* handleDestroyPartySubscriptionSuccess() {
    let selectedEntityID = yield select((state) => state.localConfigs.selectedEntityID)
    
    yield put(RESOURCES.entityReports.retrieve(selectedEntityID))

    toast.success('Abonarea la date a fost ștearsă')
}

export function* saga() {
    yield takeEvery(resource.actions.create.success, handleCreatePartySubscriptionSuccess)
    yield takeEvery(resource.actions.create.fail, handleCreatePartySubscriptionFail)
    yield takeEvery(resource.actions.update.success, handleUpdatePartySubscriptionSuccess)
    yield takeEvery(resource.actions.destroy.success, handleDestroyPartySubscriptionSuccess)
}
