import React, {Component} from 'react'
import {Button, CircularProgress, MenuItem, Select, Switch, Typography} from '@material-ui/core'
import {MuiPickersUtilsProvider, TimePicker} from '@material-ui/pickers'
import {ArrowDropDown, NotificationsNone} from '@material-ui/icons'
import DateFnsUtils from '@date-io/date-fns'
import {Form, Formik} from 'formik'

import {isEmpty} from 'lodash'
import {lightFormat, setHours, setMinutes, setSeconds} from 'date-fns'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import {colors} from '../../assets/styles/colors'

const upcomingIntervalArray = [
    {label: '1 oră', value: '01:00:00'},
    {label: '2 ore', value: '02:00:00'},
    {label: '3 ore', value: '03:00:00'},
    {label: '4 ore', value: '04:00:00'},
    {label: '8 ore', value: '08:00:00'},
    {label: '12 ore', value: '12:00:00'},
    {label: '24 ore', value: '1 00:00:00'},
    {label: '48 ore', value: '2 00:00:00'},
    {label: '72 ore', value: '3 00:00:00'},
    {label: '7 zile', value: '7 00:00:00'},
    {label: '10 zile', value: '10 00:00:00'},
    {label: '14 zile', value: '14 00:00:00'}
]

class Notifications extends Component {
    disableButton = (values) => {
        const {usersPreferences, isLoadingUsersPreferences} =this.props

        if(values.startTime && values.endTime && values.upcomingInterval) {
            return isLoadingUsersPreferences || ( 
                usersPreferences.start_time === lightFormat(values.startTime, 'HH:mm:ss') &&
                usersPreferences.end_time === lightFormat(values.endTime, 'HH:mm:ss') &&
                usersPreferences.upcoming_interval === values.upcomingInterval 
            )
        } 
        
        return false 
    }

    getDateFromInterval = (interval) => {
        let intervalSplit = interval.split(':')
        let dateWithSeconds = setSeconds(new Date(), intervalSplit[2])
        let dateWithMinutes = setMinutes(dateWithSeconds,intervalSplit[1])
        let finalDate = setHours(dateWithMinutes,intervalSplit[0])

        return new Date(finalDate)
    }

    render() {
        const {usersPreferences, isLoadingUsersPreferences} = this.props

        return (
            <div className="card-large">
                {!isLoadingUsersPreferences && !isEmpty(usersPreferences) ? (
                    <Formik
                        initialValues={{
                            startTime: this.getDateFromInterval(usersPreferences.start_time),
                            endTime: this.getDateFromInterval(usersPreferences.end_time),
                            upcomingInterval: usersPreferences.upcoming_interval
                        }}
                        onSubmit={(values) => {
                            this.props.dispatch(RESOURCES.usersPreferences.update(
                                {
                                    start_time: lightFormat(values.startTime, 'HH:mm:ss'),
                                    end_time: lightFormat(values.endTime, 'HH:mm:ss'),
                                    upcoming_interval: values.upcomingInterval
                                }, 
                                'me'
                            ))
                        }}>
                        {({handleChange, values, handleSubmit, setFieldValue}) => (
                            <Form>
                                <div className="card-main-div" id='notifications-settings-card'>
                                    <div className="card-header">
                                        <div className="card-header-title-div">
                                            <div className="icon-div"><NotificationsNone className="icon" /></div>
                                            <Typography className="card-header-title">Setări notificări</Typography>
                                        </div>
                                        <Button
                                            variant="contained"
                                            onClick={handleSubmit}
                                            className="card-header-button"
                                            disabled={this.disableButton(values)}
                                            style={{
                                                backgroundColor: this.disableButton(values) ? 
                                                    colors.lightGrey : colors.orange
                                            }}
                                        >
                                            <Typography 
                                                className='card-header-button-text' 
                                                style={{color: this.disableButton(values) ? colors.darkGrey : 'white'}}
                                            >
                                                Salvează modificările
                                            </Typography>
                                        </Button>
                                    </div>
                                    <div className="card-body">
                                        <div className='card-body-element'>
                                            <div className='notifications-settings-sm-div'>
                                                <div className='email-notifications-div'>
                                                    <Typography className='notification-type-text'>
                                                        Notificări prin email
                                                    </Typography>
                                                    <Switch
                                                        checked={usersPreferences.via_email}
                                                        color='primary'
                                                        onChange={() => {
                                                            this.props.dispatch(RESOURCES.usersPreferences.update(
                                                                {via_email:  !usersPreferences.via_email},
                                                                'me'
                                                            ))
                                                        }}
                                                    />
                                                </div>
                                                <div className='push-notifications-div'>
                                                    <Typography className='notification-type-text'>
                                                        Notificări pe telefonul mobil 
                                                    </Typography>
                                                    <Switch
                                                        checked={usersPreferences.via_push}
                                                        color='primary'
                                                        onChange={() => {
                                                            this.props.dispatch(RESOURCES.usersPreferences.update(
                                                                {via_push: !usersPreferences.via_push},
                                                                'me'
                                                            ))
                                                        }}
                                                        
                                                    />
                                                </div>
                                            </div>                                                    
                                        </div>
                                        <div className='card-body-element'>
                                            <div className='notifications-settings-sm-div'>
                                                <div className='notifications-settings-title-div'>
                                                    <Typography className='notifications-settings-title-text'>
                                                        În ce interval orar dorești să primești notificări?
                                                    </Typography>
                                                </div>
                                                <div className='notifications-settings-times-div'>
                                                    <div className="time-input">
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <TimePicker
                                                                className='input'
                                                                value={values.startTime} 
                                                                format="HH:mm"
                                                                onChange={(newTime)=>{
                                                                    setFieldValue('startTime', newTime)
                                                                }}
                                                                cancelLabel='Renunță'
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </div>
                                                    <div className="time-input">
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <TimePicker 
                                                                className='input'
                                                                value={values.endTime} 
                                                                format="HH:mm"
                                                                onChange={(newTime)=>{
                                                                    setFieldValue('endTime', newTime)
                                                                }}
                                                                cancelLabel='Renunță'
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='card-body-element'>
                                            <div className='notifications-settings-sm-div'>
                                                <div className='notifications-settings-title-div'>
                                                    <Typography className='notifications-settings-title-text'>
                                                        Cu cât timp înaintea unei ședințe dorești să fi notificat ?
                                                    </Typography>
                                                </div>
                                                <div className='notifications-settings-interval-div'>
                                                    <div className='select-div'>
                                                        <Select
                                                            value={values.upcomingInterval} 
                                                            style={{width:'100%'}}
                                                            IconComponent={() => 
                                                                <ArrowDropDown className='dropdown-icon' />
                                                            }
                                                            onChange={handleChange('upcomingInterval')}
                                                        >
                                                            {upcomingIntervalArray.map(interval => (
                                                                <MenuItem key={interval.value} value={interval.value}>
                                                                    {interval.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                ) : (
                    <div className="center-loader">
                        <CircularProgress className="circular-progress" thickness={5} color="primary" />
                    </div>
                )}
            </div>
        )
    }
}

const mapStateToProps= function (state) {
    return {
        usersPreferences: state.usersPreferences.data,
        isLoadingUsersPreferences: state.usersPreferences.isLoading
    }
}

export default connect(mapStateToProps)(Notifications)