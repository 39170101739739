import React, {useCallback, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'

import {Button, FormControl, Input, InputLabel, Typography} from '@material-ui/core'
import {Formik} from 'formik'

import {isEmpty, toString, values} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {getErrorFromCode} from 'avoapp-react-common/dist/utils'
import {connect} from 'react-redux'

import {DeletePartyDialog} from '../components/ProjectDetails/Dialogs'

import '../assets/scss/ManualAdd.scss'

export const ManualPartyEdit = ({
    party, 
    match: {params: {partyID}},
    isLoading,
    partiesErrors,
    retrieveParty,
    updateParty,
    deleteParty,
    currentSubscription
}) => {
    const [showDeletePartyDialog, setShowDeletePartyDialog] = useState(false)

    useEffect(() => {
        if((isEmpty(party) || toString(party.id) !== toString(partyID)) && !isLoading) {
            retrieveParty()
        }
    }, [isLoading, party, partyID, retrieveParty])

    const toggleDeletePartyDialog = useCallback(() => {setShowDeletePartyDialog((state) => !state)},[])

    const handleDeleteParty = useCallback(() => {
        deleteParty(party)
        toggleDeletePartyDialog()
    }, [toggleDeletePartyDialog, deleteParty, party])

    return (
        <div>
            <div className="navbar" id="navbar-manual-add">
                <div className="navbar-left">
                    <Typography className="navbar-title">Editează parte</Typography>
                </div>
                <div className="navbar-right">
                    <Button className="delete-button" onClick={toggleDeletePartyDialog}>
                        <Typography className="button-title">Șterge partea</Typography>
                    </Button>
                    <DeletePartyDialog
                        showDeletePartyDialog={showDeletePartyDialog}
                        partyToDelete={party}
                        handletoggleDeletePartyDialog={toggleDeletePartyDialog}
                        handleDeleteParty={handleDeleteParty}
                    />
                    {currentSubscription.id && (
                        <Link style={{textDecoration: 'none'}} to={`/subscriptions/${currentSubscription.id}`}>
                            <Button className="cancel-button">
                                <Typography className="button-title">Renunță</Typography>
                            </Button>
                        </Link>
                    )}
                </div>
            </div>
            <div id="manual-add-main-div">
                {!isEmpty(party) ? (
                    <Formik
                        initialValues={{name: party.name, role: party.role}}
                        onSubmit={(values) => updateParty({id: partyID, name: values.name, role: values.role})}
                    >
                        {({handleChange, handleSubmit, values}) => (
                            <div className='form'>  
                                {!isEmpty(partiesErrors) && partiesErrors.map((errorCategories) =>
                                    errorCategories.map((error, index) => (
                                        <div key={index} className='error-div'>
                                            <Typography className='error-text'>
                                                {getErrorFromCode(error, 'parties')}
                                            </Typography>
                                        </div>
                                    ))
                                )}
                                <div className="section">
                                    <div className="section-content">
                                        <FormControl className='input-form-control'>
                                            <InputLabel shrink={!isEmpty(values.name)}>
                                                Nume parte *
                                            </InputLabel>
                                            <Input
                                                value={values.name}
                                                onChange={handleChange('name')}
                                                className="input"
                                            />
                                        </FormControl>
                                        <FormControl className='input-form-control'>
                                            <InputLabel shrink={!isEmpty(values.role)}>
                                                Rol parte *
                                            </InputLabel>
                                            <Input
                                                value={values.role}
                                                onChange={handleChange('role')}
                                                className="input"
                                            />
                                        </FormControl>
                                        <Typography className="disclaimer">
                                            * - câmpuri obligatorii
                                        </Typography>
                                    </div>
                                </div>
                                <div className="buttons-div">
                                    <Button
                                        onClick={handleSubmit}
                                        disabled={isLoading || isEmpty(values.name) || isEmpty(values.role)}
                                        className='submit-button'
                                        variant='contained'
                                        size='large'
                                        fullWidth
                                    >
                                        <Typography className="button-title">Salvează parte</Typography>
                                    </Button>
                                </div>
                            </div>
                        )}
                    </Formik>
                ) : null}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    party: state.parties.currentParty,
    isLoading: state.parties.isLoading,
    partiesErrors: values(state.parties.errors),
    currentSubscription: state.subscriptions.currentSubscription
})

const mapDispatchToProps = (dispatch, props) => {
    const {partyID} = props.match.params

    return {
        retrieveParty: () => dispatch(RESOURCES.parties.retrieve(partyID)),
        deleteParty: (party) => dispatch(RESOURCES.parties.destroy(party)),
        updateParty: (values) => dispatch(RESOURCES.parties.update(values))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManualPartyEdit)
