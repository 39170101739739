import React, {Component} from 'react'
import {Modal, Typography, Button, ButtonBase, FormControl, InputLabel, Select, MenuItem} from '@material-ui/core'
import {ArrowDropDown} from '@material-ui/icons'
import {X, Plus} from 'react-feather'

import {values} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {replaceNullWithDash} from 'avoapp-react-common/dist/utils'
import {CRUDTypes, permissionRoles} from 'avoapp-react-common/dist/constants'

import '../assets/scss/AddPermission.scss'

class ProjectAddClient extends Component {
    constructor() {
        super()

        this.state = {
            selectedEntityProfileID: '',
            selectedRole: 'manager'
        }
    }

    handleAddPermission = () => {
        const {selectedEntityProfileID, selectedRole} = this.state
        const {project, handleCloseAddPermissionModal} = this.props

        let permissions = []
        let permissionsSubscriptions = []

        if (selectedRole === permissionRoles.manager.value) {
            permissions = [
                CRUDTypes.read.number,
                CRUDTypes.update.number,
                CRUDTypes.destroy.number
            ]
            permissionsSubscriptions = [
                CRUDTypes.create.number,
                CRUDTypes.read.number,
                CRUDTypes.update.number,
                CRUDTypes.destroy.number
            ]
        } else if(selectedRole === permissionRoles.internalCollaborator.value) {
            permissions = [CRUDTypes.read.number]
            permissionsSubscriptions = [CRUDTypes.read.number]
        }

        this.props.dispatch(
            RESOURCES.projectPermissions.create({
                project_id: project.id,
                entity_profile_id: selectedEntityProfileID,
                role: selectedRole,
                permissions: permissions,
                permissions_subscriptions: permissionsSubscriptions
            })
        )

        handleCloseAddPermissionModal()
    }

    render() {
        const {project, showAddPermissionModal, handleCloseAddPermissionModal, entityProfiles} = this.props
        const {selectedEntityProfileID, selectedRole} = this.state

        return (
            <Modal open={showAddPermissionModal} onClose={handleCloseAddPermissionModal}>
                <div className='add-permission-main-div'>
                    <div className="header">
                        <ButtonBase onClick={handleCloseAddPermissionModal} className='close-button'>
                            <X className='close-button-icon' />
                        </ButtonBase>
                    </div>
                    <div className="content">
                        <Typography className='title'>
                            Adaugă un manager sau un colaborator in proiectul{' '}
                            <span className='title-span'>{project.name}</span>
                        </Typography>
                        <FormControl className='styled-form-control'>
                            <InputLabel id="user-select">Utilizator</InputLabel>
                            <Select
                                labelId="user-select"
                                value={selectedEntityProfileID}
                                IconComponent={() =>
                                    <ArrowDropDown className='dropdown-icon' />
                                }
                                onChange={(event) => {
                                    this.setState({selectedEntityProfileID: event.target.value})
                                }}
                            >
                                {entityProfiles.map((profile) => (
                                    <MenuItem key={profile.id} value={profile.id}>
                                        {replaceNullWithDash(profile.last_name)}{' '}
                                        {replaceNullWithDash(profile.first_name)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl className='styled-form-control'>
                            <InputLabel id="role-select">Rol</InputLabel>
                            <Select
                                labelId="role-select"
                                value={selectedRole}
                                IconComponent={() =>
                                    <ArrowDropDown className='dropdown-icon' />
                                }
                                onChange={(event) => {
                                    this.setState({selectedRole: event.target.value})
                                }}
                            >
                                {values(permissionRoles).map((role) => (
                                    <MenuItem key={role.value} value={role.value}>
                                        {role.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <Button
                        className='action-button'
                        variant='contained'
                        size="large"
                        fullWidth
                        onClick={this.handleAddPermission}
                        disabled={!selectedEntityProfileID || !selectedRole}
                    >
                        <Typography className='button-text'>Adaugă acest utilizator</Typography>
                        <Plus size={16} className='button-icon' />
                    </Button>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = function (state) {
    const project = state.projects.currentProject
    const entityProfiles = values(state.entityProfiles.data)

    return {
        project: project,
        entityProfiles: entityProfiles,
        selectedEntityID: state.localConfigs.selectedEntityID,
        isLoading: state.projects.isLoading
    }
}
export default connect(mapStateToProps)(ProjectAddClient)