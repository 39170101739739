import {put, select, take, takeEvery} from 'redux-saga/effects'
import {toast} from 'react-toastify'

import {toLower, camelCase, find, values} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {permissionRoles} from 'avoapp-react-common/dist/constants'

import {
    ACTIONS as CLIENT_PERM_ACTIONS,
    saveCurrentClientPermissions
} from 'avoapp-react-common/dist/redux/clientPermissions'
import {getPermissionsFromNumbers} from 'avoapp-react-common/dist/utils'

const {clientPermissions: resource} = RESOURCES

function handleCreateClientPermissionSuccess(response) {
    const addedRole = permissionRoles[camelCase(response.payload.role)].label || response.role
    toast.success(`Un ${toLower(addedRole)} a fost adăugat cu succes.`)
}

function* handleGetCurrentClientPermissions(params) {
    let {entity_profile_id, client_id} = params.payload
    
    let permissions = {}
    let permissionsSubscriptions = {}
    let permissionsContactPersons = {}
    
    yield put(RESOURCES.clientPermissions.list({client_id: client_id}))
    yield take(RESOURCES.clientPermissions.actions.list.success)
    
    let currentClientPermissions = yield select((state) => 
        find(values(state.clientPermissions.data), (permission) => {            
            return permission.client_id === client_id && permission.entity_profile_id === entity_profile_id 
        })
    )

    if(currentClientPermissions) {
        permissions = getPermissionsFromNumbers(currentClientPermissions.permissions)
        permissionsSubscriptions = getPermissionsFromNumbers(currentClientPermissions.permissions_subscriptions)
        permissionsContactPersons = getPermissionsFromNumbers(currentClientPermissions.permissions_contact_persons)
    }

    yield put(saveCurrentClientPermissions(permissions, permissionsSubscriptions, permissionsContactPersons))
}

export function* saga() {
    yield takeEvery(resource.actions.create.success, handleCreateClientPermissionSuccess)
    yield takeEvery(CLIENT_PERM_ACTIONS.GET_CURRENT_CLIENT_PERMISSIONS.main, handleGetCurrentClientPermissions)
}
