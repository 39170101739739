import React, {Component} from 'react'
import {Typography, Button} from '@material-ui/core'
import {Link} from 'react-router-dom'

import {connect} from 'react-redux'

import '../assets/scss/TicketSuccess.scss'

class TicketSuccess extends Component {
    render() {
        const {user} = this.props

        return (
            <div id='ticket-success-main-div'>
                <div className='ticket-success-sm-div'>
                    <div className='check-image-div'>
                        <img
                            style={{width: '100%', height: 'auto'}}
                            src={require('../assets/images/check.svg').default}
                            alt=''
                        />
                    </div>
                    <Typography className='ticket-success-main-text'>
                        Tichetul a fost creat <br /> <span>cu succes!</span>
                    </Typography>
                    <div className='contact-details-div'>
                        <Typography className='details-main-text'>Vei fi contactat la următoarele date:</Typography>
                        <div className='contact-details-list'>
                            <Typography className='detail-text'>{user.last_name} {user.first_name}</Typography>
                            <Typography className='detail-text'>Email: {user.email}</Typography>
                            <Typography className='detail-text'>Tel: {user.phone}</Typography>
                        </div>
                    </div>
                    <Link className='to-projects-link' to='/projects' style={{textDecoration: 'none'}}>
                        <Button size="large" variant="contained" fullWidth>
                            <Typography className='to-projects-link-text'>Mergi la proiecte</Typography>
                        </Button>
                    </Link>
                </div>
            </div>
        )
    }
}

const mapStateToProps = function(state) {
    return {
        user: state.users.user
    }
}

export default connect(mapStateToProps)(TicketSuccess)