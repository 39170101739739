import React from 'react'
import {CircularProgress, Typography} from '@material-ui/core'

import '../assets/scss/LoadingMoreComponent.scss'

export default function LoadingMoreComponent({loadingText}) {
    return (
        <div className='loading-more-div'> 
            <Typography className='loading-more-text'>{loadingText}</Typography>
            <CircularProgress color="primary" size={15} thickness={5}/>
        </div>
    )
}
