import React, {Component} from 'react'
import {FormControl} from '@material-ui/core'
import {ArrowDropDown} from '@material-ui/icons'
import Select from 'react-select'

import {colors} from '../assets/styles/colors'

import '../assets/scss/InstitutionSelect.scss'

export default class InstitutionSelect extends Component {
    render() {
        const {label, value, institutionsArray, handleChange} = this.props

        return (
            <div id='institution-select'>
                <FormControl className="input-form-control">
                    <span className='institution-select-label'>{label}</span>
                    <Select
                        value={value}
                        options={institutionsArray}
                        onChange={(e) => handleChange(e)}
                        menuPortalTarget={document.querySelector('body')}
                        styles={selectStyles}
                        components={{
                            DropdownIndicator: () => <ArrowDropDown className='dropdown-icon' />,
                            IndicatorSeparator: () => null
                        }}
                    />
                </FormControl>
            </div>
        )
    }
}

const selectStyles = {
    valueContainer: (provided) => ({
        ...provided,
        padding: '2px 0'
    }),
    control: (provided) => ({
        ...provided,
        borderWidth: 0,
        borderBottomWidth: 1,
        borderRadius: 0,
        borderColor: colors.darkGrey
    })
}
