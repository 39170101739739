import React, {Component} from 'react'
import {Modal, Typography, Button, CircularProgress} from '@material-ui/core'

import {isEmpty, isNull, includes} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import '../assets/scss/SubscriptionIsSyncingModal.scss'

class SubscriptionIsSyncingModal extends Component {
    constructor() {
        super()

        this.state = {
            buttonNotClicked: true,
            didRetry: false
        }
    }

    componentDidMount = () => {
        const {showModal, project, subscription} = this.props
        
        if(showModal) {
            if(this.isSubscriptionDetails() && !isEmpty(subscription)) {
                setTimeout(() => {
                    this.props.dispatch(RESOURCES.subscriptions.retrieve(subscription.id))
                    this.props.dispatch(RESOURCES.parties.list({subscription_id: subscription.id}))
                    this.props.dispatch(RESOURCES.appeals.list({subscription_id: subscription.id}))
                    this.props.dispatch(RESOURCES.hearings.list({subscription_id: subscription.id}))
                    this.setState({didRetry: true})
                }, 3000)
            } else if(!isEmpty(project)) {
                setTimeout(() => {
                    this.props.dispatch(RESOURCES.subscriptions.list({project_id: project.id}))
                    this.props.dispatch(RESOURCES.parties.list({project_id: project.id}))
                    this.props.dispatch(RESOURCES.appeals.list({project_id: project.id}))
                    this.props.dispatch(RESOURCES.hearings.list({project_id: project.id}))
                    this.setState({didRetry: true})
                }, 3000)
            }
        }
    };

    handleClickButton = () => {
        this.setState({buttonNotClicked: false})
    }

    isSubscriptionDetails = () => includes(window.location.pathname, '/subscriptions')
    
    render() {
        const {showModal, syncingLitigationNumber} = this.props
        const {buttonNotClicked, didRetry} = this.state

        let isVisible = (showModal || (didRetry && !isNull(syncingLitigationNumber))) && buttonNotClicked

        return (
            <div>
                <Modal open={isVisible}>
                    <div className='subsctiption-is-syncing-modal'>
                        <div className="container">
                            {didRetry && !isNull(syncingLitigationNumber) ? (
                                <>
                                    <Typography className='sync-fail-text'>
                                        Dosarul nu a putut fi sincronizat momentan. <br /> 
                                        Te rugăm revino mai târziu
                                    </Typography>
                                    <Button
                                        onClick={this.handleClickButton}
                                        size="large"
                                        variant="contained"
                                        className='button'
                                    >
                                        <Typography>Am înțeles</Typography>
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Typography className='syncing-text'>
                                        {syncingLitigationNumber} este în curs de actualizare
                                    </Typography>
                                    <CircularProgress className="circular-progress" thickness={5} color="primary" />
                                </>
                            )}
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = function (state) {
    return {
        project: state.projects.currentProject,
        subscription: state.subscriptions.currentSubscription
    }
}

export default connect(mapStateToProps)(SubscriptionIsSyncingModal)