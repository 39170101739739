export const colors = {
    darkPurple: '#2D2D60',
    secondaryDarkPurple: '#232350',
    lightPurple: '#7373AC',
    orange: '#EB5D1F',
    lightOrange: 'rgba(235, 93, 31, 0.06)',
    darkGrey: '#8A8AA1',
    disabledButtonBackground: '#D8D8E6',
    lightGrey: '#E8E8F2',
    error: '#F62D2D',
    success: '#049E47'
}
