import {Typography} from '@material-ui/core'
import React, {useEffect} from 'react'

import {isEmpty} from 'lodash'

import {changeDateFormat} from 'avoapp-react-common/dist/utils'
import {connect} from 'react-redux'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import ActivityIndicatorComponent from '../components/ActivityIndicatorComponent'
import ResourceAddSuccess from '../components/ResourceAddSuccess'

function ManualAppealAddSuccess({
    currentSubscription,
    phase,
    appeal,
    isLoading,
    retrieveAppeal,
    retrievePhase
}) {
    useEffect(() => {
        if(isEmpty(appeal) && !isLoading) {
            retrieveAppeal()
        }
    }, [appeal, isLoading, retrieveAppeal, retrievePhase])
    
    if(isLoading) {
        return <ActivityIndicatorComponent />
    }

    if(!isEmpty(appeal)) {
        return (
            <ResourceAddSuccess 
                resourceText ="Calea de atac a fost adăugată"
                resourceDetails={<AppealDetails appeal={appeal}/>}
                nextText ='Vezi detalii dosar'
                nextUrl = {`/subscriptions/${currentSubscription.id}`}
                addMoreText ='Mai adaugă o cale de atac'
                addMoreUrl = {`/phases/${phase.id}/add-manual-appeal`}
            />
        )
    }
}

function AppealDetails({appeal}) {
    return (
        <div className='details-div'>
            <div className='details-list'>
                <Typography className='detail-text'>Data: <span>{changeDateFormat(appeal.date)}</span></Typography>
                <Typography className='detail-text'>Tip: <span>{appeal.type}</span></Typography>
                {appeal.party && <Typography className='detail-text'>Parte: <span>{appeal.party}</span></Typography>}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    currentSubscription: state.subscriptions.currentSubscription,
    phase: state.phases.currentPhase,
    appeal: state.appeals.currentAppeal,
    isLoading: state.appeals.isLoading
})

const mapDispatchToProps = (dispatch, props) => {
    const {appealID} = props.match.params

    return {
        retrieveAppeal: () => dispatch(RESOURCES.appeals.retrieve(appealID))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManualAppealAddSuccess)

