import React, {Component} from 'react'
import {Typography, Button, CircularProgress} from '@material-ui/core'
import {ChevronLeft} from '@material-ui/icons'
import {Link} from 'react-router-dom'

import {isEmpty, sortBy} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {selectPlan} from 'avoapp-react-common/dist/redux/billing'
import {toReadableDuration} from 'avoapp-react-common/dist/utils'
import {AppTypes} from 'avoapp-react-common/dist/constants'

import '../assets/scss/SubscriptionPlans.scss'

class SubscriptionPlans extends Component {
    componentDidMount() {
        this.props.dispatch(RESOURCES.plans.list({app: AppTypes.MD, hide_on_ui: false}))
    }

    handleChangeSubscriptionButton = (plan) => this.props.dispatch(selectPlan(plan))

    isTheSamePlan = (userPlan, currentPlan) => {
        const sameDevices = userPlan.devices === currentPlan.devices
        const sameSubscriptions = userPlan.subscriptions === currentPlan.subscriptions
        const samePrice = userPlan.price === currentPlan.price

        return sameDevices && sameSubscriptions && samePrice
    }
    
    render() {
        const {currentEntity, isLoadingEntities, plans, isLoadingPlans} = this.props

        return (
            <div id="subscription-plans-main-div">
                <div className="page-header">
                    <div className="left">
                        <Typography className="page-title">Abonamente</Typography>
                        <Typography className='page-description'>
                            Ne face plăcere să vă prezentam oferta pentru monitorizarea 
                            dosarelor de instanță prin aplicațiile noastre.
                        </Typography>
                    </div>
                    <div className="right">
                        <Link to='/settings' style={{textDecoration:'none'}} >
                            <Button className="back-button" >
                                <ChevronLeft className="back-button-icon" size={16} />
                                <Typography className="back-button-title">Înapoi la setări</Typography>
                            </Button>
                        </Link>
                    </div>
                </div>
                {!isEmpty(currentEntity) && !isEmpty(plans) && !isLoadingPlans && !isLoadingEntities ? (
                    <div className='page-body'>
                        <div className='cards-container'> 
                            {plans.map(plan => (
                                <div key={plan.id} className='card'> 
                                    <div className='card-header'>
                                        <Typography className='card-title'>{plan.name}</Typography>
                                        <div className="plan-price-div">
                                            <Typography className='plan-price'>
                                                {plan.price === 0 ? 'Gratuit' : plan.price + ' EUR'}
                                            </Typography>
                                            <Typography className='plan-frequency'>
                                                {plan.price === 0 ? '' : '/luna'}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className="card-content">
                                        <div className='card-content-child'>
                                            <Typography className='title'>Abonări</Typography>
                                            <Typography className='subtitle'>{plan.subscriptions}</Typography>
                                        </div>
                                        {plan.frequency ? (
                                            <div className='card-content-child'>
                                                <Typography className='title'>Frecvență</Typography>
                                                <Typography className='subtitle'>
                                                    {toReadableDuration(plan.frequency)}
                                                </Typography>
                                            </div>
                                        ) : null}
                                        <div className='card-content-child'>
                                            <Typography className='title'>Utilizatori</Typography>
                                            <Typography className='subtitle'>1</Typography>
                                        </div>
                                        <div className='card-content-child'>
                                            <Typography className='title'>Acces web</Typography>
                                            <Typography className='subtitle'>Da</Typography>
                                        </div>
                                        <div className='card-content-child'>
                                            <Typography className='title'>Acces smartphone</Typography>
                                            <Typography className='subtitle'>Da</Typography>
                                        </div>
                                        {!this.isTheSamePlan(currentEntity.plans.current.plan, plan) ? (
                                            <Button
                                                onClick={() => this.handleChangeSubscriptionButton(plan)}
                                                className='activate-plan-button'
                                                size="large"
                                                variant="contained"
                                                fullWidth
                                            >
                                                <Typography>Vreau acest plan</Typography>
                                            </Button>
                                        ) : (
                                            <div className="active-plan-div">
                                                <Typography className='active-plan-text'>Plan activ</Typography>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ) : isLoadingPlans || isLoadingEntities ? (
                    <div className="plans-loading-conatiner">
                        <CircularProgress className="circular-progress" thickness={5} color="primary" />
                    </div>
                ) : null}
            </div>
        )
    }
}

const mapStateToProps = function(state) {
    return {
        currentEntity: state.entities.currentEntity,
        isLoadingEntities: state.entities.isLoading,
        plans: sortBy(state.plans.data, 'weight'),
        isLoadingPlans: state.plans.isLoading
    }
}

export default connect(mapStateToProps)(SubscriptionPlans)
