import React, {Component} from 'react'
import {Button, ButtonBase, Dialog, DialogActions, DialogTitle, Typography} from '@material-ui/core'
import {Plus, X} from 'react-feather'

import {isEmpty, values, camelCase} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {permissionRoles} from 'avoapp-react-common/dist/constants'

import ClientAddPermission from '../ClientAddPermission'

import {colors} from '../../assets/styles/colors'

class Collaborators extends Component {
    constructor() { 
        super() 

        this.state = {
            showAddPermissionModal: false,
            showDeletePermissionDialog: false,
            permissionToDelete: {}

        }
    }

    handleCloseAddPermissionModal = () =>{this.setState({showAddPermissionModal: false})}

    handleCloseDeletePermissionDialog = () => {this.setState({showDeletePermissionDialog: false})}

    render() {
        const {showAddPermissionModal, showDeletePermissionDialog, permissionToDelete} = this.state
        const {permissions, clientPermissions, currentClientPermissions} = this.props

        return (
            <div className="section">
                <div className="section-header">
                    <Typography className="section-header-text">Manageri & Colaboratori</Typography>
                    {(permissions.clients.update || currentClientPermissions.permissions.update) && <>
                        <Button
                            onClick={()=>{
                                this.props.dispatch(RESOURCES.entityProfiles.list())
                                this.setState({showAddPermissionModal:true})
                            }}
                            variant="contained"
                            size="small"
                            className="navbar-main-button"
                            style={{backgroundColor: colors.success}}
                        >
                            <Plus className="navbar-button-icon" />
                            <Typography className="navbar-button-title">Adaugă manager/colaborator</Typography>
                        </Button>
                        <ClientAddPermission
                            showAddPermissionModal={showAddPermissionModal}
                            handleCloseAddPermissionModal={this.handleCloseAddPermissionModal}
                        />
                    </>}
                </div>
                <div className="section-content">
                    {!isEmpty(clientPermissions) ? (
                        <div className='cards-container'>
                            {clientPermissions.map((permission, index) => (
                                <div className="card" key={index}>
                                    <div className="card-header">
                                        <Typography className="card-title">
                                            {permission.entity_profile.first_name}{' '}
                                            {permission.entity_profile.last_name}
                                        </Typography>
                                        {
                                            (permissions.clients.update || 
                                            currentClientPermissions.permissions.update) && 
                                            <>
                                                <ButtonBase
                                                    onClick={() => {
                                                        this.setState({
                                                            showDeletePermissionDialog: true,
                                                            permissionToDelete: permission
                                                        })
                                                    }}
                                                >
                                                    <X
                                                        className="small-delete-button-icon"
                                                        size={14}
                                                        style={{alignSelf: 'center'}}
                                                    />
                                                </ButtonBase>
                                          
                                            </>
                                        }
                                    </div>
                                    <div className="card-content">
                                        <div className="card-content-child">
                                            <Typography className="title">Rol</Typography>
                                            <Typography className="subtitle">
                                                {permissionRoles[camelCase(permission.role)]?.label || permission.role}
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            ))} 
                            <DeletePermissionDialog
                                showDeletePermissionDialog={showDeletePermissionDialog}
                                permissionToDelete={permissionToDelete}
                                handleCloseDeletePermissionDialog={this.handleCloseDeletePermissionDialog}
                                handleDeletePermission={() => {
                                    this.props.dispatch(RESOURCES.clientPermissions.destroy(permissionToDelete))
                                    this.handleCloseDeletePermissionDialog()
                                }}
                            />
                        </div>
                    ) : (
                        <Typography className='no-data-text'>Nu există colaboratori/manageri</Typography>
                    )} 
                </div>
            </div>
        )
    }
}

export class DeletePermissionDialog extends Component {
    render() {
        const {showDeletePermissionDialog, handleCloseDeletePermissionDialog, handleDeletePermission} = this.props
        
        return (
            <Dialog
                className='delete-subscription-dialog'
                open={showDeletePermissionDialog}    
                onClose={handleCloseDeletePermissionDialog}
            >
                <DialogTitle>Sterge colaborator ?</DialogTitle>
                <DialogActions className='buttons-div'>
                    <Button onClick={handleCloseDeletePermissionDialog} className='close-button'>
                        <Typography className='button-text'>Nu, renunță</Typography>
                    </Button>
                    <Button className='action-button' onClick={handleDeletePermission} autoFocus>
                        <Typography className='button-text'>Da, șterge</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = function (state) {
    return {
        client: state.clients.currentClient,
        permissions: state.permissions,
        clientPermissions: values(state.clientPermissions.data),
        currentClientPermissions: state.clientPermissions.currentClientPermissions
    }
}

export default connect(mapStateToProps)(Collaborators)
