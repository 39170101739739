import React, {Component} from 'react'
import {Typography, Button, Input} from '@material-ui/core'
import {Link} from 'react-router-dom'
import {Formik} from 'formik'

import {connect} from 'react-redux'
import {createEmptyProject} from '../redux/projects'

import '../assets/scss/ProjectAdd.scss'

class ProjectAdd extends Component {

    render() {
        const {selectedEntityID} = this.props

        return (
            <>
                <div className="navbar" id="navbar-projects-add">
                    <div className="navbar-left">
                        <Typography className="navbar-title">Adaugă proiect</Typography>
                    </div>
                    <Link style={{textDecoration: 'none'}} to='/projects'>
                        <Button className="cancel-button">
                            <Typography className="cancel-button-title">Renunță</Typography>
                        </Button>
                    </Link>
                </div>
                <div id="project-add-main-div" style={{flexDirection: 'column'}}>
                    <div className="project-add-step-div">
                        <div className="add-project-left-div">
                            <div className="top">
                                <div className="step-number-div">
                                    <Typography className="step-number">1</Typography>
                                </div>
                                <Typography className="step-title">
                                    Alege
                                    <br /> nume proiect
                                </Typography>
                            </div>
                            <div className="bottom">
                                <Typography className="step-description">
                                    Un proiect reprezinta entitatea in care vei putea seta diferite surse de
                                    date pentru monitorizare. Spre exemplu, poti monitoriza, in acelasi proiect,
                                    atat numarul general de dosar de pe portal.just.ro, cat si dosare conexe
                                    (ex. /a1)
                                </Typography>
                            </div>
                        </div>
                        <div className="add-project-right-div">
                            <Formik
                                initialValues={{project_name: ''}}
                                onSubmit={(values) => {
                                    this.props.dispatch(createEmptyProject({
                                        entity_id: selectedEntityID,
                                        name: values.project_name
                                    }))
                                }}
                            >
                                {({handleChange, handleSubmit, values}) => (
                                    <div>
                                        <Input
                                            value={values.project_name}
                                            onChange={handleChange('project_name')}
                                            placeholder="Nume proiect"
                                            className="project-name-input"
                                        />
                                        <Button
                                            onClick={handleSubmit}
                                            variant="contained"
                                            size="large"
                                            fullWidth
                                        >
                                            <Typography className="button-title">Mai departe</Typography>
                                        </Button>
                                    </div>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = function(state) {
    return {
        selectedEntityID: state.localConfigs.selectedEntityID,
        isLoading: state.projects.isLoading
    }
}

export default connect(mapStateToProps)(ProjectAdd)
