import React, {Component} from 'react'
import {Typography, Button} from '@material-ui/core'

import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked'
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked'

import {orderBy, values, isEmpty} from 'lodash'

import {connect} from 'react-redux'
import {selectInterval} from 'avoapp-react-common/dist/redux/billing'

import SubscriptionChangeNavbar from '../components/SubsctiptionChangeNavbar'

import '../assets/scss/SubscriptionChange.scss'

class SubscriptionChange extends Component {
    constructor() {
        super()

        this.state = {
            intervalToSelect: ''
        }
    }

    componentDidMount() {
        const {selectedPlan} = this.props

        if(isEmpty(selectedPlan)){
            this.props.history.push('/subscription-plans')
        }
    };

    handleSelectInterval = () => {
        const {intervalToSelect} = this.state

        this.props.dispatch(selectInterval(intervalToSelect))
    }

    isSelected = (interval) => {
        const {intervalToSelect} = this.state

        return interval === intervalToSelect
    }

    saveIntervalToState = (interval) => this.setState({intervalToSelect: interval})

    render() {
        const {selectedPlan, intervals} = this.props
        const {intervalToSelect} = this.state
        
        return (
            <div>
                <SubscriptionChangeNavbar 
                    activeStep={0}
                    goToPlans={()=> this.props.history.push('/subscription-plans')}
                />
                <div id="subscription-change-main-div">
                    {!isEmpty(selectedPlan) ? (
                        <div className="intervals-container">
                            <Typography className='plan-name'>Plan MD{selectedPlan.name}</Typography>
                            <Typography className='plan-price'>{intervals[0].price_per_month} EUR /lună</Typography>
                            <Typography className='intervals-text'>Alege intervalul de facturare</Typography>
                            <div className="intervals-div">
                                {intervals.map((interval, index) => (
                                    <Interval 
                                        key={index}
                                        isSelected={this.isSelected(interval)} 
                                        interval={interval} 
                                        selectInterval={this.saveIntervalToState}
                                    />
                                ))}
                            </div>
                            <div className="button-div">
                                <Button
                                    onClick={this.handleSelectInterval}
                                    disabled={isEmpty(intervalToSelect)}
                                    type="submit"
                                    size="large"
                                    variant="contained"
                                    fullWidth
                                >
                                    <Typography>Salvează și mergi la datele de facturare</Typography>
                                </Button>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        )
    }
}

class Interval extends Component {
    render() {
        const {isSelected, interval, selectInterval} = this.props

        return (
            <div 
                className={'interval ' + (isSelected ? 'selected-interval' : null)}
                onClick={() => selectInterval(interval)}
            >
                <div className="icon-div">
                    {isSelected ? <RadioButtonChecked className='icon'/> : <RadioButtonUnchecked className='icon'/>}
                </div>
                <div className="texts-div">
                    <Typography className="interval-in-months">{interval.charging_interval_in_months} luni</Typography>
                    {
                        interval.percentage !== 0 &&
                        <Typography className="discount">
                            Discount {interval.percentage}%
                        </Typography>
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = function (state) {
    let selectedPlan = state.billing.data.selectedPlan
    let intervals = []

    if(!isEmpty(selectedPlan)) {
        intervals = orderBy(
            values(state.billing.data.selectedPlan.duration_discounts),
            'charging_interval_in_months'
        ) 
    }

    return {
        selectedPlan: selectedPlan,
        intervals: intervals
    }
}

export default connect(mapStateToProps)(SubscriptionChange)