import React, {Component} from 'react'
import {Typography, Button, Input, CircularProgress, FormControl, InputLabel} from '@material-ui/core'
import {values} from 'lodash'

import '../assets/scss/SyncGoogleCalendar.scss'

import {connect} from 'react-redux'

import {Link} from 'react-router-dom'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

class SyncGoogleNewCalendar extends Component {
    constructor() {
        super()

        this.state = {
            calendarName: ''
        }
    }

    handleChangeNameInput = (value) =>{ 
        this.setState({
            calendarName: value
        })
    }

    createNewGoogleCalendar = (calendarName) => {
        this.props.dispatch(RESOURCES.googleCalendars.create({summary: calendarName}))
    }
    
    render() {
        const {isLoading} = this.props
        const {calendarName} = this.state
        
        return (
            <div>
                <div className="navbar" id="navbar-sync-google-calendar">
                    <Link to="/" className="logo-div">
                        <img
                            src={require('../assets/images/monitor_dosare_logo_dark.svg').default}
                            alt="monitor dosare"
                        />
                    </Link>
                </div>
                <div id='sync-google-calendar-main-div'>
                    <div className="sync-text-div">
                        <div className="title-div">
                            <Typography className='title'>
                                Sincronizare 
                                <span className='google-logo-span'>
                                    <img src={require('../assets/images/google_g_logo.svg').default} alt='' />
                                </span>
                                oogle Calendar
                            </Typography>
                        </div>
                    </div>
                    <div className="sync-card">
                        <div className='card-header'>
                            <div className="img-div">
                                <img src={require('../assets/images/new_calendar.svg').default} alt=""/>
                            </div>
                            <div className="right">
                                <Typography className='text'>Creează un calendar special pentru</Typography> 
                                <Typography className='text'>Monitor Dosare</Typography> 
                            </div>
                        </div>
                        <div className="new-calendar-div">
                            <FormControl className='input-form-control'>
                                <InputLabel>Nume calendar nou</InputLabel>
                                <Input
                                    value={calendarName}
                                    onChange={(event) => {
                                        this.handleChangeNameInput(event.target.value)
                                    }}
                                    className="input"
                                />
                            </FormControl>
                            {!isLoading ? (
                                <Button
                                    onClick={() => 
                                        this.createNewGoogleCalendar(calendarName)
                                    }
                                    type="submit"
                                    size="large"
                                    variant="contained"
                                    fullWidth
                                    disabled={calendarName === ''}
                                    className='button'
                                >
                                    <Typography>Creează un calendar special pentru Monitor Dosare</Typography>
                                </Button>
                            ) : (
                                <CircularProgress className="circular-progress" thickness={5} color="primary" />
                            )}
                            <Link
                                to='/sync-google-calendar/existing'
                                className="link"
                            >
                                <Typography className="button-text">
                                    M-am răzgândit. Vreau să sincronizez un calendar existent.
                                </Typography>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps= function (state) {
    return {
        googleCalendars: values(state.googleCalendars.data),
        isLoading: state.googleCalendars.isLoading,
        isLoadingGoogleProfile: state.googleProfile.isLoading
    }

}

export default connect(mapStateToProps)(SyncGoogleNewCalendar)