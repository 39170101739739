import React, {Component} from 'react'
import IntlTelInput from 'react-intl-tel-input/dist/components/IntlTelInput'
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogTitle,
    FormControl,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from '@material-ui/core'
import {ArrowDropDown} from '@material-ui/icons'
import {Link} from 'react-router-dom'
import {Formik} from 'formik'

import {isEmpty, values as _values} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {getErrorFromCode} from 'avoapp-react-common/dist/utils'

import '../assets/scss/EntityProfileEdit.scss'

class EntityProfileEdit extends Component {
    constructor() {
        super() 

        this.state = {
            showDeleteEntityProfileDialog: false
        }
    }

    componentDidMount() {
        const{entityProfileID} = this.props.match.params

        this.props.dispatch(RESOURCES.entityProfiles.retrieve(entityProfileID))
        this.props.dispatch(RESOURCES.groups.list())
    }

    handleCloseDeleteEntityProfileDialog = () => this.setState({showDeleteEntityProfileDialog: false})
    
    render() {
        const {entityProfile, errors, isLoadingGroups, groups, isLoading, permissions} = this.props
        const {showDeleteEntityProfileDialog} = this.state

        return (
            <>
                <div className="navbar" id="navbar-entity-profiles">
                    <div className="navbar-left">
                        <Typography className="navbar-title">
                            Editare utilizator "{entityProfile?.last_name} {entityProfile?.first_name}"
                        </Typography>
                    </div>
                    {permissions.members.destroy && 
                        <div className="action-button ">
                            <Button
                                onClick={() => {this.setState({showDeleteEntityProfileDialog: true})}}
                                variant="text"
                                className='delete-button'
                                fullWidth
                            >
                                <Typography className="action-button-text delete-text">
                                    Șterge utilizator
                                </Typography>
                            </Button>
                        </div>
                    }
                    <DeleteEntityProfileDialog
                        showDeleteEntityProfileDialog={showDeleteEntityProfileDialog}
                        entityProfileToDelete={entityProfile}
                        handleDeleteEntityProfile={() => {
                            this.props.dispatch(RESOURCES.entityProfiles.destroy(entityProfile))
                            this.handleCloseDeleteEntityProfileDialog()
                        }}
                        handleCloseDeleteEntityProfileDialog={this.handleCloseDeleteEntityProfileDialog}
                    />
                    <Link style={{textDecoration: 'none'}} to='/settings'>
                        <Button className="cancel-button">
                            <Typography className="cancel-button-title">Renunță</Typography>
                        </Button>
                    </Link>
                </div>
                <div id='entity-profile-edit'>
                    <div className='entity-profile-form'>
                        {!isEmpty(errors) && isEmpty(errors.code) ?
                            _values(errors).map((errorCategories) =>
                                isEmpty(errorCategories.message) ? 
                                    errorCategories.map((error, index) => (
                                        <div key={index} className='error-div'>
                                            <Typography className='error-text'>
                                                {getErrorFromCode(error, 'entityProfiles')}
                                            </Typography>
                                        </div>
                                    )) : (
                                        <div key={errorCategories.code} className='error-div'>
                                            <Typography className='error-text'>
                                                {getErrorFromCode(errorCategories, 'entityProfiles')}
                                            </Typography>
                                        </div>
                                    ) ) : errors.code === 'server_error' ? (
                                        <div className='error-div'>
                                            <Typography className='error-text'>
                                                Am întâmpinat o eroare. 
                                                Dacă eroarea persistă te rugăm să ne contactezi.
                                            </Typography>
                                        </div>
                            ) : null}
                        {!isEmpty(entityProfile) && !isLoading && !isLoadingGroups ? (
                            <Formik
                                initialValues={{
                                    groupID: entityProfile.group_id,
                                    firstName: entityProfile.first_name,
                                    lastName: entityProfile.last_name,
                                    email: entityProfile.email,
                                    phone: entityProfile.phone
                                }}
                                onSubmit={(values) => {
                                    this.props.dispatch(RESOURCES.entityProfiles.update({
                                        id: entityProfile.id,
                                        group_id: values.groupID,
                                        first_name: values.firstName,
                                        last_name: values.lastName,
                                        email: values.email,
                                        phone: values.phone
                                    }))
                                }}
                            >
                                {({handleChange, handleSubmit, values, setFieldValue}) => (
                                    <>
                                        <FormControl className='select-form-control'>
                                            <InputLabel id="group-select">Grup</InputLabel>
                                            <Select
                                                labelId="group-select"
                                                value={values.groupID}
                                                IconComponent={() => <ArrowDropDown className='dropdown-icon' />}
                                                onChange={(event) => {setFieldValue('groupID', event.target.value)}}
                                            > 
                                                {groups.map((group) => 
                                                    <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                        <Input
                                            placeholder="Nume"
                                            value={values.lastName}
                                            onChange={handleChange('lastName')}
                                            className='input'
                                        />  
                                        <Input
                                            placeholder="Prenume"
                                            value={values.firstName}
                                            onChange={handleChange('firstName')}
                                            className='input'
                                        /> 
                                        <Input
                                            placeholder="Adresă de email"
                                            value={values.email}
                                            onChange={handleChange('email')}
                                            className='input'
                                        />
                                        <div className='phone-input-div input'>
                                            <IntlTelInput
                                                value={values.phone}
                                                formatOnInit={false}
                                                nationalMode={false}
                                                defaultCountry='ro'
                                                preferredCountries={['ro']}
                                                onPhoneNumberChange={(valid, phoneNumber) => {
                                                    setFieldValue('phone', phoneNumber)
                                                }}
                                            />
                                        </div>
                                        <div className='submit-button'>
                                            {!isLoading ? (
                                                <Button 
                                                    size="large"
                                                    variant="contained"
                                                    disabled={isLoading}
                                                    onClick={handleSubmit}
                                                    fullWidth
                                                >
                                                    <Typography className="button-title">
                                                        Salvează modificările
                                                    </Typography>
                                                </Button>
                                            ) : (
                                                <div className="center-loader">
                                                    <CircularProgress 
                                                        className="circular-progress" 
                                                        thickness={5} 
                                                        color="primary"
                                                    />
                                                </div>
                                            )}
                                     
                                        </div>
                                    </>
                                )}
                            </Formik>
                        ) : isLoading || isLoadingGroups ? (
                            <div className="center-loader">
                                <CircularProgress className="circular-progress" thickness={5} color="primary" />
                            </div>
                        ) : isEmpty(entityProfile) ? (
                            <Typography>Acest utilizator nu a fost găsit!</Typography>
                        ) : null}
                    </div>
                </div>
            </>
        )
    }
}

class DeleteEntityProfileDialog extends Component {
    render() {
        const {
            showDeleteEntityProfileDialog,
            handleCloseDeleteEntityProfileDialog,
            handleDeleteEntityProfile,
            entityProfileToDelete
        } = this.props
        
        return (
            <Dialog
                className='delete-entity-profiles-dialog'
                open={showDeleteEntityProfileDialog}
                onClose={handleCloseDeleteEntityProfileDialog}
            >
                <DialogTitle>
                    Vrei să ștergi utilizatorul "{entityProfileToDelete.last_name} {entityProfileToDelete.first_name}" 
                    din compania ta?
                </DialogTitle>
                <DialogActions className='buttons-div'>
                    <Button onClick={handleCloseDeleteEntityProfileDialog} className='close-button'>
                        <Typography className='button-text'>Nu, renunță</Typography>
                    </Button>
                    <Button className='action-button' onClick={handleDeleteEntityProfile} autoFocus>
                        <Typography className='button-text'>Da, șterge</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = function(state) {
    return {
        user: state.users.user,
        entityProfile: state.entityProfiles.currentEntityProfile,
        isLoading: state.entityProfiles.isLoading,
        errors: state.entityProfiles.errors,
        isLoadingGroups: state.groups.isLoading,
        groups: _values(state.groups.data),
        permissions: state.permissions
    }
}

export default connect(mapStateToProps)(EntityProfileEdit)
