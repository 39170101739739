import {toast} from 'react-toastify'
import {push} from 'connected-react-router'

import {takeEvery, put} from 'redux-saga/effects'
import {history} from './store'

import {contactPersons, spec} from 'avoapp-react-common'
const {ACTIONS} = contactPersons
const {RESOURCES} = spec

const {contactPersons: resource} = RESOURCES

function* handleCreateContactPersonSuccess(response) {
    const message = 'Persoana de notificat a fost creată cu succes'
    toast.success(message)
    
    if(history.location.pathname.includes('add-contact-person')) {
        yield put(push(`/clients/${response.payload.client_id}`))
    }
}

function handleCreateContactPersonFail() {
    const message = 'Persoana de notificat nu a putut fi creată'
    toast.error(message)
}

function handleDestroyContactPersonSuccess() {
    const message = 'Persoana de contact a fost ștearsă'
    toast.success(message)
}

function* handleConfirmContactPersonSuccess() {
    const message = 'Raspunsul tău a fost salvat'
    toast.success(message)

    yield put(push('/login'))
}

function handleConfirmContactPersonFail() {
    const message = 'A aparut o eroare'
    toast.error(message)
}

function handleResendConfirmationSuccess(response) {
    toast.success(`Emailul de confirmare a fost trimis la ${response.payload.email}`)
}

function handleResendConfirmationFail(response) {
    toast.error('Nu am putut trimite emailul de confirmare. Te rugăm încearcă din nou.')
}

export function* saga() {
    yield takeEvery(resource.actions.create.success, handleCreateContactPersonSuccess)
    yield takeEvery(resource.actions.create.fail, handleCreateContactPersonFail)
    yield takeEvery(resource.actions.destroy.success, handleDestroyContactPersonSuccess)
    yield takeEvery(ACTIONS.CONFIRM_CONTACT_PERSON.success, handleConfirmContactPersonSuccess)
    yield takeEvery(ACTIONS.CONFIRM_CONTACT_PERSON.fail, handleConfirmContactPersonFail)
    yield takeEvery(ACTIONS.RESEND_CONFIRMATION.success, handleResendConfirmationSuccess)
    yield takeEvery(ACTIONS.RESEND_CONFIRMATION.fail, handleResendConfirmationFail)
}
