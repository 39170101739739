import React, {Component} from 'react'
import {Button, Modal, Typography} from '@material-ui/core'

import {connect} from 'react-redux'

import {handleHideToolUnavailableModal} from 'avoapp-react-common/dist/redux/api'

import '../assets/scss/ToolUnavailable.scss'

export class ToolUnavailable extends Component {
    handleCloseToolUnavailableModal = () => {
        this.props.dispatch(handleHideToolUnavailableModal())
    }

    render() {
        const {showToolUnavailableModal} = this.props

        return (
            <Modal
                open={!!showToolUnavailableModal}
                onClose={this.handleCloseToolUnavailableModal}
            >
                <div className="tool-unavailable-modal">
                    <div className="main-div">
                        <div className="content">
                            <div className="content-text-div">
                                <Typography className="modal-title">Eroare date externe</Typography>
                                <Typography className="modal-text">
                                    Am întâmpinat o eroare la unul din instrumentele terțe pe care le folosim.
                                </Typography>
                                <Typography className="modal-text">
                                    Te rugăm să încerci mai târziu.
                                </Typography>
                            </div>
                            <Button
                                onClick={this.handleCloseToolUnavailableModal}
                                size="large"
                                variant="contained"
                                fullWidth
                            >
                                <Typography>Am înțeles!</Typography>
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => ({
    showToolUnavailableModal: state.app.showToolUnavailableModal  
})

export default connect(mapStateToProps )(ToolUnavailable)
