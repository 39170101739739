import React, {Component} from 'react'
import {ThemeProvider} from '@material-ui/core'
import {Switch, Route, Redirect} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import {includes} from 'lodash'

import {theme} from './assets/styles/theme'

import {Provider} from 'react-redux'
import {ConnectedRouter} from 'connected-react-router'
import {PersistGate} from 'redux-persist/integration/react'
import {persistor, store, history} from './redux/store'

import ErrorsModalContainer from './components/ErrorsModal/ErrorsModalContainer'

import Sidebar from './components/Sidebar'

import Login from './pages/Login'
import Register from './pages/Register'
import EntitySelect from './pages/EntitySelect'
import ResetPassword from './pages/ResetPassword'
import AccountActivation from './pages/AccountActivation'
import ContactPersonConfirm from './pages/ContactPersonConfirm'
import TermsAndConditions from './pages/TermsAndConditions'

import SubscriptionAdd from './pages/SubscriptionAdd'
import SubscriptionsList from './pages/SubscriptionsList'
import SubscriptionDetails from './pages/SubscriptionDetails'

import ProjectAdd from './pages/ProjectAdd'
import ProjectsList from './pages/ProjectsList'
import ProjectDetails from './pages/ProjectDetails'
import ProjectAddSubscription from './pages/ProjectAddSubscription'

import ClientAdd from './pages/ClientAdd'
import ClientsList from './pages/ClientsList'
import ClientDetails from './pages/ClientDetails'
import ClientAddPartySubscritpion from './pages/ClientAddPartySubscritpion'
import ClientAddContactPerson from './pages/ClientAddContactPerson'

import ManualPhaseAdd from './pages/ManualPhaseAdd'

import ManualPartyAdd from './pages/ManualPartyAdd'
import ManualPartyEdit from './pages/ManualPartyEdit'
import ManualPartyAddSuccess from './pages/ManualPartyAddSuccess'

import ManualHearingAdd from './pages/ManualHearingAdd'
import ManualHearingEdit from './pages/ManualHearingEdit'
import ManualHearingAddSuccess from './pages/ManualHearingAddSuccess'

import ManualAppealAdd from './pages/ManualAppealAdd'
import ManualAppealEdit from './pages/ManualAppealEdit'
import ManualAppealAddSuccess from './pages/ManualAppealAddSuccess'

import CalendarPage from './pages/Calendar'

import NotificationsList from './pages/NotificationsList'

import SettingsPage from './pages/Settings'

import SupportPage from './pages/Support'

import TicketSuccess from './pages/TicketSuccess'

import Ambassadors from './pages/Ambassadors'
import AmbassadorCodeActivated from './pages/AmbassadorCodeActivated'

import SubscriptionPlans from './pages/SubscriptionPlans'
import SubscriptionChange from './pages/SubscriptionChange'
import SubscriptionUpgrade from './pages/SubscriptionUpgrade'
import SubscriptionBillingData from './pages/SubscriptionBillingData'
import SubscriptionConfirm from './pages/SubscriptionConfirm'

import ToManySubscriptions from './pages/ToManySubscriptions'
import DeleteSubscriptions from './pages/DeleteSubscriptions'

import InsufficientPermissions from './pages/InsufficientPermissions'

import SyncGoogleNewCalendar from './pages/SyncGoogleNewCalendar'
import SyncGoogleCalendarMethod from './pages/SyncGoogleCalendarMethod'
import SyncGoogleExistingCalendar from './pages/SyncGoogleExistingCalendar'
import SubscriptionChangeSuccess from './pages/SubscriptionChangeSuccess'

import EntitiesEdit from './pages/EntitiesEdit'
import EntityProfiles from './pages/EntityProfiles'
import EntityProfileEdit from './pages/EntityProfileEdit'
import EntityProfileConfirm from './pages/EntityProfileConfirm'

import GroupAdd from './pages/GroupAdd'
import GroupEdit from './pages/GroupEdit'

import DownloadAppScreen from './pages/DownloadAppScreen'

import LimitPassed from './components/LimitPassed'

import ToolUnavailable from './components/ToolUnavailable'

import NotFound404 from './pages/NotFound404'

import useWindowDimensions, {minScreenWidth} from './assets/dimensions'

import {shouldPageLoadOnMobile} from './assets/utils'

function App() {
    const {width} = useWindowDimensions()

    return (
        <>
            {width > minScreenWidth || 
                (width < minScreenWidth && shouldPageLoadOnMobile()) ? 
                    <MainApp /> : 
                    <DownloadAppScreen />
            }
        </>
    )
}

function MainApp() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ThemeProvider theme={theme}>
                    <ErrorsModalContainer />
                    <ToastContainer autoClose={3000} />
                    <ConnectedRouter history={history}>
                        <div className="App">
                            <Switch>
                                <Route path="/" exact component={() => <Redirect to='/subscriptions' />}/>
                                <Route path="/register" component={Register} />
                                <Route path="/terms-and-conditions" component={TermsAndConditions} />
                                <Route path="/login" component={Login} />
                                <Route path="/select-entity" component={EntitySelect} />
                                <Route path="/account-activation/:activationCode" component={AccountActivation} />
                                <Route
                                    path="/password-confirmation/:resetPasswordUID/:resetPasswordCode"
                                    component={ResetPassword}
                                />
                                <Route
                                    path={
                                        '/contact-person-confirmation/' +
                                        ':confirmContactPersonUID/' +
                                        ':confirmContactPersonToken/' +
                                        ':confirmContactPersonResponse'
                                    }
                                    component={ContactPersonConfirm}
                                />
                                <Route 
                                    exact
                                    path="/projects" 
                                    render={(props) => (
                                        <MainComponent componentToRender={<ProjectsList {...props} />} />
                                    )}
                                />
                                <Route path="/projects/add" exact>
                                    <MainComponent componentToRender={<ProjectAdd />} />
                                </Route>
                                <Route
                                    exact
                                    path="/projects/:projectID(\d+)"
                                    render={(props) => (
                                        <MainComponent componentToRender={<ProjectDetails {...props} />} />
                                    )}
                                />
                                <Route
                                    exact
                                    path="/projects/:projectID(\d+)/add-subscription"
                                    render={(props) => (
                                        <MainComponent componentToRender={<ProjectAddSubscription {...props}/>} />
                                    )}
                                />
                                <Route 
                                    exact
                                    path="/subscriptions" 
                                    render={(props) => (
                                        <MainComponent componentToRender={<SubscriptionsList {...props} />} />
                                    )}
                                />
                                <Route 
                                    exact
                                    path="/subscriptions/add" 
                                    render={(props) => (
                                        <MainComponent componentToRender={<SubscriptionAdd {...props} />} />
                                    )}
                                />
                                <Route 
                                    exact
                                    path="/subscriptions/:subscriptionID(\d+)" 
                                    render={(props) => (
                                        <MainComponent componentToRender={<SubscriptionDetails {...props} />} />
                                    )}
                                />
                                <Route 
                                    exact
                                    path="/subscriptions/:subscriptionID(\d+)/add-manual-phase"
                                    render={(props) => (
                                        <MainComponent componentToRender={<ManualPhaseAdd {...props}/>} />
                                    )}
                                />
                                <Route 
                                    exact
                                    path="/phases/:phaseID/add-manual-party"
                                    render={(props) => (
                                        <MainComponent componentToRender={<ManualPartyAdd {...props}/>} />
                                    )}
                                />
                                <Route 
                                    exact
                                    path="/add-success"
                                    render={(props) => (
                                        <MainComponent componentToRender={<ManualHearingAddSuccess {...props}/>} />
                                    )}
                                />
                                <Route 
                                    exact
                                    path="/parties/:partyID/add-success" 
                                    render={(props) => (
                                        <MainComponent componentToRender={<ManualPartyAddSuccess {...props}/>} />
                                    )}
                                />
                                <Route 
                                    exact
                                    path="/parties/:partyID/edit" 
                                    render={(props) => (
                                        <MainComponent componentToRender={<ManualPartyEdit {...props}/>} />
                                    )}
                                />
                                <Route 
                                    exact
                                    path="/phases/:phaseID/add-manual-hearing" 
                                    render={(props) => (
                                        <MainComponent componentToRender={<ManualHearingAdd {...props}/>} />
                                    )}
                                />
                                <Route 
                                    exact
                                    path="/hearings/:hearingID/add-success" 
                                    render={(props) => (
                                        <MainComponent componentToRender={<ManualHearingAddSuccess {...props}/>} />
                                    )}
                                />
                                <Route 
                                    exact
                                    path="/hearings/:hearingID/edit" 
                                    render={(props) => (
                                        <MainComponent componentToRender={<ManualHearingEdit {...props}/>} />
                                    )}
                                />
                                <Route 
                                    exact
                                    path="/phases/:phaseID/add-manual-appeal" 
                                    render={(props) => (
                                        <MainComponent componentToRender={<ManualAppealAdd {...props}/>} />
                                    )}
                                />
                                <Route 
                                    exact
                                    path="/appeals/:appealID/add-success" 
                                    render={(props) => (
                                        <MainComponent componentToRender={<ManualAppealAddSuccess {...props}/>} />
                                    )}
                                />
                                <Route 
                                    exact
                                    path="/appeals/:appealID/edit" 
                                    render={(props) => (
                                        <MainComponent componentToRender={<ManualAppealEdit {...props}/>} />
                                    )}
                                />
                                <Route path="/clients" exact>
                                    <MainComponent componentToRender={<ClientsList />} />
                                </Route>
                                <Route 
                                    exact  
                                    path="/clients/add" 
                                    render={(props) => (
                                        <MainComponent componentToRender={<ClientAdd {...props} />} />
                                    )}
                                />
                                <Route
                                    exact
                                    path="/clients/:clientID(\d+)"
                                    render={(props) => (
                                        <MainComponent componentToRender={<ClientDetails {...props} />} />
                                    )}
                                />
                                <Route
                                    exact
                                    path="/clients/:clientID(\d+)/add-party-subscription"
                                    render={(props) => (
                                        <MainComponent componentToRender={<ClientAddPartySubscritpion {...props}/>} />
                                    )}
                                />
                                <Route
                                    exact
                                    path="/clients/:clientID(\d+)/add-contact-person"
                                    render={(props) => (
                                        <MainComponent componentToRender={<ClientAddContactPerson {...props}/>} />
                                    )}
                                />
                                <Route path="/calendar">
                                    <MainComponent componentToRender={<CalendarPage />} />
                                </Route>
                                <Route path="/notifications">
                                    <MainComponent componentToRender={<NotificationsList />} />
                                </Route>
                                <Route
                                    path="/settings"
                                    render={(props) => (
                                        <MainComponent componentToRender={<SettingsPage {...props}/>} />
                                    )}
                                />
                                <Route path="/support">
                                    <MainComponent componentToRender={<SupportPage />} />
                                </Route>
                                <Route path="/ticket-success">
                                    <MainComponent componentToRender={<TicketSuccess />} />
                                </Route>
                                <Route
                                    path="/ambassadors"
                                    render={(props) => (
                                        <MainComponent componentToRender={<Ambassadors {...props}/>} />
                                    )}
                                />
                                <Route
                                    path="/ambassador-code-activated"
                                    render={(props) => (
                                        <MainComponent componentToRender={<AmbassadorCodeActivated {...props}/>} />
                                    )}
                                />
                                <Route 
                                    path="/sync-google-calendar/method" 
                                    component={SyncGoogleCalendarMethod} 
                                />
                                <Route 
                                    path="/sync-google-calendar/new" 
                                    component={SyncGoogleNewCalendar} 
                                />
                                <Route 
                                    path="/sync-google-calendar/existing" 
                                    component={SyncGoogleExistingCalendar} 
                                />
                                <Route 
                                    path="/limit-reached"
                                    component={ToManySubscriptions} 
                                />
                                <Route 
                                    path="/insufficient-permissions"
                                    component={InsufficientPermissions} 
                                />
                                <Route 
                                    path="/delete-subscriptions" 
                                    component={DeleteSubscriptions} 
                                />
                                <Route path="/subscription-plans" exact>
                                    <MainComponent componentToRender={<SubscriptionPlans />} />
                                </Route>
                                <Route 
                                    path="/change-subscription" 
                                    exact
                                    render={(props) => (
                                        <MainComponent componentToRender={<SubscriptionChange {...props}/>} />
                                    )}
                                />
                                <Route 
                                    path="/upgrade-subscription" 
                                    exact
                                    render={(props) => (
                                        <MainComponent componentToRender={<SubscriptionUpgrade {...props}/>} />
                                    )}
                                />
                                <Route 
                                    path="/billing" 
                                    exact
                                    render={(props) => (
                                        <MainComponent componentToRender={<SubscriptionBillingData {...props}/>} />
                                    )}
                                />
                                <Route 
                                    path="/confirm-subscription" 
                                    exact
                                    render={(props) => (
                                        <MainComponent componentToRender={<SubscriptionConfirm {...props}/>} />
                                    )}
                                />
                                <Route 
                                    path="/subscription-change-success" 
                                    exact
                                    render={(props) => (
                                        <MainComponent componentToRender={<SubscriptionChangeSuccess {...props}/>} />
                                    )}
                                />
                                <Route 
                                    path="/entities/:entityID/edit" 
                                    exact
                                    render={(props) => (
                                        <MainComponent componentToRender={<EntitiesEdit {...props}/>} />
                                    )}
                                />
                                <Route
                                    path="/entity-profiles/add"
                                    render={(props) => (
                                        <MainComponent componentToRender={<EntityProfiles {...props}/>} />
                                    )}
                                />
                                <Route
                                    path="/entity-profiles/:entityProfileID/edit"
                                    render={(props) => (
                                        <MainComponent componentToRender={<EntityProfileEdit {...props}/>} />
                                    )}
                                />
                                <Route
                                    path={
                                        '/entity-profile-confirmation/' +
                                        ':confirmEntityProfileUID/' +
                                        ':confirmEntityProfileToken/' +
                                        ':confirmEntityProfileStatus'
                                    }
                                    component={EntityProfileConfirm}
                                />
                                <Route
                                    path="/groups/add"
                                    render={(props) => (
                                        <MainComponent componentToRender={<GroupAdd {...props}/>} />
                                    )}
                                />
                                <Route
                                    path="/groups/:groupID/edit"
                                    render={(props) => (
                                        <MainComponent componentToRender={<GroupEdit {...props}/>} />
                                    )}
                                />
                                <Route component={NotFound404} />
                            </Switch>
                        </div>
                    </ConnectedRouter>
                </ThemeProvider>
            </PersistGate>
        </Provider>
    )
}

class MainComponent extends Component {
    renderLimitPassed = () => {
        const {pathname} = window.location

        const routes = [
            '/support', 
            '/ticket-success',
            '/settings', 
            '/subscription-plans', 
            '/upgrade-subscription', 
            '/billing',
            '/confirm-subscription',
            '/change-subscription'
        ]

        if(!includes(routes, pathname)) {
            return <LimitPassed />
        }

        return null
    }

    render() {
        return (
            <div style={{display: 'flex'}}>
                <Sidebar />
                <ToolUnavailable />
                {this.renderLimitPassed()}
                {this.props.componentToRender}
            </div>
        )
    }
}

export default App
