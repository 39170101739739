import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Input,
    Button,
    Typography, 
    CircularProgress
} from '@material-ui/core'
import {ArrowDropDown} from '@material-ui/icons'
import {Formik} from 'formik'

import {concat, isEmpty, head, values as _values} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {getErrorFromCode} from 'avoapp-react-common/dist/utils'

import InstitutionSelect from '../components/InstitutionSelect'

import {partySubscriptionsData} from '../assets/data/choices'

class ClientAddPartySubscritpion extends Component {
    componentDidMount() {
        const currentClientId = this.props.match.params.clientID
        this.props.dispatch(RESOURCES.clients.retrieve(currentClientId))
    }

    createSourcesArray = (startingSourcesArray) => {
        let finalSourcesArray = []
        startingSourcesArray.map((source) => (
            finalSourcesArray = concat(finalSourcesArray, {
                value: source,
                label: partySubscriptionsData.source[source]
            })
        ))

        return finalSourcesArray
    }

    createInstitutionsArray = (startingInstitutionsArray) => {
        let finalInstitutionsArray = []
        startingInstitutionsArray.map((institution) => (
            finalInstitutionsArray = concat(finalInstitutionsArray, {
                value: partySubscriptionsData.institution[institution],
                label: institution
            })
        ))

        return finalInstitutionsArray
    }
    
    render() {
        const {client, isLoadingClients, partySubscriptionsErrors, planError} = this.props

        const sourcesArray = this.createSourcesArray(Object.keys(partySubscriptionsData.source))
        const institutionsArray = this.createInstitutionsArray(Object.keys(partySubscriptionsData.institution))
        
        return (
            <>
                <div className="navbar" id="navbar-clients-add">
                    <div className="navbar-left" />
                    <Link style={{textDecoration: 'none'}} to={`/clients/${client.id}`}>
                        <Button className="cancel-button">
                            <Typography className="cancel-button-title">Renunță</Typography>
                        </Button>
                    </Link>
                </div>
                <div id="client-add-main-div" style={{flexDirection: 'column'}}>
                    <div ref={this.addPartySubscriptionDiv} className="client-add-step-div">
                        <div className="add-client-left-div">
                            <div className="top">
                                <Typography className="step-title">
                                    Adaugă
                                    <br /> monitorizare juridică
                                </Typography>
                            </div>
                            <div className="bottom">
                                <Typography className="step-description">
                                    Acest tip de notificări urmărește zilnic dacă găsim dosare noi
                                    pentru un anumit set de date. Spre exemplu, poți monitoriza toate
                                    dosarele apărute pentru un anumit cuvânt de căutare într-o anumită
                                    instanță de judecată. Cuvântul de căutare va fi numele părții 
                                    pentru care dorești urmărirea.
                                </Typography>
                            </div>
                        </div>
                        <div className="add-client-right-div">
                            {!isLoadingClients ? (
                                <Formik
                                    initialValues={{
                                        source: head(sourcesArray).value,
                                        institution: head(institutionsArray),
                                        partyName: client.name
                                    }}
                                    onSubmit={(values) => 
                                        this.props.dispatch(
                                            RESOURCES.partySubscriptions.create({
                                                client_id: client.id,
                                                source: values.source,
                                                party_name: values.partyName,
                                                institution: values.institution.value
                                            })
                                        )
                                    }
                                >
                                    {({handleChange, handleSubmit, values, setFieldValue}) => (
                                        <div className="client-search-data-source-div">
                                            {!isEmpty(partySubscriptionsErrors) 
                                                && isEmpty(planError)
                                                && isEmpty(partySubscriptionsErrors.detail) 
                                                && _values(partySubscriptionsErrors).map((errorCategories) =>
                                                    errorCategories.map((error, index) => (
                                                        <div key={index} className='error-div'>
                                                            <Typography className='error-text'>
                                                                {getErrorFromCode(error, 'partySubscription')}
                                                            </Typography>
                                                        </div>
                                                    ))
                                                )}
                                            <FormControl style={{width: '100%'}}>
                                                <InputLabel id="source">Sursa</InputLabel>
                                                <Select
                                                    labelId="source"
                                                    value={values.source}
                                                    IconComponent={() => <ArrowDropDown className='dropdown-icon' />}
                                                    onChange={(event) => setFieldValue('source', event.target.value)}
                                                    className="client-select"
                                                >
                                                    {sourcesArray.map((source) => (
                                                        <MenuItem key={source.value} value={source.value}>
                                                            {source.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <FormControl className="client-add-form-control">
                                                <InstitutionSelect
                                                    label='Instituția'
                                                    value={values.institution}
                                                    institutionsArray={institutionsArray}
                                                    handleChange={(value) => setFieldValue('institution', value)}
                                                />
                                            </FormControl>
                                            <FormControl className="client-add-form-control">
                                                <InputLabel>Nume</InputLabel>
                                                <Input
                                                    value={values.partyName}
                                                    className="client-input"
                                                    onChange={handleChange('partyName')}
                                                />
                                            </FormControl>
                                            <div className='buttons-div'>
                                                <Button
                                                    onClick={handleSubmit}
                                                    size="large"
                                                    variant="contained"
                                                    fullWidth
                                                    className='submit-button'
                                                >
                                                    <Typography className="button-title">
                                                        Adaugă
                                                    </Typography>
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                </Formik>
                            ) : (
                                <div>
                                    <CircularProgress thickness={5} color="primary" />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = function(state) {
    return {
        client: state.clients.currentClient,
        isLoadingClients: state.clients.isLoading,
        partySubscriptionsErrors: state.partySubscriptions.errors,
        planError: state.partySubscriptions.plan_error
    }
}

export default connect(mapStateToProps)(ClientAddPartySubscritpion)