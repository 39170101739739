import React from 'react'

import {connect} from 'react-redux'
import {errorsModalTypes} from '../../../redux/errorsModal'

import ErrorsModal from '../ErrorsModal'

const ToolUnavailableModal = ({open}) => {    
    return (
        <ErrorsModal
            open={open }
            title='Eroare date externe'
            messageContent={() => (
                <>
                    <p className="avo-errors-modal-message">
                        Am întâmpinat o eroare la unul din instrumentele terțe pe care le folosim.
                    </p>
                    <p className="avo-errors-modal-secondary-message">
                        Te rugăm să încerci mai târziu.
                    </p>
                </>
            )}
        />
    )
}

const mapStateToProps = (state) => ({
    open: state.errorsModal.type === errorsModalTypes.TOOL_UNAVAILABLE
})

export default connect(mapStateToProps)(ToolUnavailableModal)
