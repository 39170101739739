import React, {Component} from 'react'
import {Typography} from '@material-ui/core'

import '../assets/scss/SubscriptionActiveStatusPill.scss'

export default class SubscriptionActiveStatusPill extends Component {
    getSubscriptionStatus = (subscription) => subscription.active ? 'Activ' : 'Inactiv'

    render() {
        const{subscription} = this.props

        return (
            <div className={`subscription-active-status-pill ${subscription.active ? 'active' : 'inactive'}`}>
                <Typography className='status-text'>
                    {this.getSubscriptionStatus(subscription)}
                </Typography> 
            </div>
        )
    }
}
