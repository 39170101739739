import React, {Component} from 'react'
import {Typography} from '@material-ui/core'

import {changeDateFormat} from 'avoapp-react-common/dist/utils'

export default class LitigationFullDetails extends Component {
    render() {
        const {litigation} = this.props

        return (
            <div id="litigation-full-details-main-div">
                <div className="card">
                    <div className="card-header">
                        <Typography className="card-title">{litigation.number}</Typography>
                    </div>
                    <div className="card-content">
                        <div className="card-content-child">
                            <Typography className="title">Data: </Typography>
                            <Typography className="subtitle">{changeDateFormat(litigation.date)}</Typography>
                        </div>
                        <div className="card-content-child">
                            <Typography className="title"> Instanța: </Typography>
                            <Typography className="subtitle" noWrap>{litigation.court}</Typography>
                        </div>
                        <div className="card-content-child">
                            <Typography className="title">Următorul termen: </Typography>
                            <Typography className="subtitle">
                                {litigation.hearings[0]?.date ? changeDateFormat(litigation.hearings[0].date) : '-'}
                            </Typography>
                        </div>
                        <div className="card-content-child-details-parties">
                            <Typography className="title">Părți: </Typography>
                            {litigation.parties.map((party, index) => (
                                <Typography key={index} className="subtitle">
                                    - {party.name}
                                </Typography>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
