import React, {Component} from 'react'

import {Typography} from '@material-ui/core'

import {retrieveAndSetCurrent} from 'avoapp-react-common/dist/redux/entities'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'

import CompanyData from '../components/Settings/CompanyData'
import CompanyUsers from '../components/Settings/CompanyUsers'
import MySubscription from '../components/Settings/MySubscription'
import Notifications from '../components/Settings/Notifications'
import Profile from '../components/Settings/Profile'
import ProjectSettings from '../components/Settings/ProjectSettings'
import SyncCalendar from '../components/Settings/SyncCalendar'

import '../assets/scss/Settings.scss'

export class SettingsPage extends Component {    
    componentDidMount() {
        const {selectedEntityID} = this.props

        this.props.dispatch(RESOURCES.users.retrieve('me'))
        this.props.dispatch(RESOURCES.usersPreferences.retrieve('me'))
        this.props.dispatch(RESOURCES.entityReports.retrieve(selectedEntityID))
        this.props.dispatch(RESOURCES.googleProfile.retrieve('me'))
        this.props.dispatch(RESOURCES.entityProfiles.list())
        this.props.dispatch(RESOURCES.entityPreferences.retrieve(selectedEntityID))
        this.props.dispatch(retrieveAndSetCurrent(selectedEntityID))
        this.props.dispatch(RESOURCES.groups.list())
    }

    componentDidUpdate(prevProps) {
        const {selectedEntityID, isLoadingEntities, isLoadingEntityProfiles, isLoadingGroups} = this.props

        let isLoading = isLoadingEntities && isLoadingEntityProfiles && isLoadingGroups

        if(prevProps.selectedEntityID !== selectedEntityID && !isLoading) {
            this.props.dispatch(RESOURCES.groups.list())
            this.props.dispatch(RESOURCES.entityProfiles.list())
            this.props.dispatch(RESOURCES.entityReports.retrieve(selectedEntityID))
            this.props.dispatch(RESOURCES.entityPreferences.retrieve(selectedEntityID))
            this.props.dispatch(retrieveAndSetCurrent(selectedEntityID))
        }
    }
   
    render() {
        const {permissions, history} = this.props

        return (
            <>
                <div className="navbar" id="navbar-settings">
                    <Typography className="navbar-title">Setări</Typography>
                </div>
                <div id="settings-main-div">
                    <Profile />
                    <Notifications />
                    <ProjectSettings />
                    <SyncCalendar />
                    {permissions.plans.read && 
                        <MySubscription shouldScroll={history.location.state?.scrollToMySubscription}/>}
                    {permissions.entity.read && <CompanyData />}
                    {permissions.members.read && <CompanyUsers />}
                </div>
            </>
        )
    }
}

const mapStateToProps = function(state) {
    return {
        selectedEntityID: state.localConfigs.selectedEntityID,
        isLoadingEntities:  state.entities.isLoading,
        isLoadingEntityProfiles:  state.entityProfiles.isLoading,
        isLoadingGroups:  state.groups.isLoading,
        permissions: state.permissions
    }
}

export default connect(mapStateToProps)(SettingsPage)
 
