import React, {Component} from 'react'
import {Modal, Typography, Button, ButtonBase, IconButton, Divider, InputBase} from '@material-ui/core'
import {Close} from '@material-ui/icons'
import {Copy} from 'react-feather'
import {toast} from 'react-toastify'

import {isEmpty} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import ActivityIndicatorComponent from './ActivityIndicatorComponent'

import '../assets/scss/SynciCalComponent.scss'

class SynciCalComponent extends Component {
    componentDidMount() {
        this.props.dispatch(RESOURCES.users.retrieve('me'))
    }

    handleCopy = () => {
        const {user} = this.props

        navigator.clipboard.writeText(user.icalendar_url)
        toast.success('Copiat in clipboard!')
    }

    render() {
        const {user, isLoading, showSynciCalComponent, handleCloseSynciCalComponent} = this.props
        
        return (
            <Modal open={showSynciCalComponent} onClose={handleCloseSynciCalComponent}>
                <div className="sync-ical-component">
                    <div className="sync-ical-main-div">
                        <div className="left">
                            <div className="content-div">
                                <div>
                                    <Typography className='title'>Sincronizare calendar alte sisteme</Typography>
                                    <Typography className='info-text'>
                                        Link-ul de mai jos este un fișier .ics generat special pentru tine. 
                                    </Typography>
                                    <Typography className='info-text'>
                                        .ics este un format de fișier care se poate importa în majoritatea 
                                        softurilor de calendar (Google Calendar, Outlook, Sunrise etc).
                                    </Typography>
                                </div>
                                <div>
                                    {!isLoading && !isEmpty(user.icalendar_url) ? (
                                        <>
                                            <div className="input-div">
                                                <InputBase className='ical-link' value={user.icalendar_url} readOnly />
                                                <ButtonBase className='copy' onClick={this.handleCopy}>
                                                    <Copy className='icon' />
                                                </ButtonBase>
                                            </div>
                                            <div className="buttons-div">
                                                <Button size='large' variant='contained' onClick={this.handleCopy}>
                                                    <Typography>Copiaza link-ul</Typography>
                                                </Button>
                                            </div>
                                        </>
                                    ) : isLoading ? (
                                        <ActivityIndicatorComponent />
                                    ) : isEmpty(user.icalendar_url) ? (
                                        <Typography>
                                            Nu am putut încărca link-ul. Te rugăm încearcă din nou!
                                        </Typography>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="right"> 
                            <div className="sync-ical-header">
                                <IconButton className="close-modal-button" onClick={handleCloseSynciCalComponent}>
                                    <Close />
                                </IconButton>
                            </div>
                            <div className="content-div">
                                <div className="top">
                                    <Typography className='title'>Instrucțiuni</Typography>
                                    <Typography className='subtitle'>
                                        Ajutor pentru integrarea calendarului din AvoApp cu alte servicii externe 
                                    </Typography>
                                </div>
                                <div className="bottom">
                                    <div>
                                        <Divider className='divider' />
                                        <Typography className='info-text'>
                                            Îți sugerăm să ți link-ul secret. Oricine are acest link poate să
                                            vadă sarcinile tale din calendarul Monitor Dosare.
                                        </Typography>
                                    </div>
                                    <div>
                                        <Divider className='divider' />
                                        <Typography className='info-text'>
                                            Dacă ai suspiciuni că linkul este accesat de altcineva decăt de tine,
                                            apasă pe butonul 'Resetează link' pentru a genera un nou link secret, 
                                            moment în care orice link anterior devine inaccesibil. Noul link generat 
                                            va trebui importat din nou în softul de calendar pe care îl folosești.
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = function(state) {
    return {
        user: state.users.user,
        isLoading: state.users.isLoading
    }
}

export default connect(mapStateToProps)(SynciCalComponent)