import {createStore as createReduxStore, applyMiddleware, combineReducers} from 'redux'
import createSagaMiddleware from 'redux-saga'
import storage from 'redux-persist/lib/storage'
import {all} from 'redux-saga/effects'
import {connectRouter} from 'connected-react-router'
import {createBrowserHistory} from 'history'
import {routerMiddleware} from 'connected-react-router'

import {persistReducer, persistStore} from 'redux-persist'

import {
    ambassadorCodes,
    ambassadorCodeUsages,
    api,
    appeals,
    auth,
    billing,
    clients,
    clientPermissions,
    companies,
    contactPersons,
    entities,
    entityPlans,
    entityPreferences,
    entityProfiles,
    entityReports,
    googleCalendars,
    googleProfile,
    groups,
    hearings,
    litigations,
    localConfigs,
    notifications,
    parties,
    partySubscriptions,
    permissions,
    phases,
    plans,
    projectPermissions,
    projects,
    promoCodeUsages,
    subscriptions,
    terms,
    tickets,
    users,
    usersPreferences
} from 'avoapp-react-common'

import {reducer as appReducer} from './app'
import {reducer as errorsModalReducer} from './errorsModal'
import routerLocations from './router'

import {env, environmentTypes} from '../assets/utils'

import {saga as ambassadorCodeUsagesSaga} from './ambassadorCodeUsages'
import {saga as appSaga} from './app'
import {saga as authSaga} from './auth'
import {saga as appealsSaga} from './appeals'
import {saga as billingSaga} from './billing'
import {saga as clientPermissionsSaga} from './clientPermissions'
import {saga as clientsSaga} from './clients'
import {saga as companiesSaga} from './companies'
import {saga as contactPersonsSaga} from './contactPersons'
import {saga as entitiesSaga} from './entities'
import {saga as entityProfilesSaga} from './entityProfiles'
import {saga as entityPlansSaga} from './entityPlans'
import {saga as entityPreferencesSaga} from './entityPreferences'
import {saga as googleCalendarsSaga} from './googleCalendars'
import {saga as googleProfileSaga} from './googleProfile'
import {saga as groupsSaga} from './groups'
import {saga as hearingsSaga} from './hearings'
import {saga as litigationsSaga} from './litigations'
import {saga as localConfigsSaga} from './localConfigs'
import {saga as partiesSaga} from './parties'
import {saga as partySubscriptionsSaga} from './partySubscriptions'
import {saga as permissionsSaga} from './permissions'
import {saga as phasesSaga} from './phases'
import {saga as projectPermissionsSaga} from './projectPermissions'
import {saga as projectsSaga} from './projects'
import {saga as promoCodeUsagesSaga, promoModalReducer} from './promoCodeUsages'
import {saga as subscriptionsSaga} from './subscriptions'
import {saga as ticketsSaga} from './tickets'
import {saga as usersSaga} from './users'
import {saga as usersPreferencesSaga} from './usersPreferences'

function* rootSaga() {
    yield all([
        ambassadorCodeUsagesSaga(),
        appSaga(),
        appealsSaga(),
        api.saga(),
        authSaga(),
        billingSaga(),
        clientPermissionsSaga(),
        clientsSaga(),
        companiesSaga(),
        contactPersonsSaga(),
        entitiesSaga(),
        entityProfilesSaga(),
        entityPlansSaga(),
        entityPreferencesSaga(),
        googleCalendarsSaga(),
        googleProfileSaga(),
        groupsSaga(),
        hearingsSaga(),
        litigationsSaga(),
        localConfigsSaga(),
        partiesSaga(),
        partySubscriptionsSaga(),
        permissionsSaga(),
        phasesSaga(),
        projectPermissionsSaga(),
        projectsSaga(),
        promoCodeUsagesSaga(),
        subscriptionsSaga(),
        ticketsSaga(),
        usersSaga(),
        usersPreferencesSaga()
    ])
}

const persistConfig = {
    timeout: 0,
    key: 'cache',
    storage: storage,
    whitelist: ['users', 'localConfigs']
}

const rootReducer = (history) =>
    combineReducers({
        ambassadorCodes: ambassadorCodes.reducer,
        ambassadorCodeUsages: ambassadorCodeUsages.reducer,
        api: api.reducer,
        app: appReducer,
        appeals: appeals.reducer,
        auth: auth.reducer,
        billing: billing.reducer,
        clients: clients.reducer,
        clientPermissions: clientPermissions.reducer,
        companies: companies.reducer,
        contactPersons: contactPersons.reducer,
        entities: entities.reducer,
        entityPlans: entityPlans.reducer,
        entityPreferences: entityPreferences.reducer,
        entityProfiles: entityProfiles.reducer,
        entityReports: entityReports.reducer,
        errorsModal: errorsModalReducer,
        googleCalendars: googleCalendars.reducer,
        googleProfile: googleProfile.reducer,
        groups: groups.reducer,
        hearings: hearings.reducer,
        litigations: litigations.reducer,
        localConfigs: localConfigs.reducer,
        notifications: notifications.reducer,
        parties: parties.reducer,
        partySubscriptions: partySubscriptions.reducer,
        permissions: permissions.reducer,
        phases: phases.reducer,
        plans: plans.reducer,
        projectPermissions: projectPermissions.reducer,
        projects: projects.reducer,
        promoCodeUsages: promoCodeUsages.reducer,
        promoModal: promoModalReducer,
        router: connectRouter(history),
        routerLocations: routerLocations,
        subscriptions: subscriptions.reducer,
        terms: terms.reducer,
        tickets: tickets.reducer,
        users: users.reducer,
        usersPreferences: usersPreferences.reducer
    })

export const history = createBrowserHistory()

const persistedRootReducer = persistReducer(persistConfig, rootReducer(history))

export function createRootStore(initialState = {}) {
    const sagaMiddleware = createSagaMiddleware()

    const store = createReduxStore(
        persistedRootReducer,
        initialState,
        applyMiddleware(routerMiddleware(history), sagaMiddleware)
    )

    sagaMiddleware.run(rootSaga)

    if (env === environmentTypes.DEV) {
        store.subscribe(() => {
            console.log(store.getState())
        })
    }

    return store
}

export const store = createRootStore()
export const persistor = persistStore(store)
