import {Button, CircularProgress, Typography} from '@material-ui/core'
import React, {Component} from 'react'
import {Link} from 'react-router-dom'

import {lightFormat} from 'date-fns'
import {isEmpty} from 'lodash'

import {toReadableDuration} from 'avoapp-react-common/dist/utils'
import {connect} from 'react-redux'

class MySubscription extends Component {
    componentDidMount = () => {
        const {shouldScroll} = this.props

        if(shouldScroll) {
            this.mySubscriptionRef.scrollIntoView({block: 'center'})
        }
    };

    getUserData = (user) => {
        switch (user.plans.current.plan.type) {
            case 'free':
                return {
                    detailsFor: 'abonamentul tău gratuit',
                    price: 'Gratuit',
                    payFrequency: '-',
                    paidUntil: '-',
                    buttonText: 'Vreau să activez un abonament plătit'
                }
            case 'trial':
                return {
                    detailsFor: 'contului demo gratuit',
                    price: '-',
                    payFrequency: '-',
                    paidUntil: '-',
                    buttonText: 'Vreau să activez un abonament plătit'
                }
            case 'paid':
                return {
                    detailsFor: 'abonamentului tău',
                    price: user.plans.current.price + '€',
                    payFrequency: user.plans.current.charging_interval_in_months + ' luni',
                    paidUntil: lightFormat(new Date(user.plans.current.current_payment.interval.upper), 'dd/MM/yyyy'),
                    buttonText: 'Schimbă abonament'
                }
            default: return {}
        }
    }
    
    render() {
        const {currentEntity, isLoadingEntities, entityReports, permissions} = this.props

        return (
            <div className="card-large" ref={(ref) => {this.mySubscriptionRef = ref}}>
                <div className="card-main-div" id='my-subscription-settings-card'>
                    {!isLoadingEntities && !isEmpty(currentEntity) ? (
                        <>
                            <div className="card-header">
                                <div className="card-header-title-div">
                                    <Typography className="card-header-title">Abonamentul meu</Typography>
                                </div>
                                {!isLoadingEntities && !isEmpty(currentEntity) && 
                                    (permissions.plans.create || permissions.plans.update) &&
                                        <Link to='/subscription-plans' style={{textDecoration: 'none'}}>
                                            <Button variant="contained" className="card-header-button">
                                                <Typography>{this.getUserData(currentEntity).buttonText}</Typography>
                                            </Button>
                                        </Link>
                                }
                            </div>
                            <div className="card-body">
                                <div className='cards-container'> 
                                    <div className='card-body-element'>
                                        <Typography className='title'>Abonări la date:</Typography>
                                        <Typography className='value'>
                                            {entityReports.paid_subscriptions_md}
                                            {` (${entityReports.active_subscriptions + 
                                        entityReports.party_subscriptions} active)`}
                                        </Typography>
                                    </div>
                                    <div className='card-body-element'>
                                        <Typography className='title'>Utilizatori:</Typography>
                                        <Typography className='value'>{entityReports.members}</Typography>
                                    </div>
                                    <div className='card-body-element'>
                                        <Typography className='title'>Frecvență monitorizare:</Typography>
                                        <Typography className='value'> 
                                            {toReadableDuration(currentEntity.plans.current.frequency)}
                                        </Typography>
                                    </div>
                                    <div className='card-body-element'>
                                        <Typography className='title'>Cost:</Typography>
                                        <Typography className='value'>
                                            {this.getUserData(currentEntity).price}
                                        </Typography>
                                    </div>
                                    <div className='card-body-element'>
                                        <Typography className='title'>Frecvență plată:</Typography>
                                        <Typography className='value'>
                                            {this.getUserData(currentEntity).payFrequency}
                                        </Typography>
                                    </div>
                                    <div className='card-body-element'>
                                        <Typography className='title'>Activ pâna la:</Typography>
                                        <Typography className='value'>
                                            {this.getUserData(currentEntity).paidUntil}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : isLoadingEntities ? (
                        <div className="card-body">
                            <div className="center-loader">
                                <CircularProgress className="circular-progress" thickness={5} color="primary" />
                            </div>
                        </div>
                    ) : isEmpty(currentEntity) ? (
                        <div className="card-body">
                            <Typography>Nu am putut încărca detaliile abonamentului....</Typography>
                        </div>
                    ) : null}
                </div>                                                    
            </div>
        )
    }
}

const mapStateToProps = function (state) {    
    return {
        currentEntity: state.entities.currentEntity,
        isLoadingEntities: state.entities.isLoading,
        entityReports: state.entityReports.data,
        permissions: state.permissions
    }
}

export default connect(mapStateToProps)(MySubscription)
