import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {
    Dialog,
    DialogTitle,
    DialogActions,
    Button,
    Typography,
    DialogContent,
    DialogContentText
} from '@material-ui/core'

import {changeDateFormat} from 'avoapp-react-common/dist/utils'

export class ArchiveProjectDialog extends Component {
    render() {
        const {
            showArchiveProjectDialog,
            handleCloseArchiveProjectDialog,
            handleArchiveProject,
            projectToArchive
        } = this.props

        return (
            <Dialog
                className='archive-project-dialog'
                open={showArchiveProjectDialog}
                onClose={handleCloseArchiveProjectDialog}
            >
                <DialogTitle>Ești sigur că vrei să arhivezi proiectul "{projectToArchive.name}"?</DialogTitle>
                <DialogActions className='buttons-div'>
                    <Button
                        onClick={handleCloseArchiveProjectDialog}
                        className='close-button'
                    >
                        <Typography className='button-text'>Nu, renunță</Typography>
                    </Button>
                    <Button
                        className='action-button'
                        onClick={() => {
                            handleArchiveProject(projectToArchive)
                        }}
                        autoFocus
                    >
                        <Typography className='button-text'>Da, arhivează</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export class DeleteProjectDialog extends Component {
    render() {
        const {
            showDeleteProjectDialog,
            handleCloseDeleteProjectDialog,
            handleDeleteProject,
            projectToDelete
        } = this.props

        return (
            <Dialog 
                className='delete-project-dialog'
                open={showDeleteProjectDialog}
                onClose={handleCloseDeleteProjectDialog}
            >
                <DialogTitle>Ești sigur că vrei să ștergi proiectul "{projectToDelete.name}"?</DialogTitle>
                <DialogActions className='buttons-div'>
                    <Button 
                        onClick={handleCloseDeleteProjectDialog}
                        className='close-button'
                    >
                        <Typography className='button-text'>Nu, renunță</Typography>
                    </Button>
                    <Button
                        className='action-button'
                        onClick={() => {
                            handleDeleteProject(projectToDelete)
                        }}
                        autoFocus
                    >
                        <Typography className='button-text'>Da, șterge</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export class DeleteSubscriptionDialog extends Component {
    render() {
        const {
            showDeleteSubscriptionDialog,
            handleCloseDeleteSubscriptionsDialog,
            handleDeleteSubscription,
            subscriptionToDelete
        } = this.props

        return (
            <Dialog 
                className='delete-subscription-dialog'
                open={showDeleteSubscriptionDialog}
                onClose={handleCloseDeleteSubscriptionsDialog}
            >
                <DialogTitle>Vrei sa stergi sursa de date pentru {subscriptionToDelete.litigation_number}?</DialogTitle>
                <DialogActions className='buttons-div'>
                    <Button 
                        onClick={handleCloseDeleteSubscriptionsDialog}
                        className='close-button'
                    >
                        <Typography className='button-text'>Nu, renunță</Typography>
                    </Button>
                    <Button
                        className='action-button'
                        onClick={handleDeleteSubscription}
                        autoFocus
                    >
                        <Typography className='button-text'>Da, șterge</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export class DeletePermissionDialog extends Component {
    render() {
        const {
            showDeletePermissionDialog,
            handleCloseDeletePermissionDialog,
            handleDeletePermission
        } = this.props
        
        return (
            <Dialog 
                className='delete-subscription-dialog'
                open={showDeletePermissionDialog}
                onClose={handleCloseDeletePermissionDialog}
            >
                <DialogTitle>Sterge colaborator ?</DialogTitle>
                <DialogActions className='buttons-div'>
                    <Button 
                        onClick={handleCloseDeletePermissionDialog}
                        className='close-button'
                    >
                        <Typography className='button-text'>Nu, renunță</Typography>
                    </Button>
                    <Button
                        className='action-button'
                        onClick={handleDeletePermission}
                        autoFocus
                    >
                        <Typography className='button-text'>Da, șterge</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export class SyncNoSubscriptionsDialog extends Component {
    render() {
        const {showSyncNoSubscriptionsDialog, handleCloseSyncNoSubscriptionsDialog, project} = this.props
        return (
            <Dialog
                className='sync-no-subscription-dialog'
                open={showSyncNoSubscriptionsDialog}
                onClose={handleCloseSyncNoSubscriptionsDialog}
            >
                <DialogTitle> Momentan nu ai nicio abonare la date în acest proiect.</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Dorești să aduagi o abonare la date nouă?
                    </DialogContentText>
                </DialogContent>
                <DialogActions className='buttons-div'>
                    <Button 
                        onClick={handleCloseSyncNoSubscriptionsDialog}
                        className='close-button'
                    >
                        <Typography className='button-text'>Nu, renunță</Typography>
                    </Button>
                    <Link
                        className='action-link' 
                        to={`/projects/${project.id}/add-subscription`}
                        onClick={handleCloseSyncNoSubscriptionsDialog}
                    >
                        <Typography className='link-text'>
                            Da, adaugă o sursă de date nouă
                        </Typography>
                    </Link>
                </DialogActions>
            </Dialog>
        )
    }
}

export class DeletePartyDialog extends Component {
    render() {
        const {
            showDeletePartyDialog,
            handleCloseDeletePartyDialog,
            handleDeleteParty,
            partyToDelete
        } = this.props

        return (
            <Dialog 
                className='delete-subscription-dialog'
                open={showDeletePartyDialog}
                onClose={handleCloseDeletePartyDialog}
            >
                <DialogTitle>Vrei să ștergi partea "{partyToDelete.name}"?</DialogTitle>
                <DialogActions className='buttons-div'>
                    <Button onClick={handleCloseDeletePartyDialog} className='close-button'>
                        <Typography className='button-text'>Nu, renunță</Typography>
                    </Button>
                    <Button className='action-button' onClick={handleDeleteParty} autoFocus>
                        <Typography className='button-text'>Da, șterge</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export class DeleteHearingDialog extends Component {
    render() {
        const {
            showDeleteHearingDialog,
            handleCloseDeleteHearingDialog,
            handleDeleteHearing,
            hearingToDelete
        } = this.props

        return (
            <Dialog 
                className='delete-subscription-dialog'
                open={showDeleteHearingDialog}
                onClose={handleCloseDeleteHearingDialog}
            >
                <DialogTitle>Vrei să ștergi termenul din {changeDateFormat(hearingToDelete.date)}?</DialogTitle>
                <DialogActions className='buttons-div'>
                    <Button onClick={handleCloseDeleteHearingDialog} className='close-button'>
                        <Typography className='button-text'>Nu, renunță</Typography>
                    </Button>
                    <Button className='action-button' onClick={handleDeleteHearing} autoFocus>
                        <Typography className='button-text'>Da, șterge</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export class DeleteAppealDialog extends Component {
    render() {
        const {
            showDeleteAppealDialog,
            handleCloseDeleteAppealDialog,
            handleDeleteAppeal,
            appealToDelete
        } = this.props

        return (
            <Dialog 
                className='delete-subscription-dialog'
                open={showDeleteAppealDialog}
                onClose={handleCloseDeleteAppealDialog}
            >
                <DialogTitle>
                    Vrei să ștergi calea de atac de tip "{appealToDelete.type}" 
                    din {changeDateFormat(appealToDelete.date)}?
                </DialogTitle>
                <DialogActions className='buttons-div'>
                    <Button onClick={handleCloseDeleteAppealDialog} className='close-button'>
                        <Typography className='button-text'>Nu, renunță</Typography>
                    </Button>
                    <Button className='action-button' onClick={handleDeleteAppeal} autoFocus>
                        <Typography className='button-text'>Da, șterge</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}