import {goBack, push} from 'connected-react-router'
import {toast} from 'react-toastify'
import {put, select, takeEvery} from 'redux-saga/effects'

import {values} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

const {parties: resource} = RESOURCES

function* handleCreatePartySuccess(response) {
    const {id: partyID} = response.payload
    const routerLocations = values(yield select((state) => state.routerLocations))

    if (
        routerLocations[routerLocations.length - 2]?.location?.pathname?.includes('/subscriptions/') &&
        !routerLocations[routerLocations.length - 2].location.pathname.includes('/add-manual-phase')
    ) {
        yield put(push(routerLocations[routerLocations.length -2].location.pathname))
    } else {
        yield put(push(`/parties/${partyID}/add-success`))
    }
}

function* handleUpdatePartySuccess() {
    yield(put(goBack()))
    toast.success('Modificările au fost salvate')
}

function* handleDestroyPartySuccess() {
    yield(put(goBack()))
}

export function* saga() {
    yield takeEvery(resource.actions.create.success, handleCreatePartySuccess)
    yield takeEvery(resource.actions.update.success, handleUpdatePartySuccess)
    yield takeEvery(resource.actions.destroy.success, handleDestroyPartySuccess)
}
