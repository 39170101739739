import React, {Component} from 'react'
import {Typography, Button} from '@material-ui/core'
import {Link} from 'react-router-dom'

import {isEmpty} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {PaymentTypes} from 'avoapp-react-common/dist/constants'

import ActivityIndicatorComponent from '../components/ActivityIndicatorComponent'

import '../assets/scss/SubscriptionChangeSuccess.scss'

class SubscriptionChangeSuccess extends Component {
    componentDidMount() {
        this.props.dispatch(RESOURCES.entityPlans.list())
    }

    render() {
        const {isLoading, currentEntity, isLoadingEntities, latestEntityPlan, isLoadingEntityPlans} = this.props
        
        return (
            <>
                {!isLoading && !isEmpty(latestEntityPlan) && !isEmpty(currentEntity) ? (
                    <>
                        <div className="navbar" id="navbar-subscription-change" />
                        <div id="subscription-change-success-main-div">
                            <div className='container'>
                                <img
                                    className='success-image'
                                    src={require('../assets/images/check.svg').default}
                                    alt=''
                                />
                                {latestEntityPlan.latest_payment.type === PaymentTypes.DOWNGRADE ? (
                                    <>
                                        <Typography className='success-text'>
                                            Abonamentul tău a fost modificat cu succes!
                                        </Typography>
                                        <Link to='/' className='link-button'>
                                            <Button size="large" variant="contained" fullWidth >
                                                <Typography>Mergi la proiecte</Typography>
                                            </Button>
                                        </Link>
                                    </>
                                ) : (
                                    <>
                                        <Typography className='success-text'>
                                            Factura proformă a fost trimisă la adresa ta de email!
                                        </Typography>
                                        <Typography className='email'>({currentEntity.email})</Typography>
                                        <a 
                                            href={latestEntityPlan.latest_payment.payment_url} 
                                            target="_blank" 
                                            rel="noopener noreferrer"
                                            className='link-button'
                                        >
                                            <Button size="large" variant="contained" fullWidth >
                                                <Typography>Mergi la plată</Typography>
                                            </Button>
                                        </a>
                                    </>
                                )}
                            </div>
                        </div>
                    </>
                ) : isLoadingEntityPlans || isLoadingEntities ? (
                    <ActivityIndicatorComponent />
                ): null}
            </>
        )
    }
}

const mapStateToProps = function (state) {
    const isLoadingEntities = state.entities.isLoading
    const isLoadingEntityPlans = state.entityPlans.isLoading

    const isLoading = isLoadingEntityPlans && isLoadingEntities

    return {
        isLoadingEntities: isLoadingEntities,
        currentEntity: state.entities.currentEntity,
        isLoadingEntityPlans: isLoadingEntityPlans,
        latestEntityPlan: state.entityPlans.latestEntity,
        isLoading: isLoading
    }
}

export default connect(mapStateToProps)(SubscriptionChangeSuccess)