import React, {Component} from 'react'
import {Typography} from '@material-ui/core'

export default class SubscriptionSyncing extends Component {
    render() {
        const {syncingLitigationNumber} = this.props
        
        return (
            <Typography className='no-data-text'>
                {syncingLitigationNumber} este în curs de actualizare
            </Typography>
        )
    }
}
