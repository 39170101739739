import React, {useEffect, useState} from 'react'
import {Typography, Tabs, Tab, Button, Dialog, DialogTitle, DialogActions} from '@material-ui/core'
import InfiniteScroll from 'react-infinite-scroller'
import {Link} from 'react-router-dom'

import {values, orderBy, isNull} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {OrderingTypes} from 'avoapp-react-common/dist/constants'

import SubscriptionsStatus from '../components/SubscriptionsStatus'
import LoadingMoreComponent from '../components/LoadingMoreComponent'

import '../assets/scss/DeleteSubscriptions.scss'

const tabs = {
    SUBSCRIPTIONS: 'SUBSCRIPTIONS',
    PARTY_SUBSCRIPTIONS: 'PARTY_SUBSCRIPTIONS'
}

const modalTypes = {
    DELETE_SUBSCRIPTION: 'DELETE_SUBSCRIPTION',
    DELETE_PARTY_SUBSCRIPTION: 'DELETE_PARTY_SUBSCRIPTION',
    ARCHIVE_PROJECT: 'ARCHIVE_PROJECT'
}

export const DeleteSubscriptions = ({subscriptions, partySubscriptions,
    isLoadingSubscriptions,
    nextPageSubscriptions,
    isLoadingPartySubscriptions,
    nextPagePartySubscriptions,
    listSubscriptions,
    deleteSubscription,
    listPartySubscriptions,
    deletePartySubscription,
    setProjectInactive
}) => {
    const [selectedTab, setSelectedTab] = useState(tabs.SUBSCRIPTIONS)
    const [showActionModal, setShowActionModal] = useState(false)
    const [selectedSubscription, setSelectedSubscription] = useState(null)
    const [modalType, setModalType] = useState(null)
        
    useEffect(() => {
        listSubscriptions()
        listPartySubscriptions()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getModalPropsFromType = (modalType) => {
        const initialState = {
            text: '',
            action: null
        }

        switch (modalType) {
            case modalTypes.DELETE_SUBSCRIPTION:
                return {
                    text: `Ești sigur că vrei să ștergi sursa de date pentru ${
                        selectedSubscription.litigation_number
                    }?`,
                    actionButtonText: 'Da, șterge',
                    action: () => deleteSubscription(selectedSubscription)
                }
            case modalTypes.ARCHIVE_PROJECT:
                return {
                    text: `Ești sigur că vrei să marchezi proiectul ${selectedSubscription.project.name} ca inactiv?`,
                    actionButtonText: 'Da, marchează ca inactiv',
                    action: () => setProjectInactive(selectedSubscription.project.id)
                }
            case modalTypes.DELETE_PARTY_SUBSCRIPTION:
                return {
                    text: `Ești sigur că vrei să ștergi sursa de date pentru ${selectedSubscription.party_name}?`,
                    actionButtonText: 'Da, șterge',
                    action: () => deletePartySubscription(selectedSubscription)
                }
            default:
                return initialState
        }
        
    }

    const handlePressButton = (subscription, modal) => {
        setShowActionModal(true)
        setSelectedSubscription(subscription)
        setModalType(modal)
    }

    const handleChangeTab = (newTab) => {
        if (selectedTab !== newTab) {
            setSelectedTab(newTab)
        }
    } 

    const loadMore = () => {
        if(
            selectedTab === tabs.SUBSCRIPTIONS &&
            !isNull(nextPageSubscriptions) &&
            !isLoadingSubscriptions
        ) {
            listSubscriptions(nextPageSubscriptions)
        }
        
        if(
            selectedTab === tabs.PARTY_SUBSCRIPTIONS && 
            !isNull(nextPagePartySubscriptions) && 
            !isLoadingPartySubscriptions
        ) {
            listPartySubscriptions(nextPagePartySubscriptions)
        }
    }

    const hasMore =
        (selectedTab === tabs.SUBSCRIPTIONS && !isNull(nextPageSubscriptions)) ||
        (selectedTab === tabs.PARTY_SUBSCRIPTIONS && !isNull(nextPagePartySubscriptions))
            
    return (
        <div>
            <div className="navbar" id="navbar-delete-subscriptions">
                <div className="navbar-top">
                    <Typography className="navbar-title">Șterge abonări</Typography>
                    <Link to="/projects/add" style={{textDecoration: 'none'}}>
                        <Button className="navbar-main-button" variant="contained" size="large" >
                            <Typography className="navbar-button-title">Mergi la adaugă proiect</Typography>
                        </Button>
                    </Link>
                </div>
                <div className="navbar-bottom">
                    <Tabs
                        value={selectedTab}
                        onChange={(_e, newValue) => handleChangeTab(newValue)}
                        style={{width: '325px'}}
                        textColor="primary"
                    >
                        <Tab label="Din proiecte" value={tabs.SUBSCRIPTIONS} className="tab" disableRipple />
                        <Tab label="Din clienți" value={tabs.PARTY_SUBSCRIPTIONS} className="tab" disableRipple />
                    </Tabs>
                </div>
            </div>
            <div id='delete-subscriptions-main-div'>
                <SubscriptionsStatus />
                <InfiniteScroll
                    className='cards-container'
                    pageStart={1}
                    loadMore={loadMore}
                    hasMore={hasMore}
                    loader={
                        <LoadingMoreComponent loadingText='Se încarcă...' key='load-more' />
                    }
                >
                    {selectedTab === tabs.SUBSCRIPTIONS ? (
                        subscriptions.map((subscription) => (
                            <div className="card" key={subscription.id}>
                                <div className="card-header">
                                    <Typography className="card-title">
                                        {subscription.litigation_number}
                                    </Typography>
                                </div>
                                <div className="card-content">
                                    <div className="card-content-child">
                                        <Typography className="title"> Proiect: </Typography>
                                        <Typography className="subtitle">
                                            {subscription.project.name}
                                        </Typography>
                                    </div>
                                    <div className="card-content-child buttons-div">
                                        <Button 
                                            onClick={() => 
                                                handlePressButton(subscription, modalTypes.DELETE_SUBSCRIPTION)
                                            }
                                            className='delete-button'
                                            variant='outlined'
                                            fullWidth
                                        >
                                            <Typography className='button-text'>
                                                Șterge abonarea
                                            </Typography>
                                        </Button>
                                        <Button
                                            onClick={() =>                                                     
                                                handlePressButton(subscription, modalTypes.ARCHIVE_PROJECT)
                                            }
                                            className='archive-button'
                                            variant='outlined'
                                            fullWidth
                                        >
                                            <Typography className='button-text'>
                                                Marchează proiectul ca inactiv
                                            </Typography>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : selectedTab === tabs.PARTY_SUBSCRIPTIONS ? (
                        partySubscriptions.map((partySubscription) => (
                            <div className="card" key={partySubscription.id}>
                                <div className="card-header">
                                    <Typography className="card-title">
                                        {partySubscription.party_name}
                                    </Typography>
                                </div>
                                <div className="card-content">
                                    <div className="card-content-child">
                                        <Typography className="title"> Proiect: </Typography>
                                        <Typography className="subtitle">
                                            {partySubscription.client_name}
                                        </Typography>
                                    </div>
                                    <div className="card-content-child buttons-div">
                                        <Button 
                                            onClick={() => 
                                                handlePressButton(
                                                    partySubscription,
                                                    modalTypes.DELETE_PARTY_SUBSCRIPTION
                                                )
                                            }
                                            className='delete-button'
                                            variant='outlined'
                                            fullWidth
                                        >
                                            <Typography className='button-text'>
                                                Șterge abonarea
                                            </Typography>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : null}
                </InfiniteScroll>
            </div>
            <ActionsDialog 
                showActionModal={showActionModal}
                handleClose = {() => setShowActionModal(false)}
                getModalPropsFromType={getModalPropsFromType}
                selectedSubscription={selectedSubscription}
                type={modalType}
            />
        </div>
    )
}

function ActionsDialog({type, getModalPropsFromType, showActionModal, handleClose}) {
    const {text, action, actionButtonText} = getModalPropsFromType(type)

    return (
        <Dialog open={showActionModal} onClose={handleClose} className='actions-dialog'>
            <DialogTitle>{text}</DialogTitle>
            <DialogActions className='buttons-div'>
                <Button 
                    onClick={handleClose}
                    className='close-button'
                >
                    <Typography className='button-text'>
                        Nu, renunță
                    </Typography>
                </Button>
                <Button
                    onClick={() => {
                        action()
                        handleClose()
                    }}
                    className='action-button'
                    autoFocus
                >
                    <Typography className='button-text'>
                        {actionButtonText}
                    </Typography>
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const mapStateToProps = (state) => ({ 
    subscriptions: orderBy(
        state.subscriptions.data,
        [(subscription) => subscription.interval ? parseInt(subscription.interval) : null, 'id'],
        ['asc', 'desc']
    ),
    isLoadingSubscriptions: state.subscriptions.isLoading,
    nextPageSubscriptions: state.subscriptions.next,
    partySubscriptions: values(state.partySubscriptions.data),
    isLoadingPartySubscriptions: state.partySubscriptions.isLoading,
    nextPagePartySubscriptions: state.partySubscriptions.next
})

const mapDispatchToProps = (dispatch) => ({
    listSubscriptions: (page = 1) => dispatch(RESOURCES.subscriptions.list({
        project__active: true,
        ordering: OrderingTypes.INTERVAL_ASC,
        page
    })),
    deleteSubscription: (subscription) => dispatch(RESOURCES.subscriptions.destroy(subscription)),
    listPartySubscriptions: (page = 1) => dispatch(RESOURCES.partySubscriptions.list({page})),
    deletePartySubscription: (subscription) => dispatch(RESOURCES.partySubscriptions.destroy(subscription)),
    setProjectInactive: (projectId) => dispatch(RESOURCES.projects.update({active: false}, projectId))
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteSubscriptions)

