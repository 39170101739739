import React, {Component} from 'react'
import {Typography, Button, Input, FormControl, InputLabel, Select, MenuItem} from '@material-ui/core'
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import {ArrowDropDown} from '@material-ui/icons'
import {Formik} from 'formik'

import {lightFormat, sub} from 'date-fns'
import DateFnsUtils from '@date-io/date-fns'

import InstitutionSelect from '../InstitutionSelect'

import '../../assets/scss/SubscriptionAdd.scss'

export default class AdvancedCheck extends Component {
    render() {
        const {sourcesArray, institutionsArray, handleCheck} = this.props

        return (
            <Formik
                initialValues={{
                    source: sourcesArray[0].value,
                    litigation_number: '',
                    party_name: '',
                    institution: institutionsArray[0],
                    object: '',
                    start_date: sub(new Date(), {years: 1}),
                    stop_date: new Date()
                }}
                onSubmit={(values) => {
                    handleCheck({
                        source: values.source,
                        party_name: values.party_name,
                        institution: values.institution ? values.institution.value : null,
                        object: values.object ? values.object : null,
                        start_date: lightFormat(new Date(values.start_date), 'yyyy-MM-dd'),
                        stop_date: lightFormat(new Date(values.stop_date), 'yyyy-MM-dd')
                    })
                }}
            >
                {({handleChange, handleSubmit, values, setFieldValue}) => (
                    <>
                        <FormControl style={{width: '100%'}}>
                            <InputLabel id="source">Sursa</InputLabel>
                            <Select
                                labelId="source"
                                value={values.source}
                                IconComponent={() => <ArrowDropDown className='dropdown-icon' />}
                                onChange={(event) => {setFieldValue('source', event.target.value)}}
                                className="subscriptions-select"
                            >
                                {sourcesArray.map((source) => (
                                    <MenuItem key={source.value} value={source.value}>
                                        {source.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl className="subscriptions-add-form-control">
                            <InputLabel>Nume parte</InputLabel>
                            <Input
                                value={values.party_name}
                                onChange={handleChange('party_name')}
                                className="subscriptions-input"
                            />
                        </FormControl>
                        <FormControl className="subscriptions-add-form-control">
                            <InputLabel>Obiect</InputLabel>
                            <Input
                                value={values.object}
                                onChange={handleChange('object')}
                                className="subscriptions-input"
                            />
                        </FormControl>
                        <FormControl className="subscriptions-add-form-control">
                            <InstitutionSelect
                                label='Instituția'
                                value={values.institution}
                                institutionsArray={institutionsArray}
                                handleChange={(value) => setFieldValue('institution', value)}
                            />
                        </FormControl>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <FormControl className="subscriptions-add-form-control date-select">
                                <DatePicker
                                    label="Data start"
                                    value={values.start_date}
                                    onChange={(value) => {setFieldValue('start_date', value)}}
                                    InputProps={{disableUnderline: true}}
                                    format="dd/MM/yyyy"
                                    className="date-picker"
                                    cancelLabel="Închide"
                                    okLabel="Ok"
                                />
                                <ArrowDropDown className='dropdown-icon' />
                            </FormControl>
                        </MuiPickersUtilsProvider>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <FormControl className="subscriptions-add-form-control date-select">
                                <DatePicker
                                    label="Data stop"
                                    value={values.stop_date}
                                    onChange={(value) => {setFieldValue('stop_date', value)}}
                                    InputProps={{disableUnderline: true}}
                                    format="dd/MM/yyyy"
                                    className="date-picker"
                                    cancelLabel="Închide"
                                    okLabel="Ok"
                                />
                                <ArrowDropDown className='dropdown-icon' />
                            </FormControl>
                        </MuiPickersUtilsProvider>
                        <Button size="large" variant="contained" className='submit-button' onClick={handleSubmit}>
                            <Typography className="button-title">Mai departe</Typography>
                        </Button>
                    </>
                )}
            </Formik> 
        )
    }
}
