import React, {Component} from 'react'
import {Modal, Typography, Button, ButtonBase, FormControl, InputLabel, Select, MenuItem} from '@material-ui/core'
import {ArrowDropDown} from '@material-ui/icons'
import {X, Plus} from 'react-feather'

import {values} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {replaceNullWithDash} from 'avoapp-react-common/dist/utils'
import {CRUDTypes, permissionRoles} from 'avoapp-react-common/dist/constants'

import '../assets/scss/AddPermission.scss'

class ClientAddClient extends Component {
    constructor() {
        super()

        this.state = {
            selectedEntityProfileID: '',
            selectedRole: 'manager'
        }
    }

    handleAddPermission = () => {
        const {selectedEntityProfileID, selectedRole} = this.state
        const {client, handleCloseAddPermissionModal} = this.props

        let permissions = []
        let permissionsSubscriptions = []
        let permissionsContactPersons = []

        if (selectedRole === permissionRoles.manager.value) {
            permissions = [
                CRUDTypes.read.number,
                CRUDTypes.update.number,
                CRUDTypes.destroy.number
            ]
            permissionsSubscriptions = [
                CRUDTypes.create.number,
                CRUDTypes.read.number,
                CRUDTypes.update.number,
                CRUDTypes.destroy.number
            ]
            permissionsContactPersons = [
                CRUDTypes.create.number,
                CRUDTypes.read.number,
                CRUDTypes.update.number,
                CRUDTypes.destroy.number
            ]
        } else if(selectedRole === permissionRoles.internalCollaborator.value) {
            permissions = [CRUDTypes.read.number]
            permissionsSubscriptions = [CRUDTypes.read.number]
            permissionsContactPersons = [CRUDTypes.read.number]
        }

        this.props.dispatch(RESOURCES.clientPermissions.create({
            client_id: client.id,
            entity_profile_id: selectedEntityProfileID,
            role: selectedRole,
            permissions: permissions,
            permissions_subscriptions: permissionsSubscriptions,
            permissions_contact_persons: permissionsContactPersons
        }))

        handleCloseAddPermissionModal()
    }

    render() {
        const {client, showAddPermissionModal, handleCloseAddPermissionModal, entityProfiles} = this.props
        const {selectedEntityProfileID, selectedRole} = this.state

        return (
            <Modal open={showAddPermissionModal} onClose={handleCloseAddPermissionModal}>
                <div className='add-permission-main-div'>
                    <div className="header">
                        <ButtonBase onClick={handleCloseAddPermissionModal} className='close-button'>
                            <X className='close-button-icon' />
                        </ButtonBase>
                    </div>
                    <div className="content">
                        <Typography className='title'>
                            Adaugă un manager sau un colaborator in clientul{' '}
                            <span className='title-span'>{client.name}</span>
                        </Typography>
                        <FormControl className='styled-form-control'>
                            <InputLabel id="user-select">Utilizator</InputLabel>
                            <Select
                                labelId="user-select"
                                value={selectedEntityProfileID}
                                IconComponent={() => <ArrowDropDown className='dropdown-icon' />}
                                onChange={(event) => {
                                    this.setState({selectedEntityProfileID: event.target.value})
                                }}
                            >
                                {entityProfiles.map((profile) => (
                                    <MenuItem key={profile.id} value={profile.id}>
                                        {replaceNullWithDash(profile.last_name)}{' '}
                                        {replaceNullWithDash(profile.first_name)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl className='styled-form-control'>
                            <InputLabel id="role-select">Rol</InputLabel>
                            <Select
                                labelId="role-select"
                                value={selectedRole}
                                IconComponent={() => <ArrowDropDown className='dropdown-icon' />}
                                onChange={(event) => {
                                    this.setState({selectedRole: event.target.value})
                                }}
                            >
                                {values(permissionRoles).map((role) => (
                                    <MenuItem key={role.value} value={role.value}>
                                        {role.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <Button
                        fullWidth
                        size="large"
                        variant='contained'
                        className='action-button'
                        onClick={this.handleAddPermission}
                        disabled={!selectedEntityProfileID || !selectedRole}
                    >
                        <Typography className='button-text'>Adaugă acest utilizator</Typography>
                        <Plus size={16} className='button-icon' />
                    </Button>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = function (state) {
    return {
        client: state.clients.currentClient,
        isLoading: state.clients.isLoading,
        entityProfiles: values(state.entityProfiles.data),
        selectedEntityID: state.localConfigs.selectedEntityID
    }
}

export default connect(mapStateToProps)(ClientAddClient)