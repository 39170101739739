import React from 'react'
import {Link} from 'react-router-dom'
import {Button, Checkbox, Input, Typography} from '@material-ui/core'
import IntlTelInput from 'react-intl-tel-input/dist/components/IntlTelInput'
import {ArrowBack} from '@material-ui/icons'
import {Form, Formik} from 'formik'

import {isEmpty, values as _values} from 'lodash'

import {connect} from 'react-redux'
import {signup} from 'avoapp-react-common/dist/redux/auth'
import {getErrorFromCode} from 'avoapp-react-common/dist/utils'

import PromoComponent from '../components/PromoComponent'

import '../assets/scss/Register.scss'

const Register = (props) => {
    const {authErrors, isLoading} = props

    const isDisabled = (values) => {
        return (
            isLoading ||
            isEmpty(values.email) || 
            isEmpty(values.password) || 
            isEmpty(values.firstName) ||
            isEmpty(values.lastName) || 
            isEmpty(values.phone) ||
            values.terms === false
        )
    }

    return (
        <div className="register-main-div">
            <div className="left">
                <div className="left-main-div">
                    <div className="header-div">
                        <img 
                            src={require('../assets/images/monitor_dosare_logo_light.svg').default}
                            alt="monitordosare-logo" 
                        />
                        <div className="create-account-text">
                            <Typography className='header-title'>
                                Ești la un pas de <br />
                                <span>monitorizarea dosarelor</span>
                            </Typography>
                            <Typography className='header-helper'>
                                Creează un cont gratuit completând datele de mai jos.
                            </Typography>
                        </div>
                    </div>
                    {!isEmpty(authErrors) && isEmpty(authErrors.detail) 
                            && _values(authErrors).map((errorCategories) =>
                                _values(errorCategories).map((error, index) => (
                                    <div key={index} className='error-div'>
                                        <Typography className='error-text'>
                                            {getErrorFromCode(error, 'register')}
                                        </Typography>
                                    </div>
                                ))
                            )}
                    <Formik
                        initialValues={{
                            email: '',
                            password: '',
                            firstName: '',
                            lastName: '',
                            phone: '+40',
                            terms: false
                        }}
                        onSubmit={(values) => props.dispatch(
                            signup({
                                email: values.email,
                                password1: values.password,
                                password2: values.password,
                                first_name: values.firstName,
                                last_name: values.lastName,
                                phone: values.phone
                            })
                        )}
                    >
                        {({handleChange, setFieldValue, values}) => (
                            <Form className="styled-form">
                                <Input
                                    placeholder="Adresă de email"
                                    value={values.email}
                                    onChange={handleChange('email')}
                                    className="input"
                                    type="email"
                                    fullWidth
                                />
                                <Input
                                    placeholder="Parola"
                                    value={values.password}
                                    onChange={handleChange('password')}
                                    className="input"
                                    type="password"
                                    fullWidth
                                />
                                <Input
                                    placeholder="Nume"
                                    value={values.lastName}
                                    onChange={handleChange('lastName')}
                                    className="input"
                                    fullWidth
                                />
                                <Input
                                    placeholder="Prenume"
                                    value={values.firstName}
                                    onChange={handleChange('firstName')}
                                    className="input"
                                    fullWidth
                                />
                                <div className='phone-input-div input'>
                                    <IntlTelInput
                                        value={values.phone}
                                        formatOnInit={false}
                                        nationalMode={false}
                                        defaultCountry='ro'
                                        preferredCountries={['ro']}
                                        onPhoneNumberChange={(valid, phoneNumber) => {
                                            setFieldValue('phone', phoneNumber)
                                        }}
                                    />
                                </div>
                                <div className="terms-checkbox">
                                    <Checkbox
                                        checked={values.terms}
                                        onChange={handleChange('terms')}
                                        color="primary"
                                    />
                                    <Typography className='terms-checkbox-text'>
                                        Sunt de acord cu 
                                        <a 
                                            href={`${window.location.origin}/terms-and-conditions`}
                                            className='terms-link' 
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            Termenii și Condițiile
                                        </a>
                                    </Typography>
                                </div>      
                                <Button
                                    size="large" 
                                    variant="contained" 
                                    type="submit" 
                                    disabled={isDisabled(values)}
                                    fullWidth
                                >
                                    <Typography>Creează cont</Typography>
                                </Button>
                            </Form>
                        )}
                    </Formik>
                    <Link className="back-button" to="/login">
                        <div className="icon-div">
                            <ArrowBack />
                        </div>
                        <Typography className="back-button-text">Mergi la autentificare</Typography>
                    </Link>
                </div>
            </div>
            <div className="right" >
                <PromoComponent />
            </div>
        </div>
    )
}

const mapStateToProps = function(state) {
    return {
        authErrors: state.auth.errors,
        isLoading: state.auth.isLoading
    }
}

export default connect(mapStateToProps)(Register)
