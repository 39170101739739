import React, {Component} from 'react'
import {Button, Input, Typography} from '@material-ui/core'
import {Link} from 'react-router-dom'

import {isEmpty, values} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {getErrorFromCode} from 'avoapp-react-common/dist/utils'

import PermissionsSelect from './PermissionsSelect'

import {getResourcePermissions, getResourcesForGroups} from '../assets/utils'

import '../assets/scss/Groups.scss'

class GroupAdd extends Component {
    constructor() {
        super() 

        this.state = {
            groupName: '',
            localResources: getResourcesForGroups()
        }
    }
    
    handleGivePermission = (resource, permission, permissionValue) => {
        let localResources = {...this.state.localResources}
        localResources[resource.name]['permissions'][permission] = permissionValue
        this.setState({localResources})    
    }

    handleChangeGroupName = (value) => this.setState({groupName: value})

    handleAddGroup = () => {
        const {selectedEntityID} = this.props
        const {groupName, localResources} = this.state

        let newGroupData = {
            entity_id: selectedEntityID,
            name: groupName,
            permissions_entity: getResourcePermissions(localResources.entity),
            permissions_projects:  getResourcePermissions(localResources.projects),
            permissions_clients: getResourcePermissions(localResources.clients),
            permissions_members: getResourcePermissions(localResources.members),
            permissions_plans: getResourcePermissions(localResources.plans)
        }

        this.props.dispatch(RESOURCES.groups.create({...newGroupData}))
    }
    
    render() {
        const {isLoading, errors} = this.props
        const {groupName, localResources} = this.state

        return (
            <>
                <div className="navbar" id="navbar-groups">
                    <div className="navbar-left">
                        <Typography className="navbar-title">Adaugă un grup</Typography>
                    </div>
                    <Link style={{textDecoration: 'none'}} to='/settings'>
                        <Button className="cancel-button">
                            <Typography className="cancel-button-title">Renunță</Typography>
                        </Button>
                    </Link>
                </div>
                <div id="groups-main-div">
                    <div className="left">
                        <Typography className="title">
                            Adaugă
                            <br /> un grup
                        </Typography>
                    </div>
                    <div className="right">
                        {!isEmpty(errors) && values(errors).map((errorCategories) =>
                            errorCategories.map((error, index) => (
                                <div key={index} className='error-div'>
                                    <Typography className='error-text'>
                                        {getErrorFromCode(error, 'groups')}
                                    </Typography>
                                </div>
                            ))
                        )}
                        <div className="add-group-form">
                            <Typography className="step-title">Alege un nume</Typography>
                            <Input
                                placeholder="Nume grup"
                                className='group-name-input'
                                value={groupName}
                                onChange={(event) => {
                                    this.handleChangeGroupName(event.target.value)
                                }}
                            />
                            <Typography className="step-title">Alege permisiunile grupului</Typography>
                            <PermissionsSelect
                                resources={localResources}
                                handleGivePermission={this.handleGivePermission}
                            />
                            <Button
                                fullWidth
                                size="large"
                                variant="contained"
                                className='add-group-button'
                                onClick={this.handleAddGroup}
                                disabled={isLoading || isEmpty(groupName)}
                            >
                                <Typography className="button-title">Adaugă grup</Typography>
                            </Button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = function(state) {
    return {
        selectedEntityID: state.localConfigs.selectedEntityID,
        groups: state.groups.data,
        isLoading: state.groups.isLoading,
        errors: state.groups.errors
    }
}

export default connect(mapStateToProps)(GroupAdd)