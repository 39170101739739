import React from 'react'

import {connect} from 'react-redux'
import {errorsModalTypes} from '../../../redux/errorsModal'

import ErrorsModal from '../ErrorsModal'

const Error500Modal = ({open}) => {
    return (
        <ErrorsModal
            open={open}
            messageContent={() => (
                <p className="avo-errors-modal-message">
                    A apărut o eroare, te rugăm să încerci mai târziu. Dacă eroarea persistă, te rugăm sa ne{' '}
                    <a href='/support'>contactezi</a>!
                </p>
            )}
        />
    )
}

const mapStateToProps = (state) => ({
    open: state.errorsModal.type === errorsModalTypes.ERROR_500
})

export default connect(mapStateToProps)(Error500Modal)