import {takeEvery, put} from 'redux-saga/effects'
import {push} from 'connected-react-router'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

const {ambassadorCodeUsages: resource} = RESOURCES

function* handleCreateAmbassadorCodeUseSuccess() {
    yield put(push('/ambassador-code-activated'))
}

export function* saga() {
    yield takeEvery(resource.actions.create.success, handleCreateAmbassadorCodeUseSuccess)
}
