import {Button, Modal, Typography} from '@material-ui/core'
import React from 'react'

import {connect} from 'react-redux'
import {closeErrorsModal} from '../../redux/errorsModal'

import './ErrorsModal.scss'

const ErrorsModal = ({open, messageContent, closeErrorsModal}) => {
    return (
        <Modal open={open || false} onClose={closeErrorsModal}>
            <div className="avo-errors-modal">
                <div className="avo-errors-modal-content-container">
                    <div className="avo-errors-modal-message-container">
                        {messageContent()}
                    </div>   
                    <Button size="large" variant="contained" onClick={closeErrorsModal}>
                        <Typography>Ok</Typography>
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
    closeErrorsModal: () => dispatch(closeErrorsModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(ErrorsModal)