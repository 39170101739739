import {colors} from './colors'

import {createTheme} from '@material-ui/core/styles'

export const theme = createTheme({
    overrides: {
        MuiButton: {
            text: {
                textTransform: 'initial'
            },
            contained: {
                color: 'white',
                textTransform: 'initial',
                backgroundColor: colors.orange,
                transition: 'all 0.4s ease-in-out',

                '&:hover': {
                    backgroundColor: colors.orange,
                    transform: 'scale(1.05)'
                },
                '&$disabled': {
                    color: colors.darkGrey,
                    backgroundColor: colors.disabledButtonBackground
                }
            },
            containedSizeLarge: {
                padding: '15px 25px'
            },
            outlinedSizeLarge: {
                padding: '15px 25px'
            }
        },
        MuiTab: {
            root: {
                maxWidth: '100%',
                textTransform: 'initial',
                borderRight: `1px solid ${colors.lightGrey}`,
                borderLeft: `1px solid ${colors.lightGrey}`,
                flexShrink: 'inherit',
                overflow: 'hidden',
                whiteSpace: 'inherit',
                textAlign: 'center'
            },
            textColorPrimary: {
                color: colors.darkGrey,
                padding: 0,
                borderBottom: 0,

                '&$selected': {
                    color: colors.orange,
                    borderBottom: 0,
                    fontWeight: 'bold'
                }
            }
        },
        MuiStepIcon: {
            root: {
                color: colors.lightGrey,
                '&$completed': {
                    color: colors.orange
                },
                '&$active': {
                    color: colors.orange
                },
                '&$error': {
                    color: colors.error
                }
            }
        },
        MuiInput: {
            underline: {
                borderBottom: 0
            }
        },
        MuiCircularProgress: {
            colorPrimary: {
                color: colors.orange
            }
        },
        MuiSwitch: {
            colorPrimary: {
                '&$checked': {
                    color: colors.orange
                },
                '&$disabled': {
                    color: colors.lightGrey
                },
                '&$checked + $track': {
                    backgroundColor: colors.orange
                },
                '&$disabled + $track': {
                    backgroundColor: colors.lightGrey
                }
            }
        },
        MuiCheckbox: {
            colorPrimary: {
                color: colors.orange,

                '&$checked': {
                    color: colors.orange
                }
            }
        }
    }
})
