import React, {Component} from 'react'
import {
    Modal,
    Typography,
    Button,
    ButtonBase,
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@material-ui/core'
import {Link} from 'react-router-dom'
import {X, Plus} from 'react-feather'
import {ArrowDropDown} from '@material-ui/icons'

import {isEmpty, values} from 'lodash'

import '../assets/scss/ProjectAddClient.scss'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

class ProjectAddClient extends Component {
    constructor() {
        super()

        this.state = {
            clientToAdd: null
        }
    }
    
    handleAddClient = () =>{
        const {project, handleCloseAddClientModal} = this.props
        const {clientToAdd} = this.state

        this.props.dispatch(
            RESOURCES.projects.update({
                id: project.id,
                clients_ids: [...project.clients_ids, clientToAdd]
            })
        )

        handleCloseAddClientModal()
    }
    
    render() {
        const {
            project,
            isLoading,
            clients,
            isLoadingClients,
            showAddClientModal,
            handleCloseAddClientModal
        } = this.props

        const {clientToAdd} = this.state

        return (
            <Modal open={showAddClientModal} onClose={handleCloseAddClientModal}>
                {!isEmpty(project) && !isLoading && !isLoadingClients ? (
                    <div className='project-add-client-main-div'>
                        <div className="left">
                            <div className="header" />
                            <div className="content">
                                <div className="top-div">
                                    <Typography className='title'>
                                        Selectează clientul pe care vrei să îl adaugi proiectului{' '}
                                        <span className='title-span'>{project.name}</span>
                                    </Typography>
                                    {!isEmpty(clients) ? (
                                        <FormControl className='styled-form-control'>
                                            <InputLabel id="client-select">Client</InputLabel>
                                            <Select
                                                labelId="client-select"
                                                value={clientToAdd}
                                                IconComponent={() => 
                                                    <ArrowDropDown className='dropdown-icon' />
                                                }
                                                onChange={(event) => {
                                                    this.setState({clientToAdd: event.target.value})
                                                }}
                                            >
                                                {clients.map((client) => (
                                                    <MenuItem key={client.id} value={client.id}>
                                                        {client.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        <Typography className='no-data-text'>
                                            Se pare că nu ai adăugat nici un client
                                        </Typography>
                                    )}
                                </div>
                                <Button 
                                    onClick={() => this.handleAddClient(clientToAdd)}
                                    disabled={!clientToAdd}
                                    className='action-button'
                                    variant='contained' 
                                    size="large"
                                    fullWidth
                                >
                                    <Typography className='button-text'>Adaugă acest client</Typography>
                                    <Plus size={16} className='button-icon' />
                                </Button>
                            </div>
                        </div>
                        <div className="right">
                            <div className="header">
                                <ButtonBase onClick={handleCloseAddClientModal} className='close-button'>
                                    <X className='close-button-icon' />
                                </ButtonBase>
                            </div>
                            <div className="content">
                                <div className="top-div">
                                    <Typography className='title'>Client nou ?</Typography>
                                    <Typography className='subtitle'>
                                        Creează un client nou, apoi adaugă-l acestui proiect.
                                    </Typography>
                                </div>
                                <Link 
                                    to={{
                                        pathname: '/clients/add',
                                        state: {projectID: project.id}
                                    }}
                                    style={{textDecoration: 'none'}}
                                >
                                    <Button 
                                        onClick={handleCloseAddClientModal}
                                        className='action-button'
                                        variant='contained' 
                                        size="large"
                                        fullWidth
                                    >
                                        <Typography className='button-text'>Creează client nou</Typography>
                                        <Plus size={16} className='button-icon' />
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                ): (
                    <CircularProgress className="circular-progress" thickness={5} color="primary" />
                )}
            </Modal>
        )
    }
}

const mapStateToProps = function (state) {
    return {
        project: state.projects.currentProject,
        isLoading: state.projects.isLoading,
        clients: values(state.clients.data),
        isLoadingClients: state.clients.isLoading
    }
}

export default connect(mapStateToProps)(ProjectAddClient)