import React, {Component} from 'react'
import {Button, CircularProgress, MenuItem, Select, Switch, Typography} from '@material-ui/core'
import {ArrowDropDown, NotificationsNone} from '@material-ui/icons'
import {Form, Formik} from 'formik'

import {isNull, isEmpty, head} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import {colors} from '../../assets/styles/colors'

const inactiveTimes = [
    {label: '30 de zile', value: '30 00:00:00'},
    {label: '60 de zile', value: '60 00:00:00'},
    {label: '90 de zile', value: '90 00:00:00'}
]

class Notifications extends Component {
    constructor() {
        super()

        this.state = {
            edited: false,
            automaticInactivation: false
        }
    }

    componentDidUpdate(prevProps) {
        const {entityPreferences, isLoading} = this.props

        if (prevProps.entityPreferences !== entityPreferences && !isLoading) {
            this.syncStateWithProps()
        }
    }

    syncStateWithProps = () => {
        const {entityPreferences} = this.props
        const {automaticInactivation} = this.state

        if (isNull(entityPreferences.subscriptions_inactivation_interval) && automaticInactivation !== false) {
            this.setState({automaticInactivation: false})
        } else if (!isNull( entityPreferences.subscriptions_inactivation_interval) && automaticInactivation === false) {
            this.setState({automaticInactivation: true})
        }
    }

    render() {
        const {edited, automaticInactivation} = this.state
        const {currentEntity, entityPreferences, entityPreferencesErrors, isLoading} = this.props

        return (
            <div className="card-large">
                <div className="card-main-div" id='project-settings-card'>
                    {!isLoading && !isEmpty(entityPreferences) && isEmpty(entityPreferencesErrors) ? (
                        <Formik
                            initialValues={{
                                inactivationInterval: entityPreferences.subscriptions_inactivation_interval ||
                                    head(inactiveTimes).value
                            }}
                            onSubmit={(values) => {
                                let interval = null
           
                                if (automaticInactivation) {
                                    interval = values.inactivationInterval
                                }
           
                                this.props.dispatch(RESOURCES.entityPreferences.update({
                                    id: currentEntity.id,
                                    subscriptions_inactivation_interval: interval
                                }))
           
                                this.setState({edited: false})
                            }}
                        >
                            {({handleSubmit, values, setFieldValue}) => (
                                <Form>
                                    <div className="card-header">
                                        <div className="card-header-title-div">
                                            <div className="icon-div"><NotificationsNone className="icon" /></div>
                                            <Typography className="card-header-title">Setări proiecte</Typography>
                                        </div>
                                        <Button
                                            variant="contained"
                                            onClick={handleSubmit}
                                            className="card-header-button"
                                            disabled={!edited}
                                            style={{backgroundColor: !edited ? colors.lightGrey : colors.orange}}
                                        >
                                            <Typography 
                                                className='card-header-button-text' 
                                                style={{color: !edited ? colors.darkGrey : 'white'}}
                                            >
                                                Salvează modificările
                                            </Typography>
                                        </Button>
                                    </div>
                                    <div className="card-body">
                                        <div className='card-body-element'>
                                            <div className="switch-container">
                                                <Typography className='switch-text'>
                                                    Arhivare automată proiecte
                                                </Typography>
                                                <Switch
                                                    checked={automaticInactivation}
                                                    color='primary'
                                                    onChange={() => {
                                                        this.setState(prevState => ({
                                                            edited: true,
                                                            automaticInactivation: !prevState.automaticInactivation
                                                        }))
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className='card-body-element'>
                                            <div className="interval-picker">
                                                <Typography className='interval-picker-text'>
                                                    Interval de la data ultimei modificări
                                                </Typography>
                                                {automaticInactivation ? (
                                                    <Select
                                                        value={values.inactivationInterval} 
                                                        style={{width:'100%'}}
                                                        IconComponent={() => 
                                                            <ArrowDropDown className='dropdown-icon' />
                                                        }
                                                        onChange={(e) => {
                                                            this.setState({edited: true})
                                                            setFieldValue('inactivationInterval', e.target.value)
                                                        }}
                                                    >
                                                        {inactiveTimes.map(interval => (
                                                            <MenuItem key={interval.value} value={interval.value}>
                                                                {interval.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                ) : (
                                                    <Typography className='info-text'>
                                                        Arhivarea automată a proiectelor este oprită
                                                    </Typography>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    ) : !isEmpty(entityPreferencesErrors) ? (
                        <ErrorComponent /> 
                    ) : (
                        <div className="center-loader">
                            <CircularProgress className="circular-progress" thickness={5} color="primary" />
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

class ErrorComponent extends Component {
    render() {
        return (
            <>
                <div className="card-header">
                    <div className="card-header-title-div">
                        <div className="icon-div"><NotificationsNone className="icon" /></div>
                        <Typography className="card-header-title">Setări proiecte</Typography>
                    </div>
                </div>
                <div className="card-body">
                    <Typography className='info-text'>
                        A apărut o eroare. Te rugăm încearcă din nou mai târziu!
                    </Typography>
                </div >
            </>
        )
    }
}

const mapStateToProps = function(state) {
    return {
        currentEntity: state.entities.currentEntity,
        entityPreferences: state.entityPreferences.data,
        entityPreferencesErrors: state.entityPreferences.errors,
        isLoading: state.entityPreferences.isLoading
    }
}

export default connect(mapStateToProps)(Notifications)