import {push} from 'connected-react-router'
import {takeEvery, put} from 'redux-saga/effects'

import {spec} from 'avoapp-react-common'

const {RESOURCES} = spec
const {googleProfile: resource} = RESOURCES

function* handleUpdateGoogleProfileSuccess() {
    if(window.location.pathname !== 'settings') {
        yield put(push('/settings'))
    }
}

export function* saga() {
    yield takeEvery(resource.actions.update.success, handleUpdateGoogleProfileSuccess)
}
