import React, {Component} from 'react'
import {Typography, Tabs, Tab, Button, Drawer, ButtonBase} from '@material-ui/core'
import InfiniteScroll from 'react-infinite-scroller'
import {Clock, X} from 'react-feather'
import {Link} from 'react-router-dom'

import {isEmpty, orderBy, isNull} from 'lodash'

import {lightFormat} from 'date-fns'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {changeTimeFormat} from 'avoapp-react-common/dist/utils'
import {NotificationTypes} from 'avoapp-react-common/dist/constants'

import ActivityIndicatorComponent from '../components/ActivityIndicatorComponent'
import LoadingMoreComponent from '../components/LoadingMoreComponent'

import '../assets/scss/NotificationsList.scss'

const indexMapping = {
    0: 'false',
    1: 'true'
}

export class NotificationsList extends Component {
    constructor() {
        super()

        this.state = {
            selectedIndex: 0,
            showNotificationDetails: false,
            currentNotification: {}
        }
    }

    componentDidMount() {
        this.props.dispatch(RESOURCES.notifications.list({seen: indexMapping[this.state.selectedIndex]}))
    }

    componentDidUpdate(prevProps) {
        const {selectedIndex} = this.state
        const {selectedEntityID, isLoading} = this.props

        if(prevProps.selectedEntityID !== selectedEntityID && !isLoading) {
            this.props.dispatch(RESOURCES.notifications.list({active: indexMapping[selectedIndex]}))
        }
    }

    updateIndex = (selectedIndex) => {
        this.setState({selectedIndex, searchFilter: ''})
        this.props.dispatch(RESOURCES.notifications.list({seen: indexMapping[selectedIndex]}))
    }

    handleClickNotificationCard = (notification) => {
        this.setState({showNotificationDetails: true, currentNotification: notification})

        if (!notification.seen) {
            this.props.dispatch(
                RESOURCES.notifications.update({
                    id: notification.id,
                    seen: 'true'
                })
            )
        }
    }

    closeDetailsDrawer = () => {this.setState({showNotificationDetails: false, currentNotification: {}})}

    getSubtitle = (notification) => {
        switch (notification.type) {
            case NotificationTypes.LITIGATION:
            case NotificationTypes.UPCOMING_HEARING:
                return `La dosarul ${notification.source.litigation_number}:`

            case NotificationTypes.PARTY:
                return `Pentru clientul ${notification.source.client_name}:`

            default:
                return ''
        }
    }

    getMessageFromEvent = (event, entity) => {
        switch (event) {
            case 'new_solution':
                return (
                    <Typography className="notification-details-message">
                        - S-a adăugat o soluție nouă: 
                        {entity.new_solution || entity.solution}.
                    </Typography>
                )
            case 'new_hearing':
                return (
                    <Typography className="notification-details-message">
                        - S-a adăugat o ședință nouă pe 
                        pe {lightFormat(new Date(entity.date), 'dd/MM/yyyy')}
                        ora {changeTimeFormat(entity.time)}.
                    </Typography>
                )
            case 'new_solution_and_hearing':
                return (
                    <>
                        <Typography className="notification-details-message">
                            - S-a adăugat o ședință nouă 
                            pe {lightFormat(new Date(entity.date), 'dd/MM/yyyy ')} 
                            ora {changeTimeFormat(entity.time)}.
                        </Typography>
                        <Typography className="notification-details-message">
                            - S-a adăugat o soluție nouă: {entity.new_solution ? entity.new_solution : entity.solution}.
                        </Typography>
                    </>
                )
            case 'new_appeal':
                return (
                    <Typography className="notification-details-message">
                        -  S-a adăugat o cale nouă de atac la "{entity.court}".
                    </Typography>
                )
            case 'changed_hearing_hour':
                return (
                    <Typography className="notification-details-message">
                        -  S-a schimbat ora ședinței: "{changeTimeFormat(entity.time)}".
                    </Typography>
                )
            case 'new_litigation':
                return (
                    <>
                        <Typography className="notification-details-message">
                            - {entity.length === 1 ? 'S-a adăugat un dosar nou: ' : 'S-au adăugat dosare noi: '}
                        </Typography>
                        {entity.map((litigation, index) => {
                            return (
                                <div key={index}>
                                    <Typography className="notification-details-message litigation-number-text">
                                        - {litigation.number}:
                                    </Typography>
                                    <Typography className="notification-details-message party-text">
                                        Părți:
                                    </Typography>
                                    {litigation.parties.map((party, index) => (
                                        <Typography key={index} className="notification-details-message party-value">
                                            - {party}
                                        </Typography>
                                    ))}
                                </div>
                            )
                        })} 
                    </>
                )
            case 'upcoming_hearing':
                return (
                    <Typography className="notification-details-message">
                        - Aveți o ședință 
                        în data de {lightFormat(new Date(entity.date), 'dd/MM/yyyy')}{' '}
                        la ora {changeTimeFormat(entity.time)}.
                    </Typography>
                )
            default:
                console.log('UNKNOWN EVENT')
                break
        }
    }

    loadMoreNotifications = () => {
        const {selectedIndex} = this.state
        const {nextPage, isLoading} = this.props

        if(!isNull(nextPage) && !isLoading) {
            this.props.dispatch(
                RESOURCES.notifications.list({
                    page: nextPage,
                    seen: indexMapping[selectedIndex]
                })
            )
        }
    }

    render() {
        const {notifications, isLoading, isGettingFirstPage, nextPage} = this.props
        const {selectedIndex, currentNotification, showNotificationDetails} = this.state

        var notificationCards = []
        notificationCards.push(notifications.map((notification ) => (
            <NotificationCard
                key={notification.id}
                notification={notification}
                handleClickCard = {() => {this.handleClickNotificationCard(notification)}}
            />
        )))

        return (
            <div>
                <div className="navbar" id="navbar-notifications-list">
                    <div className="navbar-top">
                        <Typography className="navbar-title">Notificări</Typography>
                    </div>
                    <div className="navbar-bottom">
                        <div className="tabs-div">
                            <Tabs
                                value={selectedIndex}
                                style={{width: '325px'}}
                                textColor="primary"
                                onChange={(data, index) => {
                                    if (index !== selectedIndex) {
                                        this.updateIndex(index)
                                    }
                                }}
                            >
                                <Tab disableRipple className="tab" label="Noi" />
                                <Tab disableRipple className="tab" label="Vazute" />
                            </Tabs>
                        </div>
                    </div>
                </div>
                {!isEmpty(notifications) ? (
                    <div id="notifications-list-main-div">
                        <InfiniteScroll
                            pageStart={1}
                            className='cards-container'
                            hasMore={!isNull(nextPage)}
                            loadMore={this.loadMoreNotifications}
                            loader={
                                <LoadingMoreComponent loadingText='Se încarcă mai multe notificări' key='load-more' />
                            }
                        >
                            {notificationCards}
                        </InfiniteScroll>
                        <Drawer anchor="right" open={showNotificationDetails} onClose={this.closeDetailsDrawer}>
                            <div id="notification-details-drawer">
                                <div className="notification-details-main-div">
                                    {!isEmpty(currentNotification) ? (
                                        <>
                                            <div className="notification-details-header">
                                                <div className="notification-details-header-data">
                                                    <Typography className="notification-details-header-text">
                                                        {lightFormat(
                                                            new Date(currentNotification.sent_moment), 
                                                            'dd/MM/yyyy'
                                                        )}
                                                    </Typography>
                                                    <Clock className="notification-details-header-icon" size={16} />
                                                    <Typography className="notification-details-header-text">
                                                        {lightFormat(
                                                            new Date(currentNotification.sent_moment),
                                                            'HH:mm'
                                                        )}
                                                    </Typography>
                                                </div>
                                                <ButtonBase onClick={this.closeDetailsDrawer}>
                                                    <div className="notification-details-close-button">
                                                        <X
                                                            className="notification-details-close-icon"
                                                            size={14}
                                                            style={{alignSelf: 'center'}}
                                                        />
                                                    </div>
                                                </ButtonBase>
                                            </div>
                                            <div className="notification-details-content">
                                                <Typography className="notification-details-subject">
                                                    {currentNotification.subject}
                                                </Typography>
                                                <div className="notification-details-modifications-div">
                                                    <Typography className="notification-details-modifications-text">
                                                        Modificari
                                                    </Typography>
                                                    <Typography className="notification-details-message">
                                                        {this.getSubtitle(currentNotification)}
                                                    </Typography>
                                                    {currentNotification.changes.map((change, index) => (
                                                        <div key={index}>
                                                            {this.getMessageFromEvent(change.event, change.entity)}
                                                        </div>
                                                    ))} 
                                                </div>
                                            </div>
                                            <div className="notification-details-buttons-div">
                                                {currentNotification.type === 'party' ? (
                                                    <Link
                                                        style={{textDecoration: 'none'}}
                                                        to={'/clients/' + currentNotification.client_id}
                                                    >
                                                        <Button size="large" variant="contained" fullWidth>
                                                            <Typography>Vezi clientul</Typography>
                                                        </Button>
                                                    </Link>
                                                ) : (
                                                    <Link
                                                        style={{textDecoration: 'none'}}
                                                        to={'/projects/' + currentNotification.project_id}
                                                    >
                                                        <Button size="large" variant="contained" fullWidth>
                                                            <Typography>Vezi proiectul</Typography>
                                                        </Button>
                                                    </Link>
                                                )}
                                            </div>
                                        </>
                                    ) : null}
                                </div>
                            </div>
                        </Drawer>
                    </div>
                ) : isLoading && isGettingFirstPage ? (
                    <ActivityIndicatorComponent />
                ) : isEmpty(notifications) ? (
                    <div id="empty-notifications-list-main-div">
                        <Typography className="no-notifications-title">
                            Nicio notificare {indexMapping[selectedIndex] === 'false' ? 'nouă' : null}
                        </Typography>
                    </div>
                ) : null}
            </div>
        )
    }
}

class NotificationCard extends Component {
    render() {
        const {notification, handleClickCard} = this.props

        return (
            <div key={notification.id} className="card" onClick={handleClickCard}>
                <div className="card-header">
                    <div className="notification-header">
                        <Typography className="notification-header-text">
                            {lightFormat(new Date(notification.sent_moment), 'dd/MM/yyyy')}
                        </Typography>
                        <Clock className="notification-header-icon" size={16} />
                        <Typography className="notification-header-text">
                            {lightFormat(new Date(notification.sent_moment), 'HH:mm')}
                        </Typography>
                    </div>
                    {!notification.seen ? <div className="orange-circle" /> : null}
                </div>
                <div className="card-content">
                    <div className="card-content-child" id="notification-subject-div">
                        <Typography className="subtitle">{notification.subject}</Typography>
                    </div>
                </div>
            </div>
        )
    }
    
}

const mapStateToProps = function(state) {
    return {
        notifications: orderBy(state.notifications.data, 'sent_moment', 'desc'),
        isGettingFirstPage: state.notifications.isGettingFirstPage,
        nextPage: state.notifications.next,
        isLoading: state.notifications.isLoading,
        selectedEntityID: state.localConfigs.selectedEntityID
    }
}

export default connect(mapStateToProps)(NotificationsList)
