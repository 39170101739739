import {goBack, push} from 'connected-react-router'
import {toast} from 'react-toastify'
import {put, select, takeEvery} from 'redux-saga/effects'

import {values} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

const {appeals: resource} = RESOURCES

function* handleCreateAppealSuccess(response) {
    const {id: appealID} = response.payload
    const routerLocations = values(yield select((state) => state.routerLocations))
    
    if (routerLocations[routerLocations.length - 2]?.location?.pathname?.includes('/subscriptions/')) {
        yield put(push(routerLocations[routerLocations.length -2].location.pathname))
    } else {
        yield put(push(`/appeals/${appealID}/add-success`))
    }
}

function* handleUpdateAppealSuccess() {
    yield(put(goBack()))
    toast.success('Modificările au fost salvate')
}

function* handleDeleteAppealSuccess() {
    const currentProject = yield select((state) => state.projects.currentProject)
    yield put(push(`/projects/${currentProject.id}`))
}

export function* saga() {
    yield takeEvery(resource.actions.create.success, handleCreateAppealSuccess)
    yield takeEvery(resource.actions.update.success, handleUpdateAppealSuccess)
    yield takeEvery(resource.actions.destroy.success, handleDeleteAppealSuccess)
}
