import React, {Component} from 'react'
import {Formik, Form} from 'formik'
import {Typography, Button, Input} from '@material-ui/core'
import {HeadsetMicOutlined, Mail} from '@material-ui/icons'
import {PhoneEnabled} from '@material-ui/icons'

import {isEmpty} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import {colors} from '../assets/styles/colors'

import '../assets/scss/Support.scss'

class SupportPage extends Component {
    constructor() {
        super() 

        this.state = { 
            showTicketSuccess: false
        }
    }

    handleCallMeButton = () => {
        this.props.dispatch(
            RESOURCES.tickets.create({
                source: 'web',
                subject: 'Nu vreau sa scriu suna-ma!',
                message: 'Nu vreau sa scriu suna-ma!'
            })
        )
    }

    handleCloseTicketSusccess = () => {
        this.setState({showTicketSuccess: false})
    }

   handleOpenModal = () => {
       this.setState({showTicketSuccess: true})
   }

   render() {
       const {isLoading} = this.props

       return (
           <div id="support-main-div">
               <div className='support-left-div'>
                   <div className='support-big-icon-div'>
                       <HeadsetMicOutlined className="support-big-icon" />
                   </div>
                   <Typography className='main-text'>
                       Cum te <br /> putem <span className='main-text-span'>ajuta?</span>
                   </Typography>
                   <Typography className='secondary-text'>
                       Dă-ne un semn, iar noi te vom contacta înapoi.
                   </Typography>
               </div>
               <div className='support-right-div'>
                   <div className='support-card'>
                       <Typography className='support-card-description-text'>
                           Creează un tichet de contact
                       </Typography>
                       <Button 
                           onClick={this.handleCallMeButton}
                           style={{backgroundColor: colors.success}}
                           className='button-large'
                           disabled={isLoading}
                           variant="contained"
                           size="large"
                           fullWidth
                       >
                           <Typography className='button-text'>
                               Nu vreau să scriu, sunați-mă!
                           </Typography>
                           <div className='button-icon-div'>
                               <PhoneEnabled className='button-icon' />
                           </div>
                       </Button>
                   </div>
                   <div className='support-card'>
                       <Typography className='support-card-description-text'>
                           Sau scrie-ne
                       </Typography>
                       <Formik
                           initialValues={{
                               source: 'web',
                               subject: 'Tichet nou',
                               message: ''
                           }}
                           onSubmit={(values) => {
                               this.props.dispatch(
                                   RESOURCES.tickets.create({
                                       source: values.source,
                                       subject: values.subject,
                                       message: values.message
                                   }))
                           }}>
                           {({handleSubmit, handleChange, values}) => (
                               <Form className="styled-form">
                                   <Input
                                       placeholder="Mesajul tău"
                                       value={values.message}
                                       onChange={handleChange('message')}
                                       className="input"
                                       fullWidth
                                   />
                                   <Button 
                                       onClick={handleSubmit}
                                       size="large"
                                       variant="contained"
                                       fullWidth
                                       className='button-large'
                                       disabled={isEmpty(values.message) || isLoading}
                                   >
                                       <Typography className='button-text'>
                                           Trimite mesajul
                                       </Typography>
                                       <div className='button-icon-div orange'>
                                           <Mail className='button-icon' />
                                       </div>
                                   </Button>
                               </Form>
                           )}
                       </Formik>
                   </div>
               </div>
           </div>
       )
   }
}

const mapStateToProps = function(state) {
    return {
        isLoading: state.tickets.isLoading
    }
}

export default connect(mapStateToProps)(SupportPage)