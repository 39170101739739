import React, {Component} from 'react'
import {Button, Typography} from '@material-ui/core'
import {Link} from 'react-router-dom'

import EntityProfileAdd from './EntityProfileAdd'

import '../assets/scss/EntityProfiles.scss'

export default class EntityProfiles extends Component {
    render() {
        return (
            <>
                <div className="navbar" id="navbar-entity-profiles">
                    <div className="navbar-left">
                        <Typography className="navbar-title">Adaugă utilizator</Typography>
                    </div>
                    <Link style={{textDecoration: 'none'}} to='/settings'>
                        <Button className="cancel-button">
                            <Typography className="cancel-button-title">Renunță</Typography>
                        </Button>
                    </Link>
                </div>
                <div id="entity-profiles-main-div">
                    <div className="left">
                        <Typography className="title">
                            Adaugă
                            <br /> un utilizator
                        </Typography>
                        <Typography className="description">
                            În cadrul contului tău poți adăuga mai mulți utilizatori. Nivelul de acces la date e dat de
                            grupul din care face parte un utilizator. Dacă nu există încă un grup cu setările potrivite,
                            poți crea unul <Link to='/groups/add' className='add-group-link'>aici</Link>.
                        </Typography>
                    </div>
                    <div className="right">
                        <EntityProfileAdd />
                    </div>
                </div>
            </>
        )
    }
}
