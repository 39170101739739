import React, {Component} from 'react'
import {Button, CircularProgress, Input, Typography} from '@material-ui/core'
import {PersonOutline} from '@material-ui/icons'
import {Formik, Form} from 'formik'

import IntlTelInput from 'react-intl-tel-input/dist/components/IntlTelInput'
import 'react-intl-tel-input/dist/main.css'

import {isEmpty} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import {colors} from '../../assets/styles/colors'

class Profile extends Component {
    disableButton = (values) => {
        const {user, isLoading} =this.props

        if(values.email && values.firstName && values.lastName && values.phone) {
            return isLoading || ( 
                user.email === values.email && 
                user.first_name === values.firstName && 
                user.last_name === values.lastName &&
                user.phone === values.phone 
            )
        } 
        
        return false 
    }
    
    render() {
        const {user, isLoading} = this.props

        return (
            <div className="card-large">
                {!isLoading && !isEmpty(user) ? ( 
                    <Formik
                        initialValues={{
                            email: user.email,
                            lastName:user.last_name,
                            firstName: user.first_name,
                            phone: user.phone
                        }}
                        onSubmit={(values) => {
                            this.props.dispatch(RESOURCES.users.update({
                                id: 'me',
                                last_name: values.lastName,
                                first_name: values.firstName,
                                phone: values.phone
                            }))
                        }}>
                        {({handleChange, values, handleSubmit, setFieldValue}) => (
                            <Form>
                                <div className="card-main-div" id='profile-settings-card'>
                                    <div className="card-header">
                                        <div className="card-header-title-div">
                                            <div className="icon-div">
                                                <PersonOutline className="icon"/>
                                            </div>
                                            <Typography className="card-header-title">Setări profil</Typography>
                                        </div>
                                        <Button
                                            variant="contained"
                                            className="card-header-button"
                                            style={{backgroundColor: this.disableButton(values) ? 
                                                colors.lightGrey : colors.orange
                                            }}
                                            onClick={handleSubmit}
                                            disabled={this.disableButton(values)}
                                        >
                                            <Typography className='card-header-button-text' 
                                                style={{
                                                    color: this.disableButton(values) ? 
                                                        colors.darkGrey : 'white'
                                                }}
                                            >
                                                Salvează modificările
                                            </Typography>
                                        </Button>
                                    </div>
                                    <div className="card-body">
                                        <Input
                                            placeholder="Adresă de email"
                                            value={values.email}
                                            onChange={handleChange('email')}
                                            className="input card-body-element"
                                            disabled
                                        />
                                        <Input
                                            placeholder="Nume"
                                            value={values.lastName}
                                            onChange={handleChange('lastName')}
                                            className="input card-body-element"
                                        />  
                                        <Input
                                            placeholder="Prenume"
                                            value={values.firstName}
                                            onChange={handleChange('firstName')}
                                            className="input card-body-element"
                                   
                                        /> 
                                        <div className='phone-input-div card-body-element'>
                                            <IntlTelInput
                                                value={values.phone}
                                                formatOnInit={false}
                                                nationalMode={false}
                                                className='card-body-element'
                                                onPhoneNumberChange={(valid, phoneNumber ) => {
                                                    setFieldValue('phone', phoneNumber)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                ) : (
                    <div className="center-loader">
                        <CircularProgress className="circular-progress" thickness={5} color="primary" />
                    </div>
                )}
            </div>
        )
    }
}

const mapStateToProps= function (state) {
    return {
        user: state.users.user,
        isLoading: state.users.isLoading
    }
}

export default connect(mapStateToProps)(Profile)