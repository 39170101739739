import React, {Component} from 'react'
import {Typography, Input, Button} from '@material-ui/core'
import {ArrowBack} from '@material-ui/icons'
import {Link} from 'react-router-dom'
import {Formik, Form} from 'formik'

import {isEmpty, values as _values} from 'lodash'

import {connect} from 'react-redux'
import {resetPassword} from 'avoapp-react-common/dist/redux/auth'
import {getErrorFromCode} from 'avoapp-react-common/dist/utils'

import '../assets/scss/ResetPassword.scss'

class ResetPassword extends Component {
    render() {
        const {resetPasswordUID, resetPasswordCode} = this.props.match.params
        const {authErrors} = this.props

        return (
            <div className="reset-main-div">
                <div className="left">
                    <div className="left-main-div">
                        <Typography style={{textAlign:'center'}} className="page-title">Resetare parola</Typography>
                        {!isEmpty(authErrors) && isEmpty(authErrors.detail) 
                            && _values(authErrors).map((errorCategories) =>
                                errorCategories.map((error, index) => (
                                    <div key={index} className='error-div'>
                                        <Typography className='error-text'>
                                            {getErrorFromCode(error, 'resetPassword')}
                                        </Typography>
                                    </div>
                                ))
                            )
                        }
                        <Formik
                            initialValues={{
                                newPassword1: '',
                                newPassword2: '',
                                uid: resetPasswordUID,
                                code: resetPasswordCode
                            }}
                            onSubmit={(values) => {
                                this.props.dispatch(resetPassword({
                                    new_password1: values.newPassword1,
                                    new_password2: values.newPassword2,
                                    uid: values.uid,
                                    code: values.code
                                         
                                }))
                            }}>
                            {({handleChange, values}) => (
                                <Form className="styled-form">
                                    <Input
                                        placeholder="Noua parola"
                                        value={values.newPassword1}
                                        onChange={handleChange('newPassword1')}
                                        className="password-input"
                                        type="password"
                                        fullWidth
                                    />
                                    <Input
                                        placeholder="Repeta parola"
                                        value={values.newPassword2}
                                        onChange={handleChange('newPassword2')}
                                        className="password-input"
                                        type="password"
                                        fullWidth
                                    />
                                    <Button 
                                        size="large" 
                                        variant="contained" 
                                        type="submit" 
                                        disabled={
                                            isEmpty(values.newPassword1) ||
                                            isEmpty(values.newPassword2)||
                                            values.newPassword1 !== values.newPassword2
                                        }
                                        fullWidth
                                    >
                                        <Typography>Schimbă parola</Typography>
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                        <Link className="back-button" to="/login">
                            <div className="icon-div">
                                <ArrowBack />
                            </div>
                            <Typography className="back-button-text">Mergi la autentificare</Typography>
                        </Link>
                    </div>
                </div>
                <div className="right" />
            </div>
        )
    }
}

const mapStateToProps = function(state) {
    return {
        authErrors: state.auth.errors,
        isLoading: state.auth.isLoading
    }
}

export default connect(mapStateToProps)(ResetPassword)
