import {toast} from 'react-toastify'

import {push} from 'connected-react-router'
import {takeEvery, put, select, take} from 'redux-saga/effects'

import {values, head} from 'lodash'

import {Action} from 'avoapp-react-common/dist/redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {getErrorFromCode} from 'avoapp-react-common/dist/utils'
import {OrderingTypes} from 'avoapp-react-common/dist/constants'
import {setCurrent, SET_CURRENT_PROJECT} from 'avoapp-react-common/dist/redux/projects'

import {createFromLocalSubscriptions} from './subscriptions'

const {projects: resource} = RESOURCES

const ACTIONS = {
    CREATE_PROJECT_WITH_SUBSCRIPTION: new Action('CREATE_PROJECT_WITH_SUBSCRIPTION'),
    CREATE_EMPTY_PROJECT: new Action('CREATE_EMPTY_PROJECT')
}

function* handleCreateProjectSuccess(response) {
    yield put(setCurrent(response.payload))
    toast.success('Proiectul a fost creat')
}

function handleCreateProjectFail(response) {
    values(response.payload).map((error) => toast.error(getErrorFromCode(head(error), 'project')))
}

function* handleUpdateProjectFail(response) {
    if (response.payload.code === 'plan_error') {
        yield put(push('/limit-reached'))
    } else {
        response.payload.non_field_errors.map((error) => toast.error(getErrorFromCode(error), 'project'))
    }
}

function* handleUpdateProjectSuccess(response) {
    let selectedEntityID = yield select((state) => state.localConfigs.selectedEntityID)

    yield put(RESOURCES.entityReports.retrieve(selectedEntityID))
    yield put(RESOURCES.clients.list({project_id: response.payload.id, entity_id: selectedEntityID}))
    yield put(setCurrent(response.payload))
    toast.success('Modificările au fost salvate')
}

function* handleDestroyProjectSuccess() {
    let selectedEntityID = yield select((state) => state.localConfigs.selectedEntityID)
    
    yield put(RESOURCES.entityReports.retrieve(selectedEntityID))
    yield put(RESOURCES.projects.list({ordering: OrderingTypes.INTERVAL_ASC}))

    yield put(push('/projects'))
}

function* handleRetrieveProjectSuccess(response) {
    yield put(setCurrent(response.payload))
}

function* handleCreateProjectWithSubscription(data) {
    const {name} = data.payload

    const selectedEntityID = yield select((state) => state.localConfigs.selectedEntityID)

    yield put(RESOURCES.projects.create({entity_id: selectedEntityID, name: name}))
    yield take(RESOURCES.projects.actions.create.success)
    yield take(SET_CURRENT_PROJECT.main)

    const currentProject = yield select((state) => state.projects.currentProject)

    yield put(createFromLocalSubscriptions({project_id: currentProject.id}))
}

function* handleCreateEmptyProject(data) {
    const {name, entity_id} = data.payload

    yield put(RESOURCES.projects.create({name: name, entity_id: entity_id}))
    yield take(RESOURCES.projects.actions.create.success)
    yield take(SET_CURRENT_PROJECT.main)

    const currentProject = yield select((state) => state.projects.currentProject)

    yield put(push(`/projects/${currentProject.id}`))
}

export function* saga() {
    yield takeEvery(resource.actions.create.success, handleCreateProjectSuccess)
    yield takeEvery(resource.actions.create.fail, handleCreateProjectFail)
    yield takeEvery(resource.actions.update.success, handleUpdateProjectSuccess)
    yield takeEvery(resource.actions.update.fail, handleUpdateProjectFail)
    yield takeEvery(resource.actions.destroy.success, handleDestroyProjectSuccess)
    yield takeEvery(resource.actions.retrieve.success, handleRetrieveProjectSuccess)
    yield takeEvery(ACTIONS.CREATE_PROJECT_WITH_SUBSCRIPTION.main, handleCreateProjectWithSubscription)
    yield takeEvery(ACTIONS.CREATE_EMPTY_PROJECT.main, handleCreateEmptyProject)
}

export const createProjectWithSubscription = (data) => {
    return {
        type: ACTIONS.CREATE_PROJECT_WITH_SUBSCRIPTION.main,
        payload: {...data}
    }
}

export const createEmptyProject = (data) => {
    return {
        type: ACTIONS.CREATE_EMPTY_PROJECT.main,
        payload: {...data}
    }
}