import React, {Component} from 'react'
import IntlTelInput from 'react-intl-tel-input/dist/components/IntlTelInput'
import {Button, CircularProgress, FormControl, Input, InputLabel, MenuItem, Select, Typography} from '@material-ui/core'
import {ArrowDropDown} from '@material-ui/icons'
import {Formik} from 'formik'

import {isEmpty, values as _values} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {getErrorFromCode} from 'avoapp-react-common/dist/utils'

class EntityProfileAdd extends Component {
    componentDidMount() {
        this.props.dispatch(RESOURCES.groups.list())
    }
    
    render() {
        const {user, selectedEntityID, errors, isLoadingGroups, groups, isLoading} = this.props

        return (
            <Formik
                initialValues={{
                    groupID: '',
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '+40'
                }}
                onSubmit={(values) => {
                    this.props.dispatch(RESOURCES.entityProfiles.create({
                        author: user.id,
                        entity_id: selectedEntityID,
                        group_id: values.groupID,
                        first_name: values.firstName,
                        last_name: values.lastName,
                        email: values.email,
                        phone: values.phone
                    }))
                }}
            >
                {({handleChange, handleSubmit, values, setFieldValue}) => (
                    <div className='entity-profile-add'>
                        <div className='entity-profile-form'>
                            {!isEmpty(errors) && isEmpty(errors.code) ?
                                _values(errors).map((errorCategories) =>
                                    isEmpty(errorCategories.message) ? 
                                        errorCategories.map((error, index) => (
                                            <div key={index} className='error-div'>
                                                <Typography className='error-text'>
                                                    {getErrorFromCode(error, 'entityProfiles')}
                                                </Typography>
                                            </div>
                                        )) : (
                                            <div key={errorCategories.code} className='error-div'>
                                                <Typography className='error-text'>
                                                    {getErrorFromCode(errorCategories, 'entityProfiles')}
                                                </Typography>
                                            </div>
                                        ) ) : errors.code === 'server_error' ? (
                                            <div className='error-div'>
                                                <Typography className='error-text'>
                                                    Am întâmpinat o eroare. 
                                                    Dacă eroarea persistă te rugăm să ne contactezi.
                                                </Typography>
                                            </div>
                                ) : null}
                            {!isLoadingGroups ? (
                                <>
                                    <FormControl className='select-form-control'>
                                        <InputLabel id="group-select">Grup</InputLabel>
                                        <Select
                                            labelId="group-select"
                                            value={values.groupID}
                                            IconComponent={() => <ArrowDropDown className='dropdown-icon' />}
                                            onChange={(event) => {setFieldValue('groupID', event.target.value)}}
                                        > 
                                            {groups.map((group) => 
                                                <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                    <Input
                                        placeholder="Nume"
                                        value={values.lastName}
                                        onChange={handleChange('lastName')}
                                        className='input'
                                    />  
                                    <Input
                                        placeholder="Prenume"
                                        value={values.firstName}
                                        onChange={handleChange('firstName')}
                                        className='input'
                                    /> 
                                    <Input
                                        placeholder="Adresă de email"
                                        value={values.email}
                                        onChange={handleChange('email')}
                                        className='input'
                                    />
                                    <div className='phone-input-div input'>
                                        <IntlTelInput
                                            value={values.phone}
                                            formatOnInit={false}
                                            nationalMode={false}
                                            defaultCountry='ro'
                                            preferredCountries={['ro']}
                                            onPhoneNumberChange={(valid, phoneNumber) => {
                                                setFieldValue('phone', phoneNumber)
                                            }}
                                        />
                                    </div>
                                    <div className='submit-button'>
                                        {!isLoading ? (
                                            <Button 
                                                size="large"
                                                variant="contained"
                                                disabled={isLoading}
                                                onClick={handleSubmit}
                                                fullWidth
                                            >
                                                <Typography className="button-title">Adaugă utilizator</Typography>
                                            </Button>
                                        ) : (
                                            <div className="center-loader">
                                                <CircularProgress 
                                                    className="circular-progress" 
                                                    thickness={5} 
                                                    color="primary"
                                                />
                                            </div>
                                        )}
                                     
                                    </div>
                                </>
                            ) : (
                                <div className="center-loader">
                                    <CircularProgress className="circular-progress" thickness={5} color="primary" />
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </Formik>
        )
    }
}

const mapStateToProps = function(state) {
    const entityProfiles = _values(state.entityProfiles.data)

    return {
        user: state.users.user,
        selectedEntityID: state.localConfigs.selectedEntityID,
        entityProfiles: entityProfiles,
        isLoading: state.entityProfiles.isLoading,
        errors: state.entityProfiles.errors,
        isLoadingGroups: state.groups.isLoading,
        groups: _values(state.groups.data)
    }
}

export default connect(mapStateToProps)(EntityProfileAdd)
