import React from 'react'
import {Formik, Form} from 'formik'
import {Typography, IconButton, Input, Button, Modal} from '@material-ui/core'
import {Close} from '@material-ui/icons'

import {isEmpty} from 'lodash'

import {connect} from 'react-redux'
import {forgotPassword} from 'avoapp-react-common/dist/redux/auth'

import '../assets/scss/ForgotPasswordModal.scss'

export const ForgotPasswordModal = ({open, isLoading, handleClose, forgotPassword}) => {
    return (
        <Modal open={open} onClose={handleClose}>
            <div className="forgot-password-modal">
                <div className="main-div">
                    <div className="header">
                        <IconButton className="close-modal-button" onClick={handleClose}>
                            <Close />
                        </IconButton>
                    </div>
                    <div className="content">
                        <div className="content-text-div">
                            <Typography className="modal-title">Resetare parolă</Typography>
                            <Typography className="modal-text">
                                Pentru a reseta parola contului tau, introdu adresa de email si iti vom trimite un link
                                de resetare.
                            </Typography>
                        </div>
                        <Formik 
                            initialValues={{email: ''}} 
                            onSubmit={(values) => {
                                forgotPassword(values.email)
                                handleClose()
                            }}
                        >
                            {({handleChange, values}) => (
                                <Form className="styled-form">
                                    <Input
                                        value={values.email}
                                        onChange={handleChange('email')}
                                        placeholder="Adresă de email"
                                        className="input"
                                        type='email'
                                        fullWidth
                                    />
                                    <Button 
                                        type='submit'
                                        disabled={isEmpty(values.email) || isLoading}
                                        variant="contained" 
                                        size="large" 
                                        fullWidth
                                    >
                                        <Typography>Trimite link de resetare</Typography>
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.auth.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    forgotPassword: (email) => dispatch(forgotPassword({email}))
})

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordModal)
