import {push} from 'connected-react-router'
import {put, select, takeEvery} from 'redux-saga/effects'

import {includes, isNil, values} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

const {phases: resource} = RESOURCES

function* handleCreatePhaseSuccess(response) {
    const {id: phaseID} = response.payload
    const routerLocations = values(yield select((state) => state.routerLocations))
    const sourceLocation = routerLocations[routerLocations.length - 2]?.location
    const currentLocation =routerLocations[routerLocations.length - 1]?.location

    // If currentLocation includes sourceLocation it means sourceLocation is the subscription details.
    // ex: sourceLocation: /subscriptions/000 -> currentLocation: /subscriptions/000/add-manual-phase => 
    //          => true (sourceLocation is subscription details)
    // ex2: sourceLocation: /subscriptions/add -> currentLocation: /subscriptions/000/add-manual-phase => 
    //          => false (sourceLocation is subscription add form)
    if(
        !isNil(sourceLocation) &&
        !isNil(currentLocation) &&
        includes(currentLocation.pathname, sourceLocation.pathname)
    ) {
        yield put(push(sourceLocation.pathname))
    } else {
        yield put(push(`/phases/${phaseID}/add-manual-party`))
    }
    
}

export function* saga() {
    yield takeEvery(resource.actions.create.success, handleCreatePhaseSuccess)
}
