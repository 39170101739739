import React, {Component} from 'react'
import {
    Button,
    CircularProgress,
    FormControl,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Tab,
    Tabs,
    Typography
} from '@material-ui/core'
import {Formik} from 'formik'
import {Search} from 'react-feather'
import {ArrowDropDown, RadioButtonUnchecked, RadioButtonChecked} from '@material-ui/icons'
import IntlTelInput from 'react-intl-tel-input/dist/components/IntlTelInput'
import 'react-intl-tel-input/dist/main.css'

import {head, values, isEmpty, omit, concat} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {EntityTypes} from 'avoapp-react-common/dist/constants'

import {entitiesData} from '../assets/data/choices'
import {checkProperties} from '../assets/utils'

import '../assets/scss/EntitiesUpdateForm.scss'

const indexMapping = {
    0: 'person',
    1: 'company'
}

class EntitiesUpdateForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            countries: [],
            selectedIndex: this.props.currentEntity?.type === EntityTypes.PERSON ? 0 : 1
        }
    }
    
    componentDidMount() {
        const {selectedEntityID} = this.props
        
        this.props.dispatch(RESOURCES.entities.retrieve(selectedEntityID))
        this.createCountriesArray()
    }

    componentDidUpdate(prevProps) {
        const {companies, isLoadingCompanies, currentEntity, isLoading} = this.props
        const {selectedIndex} = this.state

        if (prevProps.companies !== companies && !isEmpty(companies) && !isLoadingCompanies) {
            let company = head(values(companies))
            this.companyCallback('companyName', company.name)
            this.companyCallback('tradeRegisterNumber', company.trade_register_number)
            this.companyCallback('county', company.county)
            this.companyCallback('address', company.address)
        }

        if (prevProps.currentEntity !== currentEntity && !isEmpty(currentEntity) && !isLoading) {
            if (currentEntity.type === EntityTypes.PERSON && selectedIndex !== 0){
                this.updateIndex(0)
            }

            if (currentEntity.type === EntityTypes.COMPANY && selectedIndex !== 1){
                this.updateIndex(1)
            }
        }
    }

    getTabLabel = (type, text) => {
        const {selectedIndex} = this.state

        return (
            <div className='tab-label-div'>
                {indexMapping[selectedIndex] === type ? (
                    <RadioButtonChecked className='icon'/>
                ) : (
                    <RadioButtonUnchecked className='icon'/>
                )}
                <Typography className='tab-text'>{text}</Typography>
            </div>
        ) 
    }

    isButtonDisabled = (values) => {
        const {selectedIndex} = this.state
        let valuesToCheck
        
        if(indexMapping[selectedIndex] === EntityTypes.COMPANY) {
            valuesToCheck = {
                type: indexMapping[selectedIndex],
                ...omit(values, 'tradeRegisterNumber')

            }
        } else if(indexMapping[selectedIndex] === EntityTypes.PERSON) {
            valuesToCheck = {
                type: indexMapping[selectedIndex],
                ...omit(values, 'companyName', 'tradeRegisterNumber', 'vatNumber')
            }
        } else {
            return true
        }

        return checkProperties(valuesToCheck)
    }

    createCountriesArray = () => {
        let countries = Object.keys(entitiesData.country)
        let finalCountriesArray = []

        // eslint-disable-next-line array-callback-return
        countries.map((countryCode) => {
            finalCountriesArray = concat(finalCountriesArray, {
                value: countryCode,
                label: entitiesData.country[countryCode]
            })}
        )

        this.setState({countries: finalCountriesArray})
    }

    updateIndex = (index) => {this.setState({selectedIndex: index})}

    toggleCountryModal = () => {
        this.setState((prevState) => ({
            isCountryModalOpen: !prevState.isCountryModalOpen
        }))
    }

    handleChangeCountry = (value) => {
        this.phone.selectCountry(value.cca2.toLowerCase())
    }

    handleRetrieveCompanyFromVAT = (vatNumber) => {
        this.props.dispatch(RESOURCES.companies.retrieve(vatNumber))
    }

    setComanyDataCallback = (setFieldValue) => {
        this.companyCallback = setFieldValue
    }

    render() {
        const {currentEntity, isLoading, companies} = this.props
        const {countries, selectedIndex} = this.state

        return (
            <div id="entities-edit-form">
                {!isEmpty(currentEntity) && !isLoading && !isEmpty(currentEntity.phone) ? (
                    <>
                        <div className='tabs-div'>
                            <Tabs
                                className='tabs'
                                textColor="primary"
                                value={selectedIndex}
                                TabIndicatorProps={{style: {background:'transparent'}}}
                                onChange={(data, index) => {
                                    if (index !== selectedIndex) {
                                        this.updateIndex(index)
                                    }
                                }}
                            >
                                <Tab
                                    label={this.getTabLabel('person','Persoană fizică')}
                                    classes={{root: 'tabRoot', selected: 'tabSelected'}}
                                />
                                <Tab 
                                    label={this.getTabLabel('company', 'Persoană juridică') }
                                    classes={{root: 'tabRoot', selected: 'tabSelected'}}
                                />
                            </Tabs>
                        </div>
                        <Formik
                            initialValues={{
                                lastName: currentEntity.last_name,
                                firstName: currentEntity.first_name,
                                phone: currentEntity.phone,
                                billingEmail:currentEntity.email,
                                country: currentEntity.country,
                                county: currentEntity.county,
                                locality: currentEntity.locality,
                                address: currentEntity.address,
                                vatNumber: currentEntity.vat_number,
                                tradeRegisterNumber: currentEntity.trade_register_number,
                                companyName: currentEntity.name
                            }}
                            onSubmit={(values) => {
                                const {
                                    lastName,
                                    firstName,
                                    phone,
                                    billingEmail,
                                    country,
                                    county,
                                    locality,
                                    address,
                                    vatNumber,
                                    tradeRegisterNumber,
                                    companyName
                                } = values
                
                                let newCurrentEntityData = {
                                    id: currentEntity.id,
                                    type: indexMapping[selectedIndex],
                                    last_name: lastName,
                                    first_name: firstName,
                                    phone: phone,
                                    email: billingEmail,
                                    country: country,
                                    county: county,
                                    locality: locality,
                                    address: address,
                                    vat_number: vatNumber,
                                    trade_register_number: tradeRegisterNumber,
                                    name: companyName
                                }
                
                                this.props.dispatch(RESOURCES.entities.update(newCurrentEntityData))
                            }}
                        >
                            {({handleChange, handleSubmit, values, setFieldValue}) => (
                                <>
                                    <div className='form'>  
                                        {indexMapping[selectedIndex] === EntityTypes.COMPANY && (
                                        <div className="section">
                                            <div className="section-header">
                                                <Typography className='section-title'>Date firmă</Typography>
                                            </div>
                                            <div className="section-content">
                                                <FormControl className='input-form-control'>
                                                    <div className="vat-number-div">
                                                        <InputLabel>CUI</InputLabel>
                                                        <Input
                                                            value={values.vatNumber}
                                                            onChange={handleChange('vatNumber')}
                                                            className="input"
                                                        />
                                                        <Button
                                                            variant="contained"
                                                            className='search-company-button'
                                                            disabled={isEmpty(values.vatNumber)}
                                                            onClick={()=> {
                                                                this.setComanyDataCallback(setFieldValue)
                                                                this.handleRetrieveCompanyFromVAT(values.vatNumber)
                                                            }}
                                                        >
                                                            <Typography className="button-title">
                                                                Caută o firma dupa CUI-ul introdus
                                                            </Typography>
                                                            <Search size={16} className="button-icon" />
                                                        </Button>
                                                    </div>
                                                </FormControl>
                                                <FormControl className='input-form-control'>
                                                    <InputLabel 
                                                        shrink={
                                                            !isEmpty(companies) || 
                                                            !isEmpty(values.companyName)
                                                        }
                                                    >
                                                        Nume firma
                                                    </InputLabel>
                                                    <Input
                                                        value={values.companyName}
                                                        onChange={handleChange('companyName')}
                                                        className="input"
                                                    />
                                                </FormControl>
                                                <FormControl className='input-form-control'>
                                                    <InputLabel 
                                                        shrink={
                                                            !isEmpty(companies) || 
                                                            !isEmpty(values.tradeRegisterNumber)
                                                        }
                                                    >
                                                        Nr inregistrare
                                                    </InputLabel>
                                                    <Input
                                                        value={values.tradeRegisterNumber}
                                                        onChange={handleChange('tradeRegisterNumber')}
                                                        className="input"
                                                    />
                                                </FormControl>
                                                <FormControl className='input-form-control'>
                                                    <InputLabel 
                                                        id="country"
                                                        shrink={
                                                            !isEmpty(currentEntity.country) || 
                                                            !isEmpty(values.country)
                                                        }
                                                    >
                                                        Țara
                                                    </InputLabel>
                                                    <Select
                                                        labelId="country"
                                                        value={values.country}
                                                        IconComponent={() => 
                                                            <ArrowDropDown className='dropdown-icon' />
                                                        }
                                                        onChange={(event) => {
                                                            setFieldValue('country', event.target.value)
                                                        }}
                                                        className="select">
                                                        {countries.map((country) => (
                                                            <MenuItem key={country.value} value={country.value}>
                                                                {country.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                <FormControl className='input-form-control'>
                                                    <InputLabel>Județ</InputLabel>
                                                    <Input
                                                        value={values.county}
                                                        onChange={handleChange('county')}
                                                        className="input"
                                                    />
                                                </FormControl>
                                                <FormControl className='input-form-control'>
                                                    <InputLabel>Localitate</InputLabel>
                                                    <Input
                                                        value={values.locality}
                                                        onChange={handleChange('locality')}
                                                        className="input"
                                                    />
                                                </FormControl>
                                                <FormControl className='input-form-control'>
                                                    <InputLabel
                                                        shrink={
                                                            !isEmpty(currentEntity.address) || 
                                                            !isEmpty(values.address)
                                                        }
                                                    >
                                                        Adresă
                                                    </InputLabel>
                                                    <Input
                                                        value={values.address}
                                                        onChange={handleChange('address')}
                                                        className="input"
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                        )} 
                                        <div className="section">
                                            <div className="section-header">
                                                <Typography className='section-title'>Date de contact</Typography>
                                            </div>
                                            <div className="section-content">
                                                <FormControl className='input-form-control'>
                                                    <InputLabel>Nume</InputLabel>
                                                    <Input
                                                        value={values.lastName}
                                                        onChange={handleChange('lastName')}
                                                        className="input"
                                                    />
                                                </FormControl>
                                                <FormControl className='input-form-control'>
                                                    <InputLabel>Prenume</InputLabel>
                                                    <Input
                                                        value={values.firstName}
                                                        onChange={handleChange('firstName')}
                                                        className="input"
                                                    />
                                                </FormControl>
                                                <div className='phone-input-div'>
                                                    <IntlTelInput
                                                        value={values.phone}
                                                        formatOnInit={false}
                                                        nationalMode={false}
                                                        onPhoneNumberChange={(valid, phoneNumber ) => {
                                                            setFieldValue('phone', phoneNumber)
                                                        }}
                                                    />
                                                </div>
                                                <FormControl className='input-form-control'>
                                                    <InputLabel>Adresă de email pentru facturare</InputLabel>
                                                    <Input
                                                        value={values.billingEmail}
                                                        onChange={handleChange('billingEmail')}
                                                        className="input"
                                                    />
                                                </FormControl>
                                                {indexMapping[selectedIndex] === EntityTypes.PERSON ? (
                                                    <>
                                                        <FormControl className='input-form-control'>
                                                            <InputLabel id="country">Țara</InputLabel>
                                                            <Select
                                                                labelId="country"
                                                                value={values.country}
                                                                IconComponent={() => 
                                                                    <ArrowDropDown className='dropdown-icon' />
                                                                }
                                                                onChange={(event) => {
                                                                    setFieldValue('country', event.target.value)
                                                                }}
                                                                className="select">
                                                                {countries.map((country) => (
                                                                    <MenuItem key={country.value} value={country.value}>
                                                                        {country.label}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                        <FormControl className='input-form-control'>
                                                            <InputLabel>Județ</InputLabel>
                                                            <Input
                                                                value={values.county}
                                                                onChange={handleChange('county')}
                                                                className="input"
                                                            />
                                                        </FormControl>
                                                        <FormControl className='input-form-control'>
                                                            <InputLabel>Localitate</InputLabel>
                                                            <Input
                                                                value={values.locality}
                                                                onChange={handleChange('locality')}
                                                                className="input"
                                                            />
                                                        </FormControl>
                                                        <FormControl className='input-form-control'>
                                                            <InputLabel>Adresă</InputLabel>
                                                            <Input
                                                                value={values.address}
                                                                onChange={handleChange('address')}
                                                                className="input"
                                                            />
                                                        </FormControl>
                                                    </>
                                                ):null}
                                            </div>
                                        </div>
                                    </div>
                                    <Button
                                        onClick={handleSubmit}
                                        disabled={this.isButtonDisabled(values)}
                                        className='submit-button'
                                        variant="contained"
                                        size="large"
                                        fullWidth
                                    >
                                        <Typography className="button-title">Mai departe</Typography>
                                    </Button>
                                </>
                            )}
                        </Formik>
                    </>
                ) : (
                    <CircularProgress className="circular-progress" thickness={5} color="primary" />
                )}
            </div>
        )
    }
}

const mapStateToProps = function (state) {
    return {
        currentEntity: state.entities.currentEntity,
        isLoading: state.entities.isLoading,
        companies: state.companies.data,
        isLoadingCompanies: state.companies.isLoading,
        selectedEntityID: state.localConfigs.selectedEntityID
    }   
}

export default connect(mapStateToProps)(EntitiesUpdateForm)
