import React, {Component} from 'react'
import {Button, ButtonBase, CircularProgress, Dialog, DialogActions, DialogTitle, Typography} from '@material-ui/core'
import {Link} from 'react-router-dom'
import {Edit2, X} from 'react-feather'

import {isEmpty, values, toUpper} from 'lodash'

import {connect} from 'react-redux'
import {replaceNullWithDash} from 'avoapp-react-common/dist/utils'
import {entityProfilesStatus} from 'avoapp-react-common/dist/constants'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

class CompanyUsers extends Component {
    constructor() {
        super() 

        this.state = {
            showDeleteEntityProfileDialog: false,
            entityProfileToDelete: {},
            showDeleteGroupDialog: false,
            groupToDelete: {}
        }
    }

    handleCloseDeleteGroupDialog = () => this.setState({showDeleteGroupDialog: false})

    getProfileStatus = (status) => replaceNullWithDash(entityProfilesStatus[toUpper(status)].label)

    render() {
        const {showDeleteGroupDialog, groupToDelete} = this.state
        const {
            entityProfiles,
            isLoadingEntityProfiles,
            groups,
            isLoadingGroups,
            permissions
        } = this.props

        return (
            <div className="card-large">
                <div className="card-main-div" id='company-users-settings-card'>
                    <div className="card-header">
                        <div className="navbar-left">
                            <Typography className="card-header-title">
                                Utilizatori companie
                            </Typography>
                            {permissions.members.create && 
                                <Link to='/entity-profiles/add' style={{textDecoration: 'none'}}>
                                    <Button variant="contained" className="card-header-button">
                                        <Typography>Adaugă utilizatori în companie</Typography>
                                    </Button>
                                </Link>}
                        </div>
                        <div className="navbar-right">
                            <Typography className="card-header-title">Grupuri</Typography>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="left">
                            {!isLoadingEntityProfiles && !isEmpty(entityProfiles) ? (
                                <div className='cards-container'> 
                                    {entityProfiles.map((profile, index) => (
                                        <div className="card" key={index} >
                                            <div className="card-header">
                                                <Typography className="card-title">
                                                    {replaceNullWithDash(profile.last_name)}{' '}
                                                    {replaceNullWithDash(profile.first_name)}
                                                </Typography>
                                                {permissions.members.update && 
                                                    <Link
                                                        to={`/entity-profiles/${profile.id}/edit`} 
                                                        className='edit-entity-profile-button'
                                                    >
                                                        <Edit2 size={14} className='edit-entity-profile-icon' />
                                                    </Link>
                                                }
                                            </div>
                                            <div className="card-content">
                                                <div className="card-content-child">
                                                    <Typography className="title">Grup:</Typography>
                                                    <Typography className="subtitle">
                                                        {replaceNullWithDash(profile.group.name)}
                                                    </Typography>
                                                </div>
                                                <div className="card-content-child">
                                                    <Typography className="title">Email:</Typography>
                                                    <Typography className="subtitle">
                                                        {replaceNullWithDash(profile.email)}
                                                    </Typography>
                                                </div>
                                                <div className="card-content-child">
                                                    <Typography className="title">Telefon:</Typography>
                                                    <Typography className="subtitle">
                                                        {replaceNullWithDash(profile.phone)}
                                                    </Typography>
                                                </div>
                                                <div className="card-content-child">
                                                    <Typography className="title">Status:</Typography>
                                                    <Typography className="subtitle">
                                                        {this.getProfileStatus(profile.status)}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className="center-loader">
                                    <CircularProgress className="circular-progress" thickness={5} color="primary" />
                                </div>
                            )}
                        </div>
                        <div className="right">
                            <div className='cards-container'> 
                                {!isLoadingGroups && !isEmpty(groups) ? 
                                    groups.map((group, index) => (
                                        <div className="card" key={index}>
                                            <div className="card-header">
                                                <Typography className="card-title">
                                                    {replaceNullWithDash(group.name)} 
                                                    {/* <span>(nr. utilizatori)</span> */}
                                                </Typography>
                                                {permissions.entity.update && <>
                                                    <Link
                                                        to={`/groups/${group.id}/edit`} 
                                                        className='edit-group-button'
                                                    >
                                                        <Edit2 size={14} className='edit-group-icon'/>
                                                    </Link>
                                                    <ButtonBase
                                                        onClick={() => {
                                                            this.setState({
                                                                showDeleteGroupDialog: true,
                                                                groupToDelete: group 
                                                            })
                                                        }}
                                                    >
                                                        <X
                                                            className="small-delete-button-icon"
                                                            style={{alignSelf: 'center'}}
                                                            size={14}
                                                        />
                                                    </ButtonBase>
                                                </>}
                                            </div>
                                        </div>
                                    )) : isLoadingGroups ? (
                                        <div className="center-loader">
                                            <CircularProgress className="circular-progress" thickness={5}/>
                                        </div>
                                    ) : isEmpty(groups) ? (
                                        <Typography className='no-groups'>Nu există grupuri</Typography>
                                    ) : null}
                                {permissions.entity.update && 
                                    <Link to={'/groups/add'} className="card add-group">
                                        <div className="card-header">
                                            <Typography className="card-title">
                                                Adaugă un grup nou
                                            </Typography>
                                        </div>
                                    </Link>}
                                <DeleteGroupDialog
                                    showDeleteGroupDialog={showDeleteGroupDialog}
                                    groupToDelete={groupToDelete}
                                    handleDeleteGroup={() => {
                                        this.props.dispatch(RESOURCES.groups.destroy(groupToDelete))
                                        this.handleCloseDeleteGroupDialog()
                                    }}
                                    handleCloseDeleteGroupDialog={this.handleCloseDeleteGroupDialog}
                                />
                            </div>
                        </div>
                    </div>
                </div>                                                    
            </div>
        )
    }
}

class DeleteGroupDialog extends Component {
    render() {
        const {showDeleteGroupDialog, handleCloseDeleteGroupDialog, handleDeleteGroup, groupToDelete} = this.props

        return (
            <Dialog className='delete-group-dialog' open={showDeleteGroupDialog} onClose={handleCloseDeleteGroupDialog}>
                <DialogTitle>Vrei să ștergi grupul "{groupToDelete.name}" din compania ta?</DialogTitle>
                <DialogActions className='buttons-div'>
                    <Button onClick={handleCloseDeleteGroupDialog} className='close-button'>
                        <Typography className='button-text'>Nu, renunță</Typography>
                    </Button>
                    <Button className='action-button' onClick={handleDeleteGroup} autoFocus>
                        <Typography className='button-text'>Da, șterge</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = function (state) {
    return {
        entityProfiles: values(state.entityProfiles.data),
        isLoadingEntityProfiles: state.entityProfiles.isLoading, 
        isLoadingEntities: state.entities.isLoading,
        isLoadingGroups: state.groups.isLoading,
        groups: values(state.groups.data),
        permissions: state.permissions
    }
}

export default connect(mapStateToProps)(CompanyUsers)
