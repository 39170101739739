import {Button, InputBase, Tab, Tabs, Typography} from '@material-ui/core'
import React, {Component, useMemo} from 'react'
import {Plus, Search} from 'react-feather'
import InfiniteScroll from 'react-infinite-scroller'
import {Link} from 'react-router-dom'

import ActivityIndicatorComponent from '../components/ActivityIndicatorComponent'
import LoadingMoreComponent from '../components/LoadingMoreComponent'

import {debounce, isEmpty, isNull, join, omitBy, orderBy, toString, values} from 'lodash'

import {OrderingTypes} from 'avoapp-react-common/dist/constants'
import {setCurrent} from 'avoapp-react-common/dist/redux/projects'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {changeDateFormat} from 'avoapp-react-common/dist/utils'
import {connect} from 'react-redux'

import {differenceInDays} from 'date-fns'
import Wizard from '../assets/images/wizard.svg'
import '../assets/scss/ProjectList.scss'
import {colors} from '../assets/styles/colors'

const indexMapping = {
    0: 'true',
    1: 'false'
}

export class ProjectsList extends Component {
    constructor() {
        super()

        this.state = {
            selectedIndex: 0,
            searchFilter: '',
            isTyping: false
        }
    }

    componentDidMount() {
        const {selectedIndex} = this.state
        
        if(this.props.location.state && !this.props.location.state.projectActive) {
            this.updateIndex(1)
        } else {
            this.props.dispatch(RESOURCES.projects.list({
                ordering: OrderingTypes.INTERVAL_ASC,
                active: indexMapping[selectedIndex]
            }))
        }
    }

    componentDidUpdate(prevProps) {
        const {selectedIndex, searchFilter} = this.state
        const {selectedEntityID, isLoading} = this.props

        if(prevProps.selectedEntityID !== selectedEntityID && !isLoading) {
            this.props.dispatch(RESOURCES.projects.list({
                search: searchFilter,
                active: indexMapping[selectedIndex],
                ordering: OrderingTypes.INTERVAL_ASC
            }))
        }
    }

    updateIndex = (selectedIndex) => {
        this.setState({selectedIndex, searchFilter: ''})

        this.props.dispatch(RESOURCES.projects.list({
            active: indexMapping[selectedIndex],
            ordering: OrderingTypes.INTERVAL_ASC
        }))
    }

    debounceSearchProjects = debounce((value)=> {
        const {selectedIndex} = this.state

        this.props.dispatch(
            RESOURCES.projects.list({
                search: value,
                active: indexMapping[selectedIndex],
                ordering: OrderingTypes.INTERVAL_ASC
            })
        )

        this.setState({isTyping: false})
    }, 300)

    handleChangeSearchField = (value) => {
        this.setState({searchFilter: value, isTyping: true})
        this.debounceSearchProjects(value)
    }

    loadMoreProjects = () => {
        const {selectedIndex} = this.state
        const {nextPage, isLoading} = this.props

        if(!isNull(nextPage) && !isLoading) {
            this.props.dispatch(RESOURCES.projects.list({
                page: nextPage,
                active: indexMapping[selectedIndex],
                ordering: OrderingTypes.INTERVAL_ASC
            }))
        }
    }

    render() {
        const {isLoading, isGettingFirstPage, nextPage, permissions} = this.props
        const {selectedIndex, searchFilter, isTyping} = this.state

        let projects = values(
            omitBy(this.props.projects, (project) => toString(project.active) !== indexMapping[selectedIndex])
        )

        const mainCondition = () => {
            return (
                !isEmpty(projects) ||
                (isEmpty(projects) && indexMapping[selectedIndex] === 'false') ||
                (isEmpty(projects) && !isEmpty(searchFilter))
            )
        }

        return (
            <div>
                <div
                    className="navbar"
                    id="navbar-projects-list"
                    style={{
                        height: mainCondition() ? '15vh' : '10vh',
                        borderBottom: mainCondition() ? '1px solid $lightGrey' : '0'
                    }}
                >
                    <div className="navbar-top">
                        <Typography className="navbar-title">Listă proiecte</Typography>
                        {permissions.projects.create && 
                            <Link to="/projects/add" style={{textDecoration: 'none'}}>
                                <Button
                                    size="large"
                                    variant="contained"
                                    className="navbar-main-button"
                                    onClick={()=> this.props.dispatch(setCurrent({}))}
                                    style={{backgroundColor: colors.success}}
                                >
                                    <Typography className="navbar-button-title">Adaugă proiect</Typography>
                                    <Plus className="navbar-button-icon" />
                                </Button>
                            </Link>}
                    </div>
                    <div className="navbar-bottom">
                        <div className="tabs-and-search-div">
                            <Tabs
                                value={selectedIndex}
                                style={{width: '325px'}}
                                textColor="primary"
                                onChange={(_data, index) => {
                                    if (index !== selectedIndex) {
                                        this.updateIndex(index)
                                    }
                                }}>
                                <Tab disableRipple className="tab" label="Active" />
                                <Tab disableRipple className="tab" label="Inactive" />
                            </Tabs>
                            {mainCondition() ? (
                                <div className="search-div">
                                    <div className="search-icon"> 
                                        <Search size={20} /> 
                                    </div>
                                    <InputBase
                                        placeholder="Caută proiect"
                                        value={searchFilter}
                                        className="search-input"
                                        onChange={(event) => {
                                            this.handleChangeSearchField(event.target.value)
                                        }}
                                    />
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
                {mainCondition() ? (
                    <div id="projects-list-main-div">
                        {isEmpty(projects) && !isEmpty(searchFilter) ? (
                            <div>
                                <Typography>
                                    Nu s-au găsit proiecte care să corespundă căutării tale. Te rugăm schimbă parametrii
                                    de căutare
                                </Typography>
                            </div>
                        ) : isEmpty(projects) && indexMapping[selectedIndex] === 'false' ? (
                            <div className="empty-inactive-projects-list-div">
                                <Typography className="no-projects-title"> Nu există proiecte inactive </Typography>
                            </div>
                        ) : !isEmpty(projects) ? (
                            <InfiniteScroll
                                className='cards-container'
                                pageStart={1}
                                loadMore={this.loadMoreProjects}
                                hasMore={!isNull(nextPage)}
                                loader={
                                    <LoadingMoreComponent loadingText='Se încarcă mai multe proiecte' key='load-more' />
                                }
                            >
                                {projects.map((project) => <ProjectCard project={project} key={project.id} />)}
                            </InfiniteScroll>
                        ) : null}
                    </div>
                ) : (isEmpty(projects) && isTyping) || (isLoading && isGettingFirstPage) ? (
                    <ActivityIndicatorComponent />
                ) : isEmpty(projects) && indexMapping[selectedIndex] === 'true' ? (
                    <EmptyActiveProjectListComponent />
                ) : null}
            </div>
        )
    }
}

function ProjectCard({project}) {    
    const hearingDate = useMemo(() => {
        const hearingDate = project?.next?.hearing?.date ?? project?.previous?.hearing?.date ?? null

        if(!isNull(hearingDate)) {
            const difference = differenceInDays(new Date(hearingDate), new Date(new Date().toDateString()))
                
            if(difference === -1) return 'Soluţionat ieri'
            if(difference < 0) return `Soluţionat acum ${difference * -1 } zile`
            if(difference === 0) return 'Şedinţă astăzi'
            if(difference === 1) return 'Şedinţă mâine'
                
            return `Şedinţă în ${difference} zile`
        }

        return null
    }, [project])

    return (
        <Link key={project.id} style={{textDecoration: 'none'}} className="card" to={'/projects/' + project.id} >
            <div className="card-header">
                <div className="texts-container">
                    <Typography className="card-title">{project.name}</Typography>
                    {!isNull(hearingDate) && (
                        <Typography className='card-helper-text'>{hearingDate}</Typography>
                    )}
                </div>
                {project.active ? <div className="green-circle" /> : null}
            </div>
            <div className="card-content">
                {(project.latest_phase && !project.latest_phase.has_hearings) || isNull(project.latest_phase) ? (
                    <Typography className="no-hearings-text">Fara termene</Typography>
                ) : project.next ? (
                    <>
                        <div className="card-content-child">
                            <Typography className="title"> Următorul termen: </Typography>
                            <Typography className="subtitle">
                                {changeDateFormat(project.next.hearing.date)}
                            </Typography>
                        </div>
                        <div className="card-content-child">
                            <Typography className="title"> Număr dosar: </Typography>
                            <Typography className="subtitle"> {project.next.phase.number} </Typography>
                        </div>
                        <div className="card-content-child">
                            <Typography className="title"> Instanța: </Typography>
                            <Typography className="subtitle" noWrap>
                                {project.next.phase.court} - {project.next.hearing.full_court}
                            </Typography>
                        </div>
                    </>
                ) : !project.next && project.previous ? (
                    <>
                        <div className="card-content-child">
                            <Typography className="title"> Ultimul termen: </Typography>
                            <Typography className="subtitle">
                                {changeDateFormat(project.previous.hearing.date)}
                            </Typography>
                        </div>
                        <div className="card-content-child">
                            <Typography className="title"> Număr dosar: </Typography>
                            <Typography className="subtitle"> {project.previous.phase.number} </Typography>
                        </div>
                    </>
                ) : !project.next && !project.previous ? (
                    <>
                        <div className="card-content-child">
                            <Typography className="title"> Următorul termen: </Typography>
                            <Typography className="subtitle">-</Typography>
                        </div>
                        <div className="card-content-child">
                            <Typography className="title"> Număr dosar: </Typography>
                            <Typography className="subtitle">-</Typography>
                        </div>
                        <div className="card-content-child">
                            <Typography className="title"> Instanța: </Typography>
                            <Typography className="subtitle">-</Typography>
                        </div>
                    </>
                ) : null}
                {project.clients.length && project.latest_phase && project.latest_phase.has_hearings ? (
                    <div className="card-content-child">
                        <Typography className="title"> Clienți: </Typography>
                        <Typography className="subtitle" noWrap>
                            {join(project.clients.map((client) => client.name),',')}
                        </Typography>
                    </div>
                ) : project.latest_phase && project.latest_phase.has_hearings ? (
                    <div className="card-content-child">
                        <Typography className="title"> Clienți: </Typography>
                        <Typography className="subtitle">-</Typography>
                    </div>
                ) : null}
            </div>
        </Link>
    )
}

function EmptyActiveProjectListComponent() {
    return (
        <div id="empty-projects-list-main-div">
            <img src={Wizard} alt="wizard" />
            <Typography className="no-project-title">Niciun proiect activ</Typography>
            <Typography className="no-project-text">
                Deși ne dorim, nu putem ghici dosarul pe care dorești să îl monitorizezi.
            </Typography>
        </div>
    )
}

const mapStateToProps = (state) => ({
    projects: orderBy(
        state.projects.data, 
        [(project) => project.interval ? parseInt(project.interval) : null, 'id'],
        ['asc', 'desc']
    ),
    nextPage: state.projects.next,
    isLoading: state.projects.isLoading,
    isGettingFirstPage: state.projects.isGettingFirstPage,
    selectedEntityID: state.localConfigs.selectedEntityID,
    permissions: state.permissions
})

export default connect(mapStateToProps)(ProjectsList)
