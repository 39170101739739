import React, {Component} from 'react'
import {Typography} from '@material-ui/core'

import {isEmpty} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import ResourceAddSuccess from '../components/ResourceAddSuccess'
import ActivityIndicatorComponent from '../components/ActivityIndicatorComponent'

class ManualPartyAddSuccess extends Component {
    componentDidMount() {
        const {partyID} = this.props.match.params

        this.props.dispatch(RESOURCES.parties.retrieve(partyID))
    }
    
    render() {
        const {phase, party, isLoading} = this.props

        return (
            <>
                {!isEmpty(party) && !isLoading ? (
                    <ResourceAddSuccess 
                        resourceText ="Partea a fost adăugată"
                        resourceDetails={<PartyDetails party={party}/>}
                        nextText ='Adaugă termene'
                        nextUrl = {`/phases/${phase.id}/add-manual-hearing`}
                        addMoreText ='Mai adaugă o parte'
                        addMoreUrl = {`/phases/${phase.id}/add-manual-party`}
                    />
                ) : isLoading ? (
                    <ActivityIndicatorComponent />
                ) : null}
            </>
        )
    }
}

function PartyDetails(props) {
    const {party} = props

    return (
        <div className='details-div'>
            <div className='details-list'>
                <Typography className='detail-text'>Nume: <span>{party.name}</span></Typography>
                <Typography className='detail-text'>Rol: <span>{party.role}</span></Typography>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    phase: state.phases.currentPhase,
    party: state.parties.currentParty,
    isLoading: state.parties.isLoading
})

export default connect(mapStateToProps)(ManualPartyAddSuccess)

