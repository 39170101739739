import React, {Component} from 'react'
import {Button, Input, Typography} from '@material-ui/core'
import {Formik} from 'formik'

import {values, isEmpty} from 'lodash'

import {connect} from 'react-redux'
import {createProjectWithSubscription} from '../../redux/projects'

export class CreateNewProject extends Component {
    handleCreateNewProject = (projectName) => {
        this.props.dispatch(createProjectWithSubscription({name: projectName}))
    }

    render() {
        const {projects, isLoading} = this.props

        return (
            <div className="create-new-project">
                {!isEmpty(projects) && (
                    <div className="divider">
                        <Typography className='divider-text'>Sau</Typography>
                    </div>
                )}
                <Typography className='title'>Creează un proiect nou</Typography>
                <Formik
                    initialValues={{project_name: ''}}
                    onSubmit={(values) => this.handleCreateNewProject(values.project_name)}
                >
                    {({handleChange, handleSubmit, values}) => (
                        <div>
                            <Input
                                value={values.project_name}
                                onChange={handleChange('project_name')}
                                placeholder="Nume proiect"
                                className="project-name-input"
                            />
                            <Button
                                onClick={handleSubmit} 
                                variant="contained"
                                size="large"
                                fullWidth
                                disabled={isLoading || values.project_name === ''}
                            >
                                <Typography className="button-title">Creează și folosește acest proiect</Typography>
                            </Button>
                           
                        </div>
                    )}  
                </Formik>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    projects: values(state.projects.data),
    isLoading: state.projects.isLoading
})

export default connect(mapStateToProps)(CreateNewProject)

