import React, {Component} from 'react'
import {CircularProgress} from '@material-ui/core'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import '../assets/scss/TermsAndConditions.scss'

class TermsAndConditions extends Component {
    componentDidMount() {
        this.props.dispatch(RESOURCES.terms.list())
    }
    
    render() {
        const {latestTerms, isLoadingTerms} = this.props

        return (
            <div id="terms-main-div">
                <div className='container'>
                    {!isLoadingTerms ? (
                        <div 
                            className='terms'
                            dangerouslySetInnerHTML={{__html: latestTerms.content}} 
                        />
                    ) : (
                        <div className='center-loader'>
                            <CircularProgress className="circular-progress" thickness={5} color="primary" />
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

const mapStateToProps = function(state) {
    return {
        latestTerms: state.terms.latestTerms,
        isLoadingTerms: state.terms.isLoading
    }
}

export default connect(mapStateToProps)(TermsAndConditions)
