import React, {useCallback, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'

import {Button, CircularProgress, FormControl, Input, InputLabel, MenuItem, Select, Typography} from '@material-ui/core'
import {ArrowDropDown} from '@material-ui/icons'
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import {Formik} from 'formik'

import DateFnsUtils from '@date-io/date-fns'
import {lightFormat} from 'date-fns'
import {values as _values, find, head, isEmpty, isNull, toString} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {getErrorFromCode} from 'avoapp-react-common/dist/utils'
import {connect} from 'react-redux'

import {DeleteAppealDialog} from '../components/ProjectDetails/Dialogs'

import '../assets/scss/ManualAdd.scss'

export const ManualAppealEdit = ({
    appeal,
    match: {params: {appealID}},
    isLoading,
    appealsErrors,
    retrieveAppeal,
    updateAppeal,
    deleteAppeal,
    phase,
    isLoadingPhases,
    retrievePhase,
    parties,
    isLoadingParties,
    listParties,
    currentSubscription
}) => {
    const [showDeleteAppealDialog, setShowDeleteAppealDialog] = useState(false)

    useEffect(() => {
        if((isEmpty(appeal) || toString(appeal.id) !== toString(appealID)) && !isLoading) {
            retrieveAppeal()
        }
    }, [isLoading, appeal, appealID, retrieveAppeal])

    useEffect(() => {
        if(!isEmpty(appeal) && (isEmpty(phase) || phase?.id !== appeal?.phase_id) && !isLoadingPhases) {
            retrievePhase(appeal.phase_id)
        }
    }, [appeal, isLoadingPhases, phase, retrievePhase])

    useEffect(() => {
        if(
            !isEmpty(appeal) && 
            !isEmpty(phase) &&
            (isEmpty(parties) || head(parties).phase_id !== phase.id) &&
              !isLoadingParties
        ) {
            listParties({litigation_id: phase.litigation_id})
        }
    }, [appeal, isLoadingParties, listParties, parties, phase])

    const toggleDeleteAppealDialog = useCallback(() => {setShowDeleteAppealDialog((state) => !state)}, [])

    const handleDeleteAppeal = useCallback(() => {
        deleteAppeal(appeal)
        toggleDeleteAppealDialog()
    }, [appeal, deleteAppeal, toggleDeleteAppealDialog])

    return (
        <div>
            <div className="navbar" id="navbar-manual-add">
                <div className="navbar-left">
                    <Typography className="navbar-title">Editează cale de atac</Typography>
                </div>
                <div className="navbar-right">
                    {!isEmpty(appeal) && !isLoading && (
                        <>
                            <Button className="delete-button" onClick={toggleDeleteAppealDialog}>
                                <Typography className="button-title">Șterge calea de atac</Typography>
                            </Button>
                            <DeleteAppealDialog
                                showDeleteAppealDialog={showDeleteAppealDialog}
                                appealToDelete={appeal}
                                handleCloseDeleteAppealDialog={toggleDeleteAppealDialog}
                                handleDeleteAppeal={handleDeleteAppeal}
                            />
                        </>
                    )}
                    {currentSubscription.id && (
                        <Link style={{textDecoration: 'none'}} to={`/subscriptions/${currentSubscription.id}`}>
                            <Button className="cancel-button">
                                <Typography className="button-title">Renunță</Typography>
                            </Button>
                        </Link>
                    )}
                </div>
            </div>
            <div id="manual-add-main-div">
                {
                    !isEmpty(appeal) && 
                    !isEmpty(parties) && 
                    !isLoadingParties &&
                    toString(appeal.id) === toString(appealID) ? (
                        <Formik
                            initialValues={{
                                date: new Date(appeal.date),
                                party:  find(parties, party => party.name === appeal.party)?.name || head(parties).name,
                                type: appeal.type
                            }}
                            onSubmit={(values) => {
                                updateAppeal({
                                    id: appealID,
                                    date: lightFormat(new Date(values.date), 'yyyy-MM-dd'),
                                    party: values.party,
                                    type: values.type
                                })
                            }}
                        >
                            {({handleChange, handleSubmit, values, setFieldValue}) => (
                                <div className='form'> 
                                    {!isEmpty(appealsErrors) && appealsErrors.map((errorCategories) =>
                                        errorCategories.map((error, index) => (
                                            <div key={index} className='error-div'>
                                                <Typography className='error-text'>
                                                    {getErrorFromCode(error, 'appeals')}
                                                </Typography>
                                            </div>
                                        ))
                                    )} 
                                    <div className="section">
                                        <div className="section-content">
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <FormControl className="project-add-form-control date-select">
                                                    <DatePicker
                                                        label="Data *"
                                                        value={values.date}
                                                        onChange={(value) => {setFieldValue('date', value)}}
                                                        InputProps={{disableUnderline: true}}
                                                        format="dd/MM/yyyy"
                                                        className="date-picker"
                                                        cancelLabel="Închide"
                                                        okLabel="Ok"
                                                    />
                                                    <ArrowDropDown className='dropdown-icon' />
                                                </FormControl>
                                            </MuiPickersUtilsProvider>
                                            <FormControl className="input-form-control">
                                                <InputLabel>Parte</InputLabel>
                                                <Select
                                                    value={values.party}
                                                    IconComponent={() => <ArrowDropDown className='dropdown-icon' />}
                                                    onChange={(event) => setFieldValue('party', event.target.value)}
                                                >
                                                    {parties.map((party) => (
                                                        <MenuItem key={party.id} value={party.name}>
                                                            {party.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <FormControl className='input-form-control'>
                                                <InputLabel shrink={!isEmpty(values.type)}>
                                                    Tip *
                                                </InputLabel>
                                                <Input
                                                    value={values.type}
                                                    onChange={handleChange('type')}
                                                    className="input"
                                                />
                                            </FormControl>
                                            <Typography className="disclaimer">
                                                * - câmpuri obligatorii
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className="buttons-div">
                                        <Button
                                            onClick={handleSubmit}
                                            disabled={isLoading || isNull(values.date) || isEmpty(values.type)}
                                            className='submit-button'
                                            variant='contained'
                                            size='large'
                                            fullWidth
                                        >
                                            <Typography className="button-title">Salvează calea de atac</Typography>
                                        </Button>
                                    </div>

                                </div>
                            )}
                        </Formik>
                        ) : isLoading || isLoadingParties || isLoadingPhases ? (
                            <CircularProgress className="circular-progress" thickness={5} color="primary" />
                        ) : null
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    phase: state.phases.currentPhase,
    isLoadingPhases: state.phases.isLoading,
    appeal: state.appeals.currentAppeal,
    isLoading: state.appeals.isLoading,
    appealsErrors: _values(state.appeals.errors),
    parties: _values(state.parties.data),
    currentSubscription: state.subscriptions.currentSubscription
})

const mapDispatchToProps = (dispatch, props) => {
    const {appealID} = props.match.params

    return {
        retrievePhase: (phaseID) => dispatch(RESOURCES.phases.retrieve(phaseID)),
        listParties: (fitlers) => dispatch(RESOURCES.parties.list(fitlers)),
        retrieveAppeal: () => dispatch(RESOURCES.appeals.retrieve(appealID)),
        deleteAppeal: (appeal) => dispatch(RESOURCES.appeals.destroy(appeal)),
        updateAppeal: (values) => dispatch(RESOURCES.appeals.update(values))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManualAppealEdit)
