import React, {Component} from 'react'
import {Button, CircularProgress, Typography} from '@material-ui/core'
import {Link} from 'react-router-dom'

import {isEmpty} from 'lodash'

import {connect} from 'react-redux'
import {EntityTypes} from 'avoapp-react-common/dist/constants'
import {replaceNullWithDash} from 'avoapp-react-common/dist/utils'

class CompanyData extends Component {
    render() {
        const {currentEntity, isLoading, permissions} = this.props

        return ( 
            <div className="card-large">
                <div className="card-main-div" id='company-data-settings-card'>
                    {!isEmpty(currentEntity) && !isLoading ? (
                        <>
                            <div className="card-header">
                                <Typography className="card-header-title">Datele companiei</Typography>
                                {permissions.entity.update && (
                                    <Link to={`/entities/${currentEntity.id}/edit`} style={{textDecoration: 'none'}}>
                                        <Button variant="contained" className="card-header-button">
                                            <Typography>Schimbă datele companiei</Typography>
                                        </Button>
                                    </Link>
                                )}
                            </div>
                            <div className="card-body">
                                {currentEntity.type === EntityTypes.PERSON ? (
                                    <PersonTypeData entity={currentEntity}/>
                                ) : currentEntity.type === EntityTypes.COMPANY ? (
                                    <CompanyTypeData entity={currentEntity}/>
                                ) : null}
                            </div>
                        </>
                    ) : isLoading ? (
                        <div className="card-body">
                            <div className="center-loader">
                                <CircularProgress className="circular-progress" thickness={5} color="primary" />
                            </div>
                        </div>
                    ) : (
                        <div className="card-body">
                            <Typography className='no-groups'>Nu există date</Typography>
                        </div>
                    )}
                </div>                                                    
            </div>
        )
    }
}

class PersonTypeData extends Component {
    render() {
        const {entity} = this.props

        return (
            <div className='entity-data person'> 
                <div className='col'>
                    <div className="row">
                        <Typography className='title'>Nume:</Typography>
                        <Typography className='value'>{entity.last_name}</Typography>
                    </div>
                    <div className="row">
                        <Typography className='title'>Prenume:</Typography>
                        <Typography className='value'>{entity.first_name}</Typography>
                    </div>
                    <div className="row">
                        <Typography className='title'>Tip:</Typography>
                        <Typography className='value'>Persoană fizică</Typography>
                    </div>
                    <div className="row">
                        <Typography className='title'>Email:</Typography>
                        <Typography className='value'>{replaceNullWithDash(entity.email)}</Typography>
                    </div>
                    <div className="row">
                        <Typography className='title'>Telefon:</Typography>
                        <Typography className='value'>{replaceNullWithDash(entity.phone)}</Typography>
                    </div>   
                </div>
                <div className='col'>
                    <div className="row">
                        <Typography className='title'>Adresă:</Typography>
                        <Typography className='value'>{replaceNullWithDash(entity.address)}</Typography>
                    </div>
                    <div className="row">
                        <Typography className='title'>Țara:</Typography>
                        <Typography className='value'>{replaceNullWithDash(entity.country)}</Typography>
                    </div>
                    <div className="row">
                        <Typography className='title'>Județ:</Typography>
                        <Typography className='value'>{replaceNullWithDash(entity.county)}</Typography>
                    </div>
                    <div className="row">
                        <Typography className='title'>Localitate:</Typography>
                        <Typography className='value'>{replaceNullWithDash(entity.locality)}</Typography>
                    </div>
                </div>
            </div>
        )
    }
}

class CompanyTypeData extends Component {
    render() {
        const {entity} = this.props
        
        return (
            <div className='entity-data'> 
                <div className='col'>
                    <div className="row">
                        <Typography className='title'>Nume:</Typography>
                        <Typography className='value'>{entity.name}</Typography>
                    </div>
                    <div className="row">
                        <Typography className='title'>Tip:</Typography>
                        <Typography className='value'>Persoană juridică</Typography>
                    </div>
                    <div className="row">
                        <Typography className='title'>Cod J:</Typography>
                        <Typography className='value'>{replaceNullWithDash(entity.trade_register_number)}</Typography>
                    </div>
                    <div className="row">
                        <Typography className='title'>CUI:</Typography>
                        <Typography className='value'>{replaceNullWithDash(entity.vat_number)}</Typography>
                    </div>
                </div>
                <div className='col'>
                    <div className="row">
                        <Typography className='title'>Adresă:</Typography>
                        <Typography className='value'>{replaceNullWithDash(entity.address)}</Typography>
                    </div>
                    <div className="row">
                        <Typography className='title'>Țara:</Typography>
                        <Typography className='value'>{replaceNullWithDash(entity.country)}</Typography>
                    </div>
                    <div className="row">
                        <Typography className='title'>Județ:</Typography>
                        <Typography className='value'>{replaceNullWithDash(entity.county)}</Typography>
                    </div>
                    <div className="row">
                        <Typography className='title'>Localitate:</Typography>
                        <Typography className='value'>{replaceNullWithDash(entity.locality)}</Typography>
                    </div>
                </div>
                <div className='col'>
                    <div className="row">
                        <Typography className='title'>Email:</Typography>
                        <Typography className='value'>{replaceNullWithDash(entity.email)}</Typography>
                    </div>
                    <div className="row">
                        <Typography className='title'>Telefon:</Typography>
                        <Typography className='value'>{replaceNullWithDash(entity.phone)}</Typography>
                    </div>               
                </div>
            </div>
        )
    }
}

const mapStateToProps = function (state) {
    return {
        currentEntity: state.entities.currentEntity,
        isLoading: state.entities.isLoading,
        permissions: state.permissions
    }   
}

export default connect(mapStateToProps)(CompanyData)
