import {toast} from 'react-toastify'
import {put, takeEvery} from 'redux-saga/effects'
import {push} from 'connected-react-router'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {ACTIONS} from 'avoapp-react-common/dist/redux/entityProfiles'

const {entityProfiles: resource} = RESOURCES

function* handleCreateEntityProfileSuccess() {
    toast.success('Utilizatorul a fost adăugat cu succes.')

    yield put(push('/settings'))
}

function* handleUpdateEntityProfileSuccess() {
    toast.success('Modificările au fost salvate')

    yield put(push('/settings'))
}

function* handleDestroyEntityProfileSuccess() {
    toast.success('Utilizatorul a fost șters din companie cu succes')

    yield put(push('/settings'))
}

function* handleConfirmEntityProfileSuccess() {
    const message = 'Raspunsul tău a fost salvat'
    toast.success(message)

    yield put(push('/login'))
}

function handleConfirmEntityProfileFail() {
    const message = 'A aparut o eroare'
    toast.error(message)
}

export function* saga() {
    yield takeEvery(resource.actions.create.success, handleCreateEntityProfileSuccess)
    yield takeEvery(resource.actions.update.success, handleUpdateEntityProfileSuccess)
    yield takeEvery(resource.actions.destroy.success, handleDestroyEntityProfileSuccess)
    yield takeEvery(ACTIONS.CONFIRM_ENTITY_PROFILE.success, handleConfirmEntityProfileSuccess)
    yield takeEvery(ACTIONS.CONFIRM_ENTITY_PROFILE.fail, handleConfirmEntityProfileFail)
}

