import {takeEvery, put} from 'redux-saga/effects'
import {push} from 'connected-react-router'
import {toast} from 'react-toastify'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {getPermissions} from 'avoapp-react-common/dist/redux/permissions'

const {groups: resource} = RESOURCES

function* handleCreateGroupSuccess() {
    toast.success('Grupul a fost creat cu success.')
    yield put(push('/settings'))
}

function* handleUpdateGroupSuccess() {
    toast.success('Modificările au fost salvate!')
    yield put(getPermissions())

    yield put(push('/settings'))
}

export function* saga() {
    yield takeEvery(resource.actions.create.success, handleCreateGroupSuccess)
    yield takeEvery(resource.actions.update.success, handleUpdateGroupSuccess)
}
