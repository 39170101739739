import React, {Component} from 'react'
import {Button, FormControl, Input, InputLabel, MenuItem, Select, Typography} from '@material-ui/core'
import {ArrowDropDown} from '@material-ui/icons'
import {Formik} from 'formik'

import {manualLitigationSource} from 'avoapp-react-common/dist/constants'

import '../../assets/scss/ProjectAdd.scss'

export default class SimpleCheck extends Component {
    render() {
        const {sourcesArray, handleCheck} = this.props

        return (
            <Formik
                initialValues={{
                    source: sourcesArray[0].value,
                    litigation_number: ''
                }}
                onSubmit={(values) => {
                    handleCheck({
                        source: values.source,
                        litigation_number: values.litigation_number
                    })
                }}
            >
                {({handleChange, handleSubmit, values, setFieldValue}) => (
                    <>
                        <FormControl style={{width: '100%'}}>
                            <InputLabel id="source">Sursa</InputLabel>
                            <Select
                                labelId="source"
                                value={values.source}
                                IconComponent={() => <ArrowDropDown className='dropdown-icon' />}
                                onChange={(event) => {setFieldValue('source', event.target.value)}}
                                className="subscriptions-select"
                            >
                                {sourcesArray.map((source) => (
                                    <MenuItem key={source.value} value={source.value}>
                                        {source.label}
                                    </MenuItem>
                                ))}
                                <MenuItem key={manualLitigationSource} value={manualLitigationSource}>
                                    Dosar Manual
                                </MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl className="subscriptions-add-form-control">
                            <InputLabel>Număr dosar</InputLabel>
                            <Input
                                value={values.litigation_number}
                                className="subscriptions-input"
                                onChange={handleChange('litigation_number')}
                            />
                        </FormControl>
                        <Button size="large" variant="contained" className='submit-button' onClick={handleSubmit}>
                            <Typography className="button-title">Mai departe</Typography>
                        </Button>
                    </>
                )}
            </Formik>
        )
    }
}
