import React, {Component} from 'react'
import {Typography, Button, ButtonBase, Dialog, DialogTitle, DialogActions, CircularProgress} from '@material-ui/core'
import {X, Plus} from 'react-feather'
import {Link} from 'react-router-dom'

import {values, isEmpty, isNull} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import {colors} from '../../assets/styles/colors'

class PartySubscriptions extends Component {
    constructor() {
        super()

        this.state = {
            showDeletePartySubDialog: false,
            partySubscriptionToDelete: {}
        }
    }

    handleCloseDeletePartySubDialog = () => {
        this.setState({showDeletePartySubDialog: false, partySubscriptionToDelete: {}})
    }
    
    render() {
        const {showDeletePartySubDialog, partySubscriptionToDelete} = this.state
        const {client,partySubscriptions,isLoadingPartySubscriptions,permissions,currentClientPermissions} = this.props

        return (
            <div className="section">
                <div className="section-header">
                    <Typography className="section-header-text">Monitorizări juridice</Typography>
                    {(permissions.clients.update || currentClientPermissions.permissions_subscriptions.create) && <>
                        <Link style={{textDecoration: 'none'}} to={`/clients/${client.id}/add-party-subscription`}>
                            <Button
                                size="small"
                                variant="contained"
                                className="navbar-main-button"
                                style={{backgroundColor: colors.success}}
                            >
                                <Plus className="navbar-button-icon" />
                                <Typography className="navbar-button-title">
                                    Adaugă monitorizare
                                </Typography>
                            </Button>
                        </Link>
                    </>}
                </div>
                <div className="section-content">
                    {!isLoadingPartySubscriptions && !isEmpty(partySubscriptions) ? (
                        <div className='cards-container'> 
                            {partySubscriptions.map((partySubscription) => (
                                <div key={partySubscription.id} className="card">
                                    <div className="card-header">
                                        <Typography className="card-title">"{partySubscription.party_name}"</Typography>
                                        {
                                            (permissions.clients.update || 
                                            currentClientPermissions.permissions_subscriptions.destroy) && 
                                            <>
                                                <ButtonBase
                                                    onClick={() => {this.setState({
                                                        showDeletePartySubDialog: true,
                                                        partySubscriptionToDelete: partySubscription
                                                    })}}
                                                >
                                                    <X
                                                        className="small-delete-button-icon"
                                                        size={14}
                                                        style={{alignSelf: 'center'}}
                                                    />
                                                </ButtonBase>
                                          
                                            </>
                                        }
                                    </div>
                                    <div className="card-content">
                                        <div className="card-content-child">
                                            <Typography className="title">Institutia:</Typography>
                                            <Typography className="subtitle">
                                                {
                                                    !isNull(partySubscription.institution) ? 
                                                        partySubscription.institution : 
                                                        'Toate'
                                                }
                                            </Typography>
                                        </div>
                                        <div className="card-content-child">
                                            <Typography className="title">Sursa:</Typography>
                                            <Typography className="subtitle">
                                                {partySubscription.source === 'portal_just'
                                                    ? 'Portal Just'
                                                    : partySubscription.source}
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <DeletePartySubscriptionDialog
                                showDeletePartySubDialog={showDeletePartySubDialog}
                                partySubscriptionToDelete={partySubscriptionToDelete}
                                handleCloseDeletePartySubDialog={this.handleCloseDeletePartySubDialog}
                                handleDeletePartySubscription={() => {
                                    this.props.dispatch(RESOURCES.partySubscriptions.destroy(partySubscriptionToDelete))
                                    this.handleCloseDeletePartySubDialog()
                                }}
                            />
                        </div>
                    ) : isEmpty(partySubscriptions) ? (
                        <Typography className='no-data-text'>
                            Nu există nicio sursă de date
                        </Typography>
                    ) : (
                        <div className='loading-div'>
                            <CircularProgress className="circular-progress" thickness={5} color="primary" />
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

class DeletePartySubscriptionDialog extends Component {
    render() {
        const {
            showDeletePartySubDialog,
            handleCloseDeletePartySubDialog,
            handleDeletePartySubscription,
            partySubscriptionToDelete
        } = this.props
        
        return (
            <Dialog className='delete-dialog' open={showDeletePartySubDialog} onClose={handleCloseDeletePartySubDialog}>
                <DialogTitle>
                    Ești sigur că vrei să ștergi abonarea de date pentru "{partySubscriptionToDelete.party_name}" din
                    {' '}"{partySubscriptionToDelete.institution}"?
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleCloseDeletePartySubDialog} className='close-button'>
                        <Typography className='button-text'>Nu, renunță</Typography>
                    </Button>
                    <Button onClick={handleDeletePartySubscription} className='action-button'>
                        <Typography className='button-text'> Da, șterge</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = function (state) {
    return {
        client: state.clients.currentClient,
        partySubscriptions: values(state.partySubscriptions.data),
        isLoadingPartySubscriptions: state.partySubscriptions.isLoading,
        permissions: state.permissions,
        currentClientPermissions: state.clientPermissions.currentClientPermissions
    }
}

export default connect(mapStateToProps)(PartySubscriptions)
