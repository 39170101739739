import {toast} from 'react-toastify'

import {takeEvery, put} from 'redux-saga/effects'
import {push} from 'connected-react-router'

import {googleCalendars} from 'avoapp-react-common'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

const {googleCalendars: resource} = RESOURCES
const {ACTIONS} = googleCalendars

function* handleGoogleSyncSuccess() {
    yield put(RESOURCES.users.retrieve('me'))
    const message = 'Sincronizarea a început.'
    
    toast.success(message)
    yield put(push('/sync-google-calendar/method'))
}

function* handleGoogleUnsyncSuccess() {
    toast.success('Sincronizarea a fost oprită cu succes.')

    yield put(RESOURCES.googleProfile.retrieve('me'))
    yield put(RESOURCES.users.retrieve('me'))
}

function handleGoogleSyncFail(response) {
    const message = 'Sincronizarea NU a fost făcută.'

    toast.error(message)
}

function handleGoogleUnsyncFail(response) {
    const message = 'A apărut o eroare la oprirea sincronizării.'

    toast.error(message)
}

function* handleCreateGoogleCalendarSuccess(response) {
    yield put(
        RESOURCES.googleProfile.update({
            id: 'me',
            calendar_id: response.payload.id
        })
    )

    yield put(push('/settings'))
}

export function* saga() {
    yield takeEvery(ACTIONS.GOOGLE_SYNC.success, handleGoogleSyncSuccess)
    yield takeEvery(ACTIONS.GOOGLE_SYNC.fail, handleGoogleSyncFail)
    yield takeEvery(ACTIONS.GOOGLE_UNSYNC.success, handleGoogleUnsyncSuccess)
    yield takeEvery(ACTIONS.GOOGLE_UNSYNC.fail, handleGoogleUnsyncFail)
    yield takeEvery(resource.actions.create.success, handleCreateGoogleCalendarSuccess)

}

