import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'

import {Formik} from 'formik'

import {Button, CircularProgress, FormControl, Input, InputLabel, MenuItem, Select, Typography} from '@material-ui/core'
import {ArrowDropDown} from '@material-ui/icons'
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'

import DateFnsUtils from '@date-io/date-fns'
import {lightFormat} from 'date-fns'
import {head, isEmpty, isNull, values} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {getErrorFromCode} from 'avoapp-react-common/dist/utils'
import {connect} from 'react-redux'

import '../assets/scss/ManualAdd.scss'

function ManualAppealAdd({
    phase, 
    isLoadingPhases,
    currentSubscription,
    match: {params : {phaseID}},
    parties,
    currentParty,
    isLoadingParties,
    isLoading,
    appealsErrors,
    retrievePhase,
    listParties,
    createAppeal
}) {
    useEffect(() => {
        retrievePhase()
    }, [retrievePhase])

    useEffect(() => {
        if(!isEmpty(phase) && !isLoadingPhases) {
            listParties(phase.litigation_id)
        }
    }, [isLoadingPhases, listParties, phase])
    
    return (
        <div>
            <div className="navbar" id="navbar-manual-add">
                <div className="navbar-left">
                    <Typography className="navbar-title">
                        Adaugă o cale de atac pentru dosarul "{phase.number}"
                    </Typography>
                </div>
                <div className="navbar-right">
                    {currentSubscription.id && (
                        <Link style={{textDecoration: 'none'}} to={`/subscriptions/${currentSubscription.id}`}>
                            <Button className="cancel-button">
                                <Typography className="button-title">Vezi detalii dosar</Typography>
                            </Button>
                        </Link>
                    )}
                </div>
            </div>
            <div id="manual-add-main-div">
                {!isEmpty(parties) && !isLoadingParties ? (
                    <Formik
                        initialValues={{
                            date: new Date(),
                            party: currentParty.name || head(parties).name,
                            type: ''
                        }}
                        onSubmit={(values) => {
                            createAppeal({
                                phase_id: phaseID,
                                date: lightFormat(values.date, 'yyyy-MM-dd'),
                                party: values.party,
                                type: values.type
                            })
                        }}
                    >
                        {({handleChange, handleSubmit, values, setFieldValue}) => (
                            <div className='form'> 
                                {!isEmpty(appealsErrors) && appealsErrors.map((errorCategories) =>
                                    errorCategories.map((error, index) => (
                                        <div key={index} className='error-div'>
                                            <Typography className='error-text'>
                                                {getErrorFromCode(error, 'appeals')}
                                            </Typography>
                                        </div>
                                    ))
                                )} 
                                <div className="section">
                                    <div className="section-content">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <FormControl className="project-add-form-control date-select">
                                                <DatePicker
                                                    label="Data *"
                                                    value={values.date}
                                                    onChange={(value) => {setFieldValue('date', value)}}
                                                    InputProps={{disableUnderline: true}}
                                                    format="dd/MM/yyyy"
                                                    className="date-picker"
                                                    cancelLabel="Închide"
                                                    okLabel="Ok"
                                                />
                                                <ArrowDropDown className='dropdown-icon' />
                                            </FormControl>
                                        </MuiPickersUtilsProvider>
                                        <FormControl className="input-form-control">
                                            <InputLabel>Parte</InputLabel>
                                            <Select
                                                value={values.party}
                                                IconComponent={() => <ArrowDropDown className='dropdown-icon' />}
                                                onChange={(event) => {setFieldValue('party', event.target.value)}}
                                            >
                                                {parties.map((party) => (
                                                    <MenuItem key={party.id} value={party.name}>
                                                        {party.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <FormControl className='input-form-control'>
                                            <InputLabel shrink={!isEmpty(values.type)}>
                                                Tip *
                                            </InputLabel>
                                            <Input
                                                value={values.type}
                                                onChange={handleChange('type')}
                                                className="input"
                                            />
                                        </FormControl>
                                        <Typography className="disclaimer">
                                            * - câmpuri obligatorii
                                        </Typography>
                                    </div>
                                </div>
                                <div className="buttons-div">
                                    <Button
                                        onClick={handleSubmit}
                                        disabled={isLoading || isNull(values.date) || isEmpty(values.type)}
                                        className='submit-button'
                                        variant='contained'
                                        size='large'
                                        fullWidth
                                    >
                                        <Typography className="button-title">Salvează calea de atac</Typography>
                                    </Button>
                                </div>

                            </div>
                        )}
                    </Formik>
                ) : (
                    <CircularProgress className="circular-progress" thickness={5} color="primary" />
                )}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    phase: state.phases.currentPhase,
    isLoadingPhases: state.phases.isLoading,
    appeal: state.appeals.currentAppeal,
    isLoading: state.appeals.isLoading,
    appealsErrors: values(state.appeals.errors),
    parties: values(state.parties.data),
    currentParty: state.parties.currentParty,
    currentSubscription: state.subscriptions.currentSubscription
})
    
const mapDispatchToProps = (dispatch, props) => {
    const {phaseID} = props.match.params
    
    return {
        retrievePhase: () => dispatch(RESOURCES.phases.retrieve(phaseID)),
        listParties: (litigationId) => dispatch(RESOURCES.parties.list({litigation_id: litigationId})),
        createAppeal: (values) => dispatch(RESOURCES.appeals.create(values)) 
    }
    
}
export default connect(mapStateToProps, mapDispatchToProps)(ManualAppealAdd)

