import {takeEvery, put} from 'redux-saga/effects'
import {push} from 'connected-react-router'

import {spec} from 'avoapp-react-common'
const {RESOURCES} = spec

const {tickets: resource} = RESOURCES

function* handleCreateTicketsSuccess(response) {
    yield put(push('/ticket-success'))
}

export function* saga() {
    yield takeEvery(resource.actions.create.success, handleCreateTicketsSuccess)
}
