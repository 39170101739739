import {toast} from 'react-toastify'

import {takeEvery, put} from 'redux-saga/effects'
import {push} from 'connected-react-router'

import {keepClientInStore, setCurrent} from 'avoapp-react-common/dist/redux/clients'
import {getErrorFromCode} from 'avoapp-react-common/dist/utils'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

const {clients: resource} = RESOURCES

function* handleCreateClientSuccess(response) {
    const message = 'Clientul a fost creat cu succes'
    toast.success(message)
    
    yield put(keepClientInStore(response.payload))
}

function handleCreateClientFail() {
    const message = 'Clientul nu a putut fi creat'
    toast.error(message)
}

function* handleUpdateClientSuccess(response) {
    yield put(keepClientInStore(response.payload))
    toast.success('Modificările au fost salvate')
}

function handleUpdateClientFail(response) {
    response.payload.non_field_errors.map((error) => toast.error(getErrorFromCode(error), 'clients'))
}

function* handleRetrieveProjectSuccess(response) {
    yield put(setCurrent(response.payload))
}

function* handleDestroyClientSuccess() {
    yield put(push('/clients'))
}

export function* saga() {
    yield takeEvery(resource.actions.create.success, handleCreateClientSuccess)
    yield takeEvery(resource.actions.create.fail, handleCreateClientFail)
    yield takeEvery(resource.actions.update.success, handleUpdateClientSuccess)
    yield takeEvery(resource.actions.update.fail, handleUpdateClientFail)
    yield takeEvery(resource.actions.retrieve.success, handleRetrieveProjectSuccess)
    yield takeEvery(resource.actions.destroy.success, handleDestroyClientSuccess)
}
