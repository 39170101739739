import {goBack, push} from 'connected-react-router'
import {toast} from 'react-toastify'
import {put, select, takeEvery} from 'redux-saga/effects'

import {values} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

const {hearings: resource} = RESOURCES

function* handleCreateHearingSuccess(response) {
    const {id: hearingID} = response.payload
    const routerLocations = values(yield select((state) => state.routerLocations))
    
    if (routerLocations[routerLocations.length - 2]?.location?.pathname?.includes('/subscriptions/')) {
        yield put(push(routerLocations[routerLocations.length -2].location.pathname))
    } else {
        yield put(push(`/hearings/${hearingID}/add-success`))
    }
}

function* handleUpdateHearingSuccess() {
    yield(put(goBack()))
    toast.success('Modificările au fost salvate')
}

function* handleDestroyHearingSuccess(response) {
    yield(put(goBack()))
}

export function* saga() {
    yield takeEvery(resource.actions.create.success, handleCreateHearingSuccess)
    yield takeEvery(resource.actions.update.success, handleUpdateHearingSuccess)
    yield takeEvery(resource.actions.destroy.success, handleDestroyHearingSuccess)
}
