import {
    Button,
    ButtonBase,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    Typography
} from '@material-ui/core'
import {ArrowDropDown} from '@material-ui/icons'
import React, {useEffect, useMemo, useState} from 'react'
import {X} from 'react-feather'
import {Redirect} from 'react-router'

import {find, head, isEmpty, toString, values} from 'lodash'

import {setCurrent} from 'avoapp-react-common/dist/redux/phases'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {push} from 'connected-react-router'
import {connect} from 'react-redux'

import '../assets/scss/SelectPhaseModal.scss'

export const SelectPhaseModal = ({
    showSelectPhaseModal,
    handleCloseSelectPhaseModal,
    resource,
    getNextStepUrl,
    phases,
    isLoadingPhases,
    listPhases,
    litigation,
    dispatchSetCurrent,
    dispatchPush
}) => {
    const [initialized, setInitialized] = useState(false)
    const [selectedPhaseID, setSelectedPhaseID] = useState('')

    useEffect(() => {        
        if(showSelectPhaseModal && !initialized) {    
            listPhases(litigation.id)
            setInitialized(true)
        }
    }, [initialized, listPhases, litigation.id, showSelectPhaseModal])

    useEffect(() => {
        if(!isEmpty(phases) && !isLoadingPhases && isEmpty(toString(selectedPhaseID))) {
            setSelectedPhaseID(head(phases).id)
        }
    }, [isLoadingPhases, phases, selectedPhaseID])

    const nextURL = useMemo(() => {
        return getNextStepUrl(selectedPhaseID)
    }, [getNextStepUrl, selectedPhaseID])

    const handleSelectPhase = () => {
        const selectedPhase = find(phases, ['id', selectedPhaseID])
    
        dispatchSetCurrent(selectedPhase)
        dispatchPush(nextURL)
    }

    const shouldSkip = useMemo(() => {
        return showSelectPhaseModal && selectedPhaseID && phases.length === 1
    }, [phases.length, selectedPhaseID, showSelectPhaseModal])
    
    if(shouldSkip) {
        return <Redirect to={nextURL} />
    }
    
    return (
        <Modal open={showSelectPhaseModal} onClose={handleCloseSelectPhaseModal}>
            <div className="select-phase-modal">
                <div className="main-div">
                    <div className="header">
                        <ButtonBase onClick={handleCloseSelectPhaseModal} className='close-modal-button'>
                            <X className='close-button-icon' />
                        </ButtonBase>
                    </div>
                    <div className="content">
                        <div className="content-text-div">
                            <Typography className="modal-title">
                                Selectează faza pentru care dorești să adaugi {resource}
                            </Typography>
                        </div>
                        {!isEmpty(phases) && !isLoadingPhases ? (
                            <FormControl className='styled-form-control'>
                                <InputLabel id="subscription-select">Faza</InputLabel>
                                <Select
                                    labelId="subscription-select"
                                    value={selectedPhaseID}
                                    IconComponent={() => <ArrowDropDown className='dropdown-icon' />}
                                    onChange={(event) => setSelectedPhaseID(event.target.value)}
                                >
                                    {phases.map((phase) => (
                                        <MenuItem key={phase.id} value={phase.id}>
                                            {phase.phase}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        ) : !isLoadingPhases ? (
                            <CircularProgress className="circular-progress" thickness={5} color="primary" />
                        ) : null}
                        <div className='buttons-div'>
                            <Button
                                onClick={handleSelectPhase}
                                variant="contained"
                                size="large"
                                fullWidth
                            >
                                <Typography>Alege fază</Typography>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
        
const mapStateToProps = (state) => ({
    litigation: state.subscriptions.currentSubscription?.litigation,
    phases: values(state.phases.data),
    isLoadingPhases: state.phases.isLoading
})
        
const mapDispatchToProps = (dispatch, props) => ({
    listPhases: (litigationID) => dispatch(RESOURCES.phases.list({litigation_id: litigationID})),
    dispatchSetCurrent: (phase) => dispatch(setCurrent(phase)),
    dispatchPush: (url) => dispatch(push(url))
})

export default connect(mapStateToProps, mapDispatchToProps)(SelectPhaseModal)