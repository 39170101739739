import React, {Component} from 'react'
import {
    Typography,
    Stepper,
    Step,
    StepLabel,
    Input,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    ButtonBase,
    CircularProgress,
    FormControlLabel,
    Checkbox
} from '@material-ui/core'
import {Link} from 'react-router-dom'
import {ArrowDropDown} from '@material-ui/icons'
import VisibilitySensor from 'react-visibility-sensor'
import {Formik} from 'formik'
import {ArrowUp} from 'react-feather'

import {concat, isEmpty, get, head, values as _values} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {getErrorFromCode} from 'avoapp-react-common/dist/utils'

import {partySubscriptionsData} from '../assets/data/choices'

import InstitutionSelect from '../components/InstitutionSelect'

import '../assets/scss/ClientAdd.scss'

export class ClientAdd extends Component {
    constructor(props) {
        super(props)
        this.addClientDiv = React.createRef()
        this.addContactPersonDiv = React.createRef()
        this.addPartySubscriptionDiv = React.createRef()

        this.state = {
            activeStep: 0
        }
    }

    componentDidMount() {
        this.setState({activeStep: 0})
    }

    createSourcesArray = (startingSourcesArray) => {
        let finalSourcesArray = []
        startingSourcesArray.map((source) => (
            finalSourcesArray = concat(finalSourcesArray, {
                value: source,
                label: partySubscriptionsData.source[source]
            })
        ))

        return finalSourcesArray
    }

    createInstitutionsArray = (startingInstitutionsArray) => {
        let finalInstitutionsArray = []
        startingInstitutionsArray.map((institution) => (
            finalInstitutionsArray = concat(finalInstitutionsArray, {
                value: partySubscriptionsData.institution[institution],
                label: institution
            })
        ))

        return finalInstitutionsArray
    }

    render() {
        const {
            selectedEntityID,
            client,
            isLoadingClients,
            clientErrors,
            contactPersonsErrors,
            partySubscriptionErrors,
            planError
        } = this.props

        const sourcesArray = this.createSourcesArray(Object.keys(partySubscriptionsData.source))
        const institutionsArray = this.createInstitutionsArray(Object.keys(partySubscriptionsData.institution))

        return (
            <>
                <div className="navbar" id="navbar-clients-add">
                    <div className="navbar-left">
                        <Typography className="navbar-title">Adaugă client</Typography>
                        <div className="navbar-stepper">
                            <Stepper activeStep={this.state.activeStep}>
                                <Step>
                                    <StepLabel>
                                        <Typography>Nume client</Typography>
                                    </StepLabel>
                                </Step>
                                <Step>
                                    <StepLabel>
                                        <Typography>Persoane de contact</Typography>
                                        <Typography variant="caption">Opțional</Typography>
                                    </StepLabel>
                                </Step>
                                <Step>
                                    <StepLabel>
                                        <Typography>Monitorizări juridice</Typography>
                                        <Typography variant="caption">Opțional</Typography>
                                    </StepLabel>
                                </Step>
                            </Stepper>
                        </div>
                    </div>
                    <Link style={{textDecoration: 'none'}} to='/clients'>
                        <Button className="cancel-button">
                            <Typography className="cancel-button-title">Renunță</Typography>
                        </Button>
                    </Link>
                </div>
                <div id="client-add-main-div" style={{flexDirection: 'column'}}>
                    <>
                        <VisibilitySensor onChange={() => this.setState({activeStep: 0})}>
                            <div ref={this.addClientDiv} className="client-add-step-div">
                                <div className="add-client-left-div">
                                    <div className="top">
                                        <div className="step-number-div">
                                            <Typography className="step-number">1</Typography>
                                        </div>
                                        <Typography className="step-title">
                                            Alege
                                            <br /> nume client
                                        </Typography>
                                    </div>
                                    <div className="bottom">
                                        <Typography className="step-description">
                                            Introdu numele clientului pe care dorești să îl adaugi în aplicație.
                                        </Typography>
                                    </div>
                                </div>
                                <div className="add-client-right-div">
                                    <Formik
                                        initialValues={{client_name: ''}}
                                        onSubmit={(values) => {
                                            let projectsIds = []
                                            const projectID = get(this.props.history.location.state, 'projectID')

                                            if (projectID){
                                                projectsIds = [projectID]
                                            }

                                            this.props.dispatch(RESOURCES.clients.create({
                                                entity_id: selectedEntityID,
                                                name: values.client_name,
                                                projects_ids: projectsIds
                                            }))
                                        }}>
                                        {({handleChange, handleSubmit, values}) => (
                                            <div>
                                                {!isEmpty(clientErrors) 
                                                    && isEmpty(clientErrors.detail) 
                                                    && _values(clientErrors).map((errorCategories) =>
                                                        errorCategories.map((error, index) => (
                                                            <div key={index} className='error-div'>
                                                                <Typography className='error-text'>
                                                                    {getErrorFromCode(error, 'client')}
                                                                </Typography>
                                                            </div>
                                                        ))
                                                    )}
                                                <Input
                                                    value={values.client_name}
                                                    onChange={handleChange('client_name')}
                                                    placeholder="Nume client"
                                                    className="client-name-input"
                                                />
                                                <Button
                                                    onClick={() => {
                                                        handleSubmit()
                                                        window.scrollTo(0, this.addContactPersonDiv.current.offsetTop)
                                                    }}
                                                    size="large"
                                                    variant="contained"
                                                    fullWidth>
                                                    <Typography className="button-title">Mai departe</Typography>
                                                </Button>
                                            </div>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </VisibilitySensor>
                        <VisibilitySensor onChange={() => this.setState({activeStep: 1})}>
                            <div className="client-add-step-div" ref={this.addContactPersonDiv}>
                                <div className="add-client-left-div">
                                    <div className="top">
                                        <div className="step-number-div">
                                            <Typography className="step-number">2</Typography>
                                        </div>
                                        <Typography className="step-title">
                                            Adaugă
                                            <br /> persoană de notificat
                                        </Typography>
                                    </div>
                                    <div className="bottom">
                                        <Typography className="step-description">
                                            Atunci când adaugi într-un client o persoană de notificat,
                                            respectiva persoană primește automat emailuri cu privire la
                                            noutăți în proiectele clientului. De fiecare dată când te 
                                            notificăm pe tine cu privire la proiect, această persoană va 
                                            primi câte un email cu modificarea specifică din cadrul proiectului.
                                        </Typography>
                                    </div>
                                </div>
                                <div className="add-client-right-div">
                                    {!isEmpty(client) && !isLoadingClients ? (
                                        <Formik
                                            initialValues={{
                                                clientID: client.id,
                                                lastName: '',
                                                firstName: '',
                                                email: '',
                                                receiveMDNotifications: true
                                            }}
                                            onSubmit={(values) => {
                                                this.props.dispatch(
                                                    RESOURCES.contactPersons.create({
                                                        client_id: values.clientID,
                                                        first_name: values.firstName,
                                                        last_name: values.lastName,
                                                        email: values.email,
                                                        receive_md_notifications: values.receiveMdNotifications
                                                    })
                                                )
                                            }}>
                                            {({handleChange, handleSubmit, values}) => (
                                                <div className="client-search-data-source-div">
                                                    {!isEmpty(contactPersonsErrors) 
                                                    && isEmpty(contactPersonsErrors.detail) 
                                                    && _values(contactPersonsErrors).map((errorCategories) =>
                                                        errorCategories.map((error, index) => (
                                                            <div key={index} className='error-div'>
                                                                <Typography className='error-text'>
                                                                    {getErrorFromCode(error, 'contactPerson')}
                                                                </Typography>
                                                            </div>
                                                        ))
                                                    )}
                                                    <FormControl className="client-add-form-control">
                                                        <InputLabel>Nume*</InputLabel>
                                                        <Input
                                                            value={values.lastName}
                                                            className="client-input"
                                                            onChange={handleChange('lastName')}
                                                        />
                                                    </FormControl>
                                                    <FormControl className="client-add-form-control">
                                                        <InputLabel>Prenume*</InputLabel>
                                                        <Input
                                                            value={values.firstName}
                                                            className="client-input"
                                                            onChange={handleChange('firstName')}
                                                        />
                                                    </FormControl>
                                                    <FormControl className="client-add-form-control">
                                                        <InputLabel>
                                                            Adresa de email*
                                                        </InputLabel>
                                                        <Input
                                                            value={values.email}
                                                            className="client-input"
                                                            onChange={handleChange('email')}
                                                        />
                                                    </FormControl>
                                                    <FormControlLabel
                                                        label="Abonează această persoană la noutăți Monitor Dosare" 
                                                        className='client-checkbox-form-control'
                                                        control={
                                                            <Checkbox 
                                                                checked={values.receiveMdNotifications}
                                                                className="client-checkbox"
                                                                onChange={handleChange('receiveMdNotifications')} 
                                                                color="primary"
                                                            />
                                                        } 
                                                    />
                                                    <Typography className="disclaimer">
                                                        * - câmpuri obligatorii
                                                    </Typography>
                                                    <div className='buttons-div'>
                                                        <div className='skip-button-div'>
                                                            <Button
                                                                onClick={() => {
                                                                    window.scrollTo(
                                                                        0,
                                                                        this.addPartySubscriptionDiv.current.offsetTop
                                                                    )
                                                                }}
                                                                size="large"
                                                                variant="contained"
                                                                fullWidth
                                                                className='skip-button'
                                                            >
                                                                <Typography className="button-title">
                                                                    Sari peste
                                                                </Typography>
                                                            </Button>
                                                        </div>
                                                        <div className='submit-button-div'>
                                                            <Button
                                                                onClick={() => {
                                                                    handleSubmit()
                                                                    window.scrollTo(
                                                                        0,
                                                                        this.addPartySubscriptionDiv.current.offsetTop
                                                                    )
                                                                }}
                                                                size="large"
                                                                variant="contained"
                                                                fullWidth
                                                                className='submit-button'
                                                            >
                                                                <Typography className="button-title">
                                                                    Mai departe
                                                                </Typography>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Formik>
                                    ) : isLoadingClients ? (
                                        <div>
                                            <CircularProgress thickness={5} color="primary" />
                                        </div>
                                    ) :
                                        <div className='no-client-div'>
                                            <Typography className='no-client-text'>Creează un client</Typography>
                                            <ButtonBase
                                                onClick={() => {
                                                    window.scrollTo(
                                                        0, this.addClientDiv.current.offsetTop
                                                    )
                                                }}
                                                className="no-client-arrow-button"
                                            >
                                                <ArrowUp className="no-client-arrow-button-icon"/>
                                            </ButtonBase>
                                        </div>
                                    } 
                                </div>
                            </div>
                        </VisibilitySensor>
                        <VisibilitySensor onChange={() => this.setState({activeStep: 3})}>
                            <div ref={this.addPartySubscriptionDiv} className="client-add-step-div">
                                <div className="add-client-left-div">
                                    <div className="top">
                                        <div className="step-number-div">
                                            <Typography className="step-number">3</Typography>
                                        </div>
                                        <Typography className="step-title">
                                            Adaugă
                                            <br /> monitorizare juridică
                                        </Typography>
                                    </div>
                                    <div className="bottom">
                                        <Typography className="step-description">
                                            Acest tip de notificări urmărește zilnic dacă găsim dosare noi
                                            pentru un anumit set de date. Spre exemplu, poți monitoriza toate
                                            dosarele apărute pentru un anumit cuvânt de căutare într-o anumită
                                            instanță de judecată. Cuvântul de căutare va fi numele părții 
                                            pentru care dorești urmărirea.
                                        </Typography>
                                    </div>
                                </div>
                                <div className="add-client-right-div">
                                    {!isEmpty(client) && !isLoadingClients ? (
                                        <Formik
                                            initialValues={{
                                                source: head(sourcesArray).value,
                                                clientID: client.id,
                                                institution: head(institutionsArray),
                                                partyName: client.name
                                            }}
                                            onSubmit={(values) => {
                                                this.props.dispatch(
                                                    RESOURCES.partySubscriptions.create({
                                                        client_id: values.clientID,
                                                        institution: values.institution.value,
                                                        party_name: values.partyName,
                                                        source: values.source
                                                    })
                                                )
                                            }}>
                                            {({handleChange, handleSubmit, values, errors, setFieldValue}) => (
                                                <div className="client-search-data-source-div">
                                                    {!isEmpty(partySubscriptionErrors) 
                                                        && isEmpty(planError)
                                                        && isEmpty(partySubscriptionErrors.detail) 
                                                        && _values(partySubscriptionErrors).map((errorCategories) =>
                                                            errorCategories.map((error, index) => (
                                                                <div key={index} className='error-div'>
                                                                    <Typography className='error-text'>
                                                                        {getErrorFromCode(error, 'partySubscription')}
                                                                    </Typography>
                                                                </div>
                                                            ))
                                                        )}
                                                    <FormControl style={{width: '100%'}}>
                                                        <InputLabel id="source">Sursa</InputLabel>
                                                        <Select
                                                            labelId="source"
                                                            value={values.source}
                                                            IconComponent={() => 
                                                                <ArrowDropDown className='dropdown-icon' />
                                                            }
                                                            onChange={(event) => {
                                                                setFieldValue('source', event.target.value)
                                                            }}
                                                            className="client-select">
                                                            {sourcesArray.map((source) => (
                                                                <MenuItem key={source.value} value={source.value}>
                                                                    {source.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    <FormControl className="client-add-form-control">
                                                        <InstitutionSelect
                                                            label='Instituția'
                                                            value={values.institution}
                                                            institutionsArray={institutionsArray}
                                                            handleChange={(value) => 
                                                                setFieldValue('institution', value)
                                                            }
                                                        />
                                                    </FormControl>
                                                    <FormControl className="client-add-form-control">
                                                        <InputLabel>Nume</InputLabel>
                                                        <Input
                                                            value={values.partyName}
                                                            className="client-input"
                                                            onChange={handleChange('partyName')}
                                                        />
                                                    </FormControl>
                                                    <div className='buttons-div'>
                                                        <div className='skip-button-div'>
                                                            <Link 
                                                                style={{textDecoration: 'none'}} 
                                                                to={()=>{
                                                                    if (!isEmpty(client.projects_ids)) {
                                                                        return `/projects/${client.projects_ids[0]}`
                                                                        
                                                                    } else {
                                                                        return `/clients/${client.id}`
                                                                    }
                                                                }}
                                                            >
                                                                <Button
                                                                    size="large"
                                                                    variant="contained"
                                                                    fullWidth
                                                                    className='skip-button'
                                                                >
                                                                    <Typography className="button-title">
                                                                        Sari peste
                                                                    </Typography>
                                                                </Button>
                                                            </Link>
                                                        </div>
                                                        <div className='submit-button-div'>
                                                            <Button
                                                                onClick={handleSubmit}
                                                                size="large"
                                                                variant="contained"
                                                                fullWidth
                                                                className='submit-button'
                                                            >
                                                                <Typography className="button-title">
                                                                    Mai departe
                                                                </Typography>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                  
                                                </div>
                                            )}
                                        </Formik>
                                    ) : isLoadingClients ? (
                                        <div>
                                            <CircularProgress thickness={5} color="primary" />
                                        </div>
                                    ) :
                                        <div className='no-client-div'>
                                            <Typography className='no-client-text'>Creează un client</Typography>
                                            <ButtonBase
                                                onClick={() => {
                                                    window.scrollTo(
                                                        0, this.addClientDiv.current.offsetTop
                                                    )
                                                }}
                                                className="no-client-arrow-button"
                                            >
                                                <ArrowUp className="no-client-arrow-button-icon"/>
                                            </ButtonBase>
                                        </div>
                                    }
                                </div>
                            </div>
                        </VisibilitySensor>
                    
                    </>
                </div>
            </>
        )
    }

    scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)
}

const mapStateToProps = function(state) {
    return {
        selectedEntityID: state.localConfigs.selectedEntityID,
        client: state.clients.currentClient,
        isLoadingClients: state.clients.isLoading,
        clientErrors: state.clients.errors,
        contactPersonsErrors: state.contactPersons.errors,
        partySubscriptionErrors: state.partySubscriptions.errors,
        planError: state.partySubscriptions.plan_error
    }
}
export default connect(mapStateToProps)(ClientAdd)
