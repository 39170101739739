import {takeEvery, put} from 'redux-saga/effects'
import {push} from 'connected-react-router'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

const {entityPlans: resource} = RESOURCES

function* handleCreateEntityPlanSuccess() {
    yield put(push('/subscription-change-success'))
}

export function* saga() {
    yield takeEvery(resource.actions.create.success, handleCreateEntityPlanSuccess)
}

