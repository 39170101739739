import React, {Component} from 'react'
import {CircularProgress, Typography} from '@material-ui/core'
import {ChevronLeft, ChevronRight} from 'react-feather'
import {Link} from 'react-router-dom'

import {isEmpty, filter, values, indexOf} from 'lodash'

import {connect} from 'react-redux'
import {CRUDTypes} from 'avoapp-react-common/dist/constants'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import UsersIcon from '../assets/images/icon-users.svg'
import UserIcon from '../assets/images/icon-user.svg'

import '../assets/scss/InsufficientPermissions.scss'

export class InsufficientPermissions extends Component {
    componentDidMount() {
        this.props.dispatch(RESOURCES.groups.list())
        this.props.dispatch(RESOURCES.entityProfiles.list())
    };

    render() {
        const {entityProfiles, isLoadingEntityProfiles, isLoadingGroups} = this.props

        return (
            <div id='insufficient-permissions-container'>
                {!isEmpty( entityProfiles) && !isLoadingEntityProfiles && !isLoadingGroups ? (
                    <>
                        <div className="top-container">
                            <img src={UsersIcon} className='users-icon' alt="" />
                            <Typography className="primary-text">
                                Din păcate, <span>nu ai suficiente drepturi</span><br />
                                pentru această acțiune.
                            </Typography>
                            <Typography className="secondary-text">
                                Te rugăm să iei legătura cu unul din administratorii<br />
                                companiei tale.
                            </Typography>
                            <div className={`profiles-container ${entityProfiles.length === 1 && 'single-profile'}`}>
                                {entityProfiles.length >=4 && <ChevronLeft className='arrow left'/>}
                                <div className="scrollable-profiles">
                                    {entityProfiles.map((profile) => 
                                        <div className={'profile-info-container'}>
                                            <ProfileInfo profile={profile}/>
                                        </div>
                                    )}
                                </div>
                                {entityProfiles.length >=4 && <ChevronRight className='arrow right'/>}
                            </div>
                        </div>
                        <div className="bottom-container">
                            <Link to='/subscriptions' className='back-button'>
                                <Typography>Mergi înapoi la abonări</Typography>
                            </Link>
                        </div>
                    </>
                ) : isLoadingEntityProfiles || isLoadingGroups ? (
                    <div className="center-loader">
                        <CircularProgress className="circular-progress" thickness={5} color="primary" />
                    </div>
                ) : null}
            </div>
        )
    }
}

class ProfileInfo extends Component {
    render() {
        const {first_name, last_name, email} = this.props.profile

        return (
            <div className='profile-info'> 
                <div className="user-icon-container">
                    <img src={UserIcon} alt="" />
                </div>
                <Typography className='user-name'>{`${first_name} ${last_name}`}</Typography>
                <Typography className='user-email'>{email}</Typography>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const groups = filter(values(state.groups.data), (group) =>
        values(group.permissions_entity).includes(CRUDTypes.update.number) ||
        values(group.permissions_projects).includes(CRUDTypes.update.number) ||
        values(group.permissions_projects).includes(CRUDTypes.destroy.number)
    )

    const groupsIDs = groups.map((group) => group.id)

    const entityProfiles = values(state.entityProfiles.data).filter((profile) =>
        indexOf(groupsIDs, profile.group_id) !== -1
    )

    return {
        entityProfiles: entityProfiles,
        isLoadingEntityProfiles: state.entityProfiles.isLoading,
        isLoadingGroups: state.groups.isLoading
    }
}

export default connect(mapStateToProps)(InsufficientPermissions)
