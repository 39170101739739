import {toast} from 'react-toastify'
import {takeEvery} from 'redux-saga/effects'

import {ACTIONS} from 'avoapp-react-common/dist/redux/litigations'

function handleSyncLitigationSuccess() {
    const message = 'Datele au fost actualizate cu succes'

    toast.success(message)
}

export function* saga() {
    yield takeEvery(ACTIONS.SYNC.success, handleSyncLitigationSuccess)
}
