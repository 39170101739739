import React, {Component} from 'react'

import {Typography, Button, Select, MenuItem, CircularProgress, FormControl, InputLabel} from '@material-ui/core'
import {values} from 'lodash'

import '../assets/scss/SyncGoogleCalendar.scss'

import {connect} from 'react-redux'

import {Link} from 'react-router-dom'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

class SyncGoogleExistingCalendar extends Component {

    constructor() {
        super()

        this.state = {
            idCalendarToSync: ''
        }
    }

    componentDidMount() {
        this.props.dispatch(RESOURCES.googleCalendars.list())
    }
    
    render() {
        const {googleCalendars, isLoadingGoogleProfile} = this.props
        const {idCalendarToSync} = this.state
 
        return (
            <div>
                <div className="navbar" id="navbar-sync-google-calendar">
                    <Link to="/" className="logo-div">
                        <img
                            src={require('../assets/images/monitor_dosare_logo_dark.svg').default}
                            alt="monitor dosare"
                        />
                    </Link>
                </div>
                <div id='sync-google-calendar-main-div'>
                    <div className="sync-text-div">
                        <div className="title-div">
                            <Typography className='title'>
                                Sincronizare 
                                <span className='google-logo-span'>
                                    <img src={require('../assets/images/google_g_logo.svg').default} alt='' />
                                </span>
                                oogle Calendar
                            </Typography>
                        </div>
                    </div>
                    <div className="sync-card">
                        <div className='card-header'>
                            <div className="img-div">
                                <img src={require('../assets/images/existing_calendar.svg').default} alt=""/>
                            </div>
                            <div className="right">
                                <Typography className='text'>Sincronizează un calendar Google</Typography> 
                                <Typography className='text'>deja existent</Typography> 
                            </div>
                        </div>
                        <div className="existing-calendar-div">
                            <FormControl className='select-form-control'>
                                <InputLabel id="calendar-select-label">Alege calendarul Google dorit</InputLabel>
                                <Select 
                                    value={idCalendarToSync}
                                    className='google-calendars-select'
                                    labelId="calendar-select-label"
                                    onChange={(event)=>{
                                        this.setState({
                                            idCalendarToSync: event.target.value
                                        })
                                    }}
                                >
                                    {googleCalendars.map((calendar) => (
                                        <MenuItem key={calendar.id} value={calendar.id}>
                                            {calendar.summary}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {!isLoadingGoogleProfile ? (
                                <Button
                                    onClick={() => {
                                        this.props.dispatch(  
                                            RESOURCES.googleProfile.update({
                                                id: 'me',
                                                calendar_id: idCalendarToSync
                                            })
                                        )
                                    }}
                                    disabled={idCalendarToSync === ''}
                                    type="submit"
                                    size="large"
                                    variant="contained"
                                    fullWidth
                                    className='button'
                                >
                                    <Typography>Sincronizează calendarul Google ales</Typography>
                                </Button>
                            ) : (
                                <CircularProgress className="circular-progress" thickness={5} color="primary" />
                            )}
                            <Link
                                to='/sync-google-calendar/new'
                                className="link"
                            >
                                <Typography className="button-text">
                                    M-am răzgândit. Vreau să creez un calendar special.
                                </Typography>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps= function (state) {
    return {
        googleCalendars: values(state.googleCalendars.data),
        isLoading: state.googleCalendars.isLoading,
        isLoadingGoogleProfile: state.googleProfile.isLoading
    }

}

export default connect(mapStateToProps)(SyncGoogleExistingCalendar)