import React, {Component} from 'react'
import {Typography} from '@material-ui/core'

import {isEmpty} from 'lodash'

import {connect} from 'react-redux'
import {changeDateFormat, changeTimeFormat} from 'avoapp-react-common/dist/utils'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import ResourceAddSuccess from '../components/ResourceAddSuccess'
import ActivityIndicatorComponent from '../components/ActivityIndicatorComponent'

class ManualHearingAddSuccess extends Component {
    componentDidMount() {
        const {hearingID} = this.props.match.params

        this.props.dispatch(RESOURCES.hearings.retrieve(hearingID))
    }

    render() {
        const {phase, hearing, isLoading} = this.props

        return (
            <>
                {!isEmpty(hearing) && !isLoading ? (
                    <ResourceAddSuccess 
                        resourceText ="Termenul a fost adăugat"
                        resourceDetails={<HearingDetails hearing={hearing}/>}
                        nextText ='Adaugă căi de atac'
                        nextUrl = {`/phases/${phase.id}/add-manual-appeal`}
                        addMoreText ='Mai adaugă un termen'
                        addMoreUrl = {`/phases/${phase.id}/add-manual-hearing`}
                    />
                ) : isLoading ? (
                    <ActivityIndicatorComponent />
                ) : null }
            </>
        )
    }
}

function HearingDetails(props) {
    const {hearing} = props

    return (
        <div className='details-div'>
            <div className='details-list'>
                <Typography className='detail-text'>Data: <span>{changeDateFormat(hearing.date)}</span></Typography>
                <Typography className='detail-text'>Ora: <span>{changeTimeFormat(hearing.time)}</span></Typography>
                {hearing.court && 
                    <Typography className='detail-text'>
                        Instanță: <span>{hearing.court}</span>
                    </Typography>
                }
                {hearing.full_court && 
                    <Typography className='detail-text'>
                        Complet: <span>{hearing.full_court}</span>
                    </Typography>
                }
                {hearing.document_date && 
                    <Typography className='detail-text'>
                        Data document: <span>{changeDateFormat(hearing.document_date)}</span>
                    </Typography>
                }
                {hearing.pronouncement_date && 
                    <Typography className='detail-text'>
                        Data ședință: <span>{changeDateFormat(hearing.pronouncement_date)}</span>
                    </Typography>
                }
                {hearing.hearing_document && 
                    <Typography className='detail-text'>
                        Document ședință: <span>{hearing.hearing_document}</span>
                    </Typography>
                }
                {hearing.solution && 
                    <Typography className='detail-text'>
                        Soluție: <span>{hearing.solution}</span>
                    </Typography>
                }
                {hearing.solution_summary && 
                    <Typography className='detail-text'>
                        Soluție sumar: <span>{hearing.solution_summary}</span>
                    </Typography>
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    phase: state.phases.currentPhase,
    hearing: state.hearings.currentHearing,
    isLoading: state.hearings.isLoading
})

export default connect(mapStateToProps)(ManualHearingAddSuccess)
