import React, {Component} from 'react'
import {CircularProgress, MenuItem, Select, Typography} from '@material-ui/core'

import {isEmpty, values, head, find} from 'lodash'

import {connect} from 'react-redux'
import {getBelongingEntities} from 'avoapp-react-common/dist/redux/entityProfiles'
import {retrieveAndSetCurrent} from 'avoapp-react-common/dist/redux/entities'
import {selectEntityID} from 'avoapp-react-common/dist/redux/selectedEntityID'
import {entityProfilesStatus} from 'avoapp-react-common/dist/constants'
import {getEntityName} from 'avoapp-react-common/dist/utils'

import '../assets/scss/EntitySwitch.scss'

export class EntitySwitch extends Component {
    componentDidMount() {
        const {user} = this.props
        
        this.props.dispatch(getBelongingEntities(user.id))
    }

    render() {
        const {entities, isLoading, selectedEntityID} = this.props

        return (
            <div className='entity-switch'>
                {!isLoading && !isEmpty(entities) ? 
                    entities.length > 1 ? (
                        <Select
                            className='entity-select'
                            value={selectedEntityID}
                            onChange={(event) => {
                                const currentProfile = find(entities, ['id', event.target.value])
                            
                                this.props.dispatch(retrieveAndSetCurrent(currentProfile.id))
                                this.props.dispatch(selectEntityID(currentProfile.id))
                            }}
                        >
                            {entities.map((entity) => (
                                <MenuItem 
                                    key={entity.id} 
                                    value={entity.id} 
                                    disabled={entity.profileStatus !== entityProfilesStatus.ACTIVE.value}
                                >
                                    {getEntityName(entity)}
                                    {entity.profileStatus === entityProfilesStatus.PENDING.value ? (
                                        <Typography className='pending-action-text'>
                                            {` - ${entityProfilesStatus.PENDING.action}`}
                                        </Typography>
                                    ) : null}
                                </MenuItem>
                            ))}
                        </Select>
                    ) : (
                        <Typography className='entity-name'>{getEntityName(head(entities))}</Typography>
                    ) : (
                        <CircularProgress size={15} thickness={3} color="primary" />
                    )}
            </div>
        )
    }
}

const mapStateToProps = function(state) {
    return {
        user: state.users.user,
        isLoading:  state.entityProfiles.isLoading,
        entities: values(state.entityProfiles.belongingEntities),
        selectedEntityID: state.localConfigs.selectedEntityID
    }
}

export default connect(mapStateToProps)(EntitySwitch)
