import {takeEvery, put, select, take} from 'redux-saga/effects'
import {push} from 'connected-react-router'
import {toast} from 'react-toastify'

import {head} from 'lodash'

import {spec} from 'avoapp-react-common'
import {Action} from 'avoapp-react-common/dist/redux'
import {manualLitigationSource} from 'avoapp-react-common/dist/constants'
import {ACTIONS as SUBSCRIPTIONS_ACTIONS} from 'avoapp-react-common/dist/redux/subscriptions'

const {RESOURCES} = spec

const {subscriptions: resource} = RESOURCES

export const ACTIONS = {
    CREATE_FROM_LOCAL: new Action('CREATE_FROM_LOCAL'),
    DELETE_SUBSCRIPTION: new Action('DELETE_SUBSCRIPTION')
}

function* handleCreateSubscriptionSuccess(response) {
    let newSub = head(response.payload)

    if (newSub.source === manualLitigationSource) {
        yield put(push(`/subscriptions/${newSub.id}/add-manual-phase`))
    } else {
        const message = [
            'Monitorizarea a început pentru',
            Object.keys(response.payload).length > 1 ? 'dosarele selectate' : 'dosarul selectat'
        ].join(' ')

        toast.success(message)
    }
}

function* handleCreateSubscriptionFail(response) {
    if (response.payload.code === 'plan_error') {
        yield put(push('/limit-reached'))
    }
}

function* handleCreateFromLocal(data) {
    const {project_id} = data.payload
    const localSubscriptions = yield select((state) => state.subscriptions.localSubscriptions)

    var localSubscriptionWithProjectID = localSubscriptions.map(subscription => ({
        ...subscription,
        project_id: project_id
    }))

    yield put(RESOURCES.subscriptions.create(localSubscriptionWithProjectID))
    yield take(RESOURCES.subscriptions.actions.create.success)

    const currentSubscription = yield select((state) => state.subscriptions.currentSubscription)

    if (currentSubscription.source !== manualLitigationSource) {
        yield put(push(`/subscriptions/${currentSubscription.id}`))
    }
}

function* handleDestroySubscriptionSuccess(response) {
    let selectedEntityID = yield select((state) => state.localConfigs.selectedEntityID)
    
    yield put(RESOURCES.entityReports.retrieve(selectedEntityID))
    yield put(RESOURCES.parties.list({project_id: response.meta.object.project_id}))
    yield put(RESOURCES.appeals.list({project_id: response.meta.object.project_id}))
    yield put(RESOURCES.hearings.list({project_id: response.meta.object.project_id}))

    toast.success('Abonarea la date a fost ștearsă')
}

function* handleCreateSubscriptionInCurrentProject(data) {
    const {subscriptionsArray} = data.payload

    yield put(RESOURCES.subscriptions.create(subscriptionsArray))
    yield take(resource.actions.create.success)

    const currentSubscription = yield select((state) => state.subscriptions.currentSubscription)

    if (currentSubscription.source !== manualLitigationSource) {
        yield put(push(`/projects/${currentSubscription.project_id}`))
    }
}

export function* saga() {
    yield takeEvery(resource.actions.create.success, handleCreateSubscriptionSuccess)
    yield takeEvery(resource.actions.create.fail, handleCreateSubscriptionFail)
    yield takeEvery(resource.actions.destroy.success, handleDestroySubscriptionSuccess)
    yield takeEvery(ACTIONS.CREATE_FROM_LOCAL.main, handleCreateFromLocal)
    yield takeEvery(
        SUBSCRIPTIONS_ACTIONS.CREATE_SUBSCRIPTION_CURRENT_PROJECT.main,
        handleCreateSubscriptionInCurrentProject
    )
}

export const createFromLocalSubscriptions = (data) => {
    return {
        type: ACTIONS.CREATE_FROM_LOCAL.main,
        payload: {...data}
    }
}