import {put, select, take, takeEvery} from 'redux-saga/effects'
import {push} from 'connected-react-router'

import {values, find} from 'lodash'

import {getPermissions} from 'avoapp-react-common/dist/redux/permissions'
import {ACTIONS as LOCAL_CONFIGS_ACTIONS, selectEntityProfileID} from 'avoapp-react-common/dist/redux/localConfigs'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

function* redirectToEntitySelect() {
    yield put(push('/select-entity'))
}

function* handleSelectEntityID(response) {
    const {entityID} = response.payload
    
    yield put(RESOURCES.entityProfiles.list({entity_id: entityID}))
    yield take(RESOURCES.entityProfiles.actions.list.success)
    
    const user = yield select((state) => state.users.user)

    const profileForUser = yield select((state) => find(values(state.entityProfiles.data), ['user_id', user.id]))
    const myEntityProfileID = profileForUser.id

    yield put(selectEntityProfileID(myEntityProfileID))
    yield put(RESOURCES.entityReports.retrieve(entityID))
    yield put(getPermissions())
}

export function* saga() {
    yield takeEvery(LOCAL_CONFIGS_ACTIONS.NO_SELECTED_ENTITY.main, redirectToEntitySelect)
    yield takeEvery(LOCAL_CONFIGS_ACTIONS.SELECT_ENTITY_ID.main, handleSelectEntityID)
}
