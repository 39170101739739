import {takeEvery, put, take, select} from 'redux-saga/effects'
import {push} from 'connected-react-router'

import {find, values} from 'lodash'

import {SELECT_INTERVAL, SELECT_PLAN} from 'avoapp-react-common/dist/redux/billing'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

function* handleSelectPlan() {
    let currentPlan
    let canChangePlan

    const selectedEntityID = yield select((state) => state.localConfigs.selectedEntityID)
    
    yield put(RESOURCES.entities.retrieve(selectedEntityID))
    yield take(RESOURCES.entities.actions.retrieve.success)

    const currentEntity = yield select((state) => find(values(state.entities.data), ['id', selectedEntityID]))

    currentPlan = currentEntity.plans.current
    canChangePlan = currentEntity.flags.can_change_plan

    if (currentPlan.active && currentPlan.plan.type === 'paid' && !canChangePlan) {
        yield put(push('/upgrade-subscription'))
    } else {
        yield put(push('/change-subscription'))
    }
}

function* handleSelectInterval() {
    yield put(push('/billing'))

}

export function* saga() {
    yield takeEvery(SELECT_PLAN.main, handleSelectPlan)
    yield takeEvery(SELECT_INTERVAL.main, handleSelectInterval)
}

