import React, {Component} from 'react'
import {Typography} from '@material-ui/core'
import {ArrowBack} from '@material-ui/icons'
import {Link} from 'react-router-dom'

import '../assets/scss/NotFound404.scss'

export class NotFound404 extends Component {
    render() {
        return (
            <div className="not-found-main-div">
                <div className="left">
                    <div className="logo-div">
                        <img
                            src={require('../assets/images/monitor_dosare_logo_light.svg').default}
                            alt="monitordosare-logo"
                        />
                    </div>
                    <div className="message-div">
                        <Typography className="message">
                            Pagina accesată
                            <br /> <span>nu există!</span>
                        </Typography>
                        <Link className="back-button" to="/">
                            <div className="icon-div">
                                <ArrowBack />
                            </div>
                            <Typography className="back-button-text">Mergi la pagina principală</Typography>
                        </Link>
                    </div>
                </div>
                <div className="right" />
            </div>
        )
    }
}

export default NotFound404
