import React, {Component} from 'react'
import {Typography, Divider} from '@material-ui/core'

import '../assets/scss/PromoComponent.scss'
import {Link} from 'react-router-dom'

export default class PromoComponent extends Component {
    render() {
        return (
            <div className="promo-main-div">
                <div className="div-with-background-image" />
                <div className="promo-bottom-div">
                    <div className="motto-div">
                        <Typography className="motto">
                            <span>Monitorizare</span> dosare instanță
                        </Typography>
                        <Divider className="divider" />
                        <div className="promo-dowload-app-div">
                            <div className='no-account-text-div'>
                                <Typography className="no-account-text">Nu ai cont încă?</Typography>
                                <Link to='/register' className='create-account-text'>Creează unul acum!</Link>
                            </div>
                            <div className='download-links-div'>
                                <a 
                                    href="https://apps.apple.com/us/app/monitor-dosare/id1498634602?ls=1" 
                                    target="_blank" 
                                    rel='noopener noreferrer'
                                    className="image-link" 
                                >
                                    <img
                                        style={{width: '100%', height: 'auto'}}
                                        src={require('../assets/images/app_store_dowload.svg').default}
                                        alt="monitor dosare app store"
                                    />
                                </a>
                                <a 
                                    href="https://play.google.com/store/apps/details?id=ro.monitordosare" 
                                    target="_blank" 
                                    rel='noopener noreferrer'
                                    className="image-link" 
                                >
                                    <img
                                        style={{width: '100%', height: 'auto'}}
                                        src={require('../assets/images/google_play_download.svg').default}
                                        alt="monitor dosare google play"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
