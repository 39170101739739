import React, {Component} from 'react'
import {Modal, Typography, Select, FormControl, InputLabel, MenuItem, Button} from '@material-ui/core'
import {ArrowDropDown} from '@material-ui/icons'

import {values, head, isEmpty} from 'lodash'

import '../assets/scss/ResetComponent.scss'

import {connect} from 'react-redux'

class RefreshComponent extends Component {
    constructor() {
        super()
        
        this.state = {
            showRefreshOverlay: false,
            subscriptionToSync: ''
        }
    }

    componentDidUpdate(prevProps) {
        const {subscriptionsAsOptions} = this.props

        if (!isEmpty(subscriptionsAsOptions) && prevProps.subscriptionsAsOptions !== subscriptionsAsOptions) {
            this.setState({subscriptionToSync: head(subscriptionsAsOptions).value})
        }
    }

    render() {
        const {
            showRefreshComponent,
            handleCloseRefreshComponent,
            handleSyncLitigation,
            subscriptionsAsOptions
        } = this.props

        const {subscriptionToSync} = this.state
        
        return (
            <Modal
                open={showRefreshComponent}
                onClose={handleCloseRefreshComponent}
            >
                <div className="refresh-component">
                    <div className="main-div">
                        <div className="header"></div>
                        <div className="content">
                            <img className='refresh-image'
                                src={require('../assets/images/refresh_image.svg').default} alt=""
                            />
                            <div className="content-text-div">
                                <Typography className="modal-title">Refresh dosar</Typography>
                                <Typography className="modal-text">
                                    Foloseste aceasta optiune de refresh pentru a ne semnala ca dosarul a 
                                    avut modificari pe care aplicatia nu le-a sesizat. Fiecare cerere va
                                    fi analizata in vederea imbunatatirii algoritmului nostru.
                                </Typography>
                            </div>
                            <FormControl className='styled-form-control'>
                                <InputLabel id="subscription-select">Număr dosar</InputLabel>
                                <Select
                                    labelId="subscription-select"
                                    value={subscriptionToSync}
                                    IconComponent={() => 
                                        <ArrowDropDown className='dropdown-icon' />
                                    }
                                    onChange={(event) => {
                                        this.setState({subscriptionToSync: event.target.value})
 
                                    }}
                                    className="project-select">
                                    {subscriptionsAsOptions.map((subscription) => (
                                        <MenuItem key={subscription.value} value={subscription.value}>
                                            {subscription.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <div className='buttons-div'>
                                <Button 
                                    onClick={handleCloseRefreshComponent}
                                    className='button outlined' 
                                    size="large"
                                    variant="outlined"
                                    fullWidth>
                                    <Typography className='button-text'>
                                        NU exista modificari neinterceptate
                                    </Typography>
                                </Button>
                                <Button 
                                    onClick={() => handleSyncLitigation(subscriptionToSync)}
                                    className='button' 
                                    size="large"
                                    variant="contained"
                                    fullWidth>
                                    <Typography className='button-text'>
                                        DA, exista modificari pe care nu le-ati interceptat
                                    </Typography>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = function (state) {
    const subscriptions = values(state.subscriptions.data)
    let subscriptionsAsOptions = []

    if(!isEmpty(subscriptions)) {
        subscriptions.map((subscription) => (
            subscriptionsAsOptions.push({
                label: subscription.litigation_number,
                value: subscription.litigation.id
            })
        ))
    }

    return {
        subscriptionsAsOptions: subscriptionsAsOptions
    }
}

export default connect(mapStateToProps)(RefreshComponent)