import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {Formik} from 'formik'
import {
    FormControl, 
    InputLabel,
    Input,
    Button,
    Typography,
    CircularProgress,
    FormControlLabel,
    Checkbox
} from '@material-ui/core'

import {isEmpty, values as _values} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {getErrorFromCode} from 'avoapp-react-common/dist/utils'

class ClientAddContactPerson extends Component {
    componentDidMount() {
        const currentClientId = this.props.match.params.clientID
        this.props.dispatch(RESOURCES.clients.retrieve(currentClientId))
    }

    render() {
        const {client, isLoadingClients, contactPersonsErrors} = this.props

        return (
            <>
                <div className="navbar" id="navbar-clients-add">
                    <div className="navbar-left" />
                    <Link style={{textDecoration: 'none'}} to={`/clients/${client.id}`}>
                        <Button className="cancel-button">
                            <Typography className="cancel-button-title">Renunță</Typography>
                        </Button>
                    </Link>
                </div>
                <div id="client-add-main-div" style={{flexDirection: 'column'}}>
                    <div className="client-add-step-div">
                        <div className="add-client-left-div">
                            <div className="top">
                                <Typography className="step-title">
                                    Adaugă
                                    <br /> persoană de notificat
                                </Typography>
                            </div>
                            <div className="bottom">
                                <Typography className="step-description">
                                    Atunci când adaugi într-un client o persoană de notificat,
                                    respectiva persoană primește automat emailuri cu privire la
                                    noutăți în proiectele clientului. De fiecare dată când te 
                                    notificăm pe tine cu privire la proiect, această persoană va 
                                    primi câte un email cu modificarea specifică din cadrul proiectului.
                                </Typography>
                            </div>
                        </div>
                        <div className="add-client-right-div">
                            {!isLoadingClients ? (
                                <Formik
                                    initialValues={{
                                        lastName: '',
                                        firstName: '',
                                        email: '',
                                        receiveMdNotifications: true
                                    }}
                                    onSubmit={(values) => {
                                        this.props.dispatch(
                                            RESOURCES.contactPersons.create({
                                                client_id: client.id,
                                                first_name: values.firstName,
                                                last_name: values.lastName,
                                                email: values.email,
                                                receive_md_notifications: values.receiveMdNotifications
                                            })
                                        )
                                    }}>
                                    {({handleChange, handleSubmit, values}) => (
                                        <div className="client-search-data-source-div">
                                            {!isEmpty(contactPersonsErrors) 
                                                && isEmpty(contactPersonsErrors.detail) 
                                                && _values(contactPersonsErrors).map((errorCategories) =>
                                                    errorCategories.map((error, index) => (
                                                        <div key={index} className='error-div'>
                                                            <Typography className='error-text'>
                                                                {getErrorFromCode(error, 'contactPerson')}
                                                            </Typography>
                                                        </div>
                                                    ))
                                                )}
                                            <FormControl className="client-add-form-control">
                                                <InputLabel>Nume*</InputLabel>
                                                <Input
                                                    value={values.lastName}
                                                    className="client-input"
                                                    onChange={handleChange('lastName')}
                                                />
                                            </FormControl>
                                            <FormControl className="client-add-form-control">
                                                <InputLabel>Prenume*</InputLabel>
                                                <Input
                                                    value={values.firstName}
                                                    className="client-input"
                                                    onChange={handleChange('firstName')}
                                                />
                                            </FormControl>
                                            <FormControl className="client-add-form-control">
                                                <InputLabel>Adresa de email*</InputLabel>
                                                <Input
                                                    value={values.email}
                                                    className="client-input"
                                                    onChange={handleChange('email')}
                                                />
                                            </FormControl>
                                            <FormControlLabel
                                                label="Abonează această persoană la noutăți Monitor Dosare" 
                                                className='client-checkbox-form-control'
                                                control={
                                                    <Checkbox 
                                                        checked={values.receiveMdNotifications}
                                                        className="client-checkbox"
                                                        onChange={handleChange('receiveMdNotifications')} 
                                                        color="primary"
                                                    />
                                                } 
                                            />
                                            <Typography className="disclaimer">
                                                * - câmpuri obligatorii
                                            </Typography>
                                            <div className='buttons-div'>
                                                <Button
                                                    onClick={handleSubmit}
                                                    disabled={
                                                        isEmpty(values.firstName) ||
                                                        isEmpty(values.lastName) ||
                                                        (values.receiveMdNotifications && isEmpty(values.email))
                                                    }
                                                    size="large"
                                                    variant="contained"
                                                    className="submit-button"
                                                    fullWidth
                                                >
                                                    <Typography className="button-title">
                                                        Adaugă
                                                    </Typography>
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                </Formik>
                            ) : (
                                <div>
                                    <CircularProgress thickness={5} color="primary" />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = function(state) {
    return {
        client: state.clients.currentClient,
        isLoadingClients: state.clients.isLoading,
        contactPersonsErrors: state.contactPersons.errors
    }
}

export default connect(mapStateToProps)(ClientAddContactPerson)