import React, {useCallback, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'

import {Button, CircularProgress, FormControl, Input, InputLabel, Typography} from '@material-ui/core'
import {ArrowDropDown} from '@material-ui/icons'
import {DatePicker, MuiPickersUtilsProvider, TimePicker} from '@material-ui/pickers'
import {Formik} from 'formik'

import DateFnsUtils from '@date-io/date-fns'
import {lightFormat} from 'date-fns'
import {isEmpty, isNull, toString, values} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {getErrorFromCode} from 'avoapp-react-common/dist/utils'
import {connect} from 'react-redux'

import {DeleteHearingDialog} from '../components/ProjectDetails/Dialogs'

import '../assets/scss/ManualAdd.scss'

export const ManualHearingEdit = ({
    hearing, 
    match: {params: {hearingID}},
    isLoading,
    hearingsErrors,
    retrieveHearing,
    updateHearing,
    deleteHearing,
    currentSubscription
}) => {
    const [showDeleteHearingDialog, setShowDeleteHearingDialog] = useState(false)

    useEffect(() => {
        if((isEmpty(hearing) || toString(hearing.id) !== toString(hearingID)) && !isLoading) {
            retrieveHearing()
        }
    }, [isLoading, hearing, hearingID, retrieveHearing])

    const toggleDeleteHearingDialog = useCallback(() => {setShowDeleteHearingDialog((state) => !state)}, [])

    const handleDeleteHearing = useCallback(() => {
        deleteHearing(hearing)
        toggleDeleteHearingDialog()
    }, [deleteHearing, hearing, toggleDeleteHearingDialog])

    return (
        <div>
            <div className="navbar" id="navbar-manual-add">
                <div className="navbar-left">
                    <Typography className="navbar-title">
                        Editeaza termen
                    </Typography>
                </div>
                <div className="navbar-right">
                    {!isEmpty(hearing) && !isLoading &&
                    <>  
                        <Button className="delete-button" onClick={toggleDeleteHearingDialog}>
                            <Typography className="button-title">Șterge termen</Typography>
                        </Button>
                        <DeleteHearingDialog
                            showDeleteHearingDialog={showDeleteHearingDialog}
                            hearingToDelete={hearing}
                            handleCloseDeleteHearingDialog={toggleDeleteHearingDialog}
                            handleDeleteHearing={handleDeleteHearing}
                        />
                    </>}
                    {currentSubscription.id && (
                        <Link style={{textDecoration: 'none'}} to={`/subscriptions/${currentSubscription.id}`}>
                            <Button className="cancel-button">
                                <Typography className="button-title">Renunță</Typography>
                            </Button>
                        </Link>
                    )}
                </div>
            </div>
            <div id="manual-add-main-div">
                {!isEmpty(hearing) && !isLoading && toString(hearing.id) === toString(hearingID) ? (
                    <Formik
                        initialValues={{
                            date: new Date(hearing.date),
                            time: new Date(hearing.moment),
                            solution: hearing.solution || '',
                            solutionSummary: hearing.solution_summary || '',
                            fullCourt: hearing.full_court || '',
                            documentDate: hearing.document_date ? 
                                lightFormat(values.documentDate, 'yyyy-MM-dd') :
                                null,
                            pronouncementDate: hearing.pronouncement_date ? 
                                lightFormat(values.pronouncementDate, 'yyyy-MM-dd') : 
                                null,
                            hearingDocument: hearing.hearing_document
                        }}
                        onSubmit={(values) => {
                            let solution = isEmpty(values.solution) ? null : values.solution
                            let solutionSummary = isEmpty(values.solution_summary) ? null : values.solution_summary

                            updateHearing({
                                id: hearingID,
                                date: lightFormat(values.date, 'yyyy-MM-dd'),
                                solution: solution,
                                solution_summary: solutionSummary,
                                time: lightFormat(values.time, 'HH:mm'),
                                full_court: values.fullCourt,
                                document_date: values.documentDate,
                                pronouncement_date: values.pronouncementDate,
                                hearing_document: values.hearingDocument
                            })
                        }}
                    >
                        {({handleChange, handleSubmit, values, setFieldValue}) => (
                            <div className='form'>  
                                {!isEmpty(hearingsErrors) && hearingsErrors.map((errorCategories) =>
                                    errorCategories.map((error, index) => (
                                        <div key={index} className='error-div'>
                                            <Typography className='error-text'>
                                                {getErrorFromCode(error, 'hearings')}
                                            </Typography>
                                        </div>
                                    ))
                                )}
                                <div className="section">
                                    <div className="section-content">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <FormControl className="project-add-form-control date-select">
                                                <DatePicker
                                                    label="Data *"
                                                    value={values.date}
                                                    onChange={(value) => {setFieldValue('date', value)}}
                                                    InputProps={{disableUnderline: true}}
                                                    format="dd/MM/yyyy"
                                                    className="date-picker"
                                                    cancelLabel="Închide"
                                                    okLabel="Ok"
                                                />
                                                <ArrowDropDown className='dropdown-icon' />
                                            </FormControl>
                                        </MuiPickersUtilsProvider>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <TimePicker
                                                label="Ora *"
                                                className='time-input'
                                                value={values.time} 
                                                format="HH:mm"
                                                onChange={(newTime)=>{
                                                    setFieldValue('time', newTime)
                                                }}
                                                cancelLabel='Renunță'
                                            />
                                        </MuiPickersUtilsProvider>
                                        <FormControl className='input-form-control'>
                                            <InputLabel shrink={!isEmpty(values.fullCourt)}>
                                                Complet
                                            </InputLabel>
                                            <Input
                                                value={values.fullCourt}
                                                onChange={handleChange('fullCourt')}
                                                className="input"
                                            />
                                        </FormControl>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <FormControl className="project-add-form-control date-select">
                                                <DatePicker
                                                    label="Data document"
                                                    value={values.documentDate}
                                                    onChange={(value) => {setFieldValue('documentDate', value)}}
                                                    InputProps={{disableUnderline: true}}
                                                    format="dd/MM/yyyy"
                                                    className="date-picker"
                                                    cancelLabel="Închide"
                                                    okLabel="Ok"
                                                />
                                                <ArrowDropDown className='dropdown-icon' />
                                            </FormControl>
                                        </MuiPickersUtilsProvider>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <FormControl className="project-add-form-control date-select">
                                                <DatePicker
                                                    label="Data ședință"
                                                    value={values.pronouncementDate}
                                                    onChange={(value) => setFieldValue('pronouncementDate', value)}
                                                    InputProps={{disableUnderline: true}}
                                                    format="dd/MM/yyyy"
                                                    className="date-picker"
                                                    cancelLabel="Închide"
                                                    okLabel="Ok"
                                                />
                                                <ArrowDropDown className='dropdown-icon' />
                                            </FormControl>
                                        </MuiPickersUtilsProvider>
                                        <FormControl className='input-form-control'>
                                            <InputLabel shrink={!isEmpty(values.hearingDocument)}>
                                                Document ședință
                                            </InputLabel>
                                            <Input
                                                value={values.hearingDocument}
                                                onChange={handleChange('hearingDocument')}
                                                className="input"
                                            />
                                        </FormControl>
                                        <FormControl className='input-form-control'>
                                            <InputLabel shrink={!isEmpty(values.solution)}>
                                                Soluție
                                            </InputLabel>
                                            <Input
                                                value={values.solution}
                                                onChange={handleChange('solution')}
                                                className="input"
                                            />
                                        </FormControl>
                                        <FormControl className='input-form-control'>
                                            <InputLabel shrink={!isEmpty(values.solutionSummary)}>
                                                Sumar soluție
                                            </InputLabel>
                                            <Input
                                                value={values.solutionSummary}
                                                onChange={handleChange('solutionSummary')}
                                                multiline
                                                rows={4}
                                                className="input"
                                            />
                                        </FormControl>
                                        <Typography className="disclaimer">
                                            * - câmpuri obligatorii
                                        </Typography>
                                    </div>
                                </div>
                                <div className="buttons-div">
                                    <Button
                                        onClick={handleSubmit}
                                        disabled={isLoading || isNull(values.date) || isNull(values.time)}
                                        className='submit-button'
                                        variant='contained'
                                        size='large'
                                        fullWidth
                                    >
                                        <Typography className="button-title">Salvează termen</Typography>
                                    </Button>
                                </div>
                            </div>
                        )}
                    </Formik>
                ) : isLoading ? (
                    <CircularProgress className="circular-progress" thickness={5} color="primary" />
                ) : null
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    hearing: state.hearings.currentHearing,
    isLoading: state.hearings.isLoading,
    hearingsErrors: values(state.hearings.errors),
    currentSubscription: state.subscriptions.currentSubscription
})

const mapDispatchToProps = (dispatch, props) => {
    const {hearingID} = props.match.params

    return {
        retrieveHearing: () => dispatch(RESOURCES.hearings.retrieve(hearingID)),
        deleteHearing: (hearing) => dispatch(RESOURCES.hearings.destroy(hearing)),
        updateHearing: (values) => dispatch(RESOURCES.hearings.update(values))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManualHearingEdit)
