import React, {Component} from 'react'
import {Typography} from '@material-ui/core'
import {Link} from 'react-router-dom'
import {ArrowRight} from 'react-feather'

export default class SyncGoogleCalendarMethod extends Component {
    render() {
        return (
            <div>
                <div className="navbar" id="navbar-sync-google-calendar">
                    <Link to="/" className="logo-div">
                        <img
                            src={require('../assets/images/monitor_dosare_logo_dark.svg').default} alt="monitor dosare"
                        />
                    </Link>
                </div>
                <div id='sync-google-calendar-main-div'>
                    <div className="sync-text-div">
                        <div className="title-div">
                            <Typography className='title'>
                                Sincronizare 
                                <span className='google-logo-span'>
                                    <img src={require('../assets/images/google_g_logo.svg').default} alt='' />
                                </span>
                                oogle Calendar
                            </Typography>
                        </div>
                    </div>
                    <div className="sync-methods-main-div">
                        <div className='cards-container'> 
                            <div className="card new-calendar">
                                <div className='card-content'>
                                    <img src={require('../assets/images/new_calendar.svg').default} alt=""/>
                                    <Typography className='title'>
                                        Vreau să creez un calendar Google 
                                    </Typography>
                                    <Typography className='title'>
                                        special pentru Monitor Dosare
                                    </Typography>
                                    <Typography className='info-text'>
                                        Sedintele vor face parte dintr-un calendar separat in contul meu Google
                                    </Typography>
                                </div>
                                <div className="card-button-div">
                                    <Link 
                                        className='link'
                                        to='/sync-google-calendar/new'
                                    >
                                        <ArrowRight className='icon' />
                                    </Link>
                                </div>
                            </div>
                            <div className="card existing-calendar">
                                <div className='card-content'>
                                    <img src={require('../assets/images/existing_calendar.svg').default} alt=""/>
                                    <Typography className='title'>
                                        Vreau să sincronizez un calendar
                                    </Typography>
                                    <Typography className='title'>
                                        Google deja existent
                                    </Typography>
                                    <Typography className='info-text'>
                                        Sedintele vor face parte dintr-un calendar deja existent in contul meu Google
                                    </Typography>
                                </div>
                                <div className="card-button-div">
                                    <Link 
                                        className='link'
                                        to='/sync-google-calendar/existing'
                                    >
                                        <ArrowRight className='icon' />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
