import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'

import {Button, FormControl, Input, InputLabel, Typography} from '@material-ui/core'
import {Formik} from 'formik'

import {isEmpty, values} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {getErrorFromCode} from 'avoapp-react-common/dist/utils'
import {connect} from 'react-redux'

import '../assets/scss/ManualAdd.scss'

const ManualPartyAdd = ({
    phase, 
    match: {params: {phaseID}},
    partiesErrors,
    retrievePhase,
    isLoading,
    createParty,
    currentSubscription
}) => {
    useEffect(() => {
        if(isEmpty(phase)) {
            retrievePhase(retrievePhase)
        }
    }, [phase, retrievePhase])

    return (
        <div>
            <div className="navbar" id="navbar-manual-add">
                <div className="navbar-left">
                    <Typography className="navbar-title">Adaugă o parte pentru dosarul "{phase?.number}"</Typography>
                </div>
                <div className="navbar-right">
                    {currentSubscription.id && (
                        <Link style={{textDecoration: 'none'}} to={`/subscriptions/${currentSubscription.id}`}>
                            <Button className="cancel-button">
                                <Typography className="button-title">Renunță</Typography>
                            </Button>
                        </Link>
                    )}
                </div>
            </div>
            <div id="manual-add-main-div">
                <Formik
                    initialValues={{name: '', role: ''}}
                    onSubmit={(values) => createParty({phase_id: phaseID, name: values.name, role: values.role})}
                >
                    {({handleChange, handleSubmit, values}) => (
                        <div className='form'>  
                            {!isEmpty(partiesErrors) && partiesErrors.map((errorCategories) =>
                                errorCategories.map((error, index) => (
                                    <div key={index} className='error-div'>
                                        <Typography className='error-text'>
                                            {getErrorFromCode(error, 'parties')}
                                        </Typography>
                                    </div>
                                ))
                            )}
                            <div className="section">
                                <div className="section-content">
                                    <FormControl className='input-form-control'>
                                        <InputLabel shrink={!isEmpty(values.name)}>
                                            Nume parte *
                                        </InputLabel>
                                        <Input
                                            value={values.name}
                                            onChange={handleChange('name')}
                                            className="input"
                                        />
                                    </FormControl>
                                    <FormControl className='input-form-control'>
                                        <InputLabel shrink={!isEmpty(values.role)}>
                                            Rol parte *
                                        </InputLabel>
                                        <Input
                                            value={values.role}
                                            onChange={handleChange('role')}
                                            className="input"
                                        />
                                    </FormControl>
                                    <Typography className="disclaimer">
                                        * - câmpuri obligatorii
                                    </Typography>
                                </div>
                            </div>
                            <div className="buttons-div">
                                <Button
                                    onClick={handleSubmit}
                                    disabled={isLoading || isEmpty(values.name) || isEmpty(values.role)}
                                    className='submit-button'
                                    variant='contained'
                                    size='large'
                                    fullWidth
                                >
                                    <Typography className="button-title">Salvează parte</Typography>
                                </Button>
                            </div>
                        </div>
                    )}
                </Formik>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    phase: state.phases.currentPhase,
    partiesErrors: values(state.parties.errors),
    isLoading: state.parties.isLoading,
    currentSubscription: state.subscriptions.currentSubscription
})

const mapDispatchToProps = (dispatch, props) => {
    const {phaseID} = props.match.params

    return {
        retrievePhase: () => dispatch(RESOURCES.phases.retrieve(phaseID)),
        createParty: (values) => dispatch(RESOURCES.parties.create(values))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManualPartyAdd)

