import React, {Component} from 'react'
import {Typography, Button, InputBase} from '@material-ui/core'
import InfiniteScroll from 'react-infinite-scroller'
import {Plus, Search} from 'react-feather'
import {Link} from 'react-router-dom'

import {values, isEmpty, isNull, debounce} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {setCurrent} from 'avoapp-react-common/dist/redux/clients'

import LoadingMoreComponent from '../components/LoadingMoreComponent'
import ActivityIndicatorComponent from '../components/ActivityIndicatorComponent'

import {colors} from '../assets/styles/colors'

import '../assets/scss/ClientsList.scss'

export class ClientsList extends Component {
    constructor() {
        super()

        this.state = {
            searchFilter: '',
            isTyping: false
        }
    }

    componentDidMount() {
        const {selectedEntityID} = this.props

        this.props.dispatch(RESOURCES.clients.list({entity_id: selectedEntityID}))
    }

    componentDidUpdate(prevProps) {
        const {selectedEntityID, isLoading} = this.props

        if(prevProps.selectedEntityID !== selectedEntityID && !isLoading) {
            this.props.dispatch(RESOURCES.clients.list({entity_id:  selectedEntityID}))
        }
    }

    hasClients = () =>{
        const {clients} = this.props
        const {searchFilter} = this.state

        return !isEmpty(clients) || (isEmpty(clients) && !isEmpty(searchFilter))
    }

    debounceSearchClients = debounce((value)=>{
        const {selectedEntityID} = this.props

        this.props.dispatch(RESOURCES.clients.list({search: value, entity_id: selectedEntityID}))
        this.setState({isTyping: false})
    }, 300)

    handleChangeSearchField = (value) => {
        this.setState({searchFilter: value, isTyping: true})
        this.debounceSearchClients(value)
    }

    loadMoreClients = () => {
        const {nextPage, isLoading, selectedEntityID} = this.props

        if(!isNull(nextPage) && !isLoading) {
            this.props.dispatch(RESOURCES.clients.list({page: nextPage, entity_id: selectedEntityID}))
        }
    }

    render() {
        const {clients, isLoading, isGettingFirstPage, nextPage, permissions} = this.props
        const {searchFilter, isTyping} = this.state

        var clientsCards = []

        clientsCards.push(clients.map((client ) => <ClientCard client={client} key={client.id} />))

        return (
            <div>
                <div 
                    className="navbar"
                    id="navbar-clients-list"
                    style={{
                        height: this.hasClients() ? '15vh' : '10vh',
                        borderBottom: this.hasClients() ? '1px solid $lightGrey' : '0'
                    }}
                >
                    <div className="navbar-top">
                        <Typography className="navbar-title">Listă clienți</Typography>
                        {permissions.clients.create && 
                            <Link to="/clients/add" style={{textDecoration: 'none'}}>
                                <Button
                                    onClick={()=> this.props.dispatch(setCurrent({}))}
                                    variant="contained"
                                    size="large"
                                    className="navbar-main-button"
                                    style={{backgroundColor: colors.success}}
                                >
                                    <Typography className="navbar-button-title">Adaugă client</Typography>
                                    <Plus className="navbar-button-icon" />
                                </Button>
                            </Link>
                        }
                    </div>
                    <div className="navbar-bottom">
                        {this.hasClients() ? (
                            <div className="search-div">
                                <div className="search-icon">
                                    <Search size={20} />
                                </div>
                                <InputBase
                                    placeholder="Caută clienți"
                                    value={searchFilter}
                                    className="search-input"
                                    onChange={(event) => this.handleChangeSearchField(event.target.value)}
                                />
                            </div>
                        ) : null}
                    </div>
                </div>
                {this.hasClients() ? (
                    <div id="clients-list-main-div">
                        {isEmpty(clients) && !isEmpty(searchFilter) ? (
                            <div>
                                <Typography>
                                    Nu s-au găsit clienți care să corespundă căutării tale. 
                                    Te rugăm schimbă parametrii de căutare
                                </Typography>
                            </div> 
                        ) : (
                            <InfiniteScroll
                                className='cards-container'
                                pageStart={1}
                                loadMore={this.loadMoreClients}
                                hasMore={!isNull(nextPage)}
                                loader={
                                    <LoadingMoreComponent loadingText='Se încarcă mai mulți clienți' key='load-more' />
                                }
                            >
                                {clientsCards}
                            </InfiniteScroll>
                        )}
                    </div>
                ) : isTyping || (isLoading && isGettingFirstPage) ? (
                    <ActivityIndicatorComponent />
                ) : isEmpty(clients) && !isLoading ? (
                    <EmptyClientsListComponent />
                ) : null}
            </div>

        )
    }
}
class EmptyClientsListComponent extends Component {
    render() {
        return (
            <div id="empty-clients-list-main-div">
                <Typography className="no-clients-text">Nu exista niciun client</Typography>
            </div>
        )
    }
}

class ClientCard extends Component {
    render() {
        const {client} = this.props
        
        return (
            <Link 
                key={client.id}
                to={`/clients/${client.id}`} 
                style={{textDecoration: 'none'}}
                className="card"
            >
                <div className="card-header">
                    <Typography className="card-title">{client.name}</Typography>
                </div>
                <div className="card-content">
                    <div className="card-content-child">
                        <Typography className="title">Proiecte: </Typography>
                        <Typography className="subtitle">
                            {client.projects_ids.length}
                        </Typography>
                    </div>
                    <div className="card-content-child">
                        <Typography className="title">
                            Număr persoane de notificat:
                        </Typography>
                        <Typography className="subtitle">
                            {client.contact_persons.length}
                        </Typography>
                    </div>
                    <div className="card-content-child">
                        <Typography className="title"> 
                            Număr monitorizări juridice: 
                        </Typography>
                        <Typography className="subtitle">
                            {client.party_subscriptions_count}
                        </Typography>
                    </div>
                </div>
            </Link>
        )
    }
}

const mapStateToProps = function(state) {
    return {
        clients: values(state.clients.data).sort((client1, client2) => {
            if (client1.projects_ids.length > client2.projects_ids.length) {
                return -1
            } else {
                return 1
            }
        }),
        nextPage: state.clients.next,
        isLoading: state.clients.isLoading,
        isGettingFirstPage: state.clients.isGettingFirstPage,
        selectedEntityID: state.localConfigs.selectedEntityID,
        permissions: state.permissions
    }
}

export default connect(mapStateToProps)(ClientsList)
