import React, {Component} from 'react'
import {Typography, Button, Dialog, DialogTitle, DialogActions, Input, ButtonBase} from '@material-ui/core'
import {Edit2, ChevronLeft, Save, X} from 'react-feather'
import {Link} from 'react-router-dom'
import {Form, Formik} from 'formik'

import {parseInt} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import ContactPersons from '../components/ClientDetails/ContactPersons'
import PartySubscriptions from '../components/ClientDetails/PartySubscriptions'
import Collaborators from '../components/ClientDetails/Collaborators'
import ActivityIndicatorComponent from '../components/ActivityIndicatorComponent'

import {getCurrentClientPermissions} from 'avoapp-react-common/dist/redux/clientPermissions'

import '../assets/scss/ClientDetails.scss'

export class ClientDetails extends Component {
    constructor() {
        super()

        this.state = {
            showDeleteClientDialog: false,
            editMode: false
        }
    }

    componentDidMount() {
        const {clientID} = this.props.match.params
        const {entityProfileID} = this.props

        this.props.dispatch(RESOURCES.clients.retrieve(clientID))
        this.props.dispatch(RESOURCES.contactPersons.list({client_id: clientID}))
        this.props.dispatch(RESOURCES.partySubscriptions.list({client_id: clientID}))
        this.props.dispatch(RESOURCES.clientPermissions.list({client_id: clientID}))

        this.props.dispatch(getCurrentClientPermissions(parseInt(clientID), entityProfileID))
    }

    handleCloseDeleteClientDialog = () => {this.setState({showDeleteClientDialog: false})}

    render() {
        const {client, isLoading, permissions, currentClientPermissions} = this.props
        const {showDeleteClientDialog, editMode} = this.state

        return (
            <>
                {!isLoading ? (
                    <>
                        <div className="navbar" id="navbar-client-details">
                            {!editMode ? (
                                <>
                                    <div className="navbar-left">
                                        <Link style={{textDecoration: 'none'}} className="back-button" to="/clients/">
                                            <ChevronLeft className="back-button-icon" size={16} />
                                            <Typography className="back-button-title">Înapoi</Typography>
                                        </Link>
                                        <div className="navbar-title-div">
                                            <Typography className="navbar-title">{client.name}</Typography>
                                        </div>
                                    </div>
                                    <div className="navbar-right">
                                        <div className="navbar-actions-buttons-div">
                                            {(permissions.clients.update || 
                                                currentClientPermissions.permissions.update) && 
                                                    <div className="action-button">
                                                        <Button
                                                            onClick={() => {this.setState({editMode: true})}}
                                                            variant="text"
                                                            fullWidth
                                                        >
                                                            <Typography className="action-button-text">
                                                                Redenumește
                                                            </Typography>
                                                            <Edit2 size={16} className="action-button-icon" />
                                                        </Button>
                                                    </div>
                                            }
                                            {(permissions.clients.destroy || 
                                                currentClientPermissions.permissions.destroy) &&  
                                                <>
                                                    <div className="action-button ">
                                                        <Button
                                                            onClick={() => {
                                                                this.setState({showDeleteClientDialog: true})
                                                            }}
                                                            variant="text"
                                                            fullWidth
                                                            className='delete-button'
                                                        >
                                                            <Typography className="action-button-text delete-text">
                                                                Șterge
                                                            </Typography>
                                                            <X size={16} className="action-button-icon delete-icon" />
                                                        </Button>
                                                    </div>
                                                    <DeleteClientDialog
                                                        showDeleteClientDialog={showDeleteClientDialog}
                                                        handleCloseDeleteClientDialog={
                                                            this.handleCloseDeleteClientDialog
                                                        }
                                                        clientToDelete={client}
                                                        handleDeleteClient={() => {
                                                            this.props.dispatch(RESOURCES.clients.destroy(client))
                                                            this.handleCloseDeleteClientDialog()
                                                        }}
                                                    />
                                                </>
                                            }
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div className="navbar-left">
                                    <Formik
                                        initialValues={{
                                            name: client.name,
                                            id: client.id
                                        }}
                                        onSubmit={(values) => {
                                            this.setState({editMode: false})
                                            this.props.dispatch(RESOURCES.clients.update(values))
                                        }}>
                                        {({handleChange, values, handleSubmit}) => (
                                            <Form className="styled-form">
                                                <Input
                                                    placeholder="Nume client"
                                                    value={values.name}
                                                    onChange={handleChange('name')}
                                                    className="input"
                                                    fullWidth
                                                />
                                                <div className="form-buttons">
                                                    <ButtonBase onClick={handleSubmit} style={{padding: 10}}>
                                                        <Save
                                                            className="save-icon-button"
                                                            size={25}
                                                            style={{alignSelf: 'center'}}
                                                        />
                                                    </ButtonBase>
                                                    <ButtonBase
                                                        onClick={() => {
                                                            this.setState({editMode: false})
                                                        }}
                                                        style={{padding: 10}}>
                                                        <X
                                                            className="delete-icon-button"
                                                            size={25}
                                                            style={{alignSelf: 'center'}}
                                                        />
                                                    </ButtonBase>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            )}
                        </div>
                        <div id="client-details-main-div">
                            <ContactPersons />
                            <PartySubscriptions />
                            <Collaborators />
                        </div>
                    </>
                ) : (
                    <ActivityIndicatorComponent />
                )}
            </>
        )
    }
}

class DeleteClientDialog extends Component {
    render() {
        const {showDeleteClientDialog, handleCloseDeleteClientDialog, handleDeleteClient, clientToDelete} = this.props

        return (
            <Dialog 
                className='delete-dialog'
                open={showDeleteClientDialog}
                onClose={handleCloseDeleteClientDialog}
            >
                <DialogTitle>Ești sigur că vrei să ștergi clientul "{clientToDelete.name}"?</DialogTitle>
                <DialogActions>
                    <Button onClick={handleCloseDeleteClientDialog} className='close-button'>
                        <Typography className='button-text'>Nu, renunță</Typography>
                    </Button>
                    <Button onClick={handleDeleteClient} className='action-button'>
                        <Typography className='button-text'> Da, șterge</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = function (state) {
    return {
        client: state.clients.currentClient,
        isLoading: state.clients.isLoading,
        permissions: state.permissions,
        entityProfileID: state.localConfigs.entityProfileID,
        currentClientPermissions: state.clientPermissions.currentClientPermissions
    }
}

export default connect(mapStateToProps)(ClientDetails)
