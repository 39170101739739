import React, {Component} from 'react'
import {Typography, Button, CircularProgress} from '@material-ui/core'
import {CheckCircle, Copy} from 'react-feather'
import {toast} from 'react-toastify'

import {isEmpty, values, head} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {changeDateFormat} from 'avoapp-react-common/dist/utils'

import AmbassadorsUseCodeModal from '../components/AmbassadorsUseCodeModal'

import {colors} from '../assets/styles/colors'

import '../assets/scss/Ambassadors.scss'

class Ambassadors extends Component {   
    constructor() {
        super()

        this.state = {
            showActivateCodeModal: false
        }
    } 
    
    componentDidMount() {
        const {user} = this.props

        this.props.dispatch(RESOURCES.users.retrieve('me'))
        this.props.dispatch(RESOURCES.ambassadorCodes.list())
        this.props.dispatch(RESOURCES.ambassadorCodeUsages.list({owner_id: user.id}))
    }

    handleClickActivateButton = () => this.setState({showActivateCodeModal: true})
    
    handleCloseActivateCodeModal= () => this.setState({showActivateCodeModal: false})

    render() {
        const {ambassadorCode, isLoading, ambassadorCodeUsages, isLoadingUsages, user} = this.props
        const {showActivateCodeModal} = this.state

        return (
            <div>
                <div className="navbar" id="navbar-ambassadors">
                    <div className="navbar-top">
                        <Typography className="navbar-title">Ambasadori</Typography>
                    </div>
                    {!user.flags.ambassador_code_activated ? (
                        <>
                            <AmbassadorsUseCodeModal 
                                isOpen={showActivateCodeModal}
                                handleCloseActivateCodeModal={this.handleCloseActivateCodeModal}
                            />
                            <Button
                                variant="contained"
                                onClick={this.handleClickActivateButton}
                                size="large"
                                style={{backgroundColor: colors.success}}
                            >
                                <Typography className="navbar-button-title">
                                    Vreau sa activez un cod de Ambasador
                                </Typography>
                            </Button>
                        </>
                    ) : null}
                </div>
                <div id="ambassadors-main-div">
                    <div className="container">
                        <div className="left">
                            <div className="promote-div">  
                                <Typography className='promote-text'>
                                    Poți promova aplicația Monitor Dosare astfel încât atât tu, 
                                    cât și cei care folosesc codul tău de ambasador, să obțineți beneficii.
                                </Typography>
                                <Typography className='legaltech-text'>
                                    În plus, vei susține astfel legaltech-ul românesc, 
                                    promovând o echipă de antreprenori tineri :)
                                </Typography>
                            </div>
                            <div className="benefits-div">
                                <Typography className='title'>Beneficiile promovării</Typography>
                                <div className="benefit-div">
                                    <div className="benefit-header">
                                        <CheckCircle className='target-icon'/>
                                        <Typography className='benefit-target'>Pentru tine</Typography>
                                    </div>
                                    <Typography className="benefit-text">
                                        Primești o abonare de date valabilă pe toată durata contului 
                                        tău (lifetime) pentru fiecare primă factură emisă pe un cont 
                                        nou înregistrat folosind codul tău de ambasador.
                                    </Typography>
                                </div>
                                <div className="benefit-div">
                                    <div className="benefit-header">
                                        <CheckCircle className='target-icon'/>
                                        <Typography className='benefit-target'>Pentru ei</Typography>
                                    </div>
                                    <Typography className="benefit-text">
                                        Discount de 50% la prima factură emisă pe un cont nou 
                                        înregistrat folosind codul tău de ambasador.
                                    </Typography>
                                </div>
                            </div>
                            <div className="explanations-div">
                                <Typography className='title'>Cum functionează?</Typography>
                                <div className="explanation-div">
                                    <Typography className="explanation-text">
                                        Trimite codul tău de ambasador tuturor celor care ar putea fi 
                                        interesați de aplicația Monitor Dosare. După ce își creează un 
                                        cont nou în aplicație, aceștia trebuie să activeze acel cod în 
                                        secțiunea ambasador din cadrul aplicației. 
                                    </Typography>
                                    <Typography className="explanation-text">
                                        Ulterior, la prima factură emisă pentru contul nou se va genera 
                                        automat o reducere de 50%, iar tu vei primi o abonare de date 
                                        gratuită pe toată durata contului tău.
                                    </Typography>
                                </div>
                            </div>
                        </div>
                        <div className="right"> 
                            <div className="ambassador-code-div">
                                <div className="header">
                                    <Typography className='header-title'>Codul tău de ambasador</Typography>
                                </div>
                                <div className="content">
                                    {!isEmpty(ambassadorCode) && !isLoading ? (
                                        <>
                                            <div className="code-div">
                                                <Typography className='code-text'>{ambassadorCode.slug}</Typography>
                                            </div>
                                            <div className="button-div">
                                                <Button
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(ambassadorCode.slug)
                                                        toast.success('Copiat in clipboard!')
                                                    }}
                                                    className='copy-link-button'
                                                    variant="contained"
                                                    fullWidth
                                                >
                                                    <Copy className='icon' />
                                                    <Typography>Copiază codul</Typography>
                                                </Button>
                                            </div>
                                        </>
                                    ) : isLoading ? (
                                        <div className="loading-div">
                                            <CircularProgress thickness={5} color="primary" />
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="persons-div">
                                <div className="header">
                                    <Typography className='header-title'>
                                        Ultimele persoane care au  folosit codul tău
                                    </Typography>
                                </div>
                                <div className="content">
                                    {!isEmpty(ambassadorCodeUsages) && !isLoadingUsages ? (
                                        <>
                                            {ambassadorCodeUsages.map((usage, index) => (
                                                <div className="person-div" key={index}>
                                                    <div className="person-info-div">
                                                        <Typography className='person-name'>
                                                            {usage.user.first_name} {usage.user.last_name}
                                                        </Typography>
                                                        <Typography className='person-email'>
                                                            {usage.user.email}
                                                        </Typography>
                                                    </div>
                                                    <div className='date-div'>
                                                        <Typography className='date'>
                                                            {changeDateFormat(new Date(usage.created))} 
                                                        </Typography>
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    ) : isLoadingUsages ? (
                                        <div className="loading-div">
                                            <CircularProgress thickness={5} color="primary" />
                                        </div>
                                    ) : isEmpty(ambassadorCodeUsages) ? (
                                        <Typography>Codul tău nu este folosit de nicio persoană.</Typography>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = function(state) {
    return {
        ambassadorCodeUsages: values(state.ambassadorCodeUsages.data),
        isLoadingUsages: state.ambassadorCodeUsages.isLoading,
        ambassadorCode: head(values(state.ambassadorCodes.data)),
        isLoading: state.ambassadorCodes.isLoading,
        user: state.users.user
    }
}

export default connect(mapStateToProps)(Ambassadors)