
import React, {Component} from 'react'
import {Typography, Button, Stepper, Step, StepLabel} from '@material-ui/core'
import {ChevronLeft} from 'react-feather'

import {isNull} from 'lodash'

export default class SubscriptionChangeNavbar extends Component {
     getSubscriptionChangeStepper = (activeStep) => {
         const {isDowngradeFlow} = this.props
         let steps = ['Interval de facturare', 'Date de facturare', 'Confirmare']

         if(!isNull(isDowngradeFlow) && !isDowngradeFlow) {
             steps = ['Date de facturare', 'Confirmare']
         }

         if(isNull(isDowngradeFlow) || !isDowngradeFlow)
             return (
                 <Stepper activeStep={activeStep.activeStep}>
                     {steps.map((step) =>(
                         <Step key={step}>
                             <StepLabel>
                                 <Typography>{step}</Typography>
                             </StepLabel>
                         </Step>
                     ))}
                 </Stepper>
             )
         else return <div />
     }

     render() {
         const {activeStep, goToPlans} = this.props

         return (
             <div className="navbar" id="navbar-change-subscription">
                 <div className="navbar-left">
                     <Typography className="navbar-title">Schimbare Abonament</Typography>
                     <div className="navbar-stepper">
                         {this.getSubscriptionChangeStepper({activeStep: activeStep})}
                     </div>
                 </div>
                 <Button className="back-button" onClick={goToPlans}>
                     <ChevronLeft className="back-button-icon"/>
                     <Typography className="back-button-title">Înapoi la Planuri</Typography>
                 </Button>
             </div>
         )
     }
}
