import React, {Component} from 'react'
import {Typography, Button} from '@material-ui/core'
import {ChevronsDown} from 'react-feather'
import {Link} from 'react-router-dom'

import LimitIcon from '../assets/images/limit.svg'

import {connect} from 'react-redux'

import '../assets/scss/ToManySubscriptions.scss'

class ToManySubscriptions extends Component {
    getSubscriptionPath = () => {
        const {permissions} = this.props
        
        if (permissions.plans.update) {
            return '/settings#subscriptions'
        } 

        return '/insufficient-permissions'
    }

    getDeletePath = () => {
        const {permissions} = this.props
        
        if (permissions.projects.update) {
            return '/delete-subscriptions'
        } 

        return '/insufficient-permissions'

    }

    render() {

        return (
            <div id='to-many-subs-main-div'>
                <div className="info-div">
                    <img src={LimitIcon} alt=""/>
                    <Typography className='info-text'>
                        Situația e simplă, ar fi plăcerea noastră maximă să adăugăm încă o abonare de date în 
                        contul tău. Temporar, suntem blocați de limita maximă setată în abonamentul tău curent.
                    </Typography>
                    <div className="info-solutions">
                        <Typography className='solutions-text'>Avem două soluții</Typography>
                        <ChevronsDown className='solutions-icon' />
                    </div>
                </div>
                <div className="buttons-div">
                    <Link to={this.getSubscriptionPath()} className='link'>
                        <Button
                            className='orange-button'
                            size="large"
                            variant="contained"
                            fullWidth
                        >
                            <Typography className='button-text'>
                                Mărește limita <span className='italic'>(modificare abonament)</span>
                            </Typography>
                        </Button>
                    </Link>
                    <Link to={this.getDeletePath()} className='link'>
                        <Button
                            className='white-button'
                            size="large"
                            variant="contained"
                            fullWidth
                        >
                            <Typography className='button-text'>Șterge din abonările existente</Typography>
                        </Button>
                    </Link>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    permissions: state.permissions
})

export default connect(mapStateToProps)(ToManySubscriptions)
