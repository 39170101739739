import {toast} from 'react-toastify'
import {takeEvery} from '@redux-saga/core/effects'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

const {entityPreferences: resource} = RESOURCES

function handleUpdateEntityPreferencesSuccess() {
    toast.success('Modificările au fost salvate')
}

export function* saga() {
    yield takeEvery(resource.actions.update.success, handleUpdateEntityPreferencesSuccess)
}
