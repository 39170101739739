import React, {Component} from 'react'
import {Button, ButtonBase, Input, Modal, Typography} from '@material-ui/core'
import {X} from 'react-feather'

import {isEmpty , values} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {getErrorFromCode} from 'avoapp-react-common/dist/utils'

import {closePromoModal} from '../redux/promoCodeUsages'

import '../assets/scss/PromoCodeModal.scss'

class PromoCodeModal extends Component {
    constructor() {
        super()

        this.state = {
            promoCode: ''
        }
    }

    handleChangePromoCode = (event) => {
        const {value} = event.target

        this.setState({promoCode: value})
    }

    handleCreatePromoCodeUsage = () => {
        const {selectedEntityID} = this.props
        const {promoCode} = this.state

        this.props.dispatch(RESOURCES.promoCodeUsages.create({
            entity_id: selectedEntityID,
            promo_code: promoCode
        }))
    }

    handleClosePromoModal = () => {
        this.props.dispatch(closePromoModal())
    }

    render() {
        const {isModalOpen, isLoading, errors} = this.props
        const {promoCode} = this.state
        
        return (
            <Modal open={isModalOpen} onClose={this.handleClosePromoModal} >
                <div id="promo-code-main-div">
                    <div className="container">
                        <div className="header">
                            <ButtonBase className='close-button' onClick={this.handleClosePromoModal}>
                                <X className='close-button-icon'/>
                            </ButtonBase>
                        </div>
                        <div className="content">
                            <div className="texts">
                                <Typography className="title">
                                    Ne bucurăm că folosești aplicația Monitor Dosare!
                                </Typography>
                                <Typography className='subtitle'>
                                    În această secțiune ai posibilitatea de a introduce 
                                    codul primit de reducere Monitor Dosare. 
                                </Typography>
                            </div>
                            <div className="code-div">
                                {!isEmpty(errors) && values(errors).map((errorCategories) =>
                                    errorCategories.map((error, index) => (
                                        <div key={index} className='error-div'>
                                            <Typography className='error-text'>
                                                {getErrorFromCode(error, 'promoCodeUsages')}
                                            </Typography>
                                        </div>
                                    ))
                                )}
                                <Typography className='use-code-text'>
                                    Introdu codul de reducere pe care dorești să îl folosești
                                </Typography>
                                <Input
                                    placeholder='Codul de reducere'
                                    onChange={(event)=>{this.handleChangePromoCode(event)}}
                                    className='promo-code-input' 
                                    value={promoCode}
                                    autoFocus
                                />
                                <Button
                                    size="large"
                                    variant="contained"
                                    onClick={this.handleCreatePromoCodeUsage}
                                    disabled={isLoading || isEmpty(promoCode)}
                                >
                                    <Typography>Aplică codul</Typography>
                                </Button>
                            </div>
                        </div>
                        <div className="footer" />
                    </div>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = function(state) {
    return {
        isModalOpen:  state.promoModal.isModalOpen,
        isLoading: state.promoCodeUsages.isLoading,
        errors: state.promoCodeUsages.errors,
        selectedEntityID: state.localConfigs.selectedEntityID
    }
}

export default connect(mapStateToProps)(PromoCodeModal)
