import React, {Component} from 'react'

import DateFnsUtils from '@date-io/date-fns'
import {
    Button,
    CircularProgress,
    FormControl,
    Input,
    InputLabel,
    Select as MUISelect,
    MenuItem,
    Typography
} from '@material-ui/core'
import {ArrowDropDown} from '@material-ui/icons'
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import {lightFormat} from 'date-fns'
import {Formik} from 'formik'
import {concat, head, isEmpty, isNull, values} from 'lodash'
import {Link} from 'react-router-dom'

import {customPhase, phasesArray} from 'avoapp-react-common/dist/constants'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {getErrorFromCode} from 'avoapp-react-common/dist/utils'
import {connect} from 'react-redux'

import {litigationsCheckData} from '../assets/data/choices'

import InstitutionSelect from '../components/InstitutionSelect'

import '../assets/scss/ManualAdd.scss'

class ManualPhaseAdd extends Component {
    componentDidMount() {
        const {subscriptionID} = this.props.match.params
        this.props.dispatch(RESOURCES.subscriptions.retrieve(subscriptionID))
    }

    createInstitutionsArray = (startingInstitutionsArray) => {
        let finalInstitutionsArray = []
        // eslint-disable-next-line array-callback-return
        startingInstitutionsArray.map((institution) => {
            if(!isNull(litigationsCheckData.institution[institution])) {
                finalInstitutionsArray = concat(finalInstitutionsArray, {
                    value: litigationsCheckData.institution[institution],
                    label: institution
                })
            }
        })
    
        return finalInstitutionsArray
    }

    getPhase = (phaseSelect, phaseText) => {
        if(phaseSelect === customPhase.value && !isEmpty(phaseText)) {
            return phaseText
        } 

        return phaseSelect
    }

    render() {
        const {subscriptionID} = this.props.match.params
        const {isLoading, phasesErrors, currentSubscription, isLoadingSubscriptions} = this.props

        const institutionsArray = this.createInstitutionsArray(Object.keys(litigationsCheckData.institution))

        return (
            <div>
                <div className="navbar" id="navbar-manual-add">
                    <div className="navbar-left">
                        <Typography className="navbar-title">Adaugă dosar manual</Typography>
                    </div>
                    <Link style={{textDecoration: 'none'}} to={`/subscriptions/${subscriptionID}`}>
                        <Button className="cancel-button">
                            <Typography className="cancel-button-title">Renunță</Typography>
                        </Button>
                    </Link>
                </div>
                <div id="manual-add-main-div">
                    {!isEmpty(currentSubscription) && !isLoadingSubscriptions ? (
                        <Formik
                            initialValues={{
                                court: head(institutionsArray),
                                litigationID: currentSubscription.litigation.id,
                                date: new Date(),
                                number: currentSubscription.litigation.number,
                                object: '',
                                department: '',
                                category: '',
                                phaseSelect: 'Fond',
                                phaseText: ''
                            }}
                            onSubmit={(values) => {
                                const phase = this.getPhase(values.phaseSelect, values.phaseText)

                                this.props.dispatch(RESOURCES.phases.create({
                                    court: values.court.value,
                                    litigation_id: values.litigationID,
                                    date: lightFormat(new Date(values.date), 'yyyy-MM-dd'),
                                    number: values.number,
                                    object: values.object,
                                    department: values.department,
                                    category: values.category,
                                    phase: phase
                                }))
                            }}
                        >
                            {({handleChange, setFieldValue, handleSubmit, values}) => (
                                <div className='form'>  
                                    {!isEmpty(phasesErrors) && phasesErrors.map((errorCategories) =>
                                        errorCategories.map((error, index) => (
                                            <div key={index} className='error-div'>
                                                <Typography className='error-text'>
                                                    {getErrorFromCode(error, 'phases')}
                                                </Typography>
                                            </div>
                                        ))
                                    )}
                                    <div className="section">
                                        <div className="section-content">
                                            <FormControl className="input-form-control">
                                                <InputLabel>Dosar *</InputLabel>
                                                <MUISelect
                                                    value={values.litigationID}
                                                    IconComponent={() => <ArrowDropDown className='dropdown-icon' />}
                                                    disabled
                                                >
                                                    <MenuItem value={currentSubscription.litigation.id}>
                                                        {currentSubscription.litigation.number}
                                                    </MenuItem>
                                                </MUISelect>
                                            </FormControl>
                                            <FormControl className="input-form-control">
                                                <InputLabel>Fază *</InputLabel>
                                                <MUISelect
                                                    value={values.phaseSelect}
                                                    IconComponent={() => <ArrowDropDown className='dropdown-icon' />}
                                                    onChange={(event) => 
                                                        setFieldValue('phaseSelect', event.target.value)
                                                    }
                                                >
                                                    {phasesArray.map((phase) => 
                                                        <MenuItem key={phase.value} value={phase.value}>
                                                            {phase.label}
                                                        </MenuItem>
                                                    )}
                                                </MUISelect>
                                            </FormControl>
                                            {values.phaseSelect === customPhase.value && 
                                                <FormControl className='input-form-control'>
                                                    <InputLabel shrink={!isEmpty(values.phaseText)}>
                                                        Nume fază
                                                    </InputLabel>
                                                    <Input
                                                        value={values.phaseText}
                                                        onChange={handleChange('phaseText')}
                                                        className="input"
                                                    />
                                                </FormControl>
                                            }
                                            <FormControl className='input-form-control'>
                                                <InputLabel shrink={!isEmpty(values.object)}>
                                                    Obiect *
                                                </InputLabel>
                                                <Input
                                                    value={values.object}
                                                    onChange={handleChange('object')}
                                                    className="input"
                                                />
                                            </FormControl>
                                            <InstitutionSelect 
                                                label='Instanță *'
                                                value={values.court}
                                                institutionsArray={institutionsArray}
                                                handleChange={(e) => {
                                                    setFieldValue('court', e)
                                                    setFieldValue('courtValue', e.value)
                                                }}
                                            />
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <FormControl className="project-add-form-control date-select">
                                                    <DatePicker
                                                        label="Data *"
                                                        value={values.date}
                                                        onChange={(value) => {setFieldValue('date', value)}}
                                                        InputProps={{disableUnderline: true}}
                                                        format="dd/MM/yyyy"
                                                        className="date-picker"
                                                        cancelLabel="Închide"
                                                        okLabel="Ok"
                                                    />
                                                    <ArrowDropDown className='dropdown-icon' />
                                                </FormControl>
                                            </MuiPickersUtilsProvider>
                                            <FormControl className='input-form-control'>
                                                <InputLabel shrink={!isEmpty(values.category)}>
                                                    Categorie
                                                </InputLabel>
                                                <Input
                                                    value={values.category}
                                                    onChange={handleChange('category')}
                                                    className="input"
                                                />
                                            </FormControl>
                                            <FormControl className='input-form-control'>
                                                <InputLabel shrink={!isEmpty(values.department)}>
                                                    Departament
                                                </InputLabel>
                                                <Input
                                                    value={values.department}
                                                    onChange={handleChange('department')}
                                                    className="input"
                                                />
                                            </FormControl>
                                            <Typography className="disclaimer">
                                                * - câmpuri obligatorii
                                            </Typography>
                                        </div>
                                    </div>
                                    <Button
                                        onClick={handleSubmit}
                                        disabled={
                                            isLoading ||
                                            isEmpty(values.phaseSelect) ||
                                            isEmpty(values.object) ||
                                            isEmpty(values.court)
                                        }
                                        className='submit-button'
                                        variant="contained"
                                        size="large"
                                        fullWidth
                                    >
                                        <Typography className="button-title">Mai departe</Typography>
                                    </Button>
                                </div>
                            )}
                        </Formik>
                    ) : (
                        <CircularProgress className="circular-progress" thickness={5} color="primary" />
                    )}
                </div>
            </div>
        )
    }
}
  
const mapStateToProps = function(state) {
    return {
        currentSubscription: state.subscriptions.currentSubscription,
        isLoadingSubscriptions: state.subscriptions.isLoading,
        phasesErrors: values(state.phases.errors),
        isLoading: state.phases.isLoading
    }
}

export default connect(mapStateToProps)(ManualPhaseAdd)
