import {Button, Typography} from '@material-ui/core'
import React, {Component} from 'react'
import {Edit3, Plus} from 'react-feather'
import {Link} from 'react-router-dom'

import {isEmpty, uniqBy, values} from 'lodash'

import {
    changeDateFormat,
    findManualSubscriptions,
    findSyncedSubscriptions,
    getLitigationNumberForSyncingSubscription
} from 'avoapp-react-common/dist/utils'
import {connect} from 'react-redux'
import SubscriptionSyncing from './ProjectDetails/SubscriptionSyncing'
import SelectPhaseModal from './SelectPhaseModal'

class AppealsSection extends Component {
    constructor() {
        super() 

        this.state = {
            showSelectPhaseModal: false
        }
    }
    
    render() {
        const {
            appeals,
            hasSyncedSubscriptions,
            syncingLitigationNumber,
            permissions,
            currentProjectPermissions,
            hasManualSubscriptions,
            sectionNumber,
            addingAllowed
        } = this.props

        const {showSelectPhaseModal} = this.state

        return (
            <div className="section appeals-section">
                <div className="section-header">
                    <Typography className="section-title">{sectionNumber}. CĂI DE ATAC</Typography>
                    {
                        (permissions.projects.update ||
                            currentProjectPermissions.permissions.update) && 
                            addingAllowed && hasManualSubscriptions &&
                            <>
                                <Button
                                    onClick={()=> this.setState({showSelectPhaseModal: true})}
                                    className="navbar-main-button"
                                    variant="contained"
                                    size="small"
                                >
                                    <Plus className="navbar-button-icon" />
                                    <Typography className="navbar-button-title">Adaugă căi de atac</Typography>
                                </Button>
                                <SelectPhaseModal
                                    showSelectPhaseModal={showSelectPhaseModal}
                                    handleCloseSelectPhaseModal={() => this.setState({showSelectPhaseModal: false})}
                                    getNextStepUrl = {(phaseID) => `/phases/${phaseID}/add-manual-appeal`}
                                    resource='căi de atac'
                                />
                            </>
                    }
                </div>
                <div className="section-content">
                    {!isEmpty(appeals) && hasSyncedSubscriptions ? (
                        <div className='cards-container'> 
                            {appeals.map((appeal) => (
                                <div key={appeal.id} className="card">
                                    <div className="card-header">
                                        <div className='left'>
                                            <Typography className="card-title">
                                                {changeDateFormat(appeal.date)}
                                            </Typography>
                                            <div className="appeal-type-div">
                                                <Typography className="appeal-type-subtitle">
                                                    Tip
                                                </Typography>
                                                <Typography className="appeal-type-title">
                                                    {appeal.type}
                                                </Typography>
                                            </div>
                                        </div>
                                        {(permissions.projects.update || 
                                            currentProjectPermissions.permissions.update) && 
                                            appeal.is_manual &&
                                            <div className='edit-div'>
                                                <Link to={`/appeals/${appeal.id}/edit`}>
                                                    <Edit3 size={16} className='edit-icon' />
                                                </Link>
                                            </div>
                                        }
                                    </div>
                                    {appeal.party &&
                                        <div className="card-content">
                                            <div className="card-content-child">
                                                <Typography className="title"> Parte </Typography>
                                                <Typography className="subtitle">{appeal.party}</Typography>
                                            </div>
                                        </div>
                                    }
                                </div>
                            ))}
                        </div>
                    ) : syncingLitigationNumber && !hasSyncedSubscriptions ? (
                        <SubscriptionSyncing syncingLitigationNumber={syncingLitigationNumber} />
                    ) : (
                        <Typography className='no-data-text'>Nu există  nicio cale de atac</Typography>
                    )}
                </div>
            </div>
        )
    }
}

const mapStateToProps = function (state, props) {
    const {projectID, subscriptionID} = props
    
    let subscriptions 

    if(projectID) {
        subscriptions = values(state.subscriptions.data)
    } else if(subscriptionID) {
        subscriptions = [state.subscriptions.currentSubscription]
    }

    let hasManualSubscriptions = findManualSubscriptions(subscriptions)
    let hasSyncedSubscriptions = findSyncedSubscriptions(subscriptions)
    let syncingLitigationNumber = getLitigationNumberForSyncingSubscription(subscriptions)

    return {
        subscriptions: subscriptions,
        permissions: state.permissions,
        hasManualSubscriptions: hasManualSubscriptions,
        hasSyncedSubscriptions: hasSyncedSubscriptions,
        syncingLitigationNumber: syncingLitigationNumber,
        appeals: uniqBy(values(state.appeals.data), appeal => [appeal.party, appeal.date].join()),
        currentProjectPermissions: state.projectPermissions.currentProjectPermissions
    }
}

export default connect(mapStateToProps)(AppealsSection)