import {toast} from 'react-toastify'
import {push} from 'connected-react-router'

import {values, head} from 'lodash'

import {takeEvery, put, take, select} from 'redux-saga/effects'

import {auth} from 'avoapp-react-common'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {getPermissions} from 'avoapp-react-common/dist/redux/permissions'
import {retrieveAndSetCurrent} from 'avoapp-react-common/dist/redux/entities'
import {selectEntityID} from 'avoapp-react-common/dist/redux/selectedEntityID'
import {selectEntityProfileID} from 'avoapp-react-common/dist/redux/localConfigs'
import {getBelongingEntities, ACTIONS as entityProfileActions} from 'avoapp-react-common/dist/redux/entityProfiles'

const {ACTIONS} = auth

function* handleLoginSuccess() {
    const user = yield select((state) => state.users.user)
    
    yield put(getBelongingEntities(user.id))
    yield take(entityProfileActions.GET_BELONGING_ENTITIES.success)

    let belongingEntities = values(yield select((state) => state.entityProfiles.belongingEntities))

    if (belongingEntities.length === 1) {
        yield put(selectEntityID(head(belongingEntities).id))
        yield put(retrieveAndSetCurrent(head(belongingEntities).id))
        yield put(push('/subscriptions'))
    } else {
        yield put(push('/select-entity'))
    }
}

function* handleRefreshTokenSuccess() {
    const user = yield select((state) => state.users.user)

    yield put(getBelongingEntities(user.id))
    yield take(entityProfileActions.GET_BELONGING_ENTITIES.success)

    let belongingEntities = values(yield select((state) => state.entityProfiles.belongingEntities))
    let selectedEntityID = yield select((state) => state.localConfigs.selectedEntityID)

    if (belongingEntities.length === 1 && (!selectedEntityID || selectedEntityID !== head(belongingEntities).id)) {
        yield put(selectEntityID(head(belongingEntities).id))
        yield put(retrieveAndSetCurrent(head(belongingEntities).id))
    }
    
    if(selectedEntityID) {
        yield put(RESOURCES.entityProfiles.list({entity_id: selectedEntityID, user_id: user.id}))
        yield take(RESOURCES.entityProfiles.actions.list.success)

        const myEntityProfileID = yield select((state) => head(values(state.entityProfiles.data)).id)

        yield put(selectEntityProfileID(myEntityProfileID))
        yield put(retrieveAndSetCurrent(selectedEntityID))
        yield put(RESOURCES.entityReports.retrieve(selectedEntityID))
        yield put(getPermissions())
    } else {
        yield put(push('/select-entity'))
    } 

    if (window.location.pathname === '/login') {
        yield put(push('/subscriptions'))
    }
}

function* handleSignupSuccess() {
    const message = 'Contul a fost creat cu success. Verifică-ți emailul!'
    
    toast.success(message)
    yield put(push('/login'))
}

function* handleLogout() {
    yield put(push('/login'))
}

function handleForgotPasswordSuccess() {
    const message = 'Am trimis un link de resetare a parolei pe adresa de email introdusă'

    toast.success(message)
}

function* handleResetPasswordSuccess() {
    const message = 'Parola a fost resetata cu succes'

    toast.success(message)
    yield put(push('/login'))
}

export function* saga() {
    yield takeEvery(ACTIONS.LOGIN.success, handleLoginSuccess)
    yield takeEvery(ACTIONS.REFRESH_TOKEN.success, handleRefreshTokenSuccess)
    yield takeEvery(ACTIONS.SIGNUP.success, handleSignupSuccess)
    yield takeEvery(ACTIONS.FORGOT_PASSWORD.success, handleForgotPasswordSuccess)
    yield takeEvery(ACTIONS.RESET_PASSWORD.success, handleResetPasswordSuccess)
    yield takeEvery(ACTIONS.LOGOUT.main, handleLogout)
}
