import {Button, Typography} from '@material-ui/core'
import React, {Component} from 'react'
import {Edit3, Plus} from 'react-feather'
import {Link} from 'react-router-dom'

import {groupBy, isEmpty, join, toArray, uniq, values} from 'lodash'

import {
    findManualSubscriptions,
    findSyncedSubscriptions,
    getLitigationNumberForSyncingSubscription
} from 'avoapp-react-common/dist/utils'
import {connect} from 'react-redux'
import SubscriptionSyncing from './ProjectDetails/SubscriptionSyncing'
import SelectPhaseModal from './SelectPhaseModal'

class PartiesSection extends Component {
    constructor() {
        super() 

        this.state = {
            showSelectPhaseModal: false
        }
    }

    render() {
        const {
            parties,
            hasSyncedSubscriptions, 
            syncingLitigationNumber,
            hasManualSubscriptions,
            permissions, 
            currentProjectPermissions,
            sectionNumber,
            addingAllowed
        } = this.props

        const {showSelectPhaseModal} = this.state
        
        return (
            <div className="section parties-section">
                <div className="section-header">
                    <Typography className="section-title">{sectionNumber}. PĂRȚI DIN DOSARELE URMĂRITE</Typography>
                    {
                        (permissions.projects.update || currentProjectPermissions.permissions.update) && 
                            addingAllowed && hasManualSubscriptions &&
                                <>
                                    <Button
                                        onClick={()=> this.setState({showSelectPhaseModal: true})}
                                        className="navbar-main-button"
                                        variant="contained"
                                        size="small"
                                    >
                                        <Plus className="navbar-button-icon" />
                                        <Typography className="navbar-button-title">Adaugă părți</Typography>
                                    </Button>
                                    <SelectPhaseModal
                                        showSelectPhaseModal={showSelectPhaseModal}
                                        handleCloseSelectPhaseModal={() => this.setState({showSelectPhaseModal: false})}
                                        getNextStepUrl = {(phaseID) => `/phases/${phaseID}/add-manual-party`}
                                        resource='părți'
                                    />
                                </>
                    }
                </div>
                <div className="section-content">
                    {!isEmpty(parties) && hasSyncedSubscriptions ? (
                        <div className='cards-container'> 
                            {parties.map((party, index) => (
                                <div key={index} className="card">
                                    <div className="card-header">
                                        <div className="name-div">
                                            <Typography className="card-subtitle">Nume</Typography>
                                            <Typography className="card-title">{party[0].name}</Typography>
                                        </div>
                                        {(permissions.projects.update || 
                                            currentProjectPermissions.permissions.update) &&
                                            party[0].is_manual &&
                                            <>
                                                <div className='edit-div'>
                                                    <Link to={`/parties/${party[0].id}/edit`}>
                                                        <Edit3 size={16} className='edit-icon' />
                                                    </Link>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className="card-content">
                                        <div className="card-content-child">
                                            <Typography className="title"> Rol </Typography>
                                            <Typography className="subtitle">
                                                {join(uniq(party.map((_party) => _party.role)), ', ')}
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : syncingLitigationNumber && !hasSyncedSubscriptions ? (
                        <SubscriptionSyncing syncingLitigationNumber={syncingLitigationNumber} />
                    ) : (
                        <Typography className='no-data-text'>Nu există părți</Typography>
                    )}
                </div>
            </div>
        )
    }
}

const mapStateToProps = function (state, props) {
    const {projectID, subscriptionID} = props
    
    let subscriptions 

    if(projectID) {
        subscriptions = values(state.subscriptions.data)
    } else if(subscriptionID) {
        subscriptions = [state.subscriptions.currentSubscription]
    }

    let hasManualSubscriptions = findManualSubscriptions(subscriptions)
    let hasSyncedSubscriptions = findSyncedSubscriptions(subscriptions)
    let syncingLitigationNumber = getLitigationNumberForSyncingSubscription(subscriptions)

    return {
        permissions: state.permissions,
        subscriptions: subscriptions,
        hasSyncedSubscriptions: hasSyncedSubscriptions,
        hasManualSubscriptions: hasManualSubscriptions,
        syncingLitigationNumber: syncingLitigationNumber,
        parties: toArray(groupBy(values(state.parties.data), 'name')),
        currentProjectPermissions: state.projectPermissions.currentProjectPermissions
    }
}

export default connect(mapStateToProps)(PartiesSection)