import React, {Component} from 'react'
import {Button, Typography} from '@material-ui/core'
import {Link} from 'react-router-dom'

import '../assets/scss/AmbassadorCodeActivated.scss'

export default class AmbassadorCodeActivated extends Component {
    render() {
        return (
            <div id="ambassador-code-activated-main-div">
                <div className="container">
                    <div className='check-image-div'>
                        <img
                            style={{width: '100%', height: 'auto'}}
                            src={require('../assets/images/check.svg').default}
                            alt=''
                        />
                    </div>
                    <Typography className='code-activated-text'>
                        Codul de ambasador a fost activat cu succes!
                    </Typography>
                    <div className='benefit-div'>
                        <Typography className='benefit-text'>
                            La următoarea factură vei avea un 
                        </Typography>
                        <div className="discount-div">
                            <Typography className='discount'>
                                discount de 50%
                            </Typography>
                        </div>
                    </div>
                    <div className='become-ambassador-div'>
                        <Typography className='become-ambassador-text'>
                            Nu uita că și tu poți obține beneficii ca <br/> ambasador Monitor Dosare!
                        </Typography>
                    </div>
                    <Link className='to-ambassadors-link' to='/ambassadors' style={{textDecoration: 'none'}}>
                        <Button size="large" variant="contained" fullWidth>
                            <Typography className='to-ambassadors-link-text'>Vezi codul tău de ambasador</Typography>
                        </Button>
                    </Link>
                    <Link className='to-projects-link' to='/subscriptions' style={{textDecoration: 'none'}}>
                        <Button className='to-projects-button' size="large" variant="outlined" fullWidth>
                            <Typography className='to-projects-link-text'>Mergi la ecranul principal</Typography>
                        </Button>
                    </Link>
                </div>
            </div>
        )
    }
}
