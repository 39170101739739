import React, {Component} from 'react'
import {Button, ButtonBase, CircularProgress, Dialog, DialogActions, DialogTitle, Typography} from '@material-ui/core'
import {Link} from 'react-router-dom'
import {X, Plus} from 'react-feather'

import {values, isEmpty, isNull} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {resendConfirmation} from 'avoapp-react-common/dist/redux/contactPersons'

import {colors} from '../../assets/styles/colors'

class ContactPersons extends Component {
    constructor() {
        super()

        this.state = {
            showDeleteContactPersonDialog: false,
            contactPersonToDelete: {}
        }
    }

    handleResendConfirmation = (contactPerson) => {
        this.props.dispatch(resendConfirmation(contactPerson.id))
    }

    getResponseStatus = (response) => {
        let responseText = 'Acceptat'
        
        if(response === 'no') responseText = 'Refuzat' 
        if(isNull(response)) responseText = 'În așteptare ' 

        return responseText
    }

    handleCloseDeleteContactPersonDialog = () => {
        this.setState({showDeleteContactPersonDialog: false, contactPersonToDelete: {}})
    }
    
    render() {
        const {client, contactPersons, isLoadingContactPersons, permissions, currentClientPermissions} = this.props
        const {showDeleteContactPersonDialog, contactPersonToDelete} = this.state

        return (
            <div className="section">
                <div className="section-header">
                    <Typography className="section-header-text">Persoane de notificat</Typography>
                    {(permissions.clients.update || currentClientPermissions.permissions_contact_persons.create) && <>
                        <Link style={{textDecoration: 'none'}} to={`/clients/${client.id}/add-contact-person`}>
                            <Button
                                variant="contained"
                                size="small"
                                className="navbar-main-button"
                                style={{backgroundColor: colors.success}}
                            >
                                <Plus className="navbar-button-icon" />
                                <Typography className="navbar-button-title">Adaugă persoană</Typography>
                            </Button>
                        </Link>
                    </>}
                </div>
                <div className="section-content">
                    {!isLoadingContactPersons && !isEmpty(contactPersons) ? (
                        <div className='cards-container'> 
                            {contactPersons.map((contactPerson) => (
                                <div key={contactPerson.id} className="card">
                                    <div className="card-header">
                                        <Typography className="card-title">
                                            {contactPerson.last_name} {contactPerson.first_name}
                                        </Typography>
                                        {
                                            (permissions.clients.update || 
                                            currentClientPermissions.permissions_contact_persons.destroy) && 
                                            <>
                                                <ButtonBase
                                                    onClick={() => {
                                                        this.setState({
                                                            showDeleteContactPersonDialog: true,
                                                            contactPersonToDelete: contactPerson
                                                        })
                                                    }}
                                                >
                                                    <X
                                                        className="small-delete-button-icon"
                                                        size={14}
                                                        style={{alignSelf: 'center'}}
                                                    />
                                                </ButtonBase>
                                            </>
                                        }
                                    </div>
                                    <div className="card-content">
                                        <div className="card-content-child">
                                            <Typography className="title">Email:</Typography>
                                            <Typography className="subtitle">{contactPerson.email}</Typography>
                                        </div>
                                        <div className="card-content-child notifications-div">
                                            <Typography className="title">Notificări:</Typography>
                                            <Typography className="subtitle">
                                                {this.getResponseStatus(contactPerson.response)}
                                            </Typography>
                                        </div>
                                        {isNull(contactPerson.response) && 
                                        <ResendConfirmationButton 
                                            handleResendConfirmation={()=>{
                                                this.handleResendConfirmation(contactPerson)
                                            }}
                                        />}
                                    </div>
                                </div>
                            ))}
                            <DeleteContactPersonDialog
                                showDeleteContactPersonDialog={showDeleteContactPersonDialog}
                                contactPersonToDelete={contactPersonToDelete}
                                handleCloseDeleteContactPersonDialog={this.handleCloseDeleteContactPersonDialog}
                                handleDeleteContactPerson={() => {
                                    this.props.dispatch(RESOURCES.contactPersons.destroy(contactPersonToDelete))
                                    this.handleCloseDeleteContactPersonDialog()
                                }}
                            />
                        </div>
                    ) : isEmpty(contactPersons) ? (
                        <Typography className='no-data-text'>
                            Nu există nicio personă de notificat
                        </Typography>
                    ) : (
                        <div className='loading-div'>
                            <CircularProgress className="circular-progress" thickness={5} color="primary" />
                        </div>
                    )}  
                </div>
            </div>
        )
    }
}

class ResendConfirmationButton extends Component {
    render() {
        const {handleResendConfirmation} = this.props

        return (
            <Button id='resend-email-button' onClick={handleResendConfirmation} variant='outlined'>
                <Typography className='resend-email-button-text'>
                    Retrimite email
                </Typography>
            </Button>
        )
    }
}

class DeleteContactPersonDialog extends Component {
    render() {
        const {
            showDeleteContactPersonDialog,
            handleCloseDeleteContactPersonDialog,
            handleDeleteContactPerson,
            contactPersonToDelete
        } = this.props

        return (
            <Dialog
                className='delete-dialog' 
                open={showDeleteContactPersonDialog} 
                onClose={handleCloseDeleteContactPersonDialog}
            >
                <DialogTitle>
                    Ești sigur că vrei să ștergi persoana de notificat "{contactPersonToDelete.last_name}{' '}
                    {contactPersonToDelete.first_name}"?
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleCloseDeleteContactPersonDialog} className='close-button'>
                        <Typography className='button-text'>Nu, renunță</Typography>
                    </Button>
                    <Button onClick={handleDeleteContactPerson} className='action-button'>
                        <Typography className='button-text'> Da, șterge</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = function (state) {
    return {
        client: state.clients.currentClient,
        isLoading: state.clients.isLoading,
        contactPersons: values(state.contactPersons.data),
        isLoadingContactPersons: state.contactPersons.isLoading,
        permissions: state.permissions,
        currentClientPermissions: state.clientPermissions.currentClientPermissions
    }
}

export default connect(mapStateToProps)(ContactPersons)
