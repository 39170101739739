import React, {Component} from 'react'
import {Button, CircularProgress, Input, Typography} from '@material-ui/core'
import {Link} from 'react-router-dom'
import {Formik} from 'formik'

import {isEmpty, indexOf, has} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {CRUDTypes} from 'avoapp-react-common/dist/constants'

import PermissionsSelect from './PermissionsSelect'

import {getResourcePermissions, getResourcesForGroups} from '../assets/utils'

import '../assets/scss/GroupEdit.scss'

class GroupEdit extends Component {
    constructor() {
        super()

        this.state = { 
            localResources: getResourcesForGroups()
        }
    }

    componentDidMount() {
        const {groupID} = this.props.match.params

        this.props.dispatch(RESOURCES.groups.retrieve(groupID))
        this.getGivenPermissions()
    }

    componentDidUpdate(prevProps) {
        const {group} = this.props

        if (!isEmpty(group) && prevProps.group !== group) {        
            this.getGivenPermissions()
        }
    }

    handleGivePermission = (resource, permission, permissionValue) => {
        let localResources = {...this.state.localResources}
        const resourceName = has(resource, 'name') ? resource.name : resource

        localResources[resourceName]['permissions'][permission] = permissionValue
        this.setState({localResources})    
    }

    getGivenPermissions = () => {
        const {group} = this.props

        if(!isEmpty(group)) {   
            this.getPermissionsFromNumbers('projects', group.permissions_projects)
            this.getPermissionsFromNumbers('clients', group.permissions_clients)
            this.getPermissionsFromNumbers('entity', group.permissions_entity)
            this.getPermissionsFromNumbers('members', group.permissions_members)
            this.getPermissionsFromNumbers('plans', group.permissions_plans)        
        }
    }

    getPermissionsFromNumbers = (resource, permissions) => {
        if(indexOf(permissions, CRUDTypes.create.number) !== -1) {
            this.handleGivePermission(resource, CRUDTypes.create.name, true)
        } else {
            this.handleGivePermission(resource, CRUDTypes.create.name, false)
        }
        
        if(indexOf(permissions, CRUDTypes.read.number) !== -1) {
            this.handleGivePermission(resource, CRUDTypes.read.name, true)
        } else {
            this.handleGivePermission(resource, CRUDTypes.read.name, false)
        }
        
        if(indexOf(permissions, CRUDTypes.update.number) !== -1) {
            this.handleGivePermission(resource, CRUDTypes.update.name, true)
        } else {
            this.handleGivePermission(resource, CRUDTypes.update.name, false)
        }
        
        if(indexOf(permissions, CRUDTypes.destroy.number ) !== -1) {
            this.handleGivePermission(resource, CRUDTypes.destroy.name, true)
        } else {
            this.handleGivePermission(resource, CRUDTypes.destroy.name, false)
        }
    }

    render() {
        const {localResources} = this.state
        const {group, isLoading} = this.props

        return (
            <>
                <div className="navbar" id="navbar-groups">
                    <div className="navbar-left">
                        <Typography className="navbar-title">Editare grup "{!isEmpty(group) && group.name}"</Typography>
                    </div>
                    <Link style={{textDecoration: 'none'}} to='/settings'>
                        <Button className="cancel-button">
                            <Typography className="cancel-button-title">Renunță</Typography>
                        </Button>
                    </Link>
                </div>
                <div id="groups-edit-main-div">
                    {!isLoading && !isEmpty(group) ? (
                        <Formik
                            initialValues={{groupName: group.name, users: 1}}
                            onSubmit={(values) => {
                                const {selectedEntityID, group} = this.props

                                let newGroupData = {
                                    id: group.id,
                                    entity_id: selectedEntityID,
                                    name: values.groupName,
                                    permissions_entity: getResourcePermissions(localResources.entity),
                                    permissions_projects:  getResourcePermissions(localResources.projects),
                                    permissions_clients: getResourcePermissions(localResources.clients),
                                    permissions_members: getResourcePermissions(localResources.members),
                                    permissions_plans: getResourcePermissions(localResources.plans)
                                }   

                                this.props.dispatch(RESOURCES.groups.update({...newGroupData}))
                            }}
                        >
                            {({handleChange, handleSubmit, values}) => (
                                <div className='edit-group-form'>
                                    <Input
                                        placeholder="Nume grup"
                                        className='group-name-input'
                                        value={values.groupName}
                                        onChange={handleChange('groupName')}
                                    />
                                    {/* <div className="users-input-div">
                                        <Typography className="users-input-text">Număr utilizatori:</Typography>
                                        <Input
                                            className='users-input'
                                            value={values.users}
                                            type='number'
                                            onChange={handleChange('users')}
                                        />
                                    </div> */}
                                    <PermissionsSelect 
                                        resources={localResources}
                                        handleGivePermission={this.handleGivePermission} 
                                    />
                                    <Button 
                                        fullWidth 
                                        size="large"
                                        variant="contained"
                                        className='save-button'
                                        onClick={handleSubmit}
                                    >
                                        <Typography className="button-title">Salvează</Typography>
                                    </Button>
                                </div>
                            )}
                        </Formik>
                    ) : isLoading ? (
                        <div className="center-loader">
                            <CircularProgress className="circular-progress" thickness={5} color="primary" />
                        </div>
                    ) : isEmpty(group) ? (
                        <Typography>Acest grup nu a fost găsit!</Typography>
                    ) : null}
                   
                </div>
            </>
        )
    }
}

const mapStateToProps = function(state) {
    return {
        group: state.groups.currentGroup,
        isLoading: state.groups.isLoading,
        selectedEntityID: state.localConfigs.selectedEntityID
    }
}

export default connect(mapStateToProps)(GroupEdit)