import React from 'react'

import * as serviceWorker from './serviceWorker'
import * as Sentry from '@sentry/react'

import {setHeaders} from 'avoapp-react-common/dist/redux/api'
import {AppTypes} from 'avoapp-react-common/dist/constants'

import {env, environmentTypes} from './assets/utils'

import App from './App'

import './index.scss'
import {createRoot} from 'react-dom/client'

setHeaders({'User-Platform': 'Web', 'User-App': AppTypes.MD})

Sentry.init({
    dsn: 'https://f0a3623f3dde420a98316435b071cc37@o336000.ingest.sentry.io/5236227',
    environment: process.env?.REACT_APP_ENV
})

if (env === environmentTypes.PROD) {
    console.log = function () {}
}

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
    <App />
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
