import React, {Component} from 'react'
import {CircularProgress, Typography} from '@material-ui/core'
import {Link} from 'react-router-dom'

import {values, toUpper} from 'lodash'

import {connect} from 'react-redux'
import {entityProfilesStatus} from 'avoapp-react-common/dist/constants'
import {getBelongingEntities} from 'avoapp-react-common/dist/redux/entityProfiles'
import {selectEntityID} from 'avoapp-react-common/dist/redux/selectedEntityID'
import {retrieveAndSetCurrent} from 'avoapp-react-common/dist/redux/entities'
import {getEntityName} from 'avoapp-react-common/dist/utils'

import '../assets/scss/EntitySelect.scss'

class EntitySelect extends Component {
    componentDidMount() {
        const {user} = this.props
        
        this.props.dispatch(getBelongingEntities(user.id))
    }

    isActive = (entity) => entity.profileStatus === entityProfilesStatus.ACTIVE.value

    handleEntitySelect = (entity) => {
        if(this.isActive(entity)) {
            this.props.dispatch(retrieveAndSetCurrent(entity.id))
            this.props.dispatch(selectEntityID(entity.id))
        }
    }
    
    render() {
        const{entities, isLoading} = this.props

        return (
            <div id="entity-select-main-div">
                <div className="container">
                    <div className="top">
                        <Typography className='header-text'>
                            Am observat ca faci parte din mai multe companii
                        </Typography>
                        <Typography className='header-text'>
                            Selectează compania în numele căreia vrei să folosești aplicația
                        </Typography>
                    </div>
                    <div className="bottom">
                        {!isLoading ? (
                            <div className="entities-list">
                                {entities.map((entity, index) => (
                                    <Link
                                        key={index} 
                                        to='/subscriptions'
                                        className={`entity-details ${!this.isActive(entity) && 'disabled'}`}
                                        onClick={() => this.handleEntitySelect(entity)}
                                    >
                                        <Typography className="entity-name">{getEntityName(entity)}</Typography>
                                        {!this.isActive(entity) && (
                                            <Typography className='inactive-text'>
                                                {entity.profileStatus === entityProfilesStatus.PENDING.value ? 
                                                    entityProfilesStatus.PENDING.action : 
                                                    entityProfilesStatus[toUpper(entity.profileStatus)].label}
                                            </Typography>
                                        )}
                                    </Link>
                                ))}
                            </div>
                        ) : (
                            <div className="loading">
                                <CircularProgress thickness={5} color="primary" />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = function(state) {
    return {
        user: state.users.user,
        isLoading:  state.entityProfiles.isLoading,
        entities: values(state.entityProfiles.belongingEntities)
    }
}

export default connect(mapStateToProps)(EntitySelect)
