import {Button, Input, Typography} from '@material-ui/core'
import {Form, Formik} from 'formik'
import React, {useState} from 'react'

import {values as _values, isArray, isEmpty} from 'lodash'

import {login} from 'avoapp-react-common/dist/redux/auth'
import {getErrorFromCode} from 'avoapp-react-common/dist/utils'
import {connect} from 'react-redux'

import ForgotPasswordModal from '../components/ForgotPasswordModal'
import PromoComponent from '../components/PromoComponent'

import '../assets/scss/Login.scss'

export const Login = ({authErrors, isLoading, login}) => {
    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false)

    return (
        <div className="login-main-div">
            <div className="left">
                <div className="logo-div">
                    <img 
                        src={require('../assets/images/monitor_dosare_logo_light.svg').default}
                        alt="monitordosare-logo" 
                    />
                </div>
                {!isEmpty(authErrors) && isEmpty(authErrors.detail) && _values(authErrors).map((errorCategories) =>
                    isArray(errorCategories) && errorCategories.map((error, index) => (
                        <div key={index} className='error-div'>
                            <Typography className='error-text'>{getErrorFromCode(error, 'login')}</Typography>
                        </div>
                    ))
                )}
                <Formik
                    initialValues={{email: '', password: ''}}
                    onSubmit={(values) => login(values.email, values.password)}
                >
                    {({handleChange, values, handleSubmit}) => (
                        <Form className="styled-form">
                            <Input
                                placeholder="Adresă de email"
                                value={values.email}
                                onChange={handleChange('email')}
                                className="input"
                                fullWidth
                            />
                            <Input
                                placeholder="Parolă"
                                value={values.password}
                                onChange={handleChange('password')}
                                className="input"
                                type="password"
                                fullWidth
                            />
                            <Button
                                variant="text"
                                onClick={() => setShowForgotPasswordModal(true)}
                                className="back-button"
                                size="small"
                            >
                                <Typography className="back-button-text">
                                    Am uitat parola. Vreau să o resetez.
                                </Typography>
                            </Button>
                            <Button
                                onClick={handleSubmit}
                                disabled={isLoading || isEmpty(values.email) || isEmpty(values.password)}
                                type="submit"
                                size="large"
                                variant="contained"
                                fullWidth
                            >
                                <Typography>Autentificare</Typography>
                            </Button>
                        </Form>
                    )}
                </Formik>
            </div>
            <div className="right">
                <PromoComponent />
            </div>
            <ForgotPasswordModal open={showForgotPasswordModal} handleClose={() => setShowForgotPasswordModal(false)} />
        </div>
    )
}

const mapStateToProps = (state) => ({
    authErrors: state.auth.errors,
    isLoading: state.auth.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    login: (email, password) => dispatch(login({email, password}))
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
