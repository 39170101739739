import React from 'react'

import {isEmpty} from 'lodash'

import {connect} from 'react-redux'
import {errorsModalTypes} from '../../../redux/errorsModal'

import ErrorsModal from '../ErrorsModal'

const GeneralErrorModal = ({open, message}) => {
    return (
        <ErrorsModal
            open={open}
            messageContent={() => (
                <p className="avo-errors-modal-message">
                    {!isEmpty(message) ? message : 'A apărut o eroare'}
                </p>
            )}
        />
    )
}

const mapStateToProps = (state) => ({
    open: state.errorsModal.type === errorsModalTypes.GENERAL_ERROR,
    message: state.errorsModal.message
})

export default connect(mapStateToProps)(GeneralErrorModal)