import React, {Component} from 'react'
import {Typography, Drawer, ButtonBase, CircularProgress} from '@material-ui/core'
import {Link} from 'react-router-dom'
import {ChevronRight, X} from 'react-feather'

import {Calendar, dateFnsLocalizer} from 'react-big-calendar'
import Toolbar from 'react-big-calendar/lib/Toolbar'

import {orderBy, toString, isEmpty} from 'lodash'

import {format, parse, startOfWeek, getDay, sub, add, startOfMonth, endOfMonth, isSameDay} from 'date-fns'
import ro from 'date-fns/locale/ro'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {changeTimeFormat} from 'avoapp-react-common/dist/utils'
import {connect} from 'react-redux'

import '../assets/scss/Calendar.scss'

const locales = {
    'ro': ro
}

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales
})

const messages = {
    allDay: 'Toată ziua',
    previous: '<',
    next: '>',
    today: 'Astăzi',
    month: 'Luna',
    week: 'Săptămână',
    day: 'Zi',
    agenda: 'Agendă',
    date: 'Data',
    time: 'Timp',
    event: 'Eveniment',
    showMore: total => `+ încă ${total}`
}

export class CalendarPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            date: new Date(),
            view: 'month',
            selectedDate: null,
            showDayDrawer: false
        }

    }

    componentDidMount() {

        let startOfRange = new Date(sub(new Date(startOfMonth(new Date())), {days: 6}))
        let endOfRange = new Date(add(new Date(endOfMonth(new Date())), {days: 6}))

        this.props.dispatch(
            RESOURCES.hearings.listAll({
                moment__gte: new Date(startOfRange),
                moment__lte: new Date(endOfRange)
            })
        )

    }

    handleChangeMonth= (start, end) => {
        this.props.dispatch(
            RESOURCES.hearings.listAll({
                moment__gte: new Date(start),
                moment__lte: new Date(end)
            })
        )
    }

    handleCloseDrawer = () => {
        this.setState({showDayDrawer: false, selectedDate: null})
    }

    render() {
        const {hearings, isLoading, events} = this.props
        const {selectedDate, showDayDrawer} = this.state

        return (
            <div id="calendar-main-div">
                <div className='calendar-div'>
                    <Calendar
                        onSelectEvent={(eventProps)=>{
                            this.setState({showDayDrawer: true, selectedDate: eventProps.start})
                        }}
                        onSelectSlot={(slot)=>{
                            this.setState({showDayDrawer: true, selectedDate: slot.start})
                        }}
                        onDrillDown={(date)=>{
                            this.setState({showDayDrawer: true, selectedDate: date})
                        }}
                        selectable={true}
                        components={{
                            toolbar: (props) => <CustomToolbar {...props} isLoading={isLoading}/>
                        }}
                        culture={'ro'}
                        className='calendar-style'
                        localizer={localizer}
                        events={events}
                        messages={messages}
                        views={['month']}
                        onRangeChange = { (range) => {
                            this.handleChangeMonth(range.start, range.end)
                        }}
                    />
                </div>
                <DayDrawer
                    hearings={hearings}
                    selectedDate={selectedDate}
                    showDayDrawer={showDayDrawer}
                    handleCloseDrawer={this.handleCloseDrawer}
                />
            </div>
        )
    }
}

class DayDrawer extends Component {
    render() {
        const {selectedDate, hearings, showDayDrawer, handleCloseDrawer} = this.props
        let localHeargins = hearings.filter(hearing=> isSameDay(new Date(hearing.moment), new Date(selectedDate)))

        return (
            <Drawer
                anchor="right"
                open={showDayDrawer}
                onClose={handleCloseDrawer}>
                <div id='day-drawer-main-div'>
                    <div className='day-drawer-header-div'>
                        <div className='placeholder-div' />
                        <Typography className='day-text'>
                            {format(new Date(selectedDate), 'dd LLLL', {locale: ro})}
                        </Typography>
                        <ButtonBase onClick={handleCloseDrawer}>
                            <X className='header-close-icon'/>
                        </ButtonBase>
                    </div>
                    { !isEmpty(localHeargins) ? (
                        <div className='hearing-cards-list'>
                            {
                                localHeargins.map((hearing, index) => (
                                    <Link
                                        className='hearing-card-link'
                                        key={index}
                                        to={`/projects/${hearing.project?.id}`}
                                    >
                                        <div className='hearing-card-header'>
                                            <Typography className='hearing-card-title'>
                                                {hearing.phase.number}
                                            </Typography>
                                            <ChevronRight className='hearing-card-arrow'/>
                                        </div>
                                        <div className='hearing-card-body'>
                                            <div className='hearing-card-detail-item'>
                                                <Typography className='hearing-detail-title'>Ora:</Typography>
                                                <Typography className='hearing-detail-value'>
                                                    {changeTimeFormat(hearing.time)}
                                                </Typography>
                                            </div>
                                            <div className='hearing-card-detail-item'>
                                                <Typography className='hearing-detail-title'>Instituția:</Typography>
                                                <Typography className='hearing-detail-value'>
                                                    {hearing.phase.court}
                                                </Typography>
                                            </div>
                                            <div className='hearing-card-detail-item'>
                                                <Typography className='hearing-detail-title'>Complet:</Typography>
                                                <Typography className='hearing-detail-value'>
                                                    {hearing.full_court}
                                                </Typography>
                                            </div>
                                        </div>
                                    </Link>
                                ))
                            }
                        </div>
                    ) : <div className='no-hearings-for-date-div'>
                        <Typography className='no-hearings-for-date-text'>
                            Nu există niciun termen pentru data selectată
                        </Typography>
                    </div>}
                </div>
            </Drawer>
        )
    }
}

class CustomToolbar extends Toolbar {
    goToBack = () => { this.props.onNavigate('PREV') };
    goToNext = () => { this.props.onNavigate('NEXT') };
    goToCurrent = () => { this.props.onNavigate('TODAY') };

    render() {
        const {isLoading, label, localizer} = this.props
        const {today, next, previous} = localizer.messages

        return (
            <div className='rbc-toolbar'>
                <div className='title-and-loader-div'>
                    <Typography className="page-title">Calendar</Typography>
                    {isLoading && <CircularProgress thickness={5} color="primary" />}
                </div>
                <Typography className="rbc-toolbar-label">{label}</Typography>
                <span className="rbc-btn-group">
                    <button type="button" onClick={() => this.goToCurrent()}>{today}</button>
                    <button type="button" onClick={() => this.goToBack()}>{previous}</button>
                    <button type="button" onClick={() => this.goToNext()}>{next}</button>
                </span>
            </div>
        )
    }
}

const mapStateToProps = function (state) {
    const hearings = orderBy(state.hearings.data, 'time')
    let events = []

    hearings.map((hearing) => (
        events.push({
            title: toString(hearing.phase.number),
            start: new Date(hearing.moment),
            end: new Date(hearing.moment),
            allDay: true,
            fullCourt: hearing.full_court,
            institution: hearing.phase.court
        })
    ))

    return {
        hearings: hearings,
        isLoading: state.hearings.isLoading,
        events: events
    }
}

export default connect(mapStateToProps)(CalendarPage)

