import {Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, Typography} from '@material-ui/core'
import {ArrowDropDown} from '@material-ui/icons'
import React, {Component} from 'react'

import {head, isEmpty, values} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {createFromLocalSubscriptions} from '../../redux/subscriptions'

export class ChooseExistingProject extends Component {
    constructor() { 
        super()

        this.state = {
            selectedProject: {}
        }
    }

    componentDidMount = () => {
        const{selectedEntityID} = this.props
        
        this.props.dispatch(RESOURCES.projects.listAll({entity_id: selectedEntityID, active: true}))
    };

    componentDidUpdate = (prevProps) => {
        const {selectedProject} = this.state
        const {projects, isLoading} = this.props

        if(isEmpty(prevProps.projects) && !isEmpty(projects) && !isLoading && isEmpty(selectedProject)) {            
            this.setState({selectedProject: head(projects).id})
        }
    };
    
    handleChooseExistingProject = () => {  
        const {selectedProject} = this.state
    
        this.props.dispatch(createFromLocalSubscriptions({project_id: selectedProject}))
    }

    render() {
        const {selectedProject} = this.state
        const {projects, isLoading} = this.props

        if(!isEmpty(projects)) {
            return (
                <div className="choose-existing-project">
                    <Typography className='title'>Alege un proiect existent</Typography>
                    {!isEmpty(projects) && !isLoading ? (
                        <FormControl className='styled-form-control'>
                            <InputLabel id="project-select">Proiect</InputLabel>
                            <Select
                                labelId="project-select"
                                value={selectedProject}
                                IconComponent={() => <ArrowDropDown className='dropdown-icon' />}
                                onChange={(event) => this.setState({selectedProject: event.target.value})}
                            >
                                {projects.map((project) => 
                                    <MenuItem key={project.id} value={project.id}>
                                        {project.name}
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    ) : !isLoading && isEmpty(projects) ? (
                        <Typography className='no-data-text'>
                            Se pare că nu ai adăugat nici un proiect
                        </Typography>
                    ) : isLoading ? (
                        <CircularProgress
                            className="circular-progress"
                            color="primary"
                            thickness={5} 
                        />
                    ) : null}
                    <Button
                        onClick={this.handleChooseExistingProject} 
                        disabled={isLoading}
                        variant="contained"
                        size="large"
                        fullWidth
                    >
                        <Typography className="button-title">Adaugă la proiectul existent</Typography>
                    </Button>
                </div>
            )
        } 
        
        return null
          
    }
}

const mapStateToProps = (state) => ({
    projects: values(state.projects.data),
    isLoading: state.projects.isLoading,
    selectedEntityID: state.localConfigs.selectedEntityID
})

export default connect(mapStateToProps )(ChooseExistingProject)
