import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'

import {Button, FormControl, Input, InputLabel, Typography} from '@material-ui/core'
import {ArrowDropDown} from '@material-ui/icons'
import {DatePicker, MuiPickersUtilsProvider, TimePicker} from '@material-ui/pickers'
import {Formik} from 'formik'

import DateFnsUtils from '@date-io/date-fns'
import {lightFormat} from 'date-fns'
import {isEmpty, isNull, values} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {getErrorFromCode} from 'avoapp-react-common/dist/utils'
import {connect} from 'react-redux'

import '../assets/scss/ManualAdd.scss'

const ManualHearingAdd = ({
    phase,
    match: {params: {phaseID}},
    retrievePhase,
    isLoading,
    hearingsErrors, 
    createHearing,
    currentSubscription
}) => {
    useEffect(() => {
        if(isEmpty(phase)) {
            retrievePhase(retrievePhase)
        }
    }, [phase, retrievePhase])

    return (
        <div>
            <div className="navbar" id="navbar-manual-add">
                <div className="navbar-left">
                    <Typography className="navbar-title">Adaugă un termen pentru dosarul "{phase?.number}"</Typography>
                </div>
                <div className="navbar-right">
                    {currentSubscription.id && (
                        <Link style={{textDecoration: 'none'}} to={`/subscriptions/${currentSubscription.id}`}>
                            <Button className="cancel-button">
                                <Typography className="button-title">Renunță</Typography>
                            </Button>
                        </Link>
                    )}
                </div>
            </div>
            <div id="manual-add-main-div">
                <Formik
                    initialValues={{
                        date: new Date(),
                        time: new Date(),
                        solution: '',
                        solutionSummary: '',
                        fullCourt: '',
                        documentDate: null,
                        pronouncementDate: null,
                        hearingDocument: ''
                    }}
                    onSubmit={(values) => {
                        let solution = isEmpty(values.solution) ? null : values.solution
                        let solutionSummary = isEmpty(values.solution_summary) ? null : values.solution_summary

                        createHearing({
                            phase_id: phaseID,
                            date: lightFormat(values.date, 'yyyy-MM-dd'),
                            solution: solution,
                            solution_summary: solutionSummary,
                            time: lightFormat(values.time, 'HH:mm'),
                            full_court: values.fullCourt,
                            document_date: !isNull(values.documentDate) ?  
                                lightFormat(values.documentDate, 'yyyy-MM-dd') :
                                null,
                            pronouncement_date: !isNull(values.pronouncementDate) ?  
                                lightFormat(values.pronouncementDate, 'yyyy-MM-dd') :
                                null,
                            hearing_document: values.hearingDocument
                        })
                    }}
                >
                    {({handleChange, handleSubmit, values, setFieldValue}) => (
                        <div className='form'>  
                            {!isEmpty(hearingsErrors) && hearingsErrors.map((errorCategories) =>
                                errorCategories.map((error, index) => (
                                    <div key={index} className='error-div'>
                                        <Typography className='error-text'>
                                            {getErrorFromCode(error, 'hearings')}
                                        </Typography>
                                    </div>
                                ))
                            )}
                            <div className="section">
                                <div className="section-content">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <FormControl className="project-add-form-control date-select">
                                            <DatePicker
                                                label="Data *"
                                                value={values.date}
                                                onChange={(value) => {setFieldValue('date', value)}}
                                                InputProps={{disableUnderline: true}}
                                                format="dd/MM/yyyy"
                                                className="date-picker"
                                                cancelLabel="Închide"
                                                okLabel="Ok"
                                            />
                                            <ArrowDropDown className='dropdown-icon' />
                                        </FormControl>
                                    </MuiPickersUtilsProvider>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <TimePicker
                                            label="Ora *"
                                            className='time-input'
                                            value={values.time} 
                                            format="HH:mm"
                                            onChange={(newTime)=>{
                                                setFieldValue('time', newTime)
                                            }}
                                            cancelLabel='Renunță'
                                        />
                                    </MuiPickersUtilsProvider>
                                    <FormControl className='input-form-control'>
                                        <InputLabel shrink={!isEmpty(values.fullCourt)}>
                                            Complet
                                        </InputLabel>
                                        <Input
                                            value={values.fullCourt}
                                            onChange={handleChange('fullCourt')}
                                            className="input"
                                        />
                                    </FormControl>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <FormControl className="project-add-form-control date-select">
                                            <DatePicker
                                                label="Data document"
                                                value={values.documentDate}
                                                onChange={(value) => {setFieldValue('documentDate', value)}}
                                                InputProps={{disableUnderline: true}}
                                                format="dd/MM/yyyy"
                                                className="date-picker"
                                                cancelLabel="Închide"
                                                okLabel="Ok"
                                            />
                                            <ArrowDropDown className='dropdown-icon' />
                                        </FormControl>
                                    </MuiPickersUtilsProvider>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <FormControl className="project-add-form-control date-select">
                                            <DatePicker
                                                label="Data ședință"
                                                value={values.pronouncementDate}
                                                onChange={(value) => {setFieldValue('pronouncementDate', value)}}
                                                InputProps={{disableUnderline: true}}
                                                format="dd/MM/yyyy"
                                                className="date-picker"
                                                cancelLabel="Închide"
                                                okLabel="Ok"
                                            />
                                            <ArrowDropDown className='dropdown-icon' />
                                        </FormControl>
                                    </MuiPickersUtilsProvider>
                                    <FormControl className='input-form-control'>
                                        <InputLabel shrink={!isEmpty(values.hearingDocument)}>
                                            Document ședință
                                        </InputLabel>
                                        <Input
                                            value={values.hearingDocument}
                                            onChange={handleChange('hearingDocument')}
                                            className="input"
                                        />
                                    </FormControl>
                                    <FormControl className='input-form-control'>
                                        <InputLabel shrink={!isEmpty(values.solution)}>
                                            Soluție
                                        </InputLabel>
                                        <Input
                                            value={values.solution}
                                            onChange={handleChange('solution')}
                                            className="input"
                                        />
                                    </FormControl>
                                    <FormControl className='input-form-control'>
                                        <InputLabel shrink={!isEmpty(values.solutionSummary)}>
                                            Sumar soluție
                                        </InputLabel>
                                        <Input
                                            value={values.solutionSummary}
                                            onChange={handleChange('solutionSummary')}
                                            multiline
                                            rows={4}
                                            className="input"
                                        />
                                    </FormControl>
                                    <Typography className="disclaimer">
                                        * - câmpuri obligatorii
                                    </Typography>
                                </div>
                            </div>
                            <div className="buttons-div">
                                <Button
                                    onClick={handleSubmit}
                                    disabled={isLoading || isNull(values.date) || isNull(values.time)}
                                    className='submit-button'
                                    variant='contained'
                                    size='large'
                                    fullWidth
                                >
                                    <Typography className="button-title">Salvează termen</Typography>
                                </Button>
                            </div>
                        </div>
                    )}
                </Formik>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    phase: state.phases.currentPhase,
    isLoading: state.hearings.isLoading,
    hearingsErrors: values(state.hearings.errors),
    currentSubscription: state.subscriptions.currentSubscription
})

const mapDispatchToProps = (dispatch, props) => {
    const {phaseID} = props.match.params

    return {
        retrievePhase: () => dispatch(RESOURCES.phases.retrieve(phaseID)),
        createHearing: (values) => dispatch(RESOURCES.hearings.create(values))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManualHearingAdd)
