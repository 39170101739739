import React, {Component} from 'react'
import {connect} from 'react-redux'
import {isEmpty, isNull} from 'lodash'

import SubscriptionChangeNavbar from '../components/SubsctiptionChangeNavbar'
import EntitiesUpdateForm from '../components/EntitiesUpdateForm'

import {isDowngradeFlow} from '../assets/utils'

import '../assets/scss/SubscriptionBillingData.scss'

class SubscriptionBillingData extends Component {
    componentDidMount() {
        const {selectedPlan} = this.props
        
        if(isEmpty(selectedPlan)){
            this.props.history.push('/subscription-plans')
        }
    }

    render() {
        const {selectedPlan} = this.props
        const downgradeFlow = isDowngradeFlow(selectedPlan)

        return (
            <div>
                <SubscriptionChangeNavbar 
                    activeStep={!isNull(downgradeFlow) ? 0 : 1}
                    isDowngradeFlow={downgradeFlow}
                    goToPlans={()=>this.props.history.push('/subscription-plans')}
                />
                <div id="billing-main-div">
                    <EntitiesUpdateForm />
                </div>
            </div>
        )
    }
}

const mapStateToProps = function (state) {
    return {
        selectedPlan: state.billing.data.selectedPlan
    }
}

export default connect(mapStateToProps)(SubscriptionBillingData)