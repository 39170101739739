import {Button, Typography} from '@material-ui/core'
import React, {Component} from 'react'
import {ChevronLeft, ChevronRight, Layers, X} from 'react-feather'
import {Link} from 'react-router-dom'

import {isEmpty, isNull, values} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'

import ActivityIndicatorComponent from '../components/ActivityIndicatorComponent'
import AppealsSection from '../components/AppealsSection'
import HearingsSection from '../components/HearingsSection'
import PartiesSection from '../components/PartiesSection'

import {ArchiveSubscriptionDialog, DeleteSubscriptionDialog} from '../components/SubscriptionDetails/Dialogs'
import SubscriptionIsSyncingModal from '../components/SubscriptionIsSyncingModal'

import '../assets/scss/SubscriptionDetails.scss'

class SubscriptionDetails extends Component {
    constructor() {
        super()

        this.state = {
            showDeleteSubscriptionDialog: false,
            showArchiveSubscriptionDialog: false
        }
    }

    componentDidMount() {
        const {subscriptionID} = this.props.match.params

        this.props.dispatch(RESOURCES.subscriptions.retrieve(subscriptionID))
        this.props.dispatch(RESOURCES.parties.list({subscription_id:subscriptionID}))
        this.props.dispatch(RESOURCES.appeals.list({subscription_id:subscriptionID}))
        this.props.dispatch(RESOURCES.hearings.list({subscription_id:subscriptionID}))
    }

    handleOpenDeleteSubscriptionDialog = () => this.setState({showDeleteSubscriptionDialog: true})

    handleCloseDeleteSubscriptionDialog = () => this.setState({showDeleteSubscriptionDialog: false})

    handleGoBack = () => {
        const {routerLocations, subscription} = this.props

        if(routerLocations.length >= 2) { 
            let prevRoute = routerLocations[routerLocations.length - 2].location
    
            if(
                prevRoute.pathname=== '/subscriptions/add' || 
                prevRoute.pathname === `/project/${subscription.project.id}/add-subscription`
            ) { 
                this.props.history.push('/subscriptions')
            } else if(prevRoute.pathname=== '/subscriptions') {
                if(subscription.active) {
                    this.props.history.push('/subscriptions', {subscriptionsActive: true})
                } else {
                    this.props.history.push('/subscriptions', {subscriptionsActive: false})
                }
            } else {
                this.props.history.goBack()
            }
        } else {
            this.props.history.goBack()
        }
    }

    handleCloseArchiveSubscriptionDialog = () => {this.setState({showArchiveSubscriptionDialog: false})}

    render() {
        const {subscription, isLoading} = this.props
        const {subscriptionID} = this.props.match.params
        const {showDeleteSubscriptionDialog, showArchiveSubscriptionDialog} = this.state

        return (
            <>
                {!isEmpty(subscription) && !isLoading ? (
                    <>
                        <div className="navbar" id="navbar-subscription-details">
                            <div className="navbar-left">
                                <Button className="back-button" onClick={this.handleGoBack}>
                                    <ChevronLeft className="back-button-icon" size={16} />
                                    <Typography className="back-button-title">Înapoi</Typography>
                                </Button>
                                <div className="navbar-title-div">
                                    <Typography className="navbar-title">{subscription.litigation_number}</Typography>
                                    <div className="status-div">
                                        <Typography className="status-text">Status: </Typography>
                                        {subscription.active ? (
                                            <div className="active-div">
                                                <div className="green-circle" />
                                                <Typography className="active-text">Activ</Typography>
                                            </div>
                                        ) : (
                                            <Typography>Inactiv</Typography>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="navbar-right">
                                <div className="navbar-actions-buttons-div">
                                    <div className="action-button">
                                        {subscription.active ? (
                                            <Button
                                                onClick={() => this.setState({showArchiveSubscriptionDialog: true})}
                                                variant="text"
                                                fullWidth
                                            >
                                                <Typography className="action-button-text">
                                                    Arhivează
                                                </Typography>
                                                <Layers size={16} className="action-button-icon" />
                                            </Button>
                                        ) : (
                                            <Button
                                                onClick={() => 
                                                    this.props.dispatch(RESOURCES.subscriptions.update({
                                                        id: subscription.id,
                                                        active: true
                                                    }))
                                                }
                                                variant="text"
                                                fullWidth
                                            >
                                                <Typography className="action-button-text">
                                                    Dezarhivează
                                                </Typography>
                                                <Layers size={16} className="action-button-icon" />
                                            </Button>
                                        )}
                                        <ArchiveSubscriptionDialog
                                            showArchiveSubscriptionDialog={showArchiveSubscriptionDialog}
                                            handleCloseArchiveSubscriptionDialog={
                                                this.handleCloseArchiveSubscriptionDialog
                                            }
                                            handleArchiveSubscription={() => {
                                                this.props.dispatch(RESOURCES.subscriptions.update({
                                                    id: subscription.id,
                                                    active: false
                                                }))
                                                this.handleCloseArchiveSubscriptionDialog()
                                            }}
                                            subscriptionToArchive={subscription}
                                        />
                                    </div>
                                    <div className="action-button">
                                        <Link 
                                            to={`/projects/${subscription.project_id}`} 
                                            style={{textDecoration: 'none'}} 
                                        >
                                            <Button variant="text" fullWidth>
                                                <Typography className="action-button-text">
                                                    Vezi proiectul
                                                </Typography>
                                                <ChevronRight size={16} className="action-button-icon" />
                                            </Button>
                                        </Link>
                                    </div>
                                    <div className="action-button">
                                        <Button
                                            onClick={this.handleOpenDeleteSubscriptionDialog}
                                            variant="text"
                                            fullWidth
                                        >
                                            <Typography className="action-button-text delete-text">
                                                Șterge
                                            </Typography>
                                            <X size={16} className="action-button-icon delete-icon" />
                                        </Button>
                                        <DeleteSubscriptionDialog
                                            showDeleteSubscriptionDialog={showDeleteSubscriptionDialog}
                                            handleCloseDeleteSubscriptionDialog={
                                                this.handleCloseDeleteSubscriptionDialog
                                            }
                                            handleDeleteSubscription={() => {
                                                this.props.dispatch(RESOURCES.subscriptions.destroy(subscription))
                                                this.props.history.push('/subscriptions')
                                                this.handleCloseDeleteSubscriptionDialog()
                                            }}
                                            subscriptionToDelete={subscription}
                                        />
                                    </div>
                                </div>
                            </div>                                
                        </div>
                        <div id="subscription-details-main-div">
                            <SubscriptionIsSyncingModal
                                showModal={isNull(subscription.litigation.sync_moment)}
                                syncingLitigationNumber={subscription.litigation_number}
                            />
                            <PartiesSection subscriptionID={subscriptionID} sectionNumber={1} addingAllowed />
                            <AppealsSection subscriptionID={subscriptionID} sectionNumber={2} addingAllowed />
                            <HearingsSection subscriptionID={subscriptionID} sectionNumber={3} addingAllowed />
                        </div>
                    </>
                ) : (
                    <ActivityIndicatorComponent />
                )}
            </>
        )
    }
}

const mapStateToProps = function (state) {
    return {
        subscription: state.subscriptions.currentSubscription,
        isLoading:state.subscriptions.isLoading,
        routerLocations: values(state.routerLocations)
    }
}

export default connect(mapStateToProps)(SubscriptionDetails)
