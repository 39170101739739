import React from 'react'

import {connect} from 'react-redux'
import {errorsModalTypes} from '../../../redux/errorsModal'

import ErrorsModal from '../ErrorsModal'

const ForbiddenModal = ({open}) => (
    <ErrorsModal
        open={open}
        messageContent={() => (
            <p className="avo-errors-modal-message">
                Accesul interzis. Dacă crezi ca e o eroare, te rugăm sa ne{' '}
                <a href='/support'>contactezi</a>!
            </p>
        )}
    />
)

const mapStateToProps = (state) => ({
    open: state.errorsModal.type === errorsModalTypes.FORBIDDEN
})

export default connect(mapStateToProps)(ForbiddenModal)