import React, {Component} from 'react'
import {CircularProgress} from '@material-ui/core'

import '../assets/scss/_circular-progress.scss'

export class ActivityIndicatorComponent extends Component {
    render() {
        return (
            <div id="circular-progress-div">
                <CircularProgress className="circular-progress" thickness={5} color="primary" />
            </div>
        )
    }
}

export default ActivityIndicatorComponent
