import {takeEvery} from 'redux-saga/effects'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import {toast} from 'react-toastify'

const {companies: resource} = RESOURCES

function handleRetrieveCompanySuccess() {
    toast.success('Datele companiei au fost preluate cu succes.')
}

function handleRetrieveCompanyFail() {
    toast.error('Compania nu a fost găsită.')
}

export function* saga() {
    yield takeEvery(resource.actions.retrieve.success, handleRetrieveCompanySuccess)
    yield takeEvery(resource.actions.retrieve.fail, handleRetrieveCompanyFail)
}
