import React, {Component} from 'react'
import {Container, Typography} from '@material-ui/core'
import {ArrowBack} from '@material-ui/icons'

import {isEmpty} from 'lodash'

import {connect} from 'react-redux'
import {confirmEntityProfile} from 'avoapp-react-common/dist/redux/entityProfiles'

import WaitComponent from '../components/WaitComponent'

import '../assets/scss/AccountActivation.scss'

class EntityProfileConfirm extends Component {

    componentDidMount() {
        const {
            confirmEntityProfileUID,
            confirmEntityProfileToken,
            confirmEntityProfileStatus
        } = this.props.match.params

        this.props.dispatch(confirmEntityProfile({
            uid: confirmEntityProfileUID,
            token: confirmEntityProfileToken,
            status: confirmEntityProfileStatus
        }))
    }

    render() {
        const {isLoading, entityProfilesErrors} = this.props

        return (
            <Container className="main-container">
                <div className="logo-div">
                    <img src={require('../assets/images/monitor_dosare_logo_dark.svg').default} alt="monitor-dosare" />
                </div>
                {isLoading ? (
                    <WaitComponent />
                ) : (
                    !isEmpty(entityProfilesErrors) ? <ErrorComponent /> : <SuccessComponent />
                )}
            </Container>
        )
    }
}

class ErrorComponent extends Component {
    render() {
        return (
            <>
                <Typography>A apărut o eroare</Typography>
                <a className="back-button" href="/">
                    <div className="icon-div"><ArrowBack /></div>
                    <Typography className="back-button-text">Mergi la pagina principală</Typography>
                </a>
            </>
        )
    }
}

class SuccessComponent extends Component {
    render() {
        return (
            <>
                <Typography>Cofirmat cu succes!</Typography>
                <Typography>Vei fi redirecționat la pagina principală.</Typography>  
            </>
        )
    }
}

const mapStateToProps = function(state) {
    return {
        isLoading: state.entityProfiles.isLoading,
        entityProfilesErrors: state.entityProfiles.confirmErrors
    }
}

export default connect(mapStateToProps)(EntityProfileConfirm)
