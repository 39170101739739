import React, {Component} from 'react'
import {Container, Typography} from '@material-ui/core'
import {ArrowBack} from '@material-ui/icons'

import {isEmpty} from 'lodash'

import {connect} from 'react-redux'
import {confirmContactPerson} from 'avoapp-react-common/dist/redux/contactPersons'

import WaitComponent from '../components/WaitComponent'

import '../assets/scss/AccountActivation.scss'

class ContactPersonConfirm extends Component {
    componentDidMount() {
        const {
            confirmContactPersonUID,
            confirmContactPersonToken,
            confirmContactPersonResponse
        } = this.props.match.params

        this.props.dispatch(confirmContactPerson({
            uid: confirmContactPersonUID,
            token: confirmContactPersonToken,
            response: confirmContactPersonResponse
        }))
    }

    render() {
        const {isLoading, contactPersonsErrors} = this.props

        return (
            <Container className="main-container">
                <div className="logo-div">
                    <img src={require('../assets/images/monitor_dosare_logo_dark.svg').default} alt="monitor-dosare" />
                </div>
                {isLoading ? (
                    <WaitComponent />
                ) : (
                    !isEmpty(contactPersonsErrors) ? <ErrorComponent /> : <SuccessComponent />
                )}
            </Container>
        )
    }
}

function ErrorComponent() {
    return (
        <>
            <Typography>A apărut o eroare</Typography>
            <a href="/" className="back-button">
                <div className="icon-div"><ArrowBack /></div>
                <Typography className="back-button-text">Mergi la pagina principală</Typography>
            </a>
        </>
    )
}

function SuccessComponent() {
    return (
        <>
            <Typography>Cofirmat cu succes!</Typography>
            <Typography>Vei fi redirecționat la pagina principală.</Typography>  
        </>
    )
}

const mapStateToProps = function(state) {
    return {
        isLoading: state.contactPersons.isLoading,
        contactPersonsErrors: state.contactPersons.errors
    }
}

export default connect(mapStateToProps)(ContactPersonConfirm)
