import {put, select, take, takeEvery} from 'redux-saga/effects'
import {toast} from 'react-toastify'

import {toLower, camelCase, find, values} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {permissionRoles} from 'avoapp-react-common/dist/constants'
import {getPermissionsFromNumbers} from 'avoapp-react-common/dist/utils'
import {
    ACTIONS as PROJ_PERM_ACTIONS, 
    saveCurrentProjectPermissions
} from 'avoapp-react-common/dist/redux/projectPermissions'

const {projectPermissions: resource} = RESOURCES

function handleCreateProjectPermissionSuccess(response) {
    const addedRole = permissionRoles[camelCase(response.payload.role)].label || response.role
    toast.success(`Un ${toLower(addedRole)} a fost adăugat cu succes.`)
}

function* handleGetCurrentProjectPermissions(params) {
    let {entity_profile_id, project_id} = params.payload

    let permissions = {}
    let permissionsSubscriptions = {}

    yield put(RESOURCES.projectPermissions.list({project_id: project_id}))
    yield take(RESOURCES.projectPermissions.actions.list.success)
    
    let currentProjectPermissions = yield select((state) => 
        find(values(state.projectPermissions.data), (permission) => {
            return permission.project_id === project_id && permission.entity_profile_id === entity_profile_id 
        })
    )
    
    if(currentProjectPermissions) {
        permissions = getPermissionsFromNumbers(currentProjectPermissions.permissions)
        permissionsSubscriptions = getPermissionsFromNumbers(currentProjectPermissions.permissions_subscriptions)
    } 

    yield put(saveCurrentProjectPermissions(permissions, permissionsSubscriptions))
}

export function* saga() {
    yield takeEvery(resource.actions.create.success, handleCreateProjectPermissionSuccess)
    yield takeEvery(PROJ_PERM_ACTIONS.GET_CURRENT_PROJECT_PERMISSIONS.main, handleGetCurrentProjectPermissions)
}