import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    Typography
} from '@material-ui/core'
import React, {Component} from 'react'

export class ArchiveSubscriptionDialog extends Component {
    render() {
        const {
            showArchiveSubscriptionDialog,
            handleCloseArchiveSubscriptionDialog,
            handleArchiveSubscription,
            subscriptionToArchive
        } = this.props

        return (
            <Dialog
                className='archive-subscription-dialog'
                open={showArchiveSubscriptionDialog}
                onClose={handleCloseArchiveSubscriptionDialog}
            >
                <DialogTitle>
                    Ești sigur că vrei să arhivezi abonarea la date pentru dosarul 
                    "{subscriptionToArchive.litigation_number}"?
                </DialogTitle>
                <DialogActions className='buttons-div'>
                    <Button
                        onClick={handleCloseArchiveSubscriptionDialog}
                        className='close-button'
                    >
                        <Typography className='button-text'>Nu, renunță</Typography>
                    </Button>
                    <Button
                        className='action-button'
                        onClick={() => handleArchiveSubscription(subscriptionToArchive)}
                        autoFocus
                    >
                        <Typography className='button-text'>Da, arhivează</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export class DeleteSubscriptionDialog extends Component {
    render() {
        const {
            showDeleteSubscriptionDialog,
            handleCloseDeleteSubscriptionDialog,
            handleDeleteSubscription,
            subscriptionToDelete
        } = this.props

        return (
            <Dialog 
                className='delete-subscription-dialog'
                open={showDeleteSubscriptionDialog}
                onClose={handleCloseDeleteSubscriptionDialog}
            >
                <DialogTitle>Vrei sa stergi sursa de date pentru {subscriptionToDelete.litigation_number}?</DialogTitle>
                <DialogActions className='buttons-div'>
                    <Button 
                        onClick={handleCloseDeleteSubscriptionDialog}
                        className='close-button'
                    >
                        <Typography className='button-text'>Nu, renunță</Typography>
                    </Button>
                    <Button
                        className='action-button'
                        onClick={handleDeleteSubscription}
                        autoFocus
                    >
                        <Typography className='button-text'>Da, șterge</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

