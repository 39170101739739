import React, {Component} from 'react'
import {Typography, Button, CircularProgress} from '@material-ui/core'
import {Link} from 'react-router-dom'
import {Edit2} from 'react-feather'

import {isEmpty, head, isNull} from 'lodash'

import {connect} from 'react-redux'
import {openPromoModal} from '../redux/promoCodeUsages'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {EntityTypes, PaymentTypes} from 'avoapp-react-common/dist/constants'
import {checkEntityPlan} from 'avoapp-react-common/dist/redux/entityPlans'

import {isDowngradeFlow} from '../assets/utils'

import SubscriptionChangeNavbar from '../components/SubsctiptionChangeNavbar'
import PromoCodeModal from '../components/PromoCodeModal'

import '../assets/scss/SubscriptionConfirm.scss'

class SubscriptionConfirm extends Component {
    componentDidMount() {
        const{selectedPlan, selectedEntityID} = this.props

        if(!isEmpty(selectedPlan)) {
            this.props.dispatch(checkEntityPlan({
                entity_id: selectedEntityID,
                plan_id: selectedPlan.id,
                charging_interval_in_months: this.getChargingInterval()
            }))
        } else {
            this.props.history.push('/subscription-plans')
        }
    }

    handleOpenPromoModal = () => {
        this.props.dispatch(openPromoModal())
    }
    
    getChargingInterval = () => {
        const{selectedPlan, selectedInterval} = this.props

        if(!isEmpty(selectedInterval)) {
            return selectedInterval.charging_interval_in_months
        }

        return head(selectedPlan.duration_discounts).charging_interval_in_months
    }

    getSaveButtonText = () => {
        const payment = this.props.planOverview.latest_payment

        if (payment.type === 'downgrade') {
            return <Typography>Salvează modificările abonamentului</Typography> 
        }

        return <Typography>Trimite factura proformă</Typography> 
    }

    getDiscountUnit = () => {
        const {planOverview} = this.props
        const discountType = planOverview.latest_payment.discount.unit

        if(discountType === 'percent') {
            return '%'
        } 

        return ' RON'
    }

    getDiscount = () => {
        const {planOverview, selectedPlan} = this.props
        const payment = planOverview.latest_payment

        if(isDowngradeFlow(selectedPlan)) {
            return null
        }
        
        return ( 
            <>
                {!isNull(payment.discount) && 
                    <div className='discount-div'>
                        <Typography className='discount-text'>
                            Discount: <span>{payment.discount.amount.replace('.00', '')}{this.getDiscountUnit()}</span> 
                        </Typography>
                    </div>
                }
                <Button className='discount-code-modal-button' onClick={this.handleOpenPromoModal}>
                    <Typography className='discount-text'>Ai cod de reducere?</Typography>
                </Button>
            </>
        )
    }

    handleConfirm = () =>{
        const {planOverview, selectedEntityID} = this.props

        this.props.dispatch(RESOURCES.entityPlans.create({
            entity_id: selectedEntityID,
            plan_id: planOverview.plan.id,
            charging_interval_in_months: planOverview.charging_interval_in_months
        }))
    }

    render() {
        const {planOverview, isLoading, currentEntity, selectedPlan} = this.props

        const downFlow = isDowngradeFlow(selectedPlan)
        
        return (
            <div>
                <SubscriptionChangeNavbar 
                    activeStep={downFlow ? 1 : 2}
                    isDowngradeFlow={downFlow}
                    goToPlans={()=>this.props.history.push('/subscription-plans')}
                />
                <div id="subscription-confirm-main-div">
                    {!isLoading && !isEmpty(planOverview) ? (
                        <div className="container">
                            <div className="selected-plan-info">
                                <div className="info-header">
                                    <Typography className="confirm-title">Confirmare</Typography>
                                    <Link to='/billing' className='change-billing-data-link'>
                                        <Edit2 size={14} className='change-billing-data-icon'/>
                                        <Typography className='change-billing-data-text'>Modifică date</Typography>
                                    </Link>
                                </div>
                                <div className="content-div">
                                    {currentEntity.type === EntityTypes.PERSON ? (
                                        <div>
                                            <Typography className="section-title">Date De facturare</Typography>
                                            <div className="info-list">
                                                <ItemDiv title={'Nume'} value={currentEntity.last_name}/>
                                                <ItemDiv title={'Prenume'} value={currentEntity.first_name}/>
                                                <ItemDiv title={'Email'} value={currentEntity.email}/>
                                                <ItemDiv title={'Telefon'} value={currentEntity.phone}/>
                                                <ItemDiv title={'Țara'} value={currentEntity.country}/>
                                                <ItemDiv title={'Județ'} value={currentEntity.county}/>
                                                <ItemDiv title={'Localitate'} value={currentEntity.locality}/>
                                                <ItemDiv title={'Adresă'} value={currentEntity.address}/>
                                            </div>
                                        </div>
                                    ):(
                                        <>
                                            <div className="left">
                                                <Typography className="section-title">Date De facturare</Typography>
                                                <div className="info-list">
                                                    <ItemDiv title={'Nume'} value={currentEntity.name}/>
                                                    <ItemDiv title={'CUI'} value={currentEntity.vat_number}/>
                                                    <ItemDiv 
                                                        title={'Nr. înreg.'} 
                                                        value={currentEntity.trade_register_number}
                                                    />
                                                    <ItemDiv title={'Țara'} value={currentEntity.country}/>
                                                    <ItemDiv title={'Județ'} value={currentEntity.county}/>
                                                    <ItemDiv title={'Localitate'} value={currentEntity.locality}/>
                                                    <ItemDiv title={'Adresă'} value={currentEntity.address}/>

                                                </div>
                                            </div>
                                            <div className="right">
                                                <Typography className="section-title">Date De Contact</Typography>
                                                <div className="info-list">
                                                    <ItemDiv title={'Nume'} value={currentEntity.last_name}/>
                                                    <ItemDiv title={'Prenume'} value={currentEntity.first_name}/>
                                                    <ItemDiv title={'Email'} value={currentEntity.email}/>
                                                    <ItemDiv title={'Telefon'} value={currentEntity.phone}/>
                                                </div>
                                            </div>
                                        </>
                                    )} 
                                </div>
                            </div>
                            <div className="price-div">
                                <div className="about-plan-div">
                                    <Typography className="selected-plan-text">
                                        Ai ales planul <br /> MD{planOverview.plan.name}
                                    </Typography>
                                    <PriceComponent selectedPlan={selectedPlan} planOverview={planOverview} />
                                </div>
                                <div className="button-div">
                                    <PromoCodeModal />
                                    {this.getDiscount()}
                                    <Button
                                        onClick={this.handleConfirm}
                                        size="large"
                                        variant="contained"
                                        fullWidth
                                    >
                                        {this.getSaveButtonText()}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="container">
                            <CircularProgress className="circular-progress" thickness={5} color="primary" />
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

class ItemDiv extends Component {
    render() {
        const {title, value} = this.props
        
        return (
            <div className="info-div">
                <Typography className="title">{title}</Typography>
                <Typography className="value">{value}</Typography>
            </div>
        )
    }
}

class PriceComponent extends Component {
    render() {
        const {selectedPlan, planOverview} = this.props
        const {calculated_price} = selectedPlan
        const payment = planOverview.latest_payment

        if (payment.type === PaymentTypes.DOWNGRADE) {
            return (
                <>
                    <Typography className='price-text'>Total de plată</Typography>
                    <Typography className='price'>0 EUR (0 RON)</Typography>
                    {calculated_price.downgrade_RON ? (
                        <>
                            <Typography className='price-text'>Credite</Typography>
                            <Typography className='price'> 
                                {calculated_price.downgrade_RON} RON
                            </Typography>
                        </>
                    ) : null}
                </> 
            )
        } else {
            return (
                <>
                    <Typography className='price-text'>Total de plată</Typography>
                    <Typography className='price'>
                        {payment.amount_final_RON} RON
                    </Typography>
                </>
            )
        }

    }
}

const mapStateToProps = function(state) {
    return {
        selectedEntityID: state.localConfigs.selectedEntityID,
        currentEntity: state.entities.currentEntity,
        planOverview: state.entityPlans.planOverview,
        isLoading:  state.entityPlans.isLoading,
        selectedPlan: state.billing.data.selectedPlan,
        selectedInterval: state.billing.data.selectedInterval
    }
}

export default connect(mapStateToProps)(SubscriptionConfirm)