import React, {Component} from 'react'
import {Container, Typography} from '@material-ui/core'

import {connect} from 'react-redux'
import {activateAccount} from 'avoapp-react-common/dist/redux/auth'

import WaitComponent from '../components/WaitComponent'

import '../assets/scss/AccountActivation.scss'

class AccountActivation extends Component {
    componentDidMount() {
        const {activationCode} = this.props.match.params

        this.props.dispatch(activateAccount({key: activationCode}))
    }

    render() {
        const {isLoading, accountActivated} = this.props
        
        return (
            <Container className="main-container">
                <div className="logo-div">
                    <img src={require('../assets/images/monitor_dosare_logo_dark.svg').default} alt="monitor-dosare" />
                </div>
                {isLoading ? (
                    <WaitComponent />
                ) : (
                    accountActivated ? <AccountActivated /> : <AccountActivationFail />
                )}
            </Container>
        )
    }
}

class AccountActivated extends Component {
    render() {
        return (
            <div className='account-activation'>
                <Typography className='activation-message'>
                    Contul tau a fost confirmat! Poți începe monitorizarea dosarelor tale de instanță chiar acum.
                </Typography>
                <a href="/login" className='login-link'>Mergi la login</a>
            </div>
        )
    }
}

class AccountActivationFail extends Component {
    render() {
        return (
            <div className='account-activation'>
                <Typography className='activation-message'>
                    Oups! Pare ca linkul de activare a expirat. 
                    Am retimis chiar acum un alt link pe adresa ta de email pentru a putea fi validat.
                </Typography>
            </div>
        )
    }
}

const mapStateToProps = function(state) {
    return {
        accountActivated: state.auth.accountActivated,
        isLoading: state.auth.isLoading
    }
}

export default connect(mapStateToProps)(AccountActivation)
