import {
    Button,
    ButtonBase,
    CircularProgress,
    Modal,
    Step,
    StepLabel,
    Stepper,
    Switch,
    Tab,
    Tabs,
    Typography
} from '@material-ui/core'
import React, {Component} from 'react'
import {ArrowUp, ChevronDown} from 'react-feather'
import VisibilitySensor from 'react-visibility-sensor'

import {values as _values, concat, isEmpty, join} from 'lodash'

import {manualLitigationSource} from 'avoapp-react-common/dist/constants'
import {litigationsCheck} from 'avoapp-react-common/dist/redux/litigations'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {createSubscriptionsInCurrentProject} from 'avoapp-react-common/dist/redux/subscriptions'
import {changeDateFormat, getErrorFromCode} from 'avoapp-react-common/dist/utils'
import {connect} from 'react-redux'

import AdvancedCheck from '../components/SubscriptionAdd/AdvancedCheck'
import SimpleCheck from '../components/SubscriptionAdd/SimpleCheck'

import LitigationFullDetails from '../components/SubscriptionAdd/LitigationFullDetails'

import {litigationsCheckData} from '../assets/data/choices'

import '../assets/scss/SubscriptionAdd.scss'

const indexMapping = {
    0: 'SIMPLE_CHECK',
    1: 'ADVANCED_CHECK'
}

class ProjectAddSubscription extends Component {
    constructor(props) {
        super(props)
        this.searchDataSourceDiv = React.createRef()
        this.addDataSourceDiv = React.createRef()

        this.state = {
            activeStep: 0,
            selectedIndex: 0,
            litigationsToSubscribe: [],
            litigationForModal: {},
            currentProjectId: this.props.match.params.projectID
        }
    }

    componentDidMount() {
        this.setState({activeStep: 0})
    }

    createSourcesArray = (startingSourcesArray) => {
        let finalSourcesArray = []
        startingSourcesArray.map(
            (source) =>
                (finalSourcesArray = concat(finalSourcesArray, {
                    value: source,
                    label: litigationsCheckData.source[source]
                }))
        )

        return finalSourcesArray
    }

    createInstitutionsArray = (startingInstitutionsArray) => {
        let finalInstitutionsArray = []
        startingInstitutionsArray.map((institution) => (
            finalInstitutionsArray = concat(finalInstitutionsArray, {
                value: litigationsCheckData.institution[institution],
                label: institution
            })
        ))

        return finalInstitutionsArray
    }

    updateIndex = (selectedIndex) => this.setState({selectedIndex})

    handleChangeSwitch = (lit_number) => {
        const {litigations} = this.props
        const {currentProjectId} = this.state 

        const filteredLitigationsToSubscribe = this.state.litigationsToSubscribe.filter(
            (litigationToSubscribe) => litigationToSubscribe.litigation_number === lit_number
        )

        if (!isEmpty(filteredLitigationsToSubscribe)) {
            this.setState((state) => {
                const litigationsToSubscribe = state.litigationsToSubscribe.filter(
                    (litigation) => litigation.litigation_number !== lit_number
                )
                return {litigationsToSubscribe: litigationsToSubscribe}
            })

            litigations.map((litigation) => {
                if (litigation.number === lit_number) {
                    litigation.monitorize = false
                }
                return null
            })
        } else {
            this.setState((state) => {
                const litigationsToSubscribe = state.litigationsToSubscribe.concat({
                    project_id: currentProjectId,
                    source: 'portal_just',
                    litigation_number: lit_number
                })
                return {litigationsToSubscribe: litigationsToSubscribe}
            })

            litigations.map((litigation) => {
                if (litigation.number === lit_number) {
                    litigation.monitorize = true
                }

                return null
            })
        }
    }

    handleCheckLitigation = (dataToCheck) => {
        const {source} = dataToCheck
        const {currentProjectId} = this.state 

        if(source !== manualLitigationSource) {
            this.props.dispatch(litigationsCheck(dataToCheck))
            this.scrollToRef(this.addDataSourceDiv)
        } else {
            this.props.dispatch(RESOURCES.subscriptions.create([{
                source: source,
                project_id: currentProjectId,
                litigation_number: dataToCheck.litigation_number
            }]))
        }
    }

    render() {
        const {selectedIndex, litigationsToSubscribe, litigationForModal} = this.state
        const {
            litigations, 
            isLoadingLitigations, 
            litigationErrors,
            isLoadingSubscriptions,
            subscriptionsErrors,
            planError
        } = this.props

        const sourcesArray = this.createSourcesArray(Object.keys(litigationsCheckData.source))
        const institutionsArray = this.createInstitutionsArray(Object.keys(litigationsCheckData.institution))

        return (
            <>
                <div className="navbar" id="navbar-subscriptions-add">
                    <div className="navbar-left">
                        <Typography className="navbar-title">Adaugă proiect</Typography>
                        <div className="navbar-stepper">
                            <Stepper activeStep={this.state.activeStep}>
                                <Step>
                                    <StepLabel>
                                        <Typography>Caută surse de date</Typography>
                                    </StepLabel>
                                </Step>
                                <Step>
                                    <StepLabel>
                                        <Typography>Alege dosarele dorite</Typography>
                                    </StepLabel>
                                </Step>
                            </Stepper>
                        </div>
                    </div>
                    <Button className="cancel-button" onClick={() => this.props.history.goBack()}>
                        <Typography className="cancel-button-title">Renunță</Typography>
                    </Button>
                </div>
                <div id="subscriptions-add-main-div" style={{flexDirection: 'column'}}>
                    <VisibilitySensor onChange={() => {this.setState({activeStep: 0})}}>
                        <div className="subscriptions-add-step-div" ref={this.searchDataSourceDiv}>
                            <div className="subscriptions-add-left-div">
                                <div className="top">
                                    <div className="step-number-div">
                                        <Typography className="step-number">1</Typography>
                                    </div>
                                    <Typography className="step-title">
                                        Caută
                                        <br /> sursă de date
                                    </Typography>
                                </div>
                                <div className="bottom">
                                    <Typography className="step-description">
                                        Aici poti selecta sursa de date (ex.portal.just.ro) impreuna cu numarul
                                        dosarului sau alte date necesare.
                                    </Typography>
                                </div>
                            </div>
                            <div className="subscriptions-add-right-div">
                                <div className="subscription-search-data-source-div">
                                    <div className="tabs-div">
                                        <Tabs
                                            value={selectedIndex}
                                            onChange={(data, index) => {
                                                if (index !== selectedIndex) {
                                                    this.updateIndex(index)
                                                }
                                            }}
                                            textColor="primary"
                                            className='tabs'
                                        >
                                            <Tab 
                                                disableRipple 
                                                className="tab"
                                                label="Căutare simplă" 
                                            />
                                            <Tab 
                                                disableRipple
                                                className="tab" 
                                                label="Căutare avansată" 
                                            />
                                        </Tabs>
                                    </div>
                                    {indexMapping[selectedIndex] === 'SIMPLE_CHECK' ? (
                                        <SimpleCheck 
                                            sourcesArray={sourcesArray}
                                            handleCheck={(dataToCheck) => this.handleCheckLitigation(dataToCheck)}
                                        />
                                    ) : indexMapping[selectedIndex] === 'ADVANCED_CHECK' ? (
                                        <AdvancedCheck 
                                            sourcesArray={sourcesArray}
                                            institutionsArray={institutionsArray}
                                            handleCheck={(dataToCheck) => this.handleCheckLitigation(dataToCheck)}
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </VisibilitySensor>
                    <VisibilitySensor onChange={() => {this.setState({activeStep: 1})}}>
                        <div ref={this.addDataSourceDiv} className="subscriptions-add-step-div">
                            <div className="subscriptions-add-left-div">
                                <div className="top">
                                    <div className="step-number-div">
                                        <Typography className="step-number">2</Typography>
                                    </div>
                                    <Typography className="step-title">
                                        Adaugă
                                        <br /> sursă de date
                                    </Typography>
                                </div>
                                <div className="bottom">
                                    <Typography className="step-description">
                                        Am identificat următoarele dosare. Selectează-le pe cele de adăugat în
                                        proiectul curent.
                                    </Typography>
                                </div>
                            </div>
                            <div className="subscriptions-add-right-div">
                                {!isEmpty(subscriptionsErrors) && isEmpty(planError) ? 
                                    subscriptionsErrors.map((error, index) =>
                                        !isEmpty(error.message) ? (
                                            <div key={index} className='error-div'>
                                                <Typography className='error-text'>
                                                    {getErrorFromCode(error, 'subscription')}
                                                </Typography>
                                            </div>
                                        ) : !isEmpty(error.non_field_errors) ? 
                                            error.non_field_errors.map((err, i) => (
                                                <div key={i} className='error-div'>
                                                    <Typography className='error-text'>
                                                        {getErrorFromCode(err, 'subscription')}
                                                    </Typography>
                                                </div>
                                            )): null
                                    ): null}
                                <div className="subscriptions-add-data-source-div">
                                    {!isEmpty(litigations) && !isLoadingLitigations && isEmpty(litigationErrors) ? (
                                        <div className='litigations-list'>
                                            <div className='cards-container'> 
                                                {litigations.map((litigation, index) => (
                                                    <div key={index} className="card">
                                                        <div className="card-header">
                                                            <Typography className="card-title">
                                                                {litigation.number}
                                                            </Typography>
                                                            <Button
                                                                variant="outlined"
                                                                onClick={() => 
                                                                    this.setState({litigationForModal: litigation})
                                                                }
                                                            >
                                                                <Typography className="details-text">
                                                                    Vezi detalii dosar
                                                                </Typography>
                                                                <ChevronDown className="details-icon" />
                                                            </Button>
                                                        </div>
                                                        <div className="card-content">
                                                            <div className="card-content-child">
                                                                <Typography className="title">
                                                                    Instanța:
                                                                </Typography>
                                                                <Typography className="subtitle" noWrap>
                                                                    {litigation.court}
                                                                </Typography>
                                                            </div>
                                                            <div className="card-content-child">
                                                                <Typography className="title">
                                                                    Următorul termen:{' '}
                                                                </Typography>
                                                                <Typography className="subtitle">
                                                                    {!isEmpty(litigation.hearings) ? 
                                                                        changeDateFormat(litigation.hearings[0].date) : 
                                                                        '-'}
                                                                </Typography>
                                                            </div>
                                                            <div className="card-content-child">
                                                                <Typography className="title">
                                                                    Părți: 
                                                                </Typography>
                                                                <Typography className="subtitle">
                                                                    {join(litigation.parties.map((p) => p.name), ',')}
                                                                </Typography>
                                                            </div>
                                                            <div className="card-content-child">
                                                                <Typography className="title">
                                                                    Faza: 
                                                                </Typography>
                                                                <Typography className="subtitle">
                                                                    {litigation.phase}
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                        <div className="card-footer-with-select">
                                                            <Typography>
                                                                Selectează pentru monitorizare
                                                            </Typography>
                                                            <Switch
                                                                className="monitorize-switch"
                                                                color="primary"
                                                                onChange={() => 
                                                                    this.handleChangeSwitch(litigation.number)
                                                                }
                                                                checked={!!litigation.monitorize}
                                                                value={litigation.monitorize}
                                                            />
                                                        </div>
                                                        <Modal
                                                            disableAutoFocus
                                                            open={!isEmpty(this.state.litigationForModal)}
                                                            style={{display: 'flex', justifyContent: 'center'}}
                                                            onClose={() => 
                                                                this.setState({litigationForModal: {}})
                                                            }
                                                        >
                                                            <LitigationFullDetails
                                                                litigation={litigationForModal}
                                                            />
                                                        </Modal>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ) : !isLoadingLitigations && !isEmpty(litigationErrors.non_field_errors)
                                            && litigationErrors.non_field_errors.code === 'invalid' ? (
                                                <div className='litigations-no-results-div'>
                                                    <div className='no-results-left'>
                                                        <Typography className='no-results-text'>
                                                            Nu s-au găsit dosare pentru parametrii aceştia de cautare. 
                                                        </Typography>
                                                        <Typography className='no-results-text'>
                                                            Incearcă să schimbi parametrii de cautare şi caută din nou.
                                                        </Typography>
                                                    </div>
                                                    <div className='no-results-right'>
                                                        <ButtonBase 
                                                            className="go-to-form-button"
                                                            onClick={() => this.scrollToRef(this.searchDataSourceDiv)}
                                                        >
                                                            <ArrowUp className="go-to-form-button-icon"/>
                                                        </ButtonBase>
                                                    </div>
                                                </div>
                                        ) : isLoadingLitigations ? (
                                            <div className='litigations-loading-component'>
                                                <Typography className='loading-component-text'>
                                                    Interogam sursa de date...
                                                </Typography>
                                                <CircularProgress 
                                                    className="circular-progress" 
                                                    color="primary"
                                                    thickness={5}
                                                />
                                            </div>
                                        ) : isEmpty(litigations) ? (
                                            <div className='litigations-empty-div'>
                                                <Typography className='empty-text'>
                                                    Aici vor aparea rezultatele in urma cautarii
                                                </Typography>
                                                <ButtonBase
                                                    className="go-to-form-button"
                                                    onClick={() => this.scrollToRef(this.searchDataSourceDiv)}
                                                >
                                                    <ArrowUp className="go-to-form-button-icon"/>
                                                </ButtonBase>
                                            </div>
                                        ) : null
                                    }
                                    {!isEmpty(litigations) && !isLoadingLitigations && isEmpty(litigationErrors) ? (
                                        <Button
                                            onClick={() => 
                                                this.props.dispatch(
                                                    createSubscriptionsInCurrentProject(litigationsToSubscribe)
                                                )
                                            }
                                            disabled={isEmpty(litigationsToSubscribe) || isLoadingSubscriptions}
                                            className='button-save-project'
                                            variant="contained"
                                            size="large"
                                            fullWidth
                                        >
                                            <Typography className="button-title">
                                                Salvează abonarea
                                            </Typography>
                                        </Button>
                                    ) : null}
                                    {!isEmpty(litigations) && !isLoadingLitigations && isEmpty(litigationErrors) ? (
                                        <Button
                                            className="back-button"
                                            onClick={() => this.scrollToRef(this.searchDataSourceDiv)}
                                        >
                                            <Typography className='back-button-text'>Înapoi</Typography>
                                            <ArrowUp className="back-button-icon" size={14}/>
                                        </Button>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </VisibilitySensor>
                </div>
            </>
        )
    }

    scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)
}

const mapStateToProps = function(state) {
    return {
        litigations: state.litigations.data,
        isLoadingLitigations: state.litigations.isLoading,
        litigationErrors: state.litigations.errors,
        isLoadingSubscriptions: state.subscriptions.isLoading,
        subscriptionsErrors: _values(state.subscriptions.errors),
        planError: state.subscriptions.plan_error
    }
}

export default connect(mapStateToProps)(ProjectAddSubscription)
