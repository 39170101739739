import React, {Component} from 'react'
import {Button, Typography} from '@material-ui/core'
import {Link} from 'react-router-dom'
import {ChevronLeft} from 'react-feather'

import {connect} from 'react-redux'

import EntitiesUpdateForm from '../components/EntitiesUpdateForm'

import '../assets/scss/EntitiesEdit.scss'

class EntitiesEdit extends Component {
    render() {
        return (
            <div>
                <div className="navbar" id="navbar-entities-edit">
                    <Typography className="navbar-title">Schimbare Date Companie</Typography>
                    <Link to='/settings' style={{textDecoration: 'none'}}>
                        <Button className="back-button">
                            <ChevronLeft className="back-button-icon"/>
                            <Typography className="back-button-title">Înapoi la Setări</Typography>
                        </Button>
                    </Link>
                </div>
                <div id="entities-edit-main-div">
                    <EntitiesUpdateForm />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

export default connect(mapStateToProps)(EntitiesEdit)
