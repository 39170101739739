import {toast} from 'react-toastify'
import {takeEvery, put, take, select} from 'redux-saga/effects'
import {push} from 'connected-react-router'

import {find, values} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {getErrorFromCode} from 'avoapp-react-common/dist/utils'
import {ACTIONS as ACTIONS_ENTITIES, setCurrent} from 'avoapp-react-common/dist/redux/entities'

const {entities: resource} = RESOURCES

function* handleUpdateEntitiesSuccess() {
    toast.success('Noile preferinte au fost salvate')

    if (window.location.pathname === '/billing') {
        yield put(push('/confirm-subscription'))
    } else {
        yield put(push('/settings'))
    }
}

function handleUpdateEntitiesFail(response) {
    values(response.payload).map((errorCategories) => errorCategories.map((error) => 
        toast.error(getErrorFromCode(error, 'entities'))
    ))
}

function* handleRetrieveSetCurrentEntity(params) {
    const {entityID} = params.payload
    
    yield put(RESOURCES.entities.retrieve(entityID))
    yield take(RESOURCES.entities.actions.retrieve.success)

    const currentEntity = yield select((state) => find(values(state.entities.data), (entity) => entity.id === entityID))
    yield put(setCurrent(currentEntity))
}

export function* saga() {
    yield takeEvery(resource.actions.update.success, handleUpdateEntitiesSuccess)
    yield takeEvery(resource.actions.update.fail, handleUpdateEntitiesFail)
    yield takeEvery(ACTIONS_ENTITIES.RETRIEVE_SET_CURRENT.main, handleRetrieveSetCurrentEntity)
}
