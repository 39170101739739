import React, {Component} from 'react'
import {Typography, Button, ButtonBase} from '@material-ui/core'
import {Link} from 'react-router-dom'
import {Plus, X} from 'react-feather'

import {isEmpty, values, head, omitBy, camelCase} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {permissionRoles} from 'avoapp-react-common/dist/constants'

import {DeleteSubscriptionDialog, DeletePermissionDialog} from './Dialogs'

import ProjectAddClient from '../ProjectAddClient'
import ProjectAddPermission from '../ProjectAddPermission'
import SubscriptionActiveStatusPill from '../SubscriptionActiveStatusPill'

class InfoSection extends Component {
    constructor() {
        super()

        this.state = {
            showDeleteSubscriptionDialog: false,
            subscriptionToDelete: {},
            showAddClientModal: false,
            showDeletePermissionDialog: false,
            showAddPermissionModal: false,
            permissionToDelete: {}
        }
    }

    handleCloseDeletePermissionDialog = () => {
        this.setState({
            showDeletePermissionDialog: false
        })
    }

    handleCloseDeleteSubscriptionsDialog = () => {
        this.setState({
            showDeleteSubscriptionDialog: false,
            subscriptionToDelete: {}
        })
    }

    handleCloseAddClientModal = () =>{
        const {project} = this.props

        this.setState({showAddClientModal: false})
        this.props.dispatch(RESOURCES.clients.list({project_id: project.id}))
    }

    handleCloseAddPermissionModal = () =>{
        this.setState({showAddPermissionModal: false})
    }

    handleDeleteClient = (client_id) =>{
        const {project} = this.props

        this.props.dispatch(
            RESOURCES.projects.update({
                id: project.id,
                clients_ids: values(omitBy(project.clients_ids, (client) => client === client_id))
            })
        )
    }

    isProjectAdmin = () => {
        const {permissions, currentProjectPermissions} = this.props

        return( 
            (
                permissions.projects.read && 
                permissions.projects.update && 
                permissions.projects.destroy
            ) || (
                currentProjectPermissions.permissions.read && 
                currentProjectPermissions.permissions.update && 
                currentProjectPermissions.permissions.destroy
            )
        ) 
    }

    render() {
        const {
            showDeleteSubscriptionDialog,
            showAddClientModal,
            subscriptionToDelete,
            showDeletePermissionDialog,
            showAddPermissionModal,
            permissionToDelete
        } = this.state

        const {project, subscriptions, clients, projectPermissions, permissions, currentProjectPermissions} = this.props

        return (
            <div className="section info-section">
                <div className="section-header">
                    <Typography className="section-title">1. INFO PROIECT</Typography>
                </div>
                <div className="clients-div">
                    <div className="section-header bordered">
                        <Typography className="section-header-text">Clienți notificați</Typography>
                        {
                            (permissions.projects.update ||
                            currentProjectPermissions.permissions.update) && 
                            <>
                                <Button
                                    onClick={()=>{
                                        this.props.dispatch(RESOURCES.clients.list())
                                        this.setState({showAddClientModal:true})
                                    }}
                                    className="navbar-main-button"
                                    variant="contained"
                                    size="small"
                                >
                                    <Plus className="navbar-button-icon" />
                                    <Typography className="navbar-button-title">Adaugă client</Typography>
                                </Button>
                                <ProjectAddClient
                                    showAddClientModal={showAddClientModal}
                                    handleCloseAddClientModal={this.handleCloseAddClientModal}
                                />
                            </>
                        }
                    </div>
                    <div className="section-content">
                        {!isEmpty(clients) ? (
                            <div className='cards-container'>
                                {clients.map((client) => (
                                    <div key={client.id} className="card">
                                        <div className="card-header">
                                            <Typography className="card-title">{client.name}</Typography>
                                            {
                                                (permissions.projects.update ||
                                                currentProjectPermissions.permissions.update) && 
                                                    <>
                                                        <ButtonBase onClick={() => this.handleDeleteClient(client.id)}>
                                                            <X
                                                                className="small-delete-button-icon"
                                                                style={{alignSelf: 'center'}}
                                                                size={14}
                                                            />
                                                        </ButtonBase>
                                                    </>
                                            }
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) :
                            <Typography className='no-data-text'>Nu există  niciun client</Typography>
                        }
                    </div>
                </div>
                <div className="subscriptions-div">
                    <div className="section-header bordered">
                        <Typography className="section-header-text">Surse de date</Typography>
                        <Link
                            style={{textDecoration: 'none'}}
                            to={`/projects/${project.id}/add-subscription`}
                        >
                            {
                                (permissions.projects.update ||
                                currentProjectPermissions.permissions_subscriptions.create) && 
                                <>
                                    <Button
                                        className="navbar-main-button"
                                        variant="contained"
                                        size="small"
                                    >
                                        <Plus className="navbar-button-icon" />
                                        <Typography className="navbar-button-title">Adaugă dosar</Typography>
                                    </Button>
                                </>
                            }
                        </Link>
                    </div>
                    <div className="section-content">
                        {!isEmpty(subscriptions) ? (
                            <div className='cards-container'>
                                {subscriptions.map((subscription) => (
                                    <div key={subscription.id} className="card">
                                        <div className="card-header">
                                            <div className="left">
                                                <Typography className="card-title">
                                                    {subscription.litigation_number},{' '}
                                                    {subscription.source === 'portal_just'
                                                        ? 'Portal Just'
                                                        : subscription.source}
                                                </Typography>
                                                <SubscriptionActiveStatusPill subscription={subscription}/>
                                            </div>
                                            { 
                                                (permissions.projects.update ||
                                                currentProjectPermissions.permissions_subscriptions.destroy) && 
                                                <>
                                                    <ButtonBase
                                                        onClick={() => {
                                                            this.setState({
                                                                showDeleteSubscriptionDialog: true,
                                                                subscriptionToDelete: subscription
                                                            })
                                                        }}
                                                        className='delete-button-container'
                                                    >
                                                        <X
                                                            size={14}
                                                            style={{alignSelf: 'center'}}
                                                            className="small-delete-button-icon"
                                                        />
                                                    </ButtonBase>
                                                </>
                                            }                 
                                        </div>
                                        {subscription.litigation.sync_moment ? (
                                            <div className="card-content">
                                                <div className="card-content-child">
                                                    <Typography className="title">Instanța</Typography>
                                                    <Typography className="subtitle">
                                                        {!isEmpty(subscription.litigation.phases) ?
                                                            head(subscription.litigation.phases).court
                                                            : '-'}
                                                    </Typography>
                                                </div>
                                                <div className="card-content-child">
                                                    <Typography className="title">Secție</Typography>
                                                    <Typography className="subtitle">
                                                        {!isEmpty(subscription.litigation.phases) ?
                                                            head(subscription.litigation.phases).department
                                                            : '-'}
                                                    </Typography>
                                                </div>
                                                <div className="card-content-child">
                                                    <Typography className="title">Obiect</Typography>
                                                    <Typography className="subtitle">
                                                        {!isEmpty(subscription.litigation.phases) ?
                                                            head(subscription.litigation.phases).object : 
                                                            '-'}
                                                    </Typography>
                                                </div>
                                                <div className="card-content-child">
                                                    <Typography className="title">Stadiu <br/> procesual</Typography>
                                                    <Typography className="subtitle">
                                                        {!isEmpty(subscription.litigation.phases) ?
                                                            head(
                                                                subscription.litigation.phases
                                                            ).phase
                                                            : '-'}
                                                    </Typography>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="card-content">
                                                <Typography className='syncing-text'>În curs de actualizare</Typography>
                                            </div>
                                        )}
                                    </div>
                                ))}
                                <DeleteSubscriptionDialog
                                    showDeleteSubscriptionDialog={showDeleteSubscriptionDialog}
                                    subscriptionToDelete={subscriptionToDelete}
                                    handleDeleteSubscription={() => {
                                        this.props.dispatch(RESOURCES.subscriptions.destroy(subscriptionToDelete))
                                        this.handleCloseDeleteSubscriptionsDialog()
                                    }}
                                    handleCloseDeleteSubscriptionsDialog={this.handleCloseDeleteSubscriptionsDialog}
                                />
                            </div>
                        ) :
                            <Typography className='no-data-text'>Nu există  nicio sursa de date</Typography>
                        }
                    </div>
                </div>
                <div className="collaborators-div">
                    <div className="section-header bordered">
                        <Typography className="section-header-text">Manageri & Colaboratori</Typography>
                        {this.isProjectAdmin() && <>
                            <Button
                                onClick={()=>{
                                    this.props.dispatch(RESOURCES.entityProfiles.list())
                                    this.setState({showAddPermissionModal:true})
                                }}
                                className="navbar-main-button"
                                variant="contained"
                                size="small"
                            >
                                <Plus className="navbar-button-icon" />
                                <Typography className="navbar-button-title">Adaugă manager/colaborator</Typography>
                            </Button>
                            <ProjectAddPermission
                                showAddPermissionModal={showAddPermissionModal}
                                handleCloseAddPermissionModal={this.handleCloseAddPermissionModal}
                            />
                        </>}
                    </div>
                    <div className="section-content">
                        {!isEmpty(projectPermissions) ? (
                            <div className='cards-container'>
                                {projectPermissions.map((permission, index) => (
                                    <div className="card" key={index}>
                                        <div className="card-header">
                                            <Typography className="card-title">
                                                {permission.entity_profile.first_name}{' '}
                                                {permission.entity_profile.last_name}
                                            </Typography>
                                            {
                                                (permissions.projects.update ||
                                                currentProjectPermissions.permissions.update) && 
                                                <ButtonBase
                                                    onClick={() => {
                                                        this.setState({
                                                            showDeletePermissionDialog: true,
                                                            permissionToDelete: permission
                                                        })
                                                    }}>

                                                    <X
                                                        className="small-delete-button-icon"
                                                        size={14}
                                                        style={{alignSelf: 'center'}}
                                                    />
                                                </ButtonBase>
                                            }
                                        </div>
                                        <div className="card-content">
                                            <div className="card-content-child">
                                                <Typography className="title">Rol</Typography>
                                                <Typography className="subtitle">
                                                    {
                                                        permissionRoles[camelCase(permission.role)].label || 
                                                        permission.role
                                                    }
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>
                                ))} 
                                <DeletePermissionDialog
                                    showDeletePermissionDialog={showDeletePermissionDialog}
                                    permissionToDelete={permissionToDelete}
                                    handleCloseDeletePermissionDialog={this.handleCloseDeletePermissionDialog}
                                    handleDeletePermission={() => {
                                        this.props.dispatch(RESOURCES.projectPermissions.destroy(permissionToDelete))
                                        this.handleCloseDeletePermissionDialog()
                                    }}
                                />
                            </div>
                        ) : (
                            <Typography className='no-data-text'>Nu există colaboratori/manageri</Typography>
                        )} 
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = function (state) {
    const project = state.projects.currentProject
    const subscriptions = values(state.subscriptions.data)
    const clients = values(state.clients.data)

    return {
        project,
        subscriptions,
        clients,
        permissions: state.permissions,
        projectPermissions: values(state.projectPermissions.data),
        currentProjectPermissions: state.projectPermissions.currentProjectPermissions
    }
}

export default connect(mapStateToProps)(InfoSection)