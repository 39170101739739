import React, {Component} from 'react'
import {Button, CircularProgress, Dialog, DialogContent, DialogContentText, Typography} from '@material-ui/core'
import {Check} from '@material-ui/icons'

import {Calendar, Power} from 'react-feather'
import {lightFormat} from 'date-fns'

import {connect} from 'react-redux'
import {googleSync, googleUnsync} from 'avoapp-react-common/dist/redux/googleCalendars'

import SynciCalComponent from '../SynciCalComponent'

import {colors} from '../../assets/styles/colors'
import GoogleLogo from '../../assets/images/google_calendar_logo.svg'
import ICalendarLogo from '../../assets/images/icalendar.svg'
import SyncIcon from '../../assets/images/sync_icon.svg'

class SyncCalendar extends Component {
    constructor() {
        super()

        this.state = {
            showSynciCalComponent: false,
            showUnsyncDialog: false
        }
    }

    logInWithGooogle = () => {
        var googleAuth = window.gapi.auth2.getAuthInstance()
        googleAuth.grantOfflineAccess({prompt: 'consent'}).then((response) => {
            this.props.dispatch(googleSync({code: response.code}))
        }).catch(errorResponse => {
            console.error('User cancelled login or did not fully authorize.', errorResponse)
        })
    }

    openUnsyncModal = () => {
        this.setState({showUnsyncDialog: true})
    }

    closeUnsyncModal= () => {
        this.setState({showUnsyncDialog: false})
    }
    
    handleUnsyncGoogleCalendar = (shouldRemoveEvents) => {
        this.props.dispatch(googleUnsync({remove_events: shouldRemoveEvents}))
        this.closeUnsyncModal()
    }

    handleShowSynciCal = () => {
        this.setState({showSynciCalComponent: true}) 
    }

    handleCloseSynciCalComponent = () => {
        this.setState({showSynciCalComponent: false}) 
    }

    render() {
        const {user, isLoadingGoogle, googleProfile, isLoadingGoogleProfile} = this.props
        const {showSynciCalComponent, showUnsyncDialog} = this.state

        return (
            <div className="card-large">
                {!isLoadingGoogleProfile ? (
                    <div className="card-main-div" id='calendar-settings-card'>
                        <div className="card-header">
                            <div className="card-header-title-div">
                                <div className="icon-div"><Calendar className="icon"/></div>
                                <Typography className="card-header-title">Calendar</Typography>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="left">
                                <Dialog
                                    open={showUnsyncDialog}
                                    onClose={this.closeUnsyncModal}
                                    className='unsync-dialog'
                                    maxWidth='md'
                                >
                                    <div className="dialog-header">
                                        <Button 
                                            onClick={this.closeUnsyncModal}
                                            variant="outlined"
                                            className='close-dialog-button'
                                        >
                                            <Typography className='close-dialog-button-text'>Renunță</Typography>
                                        </Button>
                                    </div>
                                    <DialogContent className='dialog-content'>
                                        <DialogContentText>
                                            Odată ce oprești sincornizarea, 
                                            termenele nu vor mai fi actualizate in calendarul tău Google.
                                        </DialogContentText>
                                        <Button 
                                            onClick={() => this.handleUnsyncGoogleCalendar(false)}
                                            className='dialog-unsync-button'
                                            variant="contained"
                                            size="large"
                                        >
                                            <Typography className='button-text'>Oprește sincronizarea</Typography>
                                        </Button>
                                        <DialogContentText>
                                            Pentru oprirea sincronizării, cât și ștergerea tuturor 
                                            termenelor de pana acum apasă butonul de mai jos.
                                        </DialogContentText>
                                        <Button 
                                            onClick={()=> this.handleUnsyncGoogleCalendar(true)}
                                            className='dialog-delete-button'
                                            variant="contained"
                                            size="large"
                                        >
                                            <Typography className='button-text'>
                                                Oprește sincronizarea și șterge termenele 
                                            </Typography>
                                        </Button>
                                    </DialogContent>
                                </Dialog>
                                {user.flags.google_account_synced ? (googleProfile.state === 'synced' ? (
                                    <>
                                        <div className='google-sync-info-div'>
                                            <div className="left-sm">
                                                <img 
                                                    alt=""
                                                    src={GoogleLogo}
                                                />
                                            </div>
                                            <div className="right-sm">
                                                <Typography className='title'>
                                                    <Check className='sync-check'/>
                                                    Calendarul Google este sincronizat
                                                </Typography>
                                                <Typography className='info-text'>
                                                    Adresa cont: {googleProfile.email}
                                                </Typography>
                                                <Typography className='info-text'>
                                                    Calendar: {googleProfile.calendar_summary}
                                                </Typography>
                                                <Typography className='info-text'>
                                                    Ultima actualizare: {' '}
                                                    {
                                                        googleProfile.last_activity ?
                                                            lightFormat(
                                                                new Date(googleProfile.last_activity), 
                                                                'dd/MM/yyyy H:mm'
                                                            ) : null
                                                    }
                                                </Typography>
                                            </div>
                                        </div>
                                        {!isLoadingGoogle ? (
                                            <Button
                                                onClick={this.openUnsyncModal}
                                                style={{backgroundColor: colors.error}}
                                                className='sync-other-calendars-button'
                                                variant="contained"
                                                size="large"
                                                fullWidth
                                            >
                                                <div className='sync-button'>
                                                    <Typography>Vreau să opresc sincronizarea</Typography>
                                                    <Power />
                                                </div>
                                            </Button>
                                        ) : (
                                            <CircularProgress
                                                className="circular-progress"
                                                thickness={5} 
                                                color="primary" 
                                                style={{alignSelf: 'center'}}
                                            />
                                        )}
                                    </>
                                ) : googleProfile.state === 'pending_delete' ? (
                                    <div className='google-sync-info-div'>
                                        <div className="left-sm">
                                            <img alt="" src={GoogleLogo}/>
                                        </div>
                                        <div className="right-sm">
                                            <Typography className='title'>
                                                Calendarul Google este în procesul de desincronizare
                                            </Typography>
                                            <Typography className='info-text'>
                                                Acest proces poate dura câteva momente.
                                            </Typography>
                                        </div>
                                    </div>
                                ) : null
                                ) : (
                                    <>
                                        <div className='card-header-secondary'>
                                            <img alt="" src={GoogleLogo} />
                                            <div className='texts-div'>
                                                <Typography className='cal-sync-primary-text'>
                                                    Sincronizare Google Calendar
                                                </Typography>
                                                <Typography className='cal-sync-secondary-text'>
                                                    Prin conectarea unui cont Google existent
                                                </Typography>
                                            </div>
                                        </div>
                                        {!isLoadingGoogle ? (
                                            <Button
                                                size="large"
                                                variant="contained"
                                                className='sync-button'
                                                onClick={this.logInWithGooogle}
                                                fullWidth
                                            >
                                                <div className='sync-button-div'>
                                                    <div className='google-icon-div'>
                                                        <img 
                                                            alt="" 
                                                            src={GoogleLogo}
                                                        />
                                                    </div>
                                                    <Typography className='google-sync-text'>
                                                        Autentifică-te cu Google
                                                    </Typography>
                                                </div>
                                            </Button>
                                        ) : (
                                            <CircularProgress 
                                                className="circular-progress"
                                                thickness={5} 
                                                color="primary" 
                                                style={{alignSelf: 'center'}}
                                            />
                                        )}
                                    </>
                                )}
                            </div>
                            <div className="right">
                                <div className='card-header-secondary'>
                                    <img alt="" src={ICalendarLogo} />
                                    <div className='texts-div'>
                                        <Typography className='cal-sync-primary-text'>
                                            Sincronizare alte sisteme
                                        </Typography>
                                        <Typography className='cal-sync-secondary-text'>
                                            Prin import fisier .ics
                                        </Typography>
                                    </div>
                                </div>
                                <Button
                                    size="large"
                                    variant="contained"
                                    className='sync-other-calendars-button'
                                    onClick={this.handleShowSynciCal}
                                    fullWidth
                                >
                                    <div className='sync-button'>
                                        <Typography className='sync-text'>
                                            Vreau să sincronizez un calendar ICS
                                        </Typography>
                                        <img alt="" src={SyncIcon} />
                                    </div>
                                </Button>
                                <SynciCalComponent
                                    showSynciCalComponent={showSynciCalComponent}
                                    handleCloseSynciCalComponent={this.handleCloseSynciCalComponent}
                                />
                            </div>
                        </div>
                    </div>                                                    
                ) : (
                    <div className="center-loader">
                        <CircularProgress className="circular-progress" thickness={5} color="primary" />
                    </div>
                )}
            </div>
        )
    }
}

const mapStateToProps= function (state) {
    return {
        user: state.users.user,
        isLoadingGoogle: state.googleCalendars.isLoading,
        isLoadingGoogleProfile: state.googleProfile.isLoading,
        googleProfile: state.googleProfile.data
    }
}

export default connect(mapStateToProps)(SyncCalendar)
