import React, {Component} from 'react'
import {Typography, CircularProgress} from '@material-ui/core'

export default class WaitComponent extends Component {
    render() {
        return (
            <div className='wait-div'>
                <Typography className="wait-text">Așteaptă salvarea răspunsului tău!</Typography>
                <CircularProgress className="circular-progress" thickness={5} color="primary" />
            </div>
        )
    }
}
