import {put, select, take, takeEvery} from 'redux-saga/effects'

import {values, find} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {ACTIONS as PERMISSIONS_ACTIONS, savePermissions} from 'avoapp-react-common/dist/redux/permissions'
import {getPermissionsFromNumbers} from 'avoapp-react-common/dist/utils'

function* handleGetPermissions() {
    const user = yield select((state) => state.users.user)
    const selectedEntityID = yield select((state) => state.localConfigs.selectedEntityID)
    
    yield put(RESOURCES.entityProfiles.list({entity_id: selectedEntityID}))
    yield take(RESOURCES.entityProfiles.actions.list.success)

    const profileForUser = yield select((state) => find(values(state.entityProfiles.data), ['user_id', user.id]))
    const myGroup = profileForUser.group
    const permissions = {
        clients: {...getPermissionsFromNumbers(myGroup.permissions_clients)},
        entity: {...getPermissionsFromNumbers(myGroup.permissions_entity)},
        members: {...getPermissionsFromNumbers(myGroup.permissions_members)},
        plans: {...getPermissionsFromNumbers(myGroup.permissions_plans)},
        projects: {...getPermissionsFromNumbers(myGroup.permissions_projects)}
    }

    yield put(savePermissions(permissions))
}

export function* saga() {
    yield takeEvery(PERMISSIONS_ACTIONS.GET_PERMISSIONS.main, handleGetPermissions)
}
