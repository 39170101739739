import React, {Component} from 'react'
import {Typography, Button, CircularProgress} from '@material-ui/core'
import {ChevronLeft} from '@material-ui/icons'
import {Link} from 'react-router-dom'

import {isEmpty} from 'lodash'

import {connect} from 'react-redux'
import {changeDateFormat} from 'avoapp-react-common/dist/utils'

import '../assets/scss/SubscriptionUpgrade.scss'

class SubscriptionUpgrade extends Component {
    componentDidMount() {
        const {selectedPlan} = this.props
        
        if(isEmpty(selectedPlan)) {
            this.props.history.push('/subscription-plans')
        }

    }
    
    render() {
        const {currentEntity, isLoadingEntities, selectedPlan} = this.props

        return (
            <div>
                <div className="navbar" id="navbar-subscription-change" />
                <div id="subscription-change-main-div">
                    <div className="container">
                        {!isLoadingEntities && !isEmpty(currentEntity) && !isEmpty(selectedPlan) ? (
                            <>
                                <div className="page-header">
                                    <Typography className='page-title'>
                                        {selectedPlan.calculated_price.downgrade ? 'Downgrade ' : 'Upgrade '} 
                                        Plan MD{selectedPlan.name}
                                    </Typography>
                                    <Button className="back-button" onClick={this.props.history.goBack}>
                                        <ChevronLeft className="back-button-icon"/>
                                        <Typography className="back-button-title">Înapoi la Planuri</Typography>
                                    </Button>
                                </div>
                                <div>
                                    <div className="current-subscription-info">
                                        <div className="text-div">
                                            <Typography className='current-subscription-text'>
                                                Abonamentul curent (MD{currentEntity.plans.current.plan.name})<br /> 
                                                este achitat pana pe
                                            </Typography>
                                            <Typography className='date-text'>
                                                {changeDateFormat(
                                                    currentEntity.plans.current.current_payment.interval.upper
                                                )}
                                            </Typography>
                                            <Typography className='disclaimer'>
                                                Toate modificarile vor fi aplicate si calculate pana la aceasta data
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className="button-div">
                                        <Link 
                                            to={
                                                selectedPlan.calculated_price.downgrade ? 
                                                    '/confirm-subscription' : 
                                                    '/billing'
                                            } 
                                            style={{textDecoration:'none'}} 
                                        >
                                            <Button
                                                size="large"
                                                variant="contained"
                                                fullWidth
                                            >
                                                <Typography>
                                                    Mergi la datele de facturare
                                                </Typography>
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <CircularProgress className="circular-progress" thickness={5} color="primary" />
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = function (state) {
    return {
        currentEntity: state.entities.currentEntity,
        isLoadingEntities: state.entities.isLoading,
        selectedPlan: state.billing.data.selectedPlan
    }
}

export default connect(mapStateToProps)(SubscriptionUpgrade)