import {isEmpty, values, some, includes} from 'lodash' 
import {CRUDTypes} from 'avoapp-react-common/dist/constants'

export const env = process.env.NODE_ENV

export const environmentTypes = { 
    DEV: 'development',
    LOCAL: 'local',
    PROD: 'production'
}

export const isDowngradeFlow = (selectedPlan) => {
    // downgrade => true
    // upgrade   => false
    // new sub   => null

    let upgradePrice
    let downgradePrice

    if (selectedPlan) {
        upgradePrice = selectedPlan.calculated_price.upgrade
        downgradePrice = selectedPlan.calculated_price.downgrade
    }

    if(selectedPlan && downgradePrice !== null && upgradePrice === null){
        return true
    } else if(selectedPlan && downgradePrice === null && upgradePrice !== null) {
        return false
    } else {
        return null
    }
} 

export const getResourcesForGroups = () => {
    class Resource {
        constructor(name, label, createDisabled = false, readDisabled = false, destroyDisabled = false) {
            this.name = name
            this.label = label
            this.createDisabled = createDisabled 
            this.readDisabled = readDisabled
            this.destroyDisabled = destroyDisabled
    
            this.permissions = {
                create: false, 
                read: true,
                update: false,
                destroy: false
            }
        }
    }

    return {
        projects: new Resource('projects', 'Proiecte'),
        clients: new Resource('clients', 'Clienți'),
        entity: new Resource('entity', 'Companie', true, true),
        members: new Resource('members', 'Utilizatori') ,
        plans: new Resource('plans', 'Abonament', false, false, true)
    }
}

export const getResourcePermissions = (resource) => {
    let permissions = []

    if(resource.permissions.create) {
        permissions.push(CRUDTypes.create.number)
    }

    if(resource.permissions.read) {
        permissions.push(CRUDTypes.read.number)
    }

    if(resource.permissions.update) {
        permissions.push(CRUDTypes.update.number)
    }

    if(resource.permissions.destroy) {
        permissions.push(CRUDTypes.destroy.number)
    }

    return permissions
}

export const checkProperties = (obj) => some(values(obj), isEmpty) 

export const shouldPageLoadOnMobile = () => {
    const {pathname} = window.location

    const pathsToLoad = [
        '/terms-and-conditions',
        '/account-activation/', 
        '/password-confirmation/', 
        '/contact-person-confirmation/',
        '/entity-profile-confirmation/'
    ]

    return some(pathsToLoad, (path) => includes(pathname, path))
}

