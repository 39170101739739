import {Button, Typography} from '@material-ui/core'
import React, {Component} from 'react'

import {Link} from 'react-router-dom'

import '../assets/scss/ResourceAddSuccess.scss'

export default class ResourceAddSuccess extends Component {
    render() {
        const {resourceText, resourceDetails, nextText, nextUrl, addMoreText, addMoreUrl} = this.props

        return (
            <div id='resource-add-success-main-div'>
                <div className='container'>
                    <div className='check-image-div'>
                        <img src={require('../assets/images/check.svg').default} alt=''/>
                    </div>
                    <Typography className='main-text'>
                        {resourceText} <br />
                        <span>cu succes!</span>
                    </Typography>
                    {resourceDetails}
                    <div className="actions">
                        <Link className='link' to={addMoreUrl} style={{textDecoration: 'none'}}>
                            <Button className='outlined-button' size="large" variant="outlined" fullWidth>
                                <Typography className='link-text'>{addMoreText}</Typography>
                            </Button>
                        </Link>
                        {nextText && nextUrl && 
                            <Link className='link' to={nextUrl} style={{textDecoration: 'none'}}>
                                <Button size="large" variant="contained" fullWidth>
                                    <Typography className='link-text'>{nextText}</Typography>
                                </Button>
                            </Link>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

