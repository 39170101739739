import {Button, ButtonBase, CircularProgress, Input, Typography} from '@material-ui/core'
import {Form, Formik} from 'formik'
import React, {Component} from 'react'
import {ChevronLeft, Edit3, Layers, RefreshCw, Save, X} from 'react-feather'

import {isEmpty, parseInt, values} from 'lodash'

import {
    ArchiveProjectDialog,
    DeleteProjectDialog,
    SyncNoSubscriptionsDialog
} from '../components/ProjectDetails/Dialogs'

import ActivityIndicatorComponent from '../components/ActivityIndicatorComponent'
import AppealsSection from '../components/AppealsSection'
import HearingsSection from '../components/HearingsSection'
import PartiesSection from '../components/PartiesSection'
import InfoSection from '../components/ProjectDetails/InfoSection'
import RefreshComponent from '../components/RefreshComponent'
import SubscriptionIsSyncingModal from '../components/SubscriptionIsSyncingModal'

import {litigationsSync} from 'avoapp-react-common/dist/redux/litigations'
import {getCurrentProjectPermissions} from 'avoapp-react-common/dist/redux/projectPermissions'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {findSyncedSubscriptions, getLitigationNumberForSyncingSubscription} from 'avoapp-react-common/dist/utils'
import {connect} from 'react-redux'

import {colors} from '../assets/styles/colors'

import '../assets/scss/ProjectDetails.scss'

class ProjectDetails extends Component {
    constructor() {
        super()

        this.state = {
            editMode: false,
            showDeleteProjectDialog: false,
            showArchiveProjectDialog: false,
            showSyncNoSubscriptionsDialog :false,
            showRefreshComponent: false
        }
    }

    componentDidMount() {
        const {entityProfileID, match} = this.props
        const {projectID} = match.params

        if(projectID && !isNaN(parseInt(projectID))) {   
            this.props.dispatch(RESOURCES.projects.retrieve(projectID))
            this.props.dispatch(RESOURCES.projectPermissions.list({project_id: projectID}))
            this.props.dispatch(RESOURCES.clients.list({project_id: projectID}))
            this.props.dispatch(RESOURCES.subscriptions.list({project_id: projectID}))
            this.props.dispatch(RESOURCES.parties.list({project_id: projectID}))
            this.props.dispatch(RESOURCES.appeals.list({project_id: projectID}))
            this.props.dispatch(RESOURCES.hearings.list({project_id: projectID}))
            
            this.props.dispatch(getCurrentProjectPermissions(parseInt(projectID), entityProfileID))
        }
    }

    handleCloseDeleteProjectDialog = () => {this.setState({showDeleteProjectDialog: false})}

    handleCloseArchiveProjectDialog = () => {this.setState({showArchiveProjectDialog: false})}

    handleCloseSyncNoSubscriptionsDialog = () => {this.setState({showSyncNoSubscriptionsDialog: false})}

    handleGoBack = () => {
        const {routerLocations, project} = this.props
        if(routerLocations.length >= 2) { 
            let prevRoute = routerLocations[routerLocations.length - 2].location
    
            if(
                prevRoute.pathname=== '/projects/add' || 
                prevRoute.pathname === `/projects/${project.id}/add-subscription`
            ) { 
                this.props.history.push('/projects')
            } else if(prevRoute.pathname=== '/projects' ){
                if(project.active) {
                    this.props.history.push('/projects', {projectActive: true})
                } else {
                    this.props.history.push('/projects', {projectActive: false})
                }
            } else {
                this.props.history.goBack()
            }
        } else {
            this.props.history.goBack()
        }
    }

    handleCloseRefreshComponent = () => {this.setState({showRefreshComponent: false})}

    handleSyncLitigation = (subscriptionToSync) => {
        this.props.dispatch(litigationsSync(subscriptionToSync))
        this.setState({showRefreshComponent: false})
    }

    render() {
        const {
            project, 
            subscriptions,
            isLoading,
            isLoadingLitigations,
            showSyncingModal,
            syncingLitigationNumber,
            permissions,
            currentProjectPermissions
        } = this.props
        const {projectID} = this.props.match.params

        const {
            showDeleteProjectDialog,
            showArchiveProjectDialog,
            editMode,
            showSyncNoSubscriptionsDialog,
            showRefreshComponent
        } = this.state

        return (
            <>
                {!isLoading ? (
                    <>
                        <div className="navbar" id="navbar-project-details">
                            {!editMode ? (
                                <>
                                    <div className="navbar-left">
                                        <Button className="back-button" onClick={this.handleGoBack}>
                                            <ChevronLeft className="back-button-icon" size={16} />
                                            <Typography className="back-button-title">Înapoi</Typography>
                                        </Button>
                                        <div className="navbar-title-div">
                                            <Typography className="navbar-title">{project.name}</Typography>
                                            <div className="status-div">
                                                <Typography className="status-text">Status: </Typography>
                                                {project.active ? (
                                                    <div className="active-div">
                                                        <div className="green-circle" />
                                                        <Typography className="active-text">Activ</Typography>
                                                    </div>
                                                ) : (
                                                    <Typography>Inactiv</Typography>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="navbar-right">
                                        <div className="navbar-actions-buttons-div">
                                            {
                                                (permissions.projects.update || 
                                                currentProjectPermissions.permissions.update) && 
                                                <>
                                                    <div className="action-button">
                                                        <Button
                                                            onClick={() => {this.setState({editMode: true})}}
                                                            variant="text"
                                                            fullWidth
                                                        >
                                                            <Typography className="action-button-text">
                                                                Redenumește
                                                            </Typography>
                                                            <Edit3 size={16} className="action-button-icon" />
                                                        </Button>
                                                    </div>
                                                    <div className="action-button">
                                                        {project.active ? (
                                                            <Button
                                                                onClick={() => {
                                                                    this.setState({showArchiveProjectDialog: true})
                                                                }}
                                                                variant="text"
                                                                fullWidth>
                                                                <Typography className="action-button-text">
                                                                    Arhivează
                                                                </Typography>
                                                                <Layers size={16} className="action-button-icon" />
                                                            </Button>
                                                        ) : (
                                                            <Button
                                                                onClick={() => {
                                                                    this.props.dispatch(
                                                                        RESOURCES.projects.update({
                                                                            id: project.id,
                                                                            active: true
                                                                        })
                                                                    )
                                                                }}
                                                                variant="text"
                                                                fullWidth
                                                            >
                                                                <Typography className="action-button-text">
                                                                    Dezarhivează
                                                                </Typography>
                                                                <Layers size={16} className="action-button-icon" />
                                                            </Button>
                                                        )}
                                                        <ArchiveProjectDialog
                                                            showArchiveProjectDialog={showArchiveProjectDialog}
                                                            handleCloseArchiveProjectDialog={
                                                                this.handleCloseArchiveProjectDialog
                                                            }
                                                            handleArchiveProject={() => {
                                                                this.props.dispatch(
                                                                    RESOURCES.projects.update({
                                                                        id: project.id,
                                                                        active: false
                                                                    })
                                                                )
                                                                this.handleCloseArchiveProjectDialog()
                                                            }}
                                                            projectToArchive={project}
                                                        />
                                                    </div>
                                                </>
                                            }
                                            {
                                                (permissions.projects.destroy || 
                                                currentProjectPermissions.permissions.destroy) &&  
                                                <div className="action-button">
                                                    <Button
                                                        onClick={() => {this.setState({showDeleteProjectDialog: true})}}
                                                        variant="text"
                                                        fullWidth
                                                    >
                                                        <Typography className="action-button-text delete-text">
                                                            Șterge
                                                        </Typography>
                                                        <X size={16} className="action-button-icon delete-icon" />
                                                    </Button>
                                                    <DeleteProjectDialog
                                                        showDeleteProjectDialog={showDeleteProjectDialog}
                                                        handleCloseDeleteProjectDialog={
                                                            this.handleCloseDeleteProjectDialog
                                                        }
                                                        handleDeleteProject={() => {
                                                            this.props.dispatch(RESOURCES.projects.destroy(project))
                                                            this.handleCloseDeleteProjectDialog()
                                                        }}
                                                        projectToDelete={project}
                                                    />
                                                </div>
                                            }
                                        </div>
                                        <div className="navbar-main-button-div">
                                            {!isLoadingLitigations ? (
                                                <Button
                                                    onClick={() => {
                                                        if(isEmpty(subscriptions)) {
                                                            this.setState({showSyncNoSubscriptionsDialog: true})
                                                        } else {
                                                            this.setState({showRefreshComponent: true})
                                                        }
                                                    }}
                                                    className="navbar-main-button"
                                                    variant="contained"
                                                    size="large"
                                                    style={{backgroundColor: colors.darkPurple}}
                                                >
                                                    <Typography className="navbar-button-title">
                                                        Sincronizează
                                                    </Typography>
                                                    <RefreshCw className="navbar-button-icon" size={16} />
                                                </Button>
                                            ) : (
                                                <CircularProgress thickness={5} color="primary" />
                                            )}
                                            <SyncNoSubscriptionsDialog
                                                showSyncNoSubscriptionsDialog={showSyncNoSubscriptionsDialog}
                                                handleCloseSyncNoSubscriptionsDialog={
                                                    this.handleCloseSyncNoSubscriptionsDialog
                                                }
                                                project={project}
                                            />
                                            <RefreshComponent
                                                showRefreshComponent={showRefreshComponent}
                                                handleSyncLitigation={this.handleSyncLitigation}
                                                handleCloseRefreshComponent={this.handleCloseRefreshComponent}
                                            />
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div className="navbar-left">
                                    <Formik
                                        initialValues={{
                                            name: project.name,
                                            id: project.id
                                        }}
                                        onSubmit={(values) => {
                                            this.setState({editMode: false})
                                            this.props.dispatch(RESOURCES.projects.update(values))
                                        }}>
                                        {({handleChange, values, handleSubmit}) => (
                                            <Form className="styled-form">
                                                <Input
                                                    placeholder="Nume proiect"
                                                    value={values.name}
                                                    onChange={handleChange('name')}
                                                    className="input"
                                                    fullWidth
                                                />
                                                <div className="form-buttons">
                                                    <ButtonBase 
                                                        onClick={handleSubmit}
                                                        style={{padding: 10}}
                                                    >
                                                        <Save
                                                            className="save-icon-button"
                                                            size={25}
                                                            style={{alignSelf: 'center'}}
                                                        />
                                                    </ButtonBase>
                                                    <ButtonBase
                                                        onClick={() => this.setState({editMode: false})}
                                                        style={{padding: 10}}
                                                    >
                                                        <X
                                                            className="delete-icon-button"
                                                            size={25}
                                                            style={{alignSelf: 'center'}}
                                                        />
                                                    </ButtonBase>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            )}
                        </div>
                        <div id="project-details-main-div">
                            <SubscriptionIsSyncingModal 
                                showModal={showSyncingModal}
                                syncingLitigationNumber={syncingLitigationNumber}
                            />
                            <InfoSection />
                            <PartiesSection projectID={projectID} sectionNumber={2} />
                            <AppealsSection projectID={projectID} sectionNumber={3} />
                            <HearingsSection projectID={projectID} sectionNumber={4} />
                        </div>
                    </>
                ) : (
                    <ActivityIndicatorComponent />
                )}
            </>
        )
    }
}

const mapStateToProps = function (state) {
    let subscriptions = values(state.subscriptions.data)
    let isLoadingSubscriptions = state.subscriptions.isLoading
    let hasSyncedSubscriptions = findSyncedSubscriptions(subscriptions)
    let syncingLitigationNumber = getLitigationNumberForSyncingSubscription(subscriptions)
    
    let showSyncingModal = !isLoadingSubscriptions && !isEmpty(subscriptions) && 
                            !hasSyncedSubscriptions && syncingLitigationNumber

    return {
        project: state.projects.currentProject,
        isLoadingLitigations: state.litigations.isLoading,
        isLoading: state.projects.isLoading,
        subscriptions: subscriptions,
        isLoadingSubscriptions: isLoadingSubscriptions,
        routerLocations: values(state.routerLocations),
        hasSyncedSubscriptions: hasSyncedSubscriptions,
        syncingLitigationNumber: syncingLitigationNumber,
        showSyncingModal: showSyncingModal,
        permissions: state.permissions,
        entityProfileID: state.localConfigs.entityProfileID,
        currentProjectPermissions: state.projectPermissions.currentProjectPermissions
    }
}

export default connect(mapStateToProps)(ProjectDetails)
