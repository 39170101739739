import React, {Component} from 'react'
import {Typography} from '@material-ui/core'
import {Check, X} from 'react-feather'

import {values} from 'lodash'

import {connect} from 'react-redux'
import {CRUDTypes} from 'avoapp-react-common/dist/constants'

import {colors} from '../assets/styles/colors'

import '../assets/scss/PermissionsSelect.scss'

class PermissionsSelect extends Component {    
    giveCreatePermission = (resource, value = !resource.permissions.create) => {
        const {handleGivePermission} = this.props

        if(!resource.createDisabled) {
            handleGivePermission(resource, CRUDTypes.create.name, value)
        }
    }

    giveReadPermission = (resource) => {
        const {handleGivePermission} = this.props

        if(!resource.readDisabled) {
            handleGivePermission(resource, CRUDTypes.read.name, !resource.permissions.read)
        }

        handleGivePermission(resource, CRUDTypes.update.name, false)
        
        if(!resource.destroyDisabled) {
            handleGivePermission(resource, CRUDTypes.destroy.name, false)
        }
    }
    
    giveUpdatePermission = (resource) => {
        const {handleGivePermission} = this.props

        handleGivePermission(resource, CRUDTypes.read.name, true)
        handleGivePermission(resource, CRUDTypes.update.name, !resource.permissions.update)
        if(!resource.destroyDisabled) {
            handleGivePermission(resource, CRUDTypes.destroy.name, false)
        }
    }
    
    giveDestroyPermission = (resource) => {
        const {handleGivePermission} = this.props

        if(!resource.destroyDisabled) {
            handleGivePermission(resource, CRUDTypes.read.name, true)
            handleGivePermission(resource, CRUDTypes.update.name, true)
            handleGivePermission(resource, CRUDTypes.destroy.name, !resource.permissions.destroy)
        }
    }

    render() {
        const {resources} = this.props

        return (
            <>
                <div id='permissions-select'>
                    <div className='row'>
                        <Typography className='resource-label' />
                        <div className="permissions-header">
                            <div className="permission read">
                                <Typography className='permission-text'>Citire</Typography>
                            </div>
                            <div className="permission update">
                                <Typography className='permission-text'>Editare</Typography>
                            </div>
                            <div className="permission destroy">
                                <Typography className='permission-text'>Ștergere</Typography>
                            </div>
                            <div className="permission create">
                                <Typography className='permission-text'>Creare</Typography>
                            </div>
                        </div>
                    </div>
                
                    {values(resources).map((resource, index) => (
                        <div className='row' key={index}>
                            <Typography className='resource-label'>{resource.label}</Typography>
                            <div className="permissions">
                                <div 
                                    className={
                                        'permission read' + 
                                        (resource.permissions.read ? ' selected' : '') +
                                        (resource.readDisabled ? ' disabled' : '')
                                    }
                                    onClick={() => {
                                        if(!resource.readDisabled) { 
                                            this.giveReadPermission(resource)
                                        }
                                    }}
                                >
                                    {resource.readDisabled ? (
                                        <X color={colors.darkGrey} className='icon-disabled'/>
                                    ) : (
                                        <Check color='white' className='icon-selected'/>
                                    )}

                                </div>
                                <div 
                                    className={`permission update ${resource.permissions.update ? 'selected' : ''}`}
                                    onClick={() => {this.giveUpdatePermission(resource)}}
                                >
                                    <Check color='white' className='icon-selected'/>
                                </div>
                                <div
                                    className={
                                        'permission destroy' +
                                        (resource.permissions.destroy ? ' selected' : '') +
                                        (resource.destroyDisabled ? ' disabled' : '')
                                    }
                                    onClick={() => {
                                        if(!resource.destroyDisabled) {
                                            this.giveDestroyPermission(resource)
                                        }
                                    }}
                                >
                                    {resource.destroyDisabled ? (
                                        <X color={colors.darkGrey} className='icon-disabled'/>
                                    ) : (
                                        <Check color='white' className='icon-selected'/>
                                    )}
                                </div>
                                <div 
                                    className={
                                        'permission create' +
                                        (resource.permissions.create ? ' selected' : '') + 
                                        (resource.createDisabled ? ' disabled' : '')
                                    }
                                    onClick={() => {
                                        if(!resource.createDisabled) {
                                            this.giveCreatePermission(resource)
                                        } 
                                    }}
                                >
                                    {resource.createDisabled ? (
                                        <X color={colors.darkGrey} className='icon-disabled'/>
                                    ) : (
                                        <Check color='white' className='icon-selected'/>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </>
        )
    }
}

export default connect()(PermissionsSelect)
