import {Button, InputBase, Tab, Tabs, Typography} from '@material-ui/core'
import React, {Component, useMemo} from 'react'
import {Plus, Search} from 'react-feather'
import InfiniteScroll from 'react-infinite-scroller'
import {Link} from 'react-router-dom'

import {differenceInDays, lightFormat} from 'date-fns'
import {debounce, isEmpty, isNil, isNull, omitBy, orderBy, values} from 'lodash'

import {OrderingTypes} from 'avoapp-react-common/dist/constants'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {changeDateFormat} from 'avoapp-react-common/dist/utils'
import {connect} from 'react-redux'

import {colors} from '../assets/styles/colors'

import Wizard from '../assets/images/wizard.svg'
import ActivityIndicatorComponent from '../components/ActivityIndicatorComponent'
import LoadingMoreComponent from '../components/LoadingMoreComponent'
import SubscriptionActiveStatusPill from '../components/SubscriptionActiveStatusPill'

import '../assets/scss/SubscriptionsList.scss'

const indexMapping = {
    0: 'true',
    1: 'false'
}

export class SubscriptionsList extends Component {
    constructor() {
        super()

        this.state = {
            selectedIndex: 0,
            searchFilter: '',
            isTyping: false
        }
    }

    componentDidMount() {
        const {selectedIndex} = this.state
        
        if(this.props.location.state && !this.props.location.state.subscriptionsActive) {
            this.updateIndex(1)
        } else {
            this.props.dispatch(RESOURCES.subscriptions.list({
                ordering: OrderingTypes.INTERVAL_ASC,
                active: indexMapping[selectedIndex]
            }))
        }
    }

    componentDidUpdate(prevProps) {
        const {selectedEntityID, isLoading} = this.props
        const {selectedIndex, searchFilter} = this.state

        if(prevProps.selectedEntityID !== selectedEntityID && !isLoading) {
            this.props.dispatch(RESOURCES.subscriptions.list({
                search: searchFilter,
                active: indexMapping[selectedIndex],
                ordering: OrderingTypes.INTERVAL_ASC
            }))
        }
    }

    updateIndex = (selectedIndex) => {
        this.setState({selectedIndex, searchFilter: ''})

        this.props.dispatch(RESOURCES.subscriptions.list({
            active: indexMapping[selectedIndex],
            ordering: OrderingTypes.INTERVAL_ASC
        }))
    }

    debounceSearchSubscriptions = debounce((value)=>{
        const {selectedIndex} = this.state

        this.props.dispatch(
            RESOURCES.subscriptions.list({
                search: value,
                active: indexMapping[selectedIndex],
                ordering: OrderingTypes.INTERVAL_ASC
            })
        )

        this.setState({isTyping: false})
    }, 300)

    handleChangeSearchField = (value) => {
        this.setState({searchFilter: value, isTyping: true})
        this.debounceSearchSubscriptions(value)
    }

    loadMoreSubscriptions = () => {
        const {nextPage, isLoading} = this.props

        if(!isNull(nextPage) && !isLoading) {
            this.props.dispatch(RESOURCES.subscriptions.list({
                page: nextPage,
                ordering: OrderingTypes.INTERVAL_ASC
            }))
        }
    }
    
    render() {
        const {selectedIndex, searchFilter, isTyping} = this.state
        const {subscriptions, isLoading, isGettingFirstPage, nextPage} = this.props

        const mainCondition = () => {
            return (
                !isEmpty(subscriptions) ||
                (isEmpty(subscriptions) && indexMapping[selectedIndex] === 'false') ||
                (isEmpty(subscriptions) && !isEmpty(searchFilter))
            )
        }

        return (
            <div>
                <div className="navbar" id="navbar-subscriptions">
                    <div className="navbar-top">
                        <Typography className="navbar-title">Abonări</Typography>
                        <Link to="/subscriptions/add" style={{textDecoration: 'none'}}>
                            <Button
                                size="large"
                                variant="contained"
                                className="navbar-main-button"
                                style={{backgroundColor: colors.success}}
                            >
                                <Typography className="navbar-button-title">Adaugă abonare</Typography>
                                <Plus className="navbar-button-icon" />
                            </Button>
                        </Link>
                    </div>
                    <div className="navbar-bottom">
                        <div className="tabs-and-search-div">
                            <Tabs
                                value={selectedIndex}
                                style={{width: '325px'}}
                                textColor="primary"
                                onChange={(data, index) => {
                                    if (index !== selectedIndex) {
                                        this.updateIndex(index)
                                    }
                                }}
                            >
                                <Tab disableRipple className="tab" label="Active" />
                                <Tab disableRipple className="tab" label="Inactive" />
                            </Tabs>
                            {mainCondition() ? (
                                <div className="search-div">
                                    <div className="search-icon"> 
                                        <Search size={20} /> 
                                    </div>
                                    <InputBase
                                        placeholder="Caută abonări"
                                        value={searchFilter}
                                        className="search-input"
                                        onChange={(event) => 
                                            this.handleChangeSearchField(event.target.value)
                                        }
                                    />
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
                {mainCondition() ? (
                    <div id="subscriptions-list-main-div">
                        {isEmpty(subscriptions) && !isEmpty(searchFilter) ? (
                            <div>
                                <Typography>
                                    Nu s-au găsit abonări care să corespundă căutării tale. Te rugăm schimbă parametrii
                                    de căutare
                                </Typography>
                            </div>
                        ) : isEmpty(subscriptions) && indexMapping[selectedIndex] === 'false' ? (
                            <div className="empty-inactive-subscriptions-list-div">
                                <Typography className="no-subscriptions-title"> Nu există abonări inactive </Typography>
                            </div>
                        ) : !isEmpty(subscriptions) ? (
                            <InfiniteScroll
                                className='cards-container'
                                pageStart={1}
                                loadMore={this.loadMoreSubscriptions}
                                hasMore={!isNull(nextPage)}
                                loader={
                                    <LoadingMoreComponent loadingText='Se încarcă mai multe abonări' key='load-more' />
                                }
                            >
                                {
                                    values(omitBy(subscriptions, ['active', indexMapping[selectedIndex]])).map(
                                        (subscription) => (
                                            <SubscriptionCard subscription={subscription} key={subscription.id} />
                                        )
                                    )
                                }
                            </InfiniteScroll>
                        ) : null}
                    </div>
                ) : (isEmpty(subscriptions) && isTyping) || (isLoading && isGettingFirstPage) ? (
                    <ActivityIndicatorComponent />
                ) : isEmpty(subscriptions) && indexMapping[selectedIndex] === 'true' ? (
                    <EmptyActiveSubscriptionsListComponent />
                ) : null}
            </div>
        )
    }
}

function SubscriptionCard({subscription}) {
    const lastUpdateDate = useMemo(() => {
        const {update_moment_md} = subscription.project
        
        if (!isNil(update_moment_md)) {
            return lightFormat(new Date(update_moment_md), 'dd/MM/yyyy HH:mm')
        }

        return '-'
    }, [subscription])

    const hearingDate = useMemo(() => {
        const hearingDate = subscription?.next?.hearing?.date ?? subscription?.previous?.hearing?.date ?? null

        if(!isNull(hearingDate)) {
            const difference = differenceInDays(new Date(hearingDate), new Date(new Date().toDateString()))
                
            if(difference === -1) return 'Soluţionat ieri'
            if(difference < 0) return `Soluţionat acum ${difference * -1 } zile`
            if(difference === 0) return 'Şedinţă astăzi'
            if(difference === 1) return 'Şedinţă mâine'
                
            return `Şedinţă în ${difference} zile`
        }

        return null
    }, [subscription])
    
    return (
        <Link 
            key={subscription.id} 
            style={{textDecoration: 'none'}} 
            to={'/subscriptions/' + subscription.id}
            className="card"
        >
            <div className="card-header">
                <div className="texts-container">
                    <Typography className="card-title">{subscription.litigation_number}</Typography>
                    {!isNull(hearingDate) && (
                        <Typography className='card-helper-text'>{hearingDate}</Typography>
                    )}
                </div>
                <SubscriptionActiveStatusPill subscription={subscription}/>
            </div>
            <div className="card-content">
                <div className="card-content-child">
                    <Typography className="title"> Proiect: </Typography>
                    <Typography className="subtitle">
                        {subscription.project.name}
                    </Typography>
                </div>
                {subscription.next ? (
                    <>
                        <div className="card-content-child">
                            <Typography className="title"> Următorul termen: </Typography>
                            <Typography className="subtitle">
                                {changeDateFormat(subscription.next.hearing.date)}
                            </Typography>
                        </div>
                        <div className="card-content-child">
                            <Typography className="title"> Instanța: </Typography>
                            <Typography className="subtitle" noWrap>
                                {subscription.next.phase.court} - {subscription.next.hearing.full_court}
                            </Typography>
                        </div>
                    </>
                ) : !subscription.next && subscription.previous ? (
                    <div className="card-content-child">
                        <Typography className="title"> Ultimul termen: </Typography>
                        <Typography className="subtitle">
                            {changeDateFormat(subscription.previous.hearing.date)}
                        </Typography>
                    </div>
                ) : null}
                <div className="card-content-child">
                    <Typography className="title"> Ultima modificare: </Typography>
                    <Typography className="subtitle">
                        {lastUpdateDate}
                    </Typography>
                </div>
            </div>
        </Link>
    )
}

function EmptyActiveSubscriptionsListComponent() {
    return (
        <div id="empty-projects-list-main-div">
            <img src={Wizard} alt="wizard" />
            <Typography className="no-project-title">Nicio abonare activă</Typography>
            <Typography className="no-project-text">
                Deși ne dorim, nu putem ghici dosarul pe care dorești să îl monitorizezi.
            </Typography>
        </div>
    )
}

const mapStateToProps = (state) => ({
    subscriptions: orderBy(
        state.subscriptions.data,
        [(subscription) => subscription.interval ? parseInt(subscription.interval) : null, 'id'],
        ['asc', 'desc']
    ),
    nextPage: state.subscriptions.next,
    isLoading: state.subscriptions.isLoading,
    isGettingFirstPage: state.subscriptions.isGettingFirstPage,
    selectedEntityID: state.localConfigs.selectedEntityID,
    permissions: state.permissions

})

export default connect(mapStateToProps)(SubscriptionsList)
