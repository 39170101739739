import {Button, Typography} from '@material-ui/core'
import React, {Component} from 'react'
import {Edit3, Plus} from 'react-feather'
import {Link} from 'react-router-dom'

import {isEmpty, orderBy, values} from 'lodash'

import {
    changeDateFormat,
    changeTimeFormat,
    findManualSubscriptions,
    findSyncedSubscriptions,
    getLitigationNumberForSyncingSubscription
} from 'avoapp-react-common/dist/utils'
import {connect} from 'react-redux'

import SelectPhaseModal from './SelectPhaseModal'
import SubscriptionIsSyncingModal from './SubscriptionIsSyncingModal'

class HearingsSection extends Component {
    constructor() {
        super()

        this.state = {
            showSelectPhaseModal: false
        }
    }

    render() {
        const {
            subscriptionID,
            hearings, 
            permissions,
            hasSyncedSubscriptions, 
            syncingLitigationNumber,
            currentProjectPermissions,
            hasManualSubscriptions,
            sectionNumber,
            addingAllowed
        } = this.props
        
        const {showSelectPhaseModal} = this.state
        
        return (
            <div className="section hearings-section">
                <div className="section-header">
                    <Typography className="section-title">{sectionNumber}. TERMENE</Typography>
                    {
                        (permissions.projects.update || currentProjectPermissions.permissions.update) && 
                            addingAllowed && hasManualSubscriptions &&
                                <>
                                    <div className="section-header-buttons-container">
                                        {subscriptionID && (
                                            <Link to={`/subscriptions/${subscriptionID}/add-manual-phase`}>
                                                <Button className="navbar-main-button" variant="contained" size="small">
                                                    <Plus className="navbar-button-icon" />
                                                    <Typography className="navbar-button-title">Adaugă fază</Typography>
                                                </Button>
                                            </Link>
                                        )}
                                        <Button
                                            onClick={() => this.setState({showSelectPhaseModal: true})}
                                            className="navbar-main-button"
                                            variant="contained"
                                            size="small"
                                        >
                                            <Plus className="navbar-button-icon" />
                                            <Typography className="navbar-button-title">Adaugă termene</Typography>
                                        </Button>
                                    </div>
                                    <SelectPhaseModal
                                        showSelectPhaseModal={showSelectPhaseModal}
                                        handleCloseSelectPhaseModal={() => this.setState({showSelectPhaseModal: false})}
                                        getNextStepUrl = {(phaseID) => `/phases/${phaseID}/add-manual-hearing`}
                                        resource='termene'
                                    />
                                </>
                    }
                </div>
                <div className="section-content">
                    {!isEmpty(hearings) && hasSyncedSubscriptions ? (
                        <div className='cards-container'> 
                            {hearings.map((hearing) => (
                                <div key={hearing.id} className="card">
                                    <div className="hearings-card-left">
                                        <div className="card-header">
                                            <Typography className="card-title">
                                                {changeDateFormat(hearing.date)}
                                            </Typography>
                                            <div className='right'>
                                                <div className="label phase-label">
                                                    <Typography className='label-text'>
                                                        {hearing.phase.phase}
                                                    </Typography>
                                                </div>
                                                <div className="label">
                                                    <Typography className='label-text'>
                                                        {hearing.phase.number}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-content">
                                            <div className="card-content-child">
                                                <div className="title-div">
                                                    <Typography className="title">Instanța</Typography>
                                                </div>
                                                <div className="subtitle-div">
                                                    <Typography className="subtitle">
                                                        {hearing.phase.court}
                                                    </Typography>
                                                </div>
                                            </div>
                                            {hearing.full_court ? (
                                                <div className="card-content-child">
                                                    <div className="title-div">
                                                        <Typography className="title">Complet</Typography>
                                                    </div>
                                                    <div className="subtitle-div">
                                                        <Typography className="subtitle">
                                                            {hearing.full_court}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            ) : null}
                                            <div className="card-content-child">
                                                <div className="title-div">
                                                    <Typography className="title">Ora</Typography>
                                                </div>
                                                <div className="subtitle-div">
                                                    <Typography className="subtitle">
                                                        {changeTimeFormat(hearing.time)}
                                                    </Typography>
                                                </div>
                                            </div>
                                            {hearing.document_date ? (
                                                <div className="card-content-child">
                                                    <div className="title-div">
                                                        <Typography className="title">
                                                            Data document
                                                        </Typography>
                                                    </div>
                                                    <div className="subtitle-div">
                                                        <Typography className="subtitle">
                                                            {changeDateFormat(hearing.document_date)}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            ) : null}
                                            {hearing.pronouncement_date ? (
                                                <div className="card-content-child">
                                                    <div className="title-div">
                                                        <Typography className="title">
                                                            Data ședință
                                                        </Typography>
                                                    </div>
                                                    <div className="subtitle-div">
                                                        <Typography className="subtitle">
                                                            {changeDateFormat(
                                                                hearing.pronouncement_date
                                                            )}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            ) : null}
                                            {hearing.hearing_document ? (
                                                <div className={'card-content-child hearing-document-child'}>
                                                    <div className="title-div">
                                                        <Typography className="title">
                                                            Document ședință
                                                        </Typography>
                                                    </div>
                                                    <div className="subtitle-div">
                                                        <Typography className="subtitle">
                                                            {hearing.hearing_document}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            ) : null}
                                            {hearing.solution ? (
                                                <div className="card-content-child">
                                                    <div className="title-div">
                                                        <Typography className="title">
                                                            Soluție
                                                        </Typography>
                                                    </div>
                                                    <div className="subtitle-div">
                                                        <Typography className="subtitle">
                                                            {hearing.solution}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="hearings-card-right">
                                        <div className="header">
                                            {(permissions.projects.update || 
                                                currentProjectPermissions.permissions.update) && 
                                                hearing.is_manual &&
                                                <div className='edit-div'>
                                                    <Link to={`/hearings/${hearing.id}/edit`}>
                                                        <Edit3 size={16} className='edit-icon' />
                                                    </Link>
                                                </div>
                                            }
                                        </div>
                                        {hearing.solution_summary ? (
                                            <div className="card-content-child">
                                                <div className="title-div">
                                                    <Typography className="title">
                                                        Sumar soluție
                                                    </Typography>
                                                </div>
                                                <div className="subtitle-div">
                                                    <Typography className="subtitle">
                                                        {hearing.solution_summary}
                                                    </Typography>
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : syncingLitigationNumber && !hasSyncedSubscriptions ? (
                        <SubscriptionIsSyncingModal syncingLitigationNumber={syncingLitigationNumber} />
                    ) : (
                        <Typography className='no-data-text'>Nu există  niciun termen</Typography>
                    )}
                </div>
            </div>
        )
    }
}

const mapStateToProps = function (state, props) {
    const {projectID, subscriptionID} = props
    
    let subscriptions 

    if(projectID) {
        subscriptions = values(state.subscriptions.data)
    } else if(subscriptionID) {
        subscriptions = [state.subscriptions.currentSubscription]
    }

    let hasManualSubscriptions = findManualSubscriptions(subscriptions)
    let hasSyncedSubscriptions = findSyncedSubscriptions(subscriptions)
    let syncingLitigationNumber = getLitigationNumberForSyncingSubscription(subscriptions)

    return {
        subscriptions: subscriptions,
        permissions: state.permissions,
        hasManualSubscriptions: hasManualSubscriptions,
        hasSyncedSubscriptions: hasSyncedSubscriptions,
        syncingLitigationNumber: syncingLitigationNumber,
        hearings: orderBy(state.hearings.data, 'moment', 'desc'),
        currentProjectPermissions: state.projectPermissions.currentProjectPermissions
    }
}

export default connect(mapStateToProps)(HearingsSection)