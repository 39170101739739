import React, {Component} from 'react'
import {Typography} from '@material-ui/core'

import '../assets/scss/DownloadAppScreen.scss'

export default class DownloadAppScreen extends Component {
    render() {
        return (
            <div id='download-app'>
                <div className="container">
                    <img 
                        className='md-logo'
                        src={require('../assets/images/monitor_dosare_logo_light.svg').default}
                        alt="" 
                    />
                    <Typography className='text'>
                        Pentru o experianță mai bună instalează acum și începe 
                        <span> monitorizarea dosarelor </span> de instanță! 
                    </Typography>
                    <div className="buttons"><a
                        href="https://apps.apple.com/tt/app/monitor-dosare/id1498634602?ign-mpt=uo%3D2"
                        rel='noopener noreferrer'
                        target="_blank" 
                    >
                        <img 
                            alt="" 
                            style={{width: '100%', height: 'auto'}}
                            src={require('../assets/images/app_store_dowload.svg').default}
                        />
                    </a>
                        <a
                            href="https://play.google.com/store/apps/details?id=ro.monitordosare"
                            rel='noopener noreferrer'
                            target="_blank" 
                        >
                            <img 
                                style={{width: '100%', height: 'auto'}}
                                src={require('../assets/images/google_play_download.svg').default}
                                alt="" 
                            />
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}
