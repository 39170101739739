import {put, select, takeEvery} from 'redux-saga/effects'
import {toast} from 'react-toastify'

import {head, isUndefined} from 'lodash'

import {checkEntityPlan} from 'avoapp-react-common/dist/redux/entityPlans'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

const {promoCodeUsages: resource} = RESOURCES

const ACTIONS = {
    OPEN_MODAL: 'OPEN_PROMO_MODAL',
    CLOSE_MODAL: 'CLOSE_PROMO_MODAL'
}

const initialState = {
    isModalOpen: false
}

export function promoModalReducer(state = initialState, action = {}) {
    switch (action.type) {
        case 'OPEN_PROMO_MODAL':
            return {
                isModalOpen: true
            }
        case 'CLOSE_PROMO_MODAL':
            return {
                isModalOpen: false
            }
        default:
            return state
    }
}

function* handleCreatePromoCodeUssageSuccess(response) {
    let selectedPlan 
    let selectedInterval 
    let selectedEntityID

    yield select((state) => {
        selectedPlan = state.billing.data.selectedPlan
        selectedInterval = state.billing.data.selectedInterval
        selectedEntityID = state.localConfigs.selectedEntityID
    })

    if (!isUndefined(selectedInterval)) {
        yield put(checkEntityPlan({
            entity_id: selectedEntityID,
            plan_id: selectedPlan.id,
            charging_interval_in_months: selectedInterval.charging_interval_in_months
        }))
    } else {
        yield put(checkEntityPlan({
            entity_id: selectedEntityID,
            plan_id: selectedPlan.id,
            charging_interval_in_months: head(selectedPlan.duration_discounts).charging_interval_in_months
        }))
    }

    yield put(closePromoModal())
    toast.success('Codul de reducere a fost aplicat cu success!')
}

export function* saga() {
    yield takeEvery(resource.actions.create.success, handleCreatePromoCodeUssageSuccess)
}

export const openPromoModal = () => {
    return({
        type: ACTIONS.OPEN_MODAL
    })
}

export const closePromoModal = () => { 
    return({
        type: ACTIONS.CLOSE_MODAL
    })
}
